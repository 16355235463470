import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// Custom hook that abstracts the logic for transforming the dynamic text
export function useDynamicText() {
  const dynamicText = useSelector((state) => state?.pageTextReducer?.data);
  const [text, setText] = useState(null);

  useEffect(() => {
    const objectWithText = dynamicText?.reduce((curr, { id, text }) => {
      curr[`text_${id}`] = text;
      return curr;
    }, {});

    setText(objectWithText);
  }, [dynamicText]);

  return text;
}
