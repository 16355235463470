import React from "react";
import FreeResizing from "../../assets/svgs/FreeResizing";
import ConfictFresSvg from "../../assets/svgs/ConfictFresSvg";
import HassleReturnSvg from "../../assets/svgs/HassleReturnSvg";
import WarrantSvg from "../../assets/svgs/WarrantSvg";
import useMediaQuery from "../../Hooks/useMeadiaQuery";
import { Link } from "react-router-dom";

const DealBreakerSection = () => {
  const isAboveDesktop = useMediaQuery("(min-width: 768px)");
  return (
    <div className="summary-sec" style={{ paddingBottom: "20px" }}>
      <div
        style={{
          textAlign: "center",
          fontSize: "24px",
          marginBottom: "20px",
        }}
      >
        NO DEALBREAKERS
      </div>
      <div className="row">
        <div
          className="col-md-3 col-6 text-center"
          style={{
            borderRight: "2px solid black",
          }}
        >
          <Link to={"/our-policy#lifetime"}>
            <div
              style={{
                margin: "16px 0px",
              }}
            >
              <WarrantSvg />
            </div>
            <h6 className="text-black">Life time Warranty</h6>
          </Link>
        </div>
        <div
          className="col-md-3 col-6 text-center"
          style={{
            borderRight: isAboveDesktop ? "2px solid black" : "none",
          }}
        >
          <Link to={"/our-policy#returns"}>
            <div
              style={{
                margin: "16px 0px",
              }}
            >
              <HassleReturnSvg />
            </div>
            <h6 className="text-black">Hassle returns</h6>
          </Link>
        </div>
        <div
          className="col-md-3 col-6 text-center"
          style={{
            borderRight: "2px solid black",
          }}
        >
          {/* <img
          src={imageURL.security}
          className="img-fluid"
          alt="Free Shipping"
        /> */}
          <Link to={"/our-policy#crafting"}>
            <div
              style={{
                margin: "16px 0px",
              }}
            >
              <FreeResizing />
            </div>
            <h6 className="text-black">Free resizing</h6>
          </Link>
        </div>
        <div className="col-md-3 col-6 text-center">
          {/* <img
          src={imageURL.security}
          className="img-fluid"
          alt="Free Shipping"
        /> */}
          <Link to={"/our-policy#dimonds"}>
            <div
              style={{
                margin: "16px 0px",
              }}
            >
              <ConfictFresSvg />
            </div>
            <h6 className="text-black">Conflict free diamonds</h6>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DealBreakerSection;
