import React from "react";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { imageURL2 } from "../../images";
import Footer from "../element/Footer";

const PerfactRing = () => {
  return (
    <>
      <Container className="page-title text-start">
        <Breadcrumb>
          <LinkContainer to="/" className="link-breadcrumb">
            <Breadcrumb.Item>Home</Breadcrumb.Item>
          </LinkContainer>
          <Breadcrumb.Item active>Perfect Engagement Ring</Breadcrumb.Item>
        </Breadcrumb>
      </Container>

      {/* Begin:: Hero section */}
      <Container className="ringStyle-hero">
        <Row className="justify-content-between align-items-center">
          <Col lg="5" md="6">
            <h1>
              Finding the Perfect <br /> Engagement Ring
            </h1>
            <h4 className="mt-4 mb-lg-5 mb-4">
              A Complete Guide to Engagement Rings with Lab Grown Diamonds
            </h4>
          </Col>
          <Col lg="6" md="6" className="text-center d-none d-md-block">
            <img src={imageURL2.image_106} className="img-fluid" alt="Images" />
          </Col>
        </Row>
      </Container>
      {/* Begin:: Hero section */}

      {/* Begin:: History of engagement ring */}
      <Container className="mt-70 history-box">
        <Row className="gx-lg-5 align-items-center">
          <Col lg="4" className="d-none d-lg-block">
            <img
              src={imageURL2.image_107}
              className="img-fluid rounded-2"
              alt="Images"
            />
          </Col>
          <Col lg="8">
            <h2 className="heading-55 mb-lg-5 mb-4 fst-italic">
              Engagement Rings, A Symbol of Love
            </h2>
            <p className="h5">
              Whether you choose a traditional engagement ring or a modern one,
              it is a perfect representation of the love that you share with
              your significant other. It will last a lifetime as a reminder of
              your commitment to one another.
              <br /> <br />
              Whether you choose a traditional engagement ring or a modern one,
              it is a perfect representation of the love that you share with
              your significant other. It will last a lifetime as a reminder of
              your commitment to one another.
            </p>
          </Col>
        </Row>
      </Container>
      {/* End:: History of engagement ring */}

      <Container className="mt-80">
        <h2 className="mb-4 fw-bold">Tips to buying an engagement ring</h2>
        <p>
          If you’re looking to get engaged soon, you probably know the pressure
          that picking out the perfect engagement ring has on both partners.
        </p>
        <p>So, how can you pick the perfect engagement ring?</p>
        <p>Follow these tips:</p>
        <ul>
          <li className="mb-4">
            <h4 className="text-primary">Know your budget</h4>
            <p>
              Figure out how much you are ready to spend on a beautiful
              engagement ring, and on your partner. It doesn’t have to be a huge
              diamond, it just has to be the perfect one for them.
            </p>
          </li>
          <li className="mb-4">
            <h4 className="text-primary">Do your research</h4>
            <p>
              Before going to a jeweler, do some research on your own so you can
              know what you are looking for. Understand the 4Cs of carat,
              clarity, color, and cut. It will equip you with all the knowledge
              before you actually walk into the store to buy your engagement
              ring.
            </p>
          </li>
          <li className="mb-4">
            <h4 className="text-primary">Prioritize</h4>
            <p>
              It is always best to prioritize your finances. Ask yourself a few
              questions, such as, “Would I increase the budget due to a
              particular characteristic of a ring?”, or “Can I alter the
              timeline of another financial goal?” This will help you make a
              smart buying decision.
            </p>
          </li>
          <li className="mb-4">
            <h4 className="text-primary">Learn about the 4Cs of Diamonds</h4>
            <p>
              If you are looking to purchase a piece of diamond jewelry, either
              natural or lab grown, you must remember that the quality of a
              diamond is actually determined by its 4Cs, including Cut, Clarity,
              Color, and Carat.
            </p>
            <p>
              Without finding out the right balance between these 4Cs, you might
              end up overpaying for a few of these characteristics while failing
              to spend enough on others. Following the given recommendations,
              you will be able to find the perfect balance of value and quality
              in your purchase.
            </p>
          </li>
        </ul>
      </Container>

      {/* Shop by shape */}
      <Container fluid className="bg-secondary mt-80 shopbyShape">
        <Container>
          <h2 className="heading-55 mb-5 pb-lg-4 text-center text-white">
            Shopping for a Diamond by Shape
          </h2>

          <Row className="text-center gx-lg-5 justify-content-center">
            <Col sm="6" md="4" lg="3" className="mb-4">
              <img
                src={imageURL2.image_94}
                className="img-fluid"
                alt="Images"
              />
              <h5 className="text-white fw-bold mt-2">Round</h5>
              <p className="text-white-50">
                {" "}
                This is the most common shape of a diamond and currently the
                most popular choice for engagement rings. This dazzling shape
                has proven to be really timeless and versatile.{" "}
              </p>
            </Col>
            <Col sm="6" md="4" lg="3" className="mb-4">
              <img
                src={imageURL2.image_95}
                className="img-fluid"
                alt="Images"
              />
              <h5 className="text-white fw-bold mt-2">Oval</h5>
              <p className="text-white-50">
                A gleaming twist on the classic round shape, oval diamonds are
                preferred by people for its illusion of a larger size. They are
                remarkable and elegant.
              </p>
            </Col>
            <Col sm="6" md="4" lg="3" className="mb-4">
              <img
                src={imageURL2.image_97}
                className="img-fluid"
                alt="Images"
              />
              <h5 className="text-white fw-bold mt-2">Cushion Cut</h5>
              <p className="text-white-50">
                These diamonds have a greater rectangular or angular square
                shape. They are a romantic, timeless choice.
              </p>
            </Col>
            <Col sm="6" md="4" lg="3" className="mb-4">
              <img
                src={imageURL2.image_98}
                className="img-fluid"
                alt="Images"
              />
              <h5 className="text-white fw-bold mt-2">Pear</h5>
              <p className="text-white-50">
                Featuring a rounded edge, these diamonds form a symmetrical tear
                drop shape. This elongated shape gives a distinctive glare.
              </p>
            </Col>
            <Col sm="6" md="4" lg="3" className="mb-4">
              <img
                src={imageURL2.image_99}
                className="img-fluid"
                alt="Images"
              />
              <h5 className="text-white fw-bold mt-2">Emerald</h5>
              <p className="text-white-50">
                They are treasured for their striking outline and elegance. The
                rectangular facets offer a fine-drawn reflective shimmer.
              </p>
            </Col>
            <Col sm="6" md="4" lg="3" className="mb-4">
              <img
                src={imageURL2.image_100}
                className="img-fluid"
                alt="Images"
              />
              <h5 className="text-white fw-bold mt-2">Radiant</h5>
              <p className="text-white-50">
                This shape combines the elegant glamor of the emerald shape with
                the brilliance and spark of the round diamond.
              </p>
            </Col>
            <Col sm="6" md="4" lg="3" className="mb-4">
              <img
                src={imageURL2.image_101}
                className="img-fluid"
                alt="Images"
              />
              <h5 className="text-white fw-bold mt-2">Princess</h5>
              <p className="text-white-50">
                They are very popular because of their contemporary sparkle and
                style. Featuring a square shape, they possess four pointed
                corners and feature more than 70 facets, resulting in amazing
                sparkle.
              </p>
            </Col>
            <Col sm="6" md="4" lg="3" className="mb-4">
              <img
                src={imageURL2.image_102}
                className="img-fluid"
                alt="Images"
              />
              <h5 className="text-white fw-bold mt-2">Asscher</h5>
              <p className="text-white-50">
                Asscher cut diamonds offer a brilliant shimmer and feature a
                square shape with cut out corners.
              </p>
            </Col>
            <Col sm="6" md="4" lg="3" className="mb-4">
              <img
                src={imageURL2.image_103}
                className="img-fluid"
                alt="Images"
              />
              <h5 className="text-white fw-bold mt-2">Heart</h5>
              <p className="text-white-50">
                The ultimate symbol of love, heart-shaped diamonds tend to
                sparkle with two symmetrical halves, forming a romantic and bold
                look.
              </p>
            </Col>
            <Col sm="6" md="4" lg="3" className="mb-4">
              <img
                src={imageURL2.image_104}
                className="img-fluid"
                alt="Images"
              />
              <h5 className="text-white fw-bold mt-2">Marquise</h5>
              <p className="text-white-50">
                These diamonds are pointed on the ends and curved in the middle.
                It creates a larger surface area as compared to other diamond
                shapes.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>

      {/* Begin:: Ring Settings*/}
      <Container className="mt-100">
        <h2 className="fw-bold mb-4">
          How to Choose the Perfect Setting for your Engagement Ring
        </h2>
        <p>
          Choosing a setting for your diamond engagement ring is something that
          you will likely devote most of your time to, given the multitude of
          options that are available. In order to select a ring setting, you’ll
          need to choose a style, ring size, metal type, and metal color.
        </p>
        <p>
          The best way to choose a ring setting is to first find the right
          style. Here, finding an online jeweler, like Andor, could prove to be
          a great decision as you get to see a wide variety of ring styles. We
          have a multitude of ring styles available.
        </p>
        <p>
          To select the perfect ring, consider what exactly tends to fit your
          lifestyle and personality. Your budget, along with your personal style
          and everyday activities, are all key considerations which will guide
          your selection of a ring setting.
        </p>
        <p>To learn more about choosing the best metal type and color.</p>

        <div className="mt-70 perfact-ring-points">
          <h2 className="text-primary fw-bold text-start mb-3">
            Finding Your Partner’s Ring Size
          </h2>
          <p className="mb-lg-5 mb-4">
            Our experts at Andor are often asked how they can find the right
            ring size for their partner without letting them know. Here are a
            few tricks and tips to use!
          </p>

          <h2 className="text-primary fw-bold text-start mb-3">Be subtle</h2>
          <p className="mb-lg-5 mb-4">
            If you wish to keep your proposal a surprise, but still want to find
            the right ring size, you can ask your significant other in a smooth
            way. You can pretend to be buying a ring for your
            niece/mother/sister/aunt or anyone else. Casually ask for her ring
            size for reference!
          </p>

          <h2 className="text-primary fw-bold text-start mb-3">
            Ask Their friends
          </h2>
          <p className="mb-lg-5 mb-4">
            This is the safest way to find out your partner’s right ring size.
            Friends know the best and have the perfect insight! Even if the
            friend doesn’t know directly, he or she can be the one to ask your
            partner.
          </p>

          <h2 className="text-primary fw-bold text-start mb-3">
            Borrow one of their rings
          </h2>
          <p className="mb-lg-5 mb-4">
            Snag one of their rings for a day! Make sure that you take one which
            your significant other wears on the same finger.
          </p>

          <h2 className="text-primary fw-bold text-start mb-3">
            Trace the Ring on Paper
          </h2>
          <p className="mb-lg-5 mb-4">
            Place their ring on a piece of paper and trace a circle on the
            inside and outside of the ring. Repeat the process to ensure that
            your ring traces look consistent. It’s easy to understand the
            diameter of their ring from your drawing!
          </p>

          <h2 className="text-primary fw-bold text-start mb-3">
            Use Our Printable Ring Sizer
          </h2>
          <p className="mb-lg-5 mb-4">
            If you’re shopping together, then it doesn’t have to be a big
            secret! You can download our printable ring sizer to find the right
            size. There are two options, one for if you have a ring that fits,
            and one if you don’t. {/* Download the printable sizer here. */}
          </p>

          <h2 className="text-primary fw-bold text-start mb-3">
            How to Care for your Engagement Ring
          </h2>
          <p className="mb-lg-5 mb-4">
            It goes without saying that your engagement ring is an important
            piece of jewelry, and you never want anything to happen to it.
            Proper care of your ring is extremely important since it’s something
            that will stay with you for a lifetime. Here are a few
            recommendations to help you keep your engagement ring as good as
            new.
          </p>

          <h2 className="text-primary fw-bold text-start mb-3">
            Ring Cleansing
          </h2>
          <p className="mb-lg-5 mb-4">
            You can clean your lab grown diamond jewelry the same way that you
            do most of your fine jewelry. One simple method is to use a little
            dish soap and warm water and gently scrub your jewelry with a soft
            cloth or soft bristle tooth brush. Rinse it clean and then let it
            dry on a soft cloth. Another options is to use an ultrasonic jewelry
            cleaner. If you need more professional cleaning or repairs, please
            contact us.
          </p>

          <h2 className="text-primary fw-bold text-start mb-3">
            Refresh Metal Setting
          </h2>
          <p className="mb-lg-5 mb-4">
            Over time, the metal on your ring can get tarnished due to moisture
            and oxygen coming into contact with it. If this happens you can have
            the ring professionally re-polished. If this is something you would
            like to do, please contact us.
          </p>

          <h2 className="text-primary fw-bold text-start mb-3">
            Remove Ring During Heavy Activities
          </h2>
          <p className="mb-lg-5 mb-4">
            Doing heavy activities such as gardening, moving furniture, lifting
            weights, and manual labor could cause your engagement ring to get
            damaged. It is always best to remove your ring before you do such
            activities.
          </p>
        </div>
      </Container>
      {/* End:: Ring Settings*/}

      <Footer />
    </>
  );
};

export default PerfactRing;
