import React from "react";
import { Field, ErrorMessage } from "formik";
import classNames from "classnames";
import FileUpload from "./file-upload";

const FormField = ({
  type,
  name,
  label,
  size,
  required,
  extraClass,
  disabled,
  options,
  errors,
  touched,
  values,
  handleChange,
  extraText,
  placeholder,
}) => {
  return (
    <div className={`mb-3 ${size} ${extraClass}`}>
      <label htmlFor={name} className="form-label ">
        {label}
        {required && <span className="text-danger">*</span>}
      </label>
      {type === "select" ? (
        <>
          {" "}
          <Field
            as="select"
            name={name}
            className={classNames("form-control form-select", {
              "error-border": errors[name] && touched[name],
            })}
            placeholder={placeholder ?? label}
            value={values?.[name]}
            onChange={handleChange}
            // handleChange={handleChange}
            disabled={disabled}
          >
            <option value="" label="Select option" />
            {options?.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Field>
          {extraText && <span className="gray-color">{extraText}</span>}
        </>
      ) : type === "file" ? (
        <FileUpload
          name="fileUpload"
          onChange={handleChange}
          errors={errors}
          touched={touched}
          disabled={disabled}
        />
      ) : type === "textarea" ? (
        <>
          <Field
            rows="5"
            as="textarea"
            name={name}
            className={classNames("form-control", {
              "error-border": errors[name] && touched[name],
            })}
            value={values?.[name]}
            onChange={handleChange}
            placeholder={placeholder ?? label}
            disabled={disabled}
          />
          {extraText && <span className="gray-color">{extraText}</span>}
        </>
      ) : (
        <>
          <Field
            type={type}
            name={name}
            onChange={handleChange}
            className={classNames("form-control", {
              "error-border": errors[name] && touched[name],
            })}
            value={values?.[name]}
            placeholder={placeholder ?? label}
            disabled={disabled}
          />
          {extraText && <span className="gray-color">{extraText}</span>}
        </>
      )}
      <ErrorMessage name={name} component="div" className="text-danger" />
    </div>
  );
};

export default FormField;
