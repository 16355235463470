import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getHomeReview } from "../../constants/urls";
const intitalState = {
  success: false,
  error: false,
  data: null,
  loading: false,
};

export const getPageReview = createAsyncThunk("page/reviews", async (url) => {
  const res = await axios.get(`${getHomeReview}`);
  const data = await res.data;
  return data;
});

export const getHomeReviewSlice = createSlice({
  name: "HomeReview",
  initialState: intitalState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPageReview.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getPageReview.fulfilled, (state, action) => {
      state.data = action.payload.reviews;
      state.loading = false;
      state.success = true;
    });

    builder.addCase(getPageReview.rejected, (state, action) => {
      state.error = true;
      state.errorMsg = action.error.message;
      state.loading = false;
      state.success = false;
    });
  },
});

export default getHomeReviewSlice.reducer;
