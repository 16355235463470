import React, { useEffect, useState } from "react";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import formatCurrency from "../../helper/currencyFormater";
import { useDispatch, useSelector } from "react-redux";
import {
  getBraceletJewelry,
  getEarringJewelry,
  getNecklessJewelry,
  getRingsJewelry,
} from "../../features/jewelry/jewelrySlicer";
import {
  newTransformProductData,
  transformProductData,
} from "../../constants/function";
import ImageSliderNew from "../Slider/imageSlidernew";

const RelatedProduct = () => {
  const dispatch = useDispatch();
  const earringData = useSelector((state) => state.jewelryReducer.earrings);
  const braceletData = useSelector((state) => state.jewelryReducer.bracelet);
  const ringData = useSelector((state) => state.jewelryReducer.rings);
  const necklessData = useSelector((state) => state.jewelryReducer.neckless);
  const userdata = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    dispatch(getEarringJewelry(userdata?.id));
  }, []);

  const [countryCurrency, setCountryCurrency] = useState("INR");

  useEffect(() => {
    setCountryCurrency(localStorage.getItem("currency"));
  }, []);

  const [listOfEarring, setListOfEarring] = useState([]);
  const [listOfBracelet, setListOfBracelet] = useState([]);
  const [listOfRing, setListOfRing] = useState([]);
  const [listOfNeckless, setListOfNeckless] = useState([]);

  useEffect(() => {
    if (!!earringData?.data) {
      const data = earringData?.data?.data.map((data) => {
        const produtInfo = data?.product_variation
          ? transformProductData(data, countryCurrency)
          : newTransformProductData(data, countryCurrency);
        return {
          // ...data,
          ...produtInfo,
          // id: data.id,
          // title: data.title,
          // type: "Collection",
          // media_arr: data?.media_arr,
          // sku: data?.sku,
          // countryCurrency,
          // product_price:
          //   countryCurrency == "INR"
          //     ? formatCurrency(data?.product_price)
          //     : countryCurrency == "USD"
          //     ? formatCurrency(data?.usa_price)
          //     : countryCurrency == "GBP"
          //     ? formatCurrency(data?.uk_price)
          //     : formatCurrency(data?.usa_price),
        };
      });
      setListOfEarring(data);
    }
  }, [earringData.data]);
  useEffect(() => {
    if (!!braceletData?.data) {
      const data = braceletData?.data?.data.map((data) => {
        const produtInfo = data?.product_variation
          ? transformProductData(data, countryCurrency)
          : newTransformProductData(data, countryCurrency);
        return {
          // ...data,
          ...produtInfo,
          // id: data.id,
          // title: data.title,
          // type: "Collection",
          // media_arr: data?.media_arr,
          // sku: data?.sku,
          // countryCurrency,
          // product_price:
          //   countryCurrency == "INR"
          //     ? formatCurrency(data?.product_price)
          //     : countryCurrency == "USD"
          //     ? formatCurrency(data?.usa_price)
          //     : countryCurrency == "GBP"
          //     ? formatCurrency(data?.uk_price)
          //     : formatCurrency(data?.usa_price),
        };
      });
      setListOfBracelet(data);
    }
  }, [braceletData.data]);
  useEffect(() => {
    if (!!ringData?.data) {
      const data = ringData?.data?.data.map((data) => {
        const produtInfo = data?.product_variation
          ? transformProductData(data, countryCurrency)
          : newTransformProductData(data, countryCurrency);
        return {
          // ...data,
          ...produtInfo,
          // id: data.id,
          // title: data.title,
          // type: "Collection",
          // media_arr: data?.media_arr,
          // sku: data?.sku,
          // countryCurrency,
          // product_price:
          //   countryCurrency == "INR"
          //     ? formatCurrency(data?.product_price)
          //     : countryCurrency == "USD"
          //     ? formatCurrency(data?.usa_price)
          //     : countryCurrency == "GBP"
          //     ? formatCurrency(data?.uk_price)
          //     : formatCurrency(data?.usa_price),
        };
      });
      setListOfRing(data);
    }
  }, [ringData.data]);
  useEffect(() => {
    if (!!necklessData?.data) {
      const data = necklessData?.data?.data.map((data) => {
        const produtInfo = data?.product_variation
          ? transformProductData(data, countryCurrency)
          : newTransformProductData(data, countryCurrency);
        return {
          // ...data,
          ...produtInfo,
          // id: data.id,
          // title: data.title,
          // type: "Collection",
          // media_arr: data?.media_arr,
          // sku: data?.sku,
          // countryCurrency,
          // product_price:
          //   countryCurrency == "INR"
          //     ? formatCurrency(data?.product_price)
          //     : countryCurrency == "USD"
          //     ? formatCurrency(data?.usa_price)
          //     : countryCurrency == "GBP"
          //     ? formatCurrency(data?.uk_price)
          //     : formatCurrency(data?.usa_price),
        };
      });
      setListOfNeckless(data);
    }
  }, [necklessData.data]);

  const getBraceletData = () => {
    if (!braceletData.success) {
      dispatch(getBraceletJewelry(userdata?.id));
    }
  };
  const getNecklessData = () => {
    if (!necklessData.success) {
      dispatch(getNecklessJewelry(userdata?.id));
    }
  };
  const getRingData = () => {
    if (!ringData.success) {
      dispatch(getRingsJewelry(userdata?.id));
    }
  };

  return (
    <>
      {/* Begin:: Categories */}
      <Container className="about-sec5 position-relative my-6">
        {/* <h2 className="heading-55" data-aos="fade-up">
          {text?.text_246}
        </h2> */}
        {/* <img
          src={imageURL2.image_81}
          className="img-fluid decoImg about-deco5"
          alt="Images"
        /> */}
        <Tab.Container id="category-tabs" defaultActiveKey="Earrings">
          <Row className="">
            <Col md={12} className="">
              <Nav
                // variant="pills"
                variant="underline"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="flex category-tab-list"
              >
                <Nav.Item>
                  <Nav.Link eventKey="Earrings">Earrings</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Bracelet" onClick={getBraceletData}>
                    Bracelet
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Rings" onClick={getRingData}>
                    Rings
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="Necklaces"
                    onClick={getNecklessData}
                    // className="mb-0"
                  >
                    Necklaces
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col md={12}>
              <Tab.Content className="category-content">
                <Tab.Pane eventKey="Earrings">
                  <div className="row">
                    <ImageSliderNew data={listOfEarring} />
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="Bracelet">
                  <div className="row">
                    <ImageSliderNew data={listOfBracelet} />
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="Rings">
                  <div className="row">
                    <ImageSliderNew data={listOfRing} />
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="Necklaces">
                  <div className="row">
                    <ImageSliderNew data={listOfNeckless} />
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
      {/* End:: Categories */}
    </>
  );
};

export default RelatedProduct;
