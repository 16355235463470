import Spinner from "react-bootstrap/Spinner";

const Loader = () => {
  return (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center mt-2">
      <Spinner variant="warning" />
    </div>
  );
};

export default Loader;
