import React from 'react'
import { Container } from 'react-bootstrap'

const GlobalTitle = ({ title = "", subTitle = "", description = "" }) => {
    return (
        <div className="my-7 text-center">
            {subTitle && <p className="">{subTitle}</p>}
            <h2 className="my-3">{title}</h2>
            {description && <Container><p className="sub-line">
                {description}
            </p></Container>}
        </div>
    )
}

export default GlobalTitle