/* eslint-disable no-restricted-globals */
import axios from "axios";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { baseUrl } from "../../constants/urls";
import { Breadcrumb, Container } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Footer from "../element/Footer";
import InfiniteScroll from "react-infinite-scroll-component";
import ProductCard from "./ProductCard";
import {
  isLogin,
  newTransformProductData,
  transformProductData,
} from "../../constants/function";
import { useDispatch } from "react-redux";
import {
  addToFavourite,
  getFavouriteList,
  removeFavoriteList,
} from "../../features/cart/cartSlice";
import Loader from "../UI/Loader";

function SearchResult() {
  const useQuery = () => {
    return new URLSearchParams(useLocation()?.search ?? "");
  };
  const query = useQuery();
  const searchValue = query?.get("q");
  const dispatch = useDispatch();

  const [searchResults, setSearchResults] = useState([]);
  const [isLoader, setIsLoader] = useState(false);

  // console.log("searchResults", searchResults);
  const fetchSearchData = async ({ value, type }) => {
    // type 0 and 1 0 is default and if click on view more button than pass 1
    try {
      setIsLoader((pr) => !pr);
      const response = await axios.post(
        `${baseUrl}/api/search?field=${value}&search_type=${type}`
      );
      const data = await response.data.products;
      if (isLogin()) {
        setSearchResults(data);
        setIsLoader((pr) => !pr);
      } else {
        const localData = JSON.parse(localStorage.getItem("wishlist")) || [];
        console.log(data, localData);
        let Newdata = data?.map((item) => {
          // Iterate over the keys (e.g., "white", "yellow", "rose")
          const updatedItem = {
            ...item,
          };
          const localItem = localData.find((i) => i.id === item?.id);

          const isFavourite = localItem
            ? localItem?.product?.favourite
            : item.favourite;
          updatedItem.favourite = isFavourite;

          return updatedItem;
        });
        setSearchResults(Newdata);
        setIsLoader((pr) => !pr);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const debouncedFetchData = debounce(
    () =>
      fetchSearchData({
        type: 1,
        value: searchValue,
      }),
    300
  );

  useEffect(() => {
    if (searchValue?.length > 0) {
      // Only fetch if there is a query
      debouncedFetchData(searchValue);
    } else {
      setSearchResults([]); // Clear results if query is empty
    }
    // Cleanup function to cancel the debounce on component unmount
    return () => {
      debouncedFetchData?.cancel();
    };
  }, [searchValue]);

  const [countryCurrency, setCountryCurrency] = useState("INR");

  useEffect(() => {
    setCountryCurrency(localStorage.getItem("currency"));
  }, []);
  const onAddToFavourite = async (data) => {
    // if (!isLogin()) return toast.error("Oops, Please login to add to wishlist");

    // eslint-disable-next-line no-restricted-globals
    alert("Product has been added to your wishlist.");
    // await dispatch(addToFavourite(produtInfo?.[selectedColor?.key]?.id));
    await dispatch(addToFavourite(data));
    await dispatch(getFavouriteList());
    await dispatch(fetchSearchData({ type: 1, value: searchValue }));
  };
  const handleRemove = async (id) => {
    // if (!isLogin())
    //   return toast.error("Oops, Please login to Remove to wishlist");

    const conf = confirm(
      "Are you sure you want to remove from your wishlist item?"
    );
    if (!conf) return;
    await dispatch(removeFavoriteList(id));
    await dispatch(getFavouriteList());
    await dispatch(fetchSearchData({ type: 1, value: searchValue }));
  };

  return (
    <div>
      <div className="container my-3">
        <Breadcrumb>
          <LinkContainer to="/" className="link-breadcrumb">
            <Breadcrumb.Item>Home</Breadcrumb.Item>
          </LinkContainer>

          <Breadcrumb.Item active>Search result</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="page-title">
        <div
          style={{
            color: "#e77e50",
            fontSize: "22px",
            fontWeight: 700,
          }}
        >
          Search result
        </div>
        {/* <h5>Home - Rings Fashion - Rings</h5> */}
      </div>

      <div>
        {/* <InfiniteScroll
          dataLength={searchResults.length}
          next={fetchData}
          hasMore={hasMore}
          loader={<h4 className="text-center">Loading...</h4>}
          endMessage={<></>}
          style={{
            overflow: "hidden",
          }}
        > */}
        <Container className="product-container">
          {/* {console.log(searchResults, searchResults?.length >= 0)} */}
          <div className="row gx-4 product-listing listingpage-container">
            {searchResults?.length > 0 ? (
              searchResults?.map((item) => {
                const produtInfo =
                  item?.product_variation || !!!item?.variation_of_product
                    ? transformProductData(item, countryCurrency)
                    : newTransformProductData(item, countryCurrency);

                // const item_detail = JSON.parse(item?.item_detail);
                // const produtInfo = {
                //   name: item?.title,
                //   type: item?.Necklaces,
                //   imgUrl: item?.media_arr?.[0],
                //   hoverImageUrl: !!item?.media_arr?.[1]
                //     ? item?.media_arr?.[1]
                //     : item?.media_arr?.[0],
                //   id: item?.id,
                //   price: item?.product_price,
                //   price:
                //     countryCurrency == "INR"
                //       ? item?.product_price
                //       : countryCurrency == "USD"
                //       ? item?.usa_price
                //       : countryCurrency == "GBP"
                //       ? item?.uk_price
                //       : item?.usa_price,
                //   sku: item_detail?.SKU,
                //   // category: category,
                //   currency: countryCurrency,
                // };

                return (
                  <>
                    <ProductCard
                      produtInfo={produtInfo}
                      key={item.id}
                      item={item}
                      onAddToFavourite={onAddToFavourite}
                      handleRemove={handleRemove}
                    />
                  </>
                );
              })
            ) : (
              <Loader />
              // <div className="d-flex align-items-center justify-content-center">
              //   No data Available
              // </div>
            )}
          </div>
        </Container>
        {/* </InfiniteScroll> */}
      </div>

      <Footer />
    </div>
  );
}

export default SearchResult;
