import React from "react";

function FreeResizing() {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 118.58 96.68"
      height="52.936"
    >
      <defs>
        <style>{`.cls-1{fill:#03364c;}`}</style>
      </defs>
      <title>Free Resizing-2--2</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1-2">
          <path
            className="cls-1"
            d="M37.42,94.65c.37-2.67.65-5.35,1.15-8a57.27,57.27,0,0,1,10.51-24A58.5,58.5,0,0,1,69.76,45.23c.33-.17.65-.37,1.12-.64L65,38.77l5.53-5.49c-3.12-3.12-6.08-6.25-9.24-9.19a3.76,3.76,0,0,1-.5-5.29,3.86,3.86,0,0,1,.5-.49C66.67,13.06,72,7.66,77.29,2.29a2.63,2.63,0,0,1,2-.86h34.58a2.48,2.48,0,0,1,1.91.77c5.43,5.47,10.85,11,16.38,16.34a3.73,3.73,0,0,1,.1,5.27.94.94,0,0,1-.1.1C129,26.86,126,30,123,33.05a3.45,3.45,0,0,0-.2.32l5.54,5.35-5.93,5.8C143.8,56,154.9,73.73,156,97.91c-.49,0-.75.09-1,.1h-3.47a55,55,0,0,0-10.16-31.77c-6.69-9.47-15.69-16-26.58-20.08l7.35-7.3-2.48-2.47c-2.54,2.54-5.07,5-7.52,7.57a2.37,2.37,0,0,1-2.59.76,54.75,54.75,0,0,0-26.32.12,1.5,1.5,0,0,1-1.63-.46c-2.61-2.68-5.26-5.3-8-8l-2.4,2.47,7.22,7.19C55.26,55.61,43,72.82,41.59,98.11H37.38ZM84.81,23.46a2.67,2.67,0,0,0,0,.4c2.11,4.83,4.2,9.68,6.36,14.49a1.52,1.52,0,0,0,1.17.55c2.87,0,5.74,0,8.55.06a1.24,1.24,0,0,0,1.34-1c1.91-4.52,3.93-9,5.91-13.54.14-.31.23-.64.38-1Zm7-17.63H80.53a1.94,1.94,0,0,0-1.15.63Q73.53,12.28,67.7,18.14c-.2.2-.39.42-.65.69a2,2,0,0,0,.41.13H79.71a1.76,1.76,0,0,0,1.13-.59c1.62-1.8,3.2-3.65,4.78-5.47,2-2.28,4-4.56,6.18-7.08m9.81,0a5.64,5.64,0,0,0,.31.53c3.55,4.05,7.08,8.12,10.66,12.15a1.93,1.93,0,0,0,1.3.47h11.47c.28,0,.56-.07,1-.12-4.31-4.3-8.47-8.46-12.66-12.6a2,2,0,0,0-1.23-.43H101.61Zm24.8,18-.37-.47c-3.65,0-7.3.07-11,0-1.25,0-2,.24-2.36,1.52a22.13,22.13,0,0,1-1.23,2.87c-1.7,3.91-3.4,7.81-5.13,11.8a11.72,11.72,0,0,1,1.64.36,2.37,2.37,0,0,0,2.8-.83c4.78-4.89,9.66-9.71,14.52-14.54a5,5,0,0,1,1.08-.66M67,23.44c.15.28.17.37.23.41q8,8,16,15.95a1.22,1.22,0,0,0,.89.33c1-.12,1.81-.34,2.79-.53l-.52-1.24c-1.74-4-3.66-7.91-5.16-12-.88-2.36-2-3.21-4.59-3-3.12.26-6.28.05-9.56.05m19.23-4.51h20.84L96.65,7,86.25,18.93"
            transform="translate(-37.38 -1.43)"
          ></path>
          <path
            className="cls-1"
            d="M50.78,98h-4.4C47,80.18,54.17,65.76,69.06,56.1c18.16-11.76,37-11.73,55.3-.3A49,49,0,0,1,147.21,98H143c-.44-14.87-6.24-27.14-17.94-36.33a44.84,44.84,0,0,0-31.68-9.56A46.29,46.29,0,0,0,50.78,98"
            transform="translate(-37.38 -1.43)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default FreeResizing;
