/* eslint-disable no-restricted-globals */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import HeartFill from "../../assets/svgs/heartFill";
import { currencyIcon } from "../../constants/function";
import { colorsVariant } from "../../constants/utils";
import {
  addToCart,
  addToFavourite,
  getCartDetails,
  getFavouriteList,
  removeFavoriteList,
} from "../../features/cart/cartSlice";
import {
  getBraceletJewelry,
  getEarringJewelry,
  getNecklessJewelry,
  getRingsJewelry,
} from "../../features/jewelry/jewelrySlicer";
import formatCurrency from "../../helper/currencyFormater";

const SlideCard = (item) => {
  // const formatedPrice = formatCurrency(item?.silver?.price);
  // console.log(item);
  const [selectedColor, setSelectedColor] = useState({
    color: colorsVariant?.[item?.silver ? "silver" : "white"],
    key: item?.silver ? "silver" : "white",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const hoverImageUrl = !!item?.item?.[selectedColor?.key]?.hoverImageUrl
    ? item?.item?.[selectedColor?.key]?.hoverImageUrl
    : item?.item?.[selectedColor?.key]?.imgUrl;
  const availableColors = Object?.keys(item?.item)?.filter(
    (key) => item?.item?.[key]
  );
  const handleSelect = (color, key) => {
    setSelectedColor({ color, key });
  };
  const handleClick = (name, sku) => {
    let formattedName = name.toLowerCase();
    // Replace all white spaces with hyphens
    formattedName = formattedName.replace(/\s+/g, "-");
    navigate(`/${formattedName}/${sku}`, {
      state: {
        slug: "",
      },
    });
  };

  const onAddToCartHandelar = async (product) => {
    await dispatch(addToCart(product));
    await dispatch(getCartDetails());
  };

  const onAddToFavourite = async (productData) => {
    // if (!isLogin()) return toast.error("Oops, Please login to add to wishlist");
    const userdata = JSON.parse(localStorage.getItem("user"));

    alert("Product has been added to your wishlist.");
    await dispatch(addToFavourite(productData));
    await dispatch(getFavouriteList());

    if (item?.productKey == "earring") {
      await dispatch(getEarringJewelry(userdata?.id));
    } else if (item?.productKey == "bracelet") {
      await dispatch(getBraceletJewelry(userdata?.id));
    } else if (item?.productKey == "ring") {
      await dispatch(getRingsJewelry(userdata?.id));
    } else if (item?.productKey == "neckless") {
      await dispatch(getNecklessJewelry(userdata?.id));
    } else if (item?.productKey == "suggestionProduct") {
      await item?.getOnclick();
    }
    if (item?.refresher) {
      item?.refresher();
    }
    // await item?.getOnclick();
  };

  const handleRemove = async (id) => {
    // if (!isLogin())
    //   return toast.error("Oops, Please login to Remove to wishlist");
    const userdata = JSON.parse(localStorage.getItem("user"));
    const cat = localStorage.getItem("category");
    const conf = confirm(
      "Are you sure you want to remove from your wishlist item?"
    );
    if (!conf) return;
    await dispatch(removeFavoriteList(id));
    await dispatch(getFavouriteList());
    if (item?.productKey == "earring") {
      await dispatch(getEarringJewelry(userdata?.id));
    } else if (item?.productKey == "bracelet") {
      await dispatch(getBraceletJewelry(userdata?.id));
    } else if (item?.productKey == "ring") {
      await dispatch(getRingsJewelry(userdata?.id));
    } else if (item?.productKey == "neckless") {
      await dispatch(getNecklessJewelry(userdata?.id));
    } else if (item?.productKey == "suggestionProduct") {
      await item?.getOnclick();
    }
    if (item?.refresher) {
      item?.refresher();
    }
    // await disptach(
    //   fetchProducts({
    //     category: cat,
    //     page: 1,
    //     userId: userdata?.id,
    //     metal_type:
    //       category === "yellow-gold-engagement-rings" || category === "yellow-gold-wedding-bands"
    //         ? 1
    //         : category === "rose-gold-engagement-rings" || category === "rose-gold-wedding-bands"
    //         ? 2
    //         : null,
    //   })
    // );
  };

  return (
    <div key={item?.item?.id} className="p-3">
      <div>
        <div className="card product-card d-flex align-items-center justify-content-center">
          <div className="product-display">
            <div
              className="cursor-pointer"
              style={{
                width: window.screen.width < 500 ? "130px" : "auto",
                height: window.screen.width < 500 ? "125px" : "auto",
              }}
              onClick={() =>
                handleClick(
                  item?.item?.[selectedColor?.key]?.name.toLowerCase(),
                  item?.item?.[selectedColor?.key]?.sku
                )
              }
            >
              <img
                src={
                  item?.item?.[selectedColor?.key]?.imgUrl ??
                  item?.item?.[selectedColor?.key]?.hoverImageUrl
                }
                // width={150}
                // height={150}
                className="img-fluid"
                alt="product"
              />
              <img
                src={hoverImageUrl}
                // width={150}
                // height={150}
                className="img-fluid hover"
                alt="product"
                style={{
                  aspectRatio: 1,
                }}
              />
            </div>

            <div
              style={{
                position: "absolute",
                top: "5px",
                right: "0px",
                fontSize: "14px",
                color: "#e77e50",
                padding: "3px 10px",
                borderRadius: "5px",
              }}
            >
              {item?.item?.[selectedColor?.key]?.favourite ? (
                <div
                  className="btn-heart  cursor-pointer"
                  onClick={() =>
                    handleRemove(item?.item?.[selectedColor?.key]?.id)
                  }
                >
                  <HeartFill
                    className="wishlist-heart"
                    fillColor={"#e77e50"}
                    strokeColor={"#e77e50"}
                  />
                </div>
              ) : (
                <div
                  className="btn-heart fill-border cursor-pointer"
                  onClick={() => {
                    onAddToFavourite(item?.item?.[selectedColor?.key]);
                  }}
                >
                  <HeartFill
                    className="wishlist-heart"
                    fillColor={"none"}
                    strokeColor={"#e77e50"}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="card-body ">
            {/* <h5 className="text-primary">{"type"}</h5> */}
            <h2>
              <Link
                to={`/${item?.item?.[selectedColor?.key]?.name}/${
                  item?.item?.[selectedColor?.key]?.sku
                }`}
                className="text-black"
              >
                {/* <Link to={`/${category}/${id}`} className="text-black"> */}
                {item?.item?.[selectedColor?.key]?.name}
              </Link>
            </h2>
            <div className="color-select">
              {Object.keys(item?.item)?.map((key, index) => (
                <div
                  key={index}
                  className={`main-color ${
                    selectedColor?.color === colorsVariant?.[key]
                      ? "selected"
                      : ""
                  } ${item?.item?.[key] ? "cursor-pointer" : "not-allowed"}`}
                >
                  <div
                    key={index}
                    className={`color-circle ${
                      selectedColor?.color === colorsVariant?.[key]
                        ? "selected"
                        : ""
                    } ${item?.item?.[key] ? "cursor-pointer" : "not-allowed"}`}
                    style={{ background: colorsVariant?.[key] }}
                    onClick={() =>
                      item?.item?.[key] &&
                      handleSelect(colorsVariant?.[key], key)
                    }
                  ></div>
                </div>
              ))}
            </div>
            <div
              className="product-price product-text-center"
              style={{
                fontSize: "18px",
                marginBottom: "0px",
              }}
            >
              {currencyIcon(item?.item?.[selectedColor?.key]?.currency)}{" "}
              {formatCurrency(item?.item?.[selectedColor?.key]?.price)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlideCard;
