import { Container, Row, Col, Image, Button } from "react-bootstrap";
import React from "react";
import { Link } from "react-router-dom";
// import { imageURL2 } from "../../images";
import Slider from "react-slick";
import useMediaQuery from "../../Hooks/useMeadiaQuery";

const EngagementGridHome = ({ Products, title = "", lg = 3 }) => {
  // const isAboveDesktop = useMediaQuery("(min-width: 768px)");

  return Products?.length ? (
    <Container className="my-6 ">
      {!!title && <h2 className="text-center heading-55 pt-4 pb-4">{title}</h2>}
      <div className="mb-4">
        <Container className=" ">
          <div className=" pt-2 pb-4  collection-slider abcd ">
            {/* {isAboveDesktop ? (
            <Row className="my-3 row-gap-4">
              {Products?.map((singleProduct) => {
                return (
                  <Col lg={lg} sm={6} xs={6} key={singleProduct?.id}>
                    <div className="aspect-ratio aspect-ratio-16/9 mb-2">
                      <Link to={singleProduct?.link}>
                        <Image
                          src={singleProduct?.image}
                          alt={singleProduct?.name}
                          fluid
                          style={{
                            aspectRatio: "9/12",
                          }}
                        />
                      </Link>
                    </div>
                    <h2 className="mt-0 mb-2 h2-30">{singleProduct?.name}</h2>
                  </Col>
                );
              })}
            </Row>
          ) : (
            <ProductSlider Products={Products} />
          )} */}
            <ProductSlider Products={Products} />
          </div>
        </Container>
      </div>
    </Container>
  ) : (
    ""
  );
};

export default EngagementGridHome;

export const ProductSlider = ({ Products }) => {
  const buttonStyles = {
    backgroundColor: "#e77e50",
    padding: "10px",
    borderRadius: "50%",
    zIndex: 1,
    // fontSize: '0PX',
    color: "#333",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          ...buttonStyles,
          left: window.screen.width < 500 ? "-8px" : "-30px",
          top: "30%",
        }}
        onClick={onClick}
      >
        {/* &lsaquo; */}
      </div>
    );
  };

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          ...buttonStyles,
          right: window.screen.width < 500 ? "-8px" : "-30px",
          top: "30%",
        }}
        onClick={onClick}
      >
        {/* &rsaquo; */}
      </div>
    );
  };
  const settings = {
    infinite: true,
    dots: false,
    speed: 500,
    slidesToShow: 5,
    arrow: false,
    swipe: true,
    slidesToScroll: 1,
    centerMode: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5.5,
          slidesToScroll: 1,
          // infinite: true,
          // dots: false,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          // initialSlide: ,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          // initialSlide: ,
        },
      },
      {
        breakpoint: 480,
        settings: {
          // center : true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {Products?.map((singleProduct) => {
        return (
          <div
            key={singleProduct?.id}
            className=" Rings_hover text-center w-100 h-100"
          >
            <div className="Rings_hover  mb-2">
              <Link to={singleProduct?.link}>
                <Image
                  // style={{aspectRatio : 1}}
                  src={singleProduct?.image}
                  alt={singleProduct?.name}
                  fluid
                  // style={{
                  //   aspectRatio: "9/12",
                  // }}
                  className="Rings_img_hover"
                />
              </Link>
            </div>
            <h2 className="h2-32">{singleProduct?.name}</h2>
          </div>
        );
      })}
    </Slider>
  );
};
