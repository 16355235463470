import React from "react";
import { Button, Card, Image, Modal } from "react-bootstrap";
import { currencyIcon } from "../../constants/function";
import { orderStatus } from "../../constants/utils";

const OrderModal = ({ showModal, handleCloseModal, selectedOrder }) => {

  return (
    <Modal show={showModal} onHide={handleCloseModal} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Order Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {selectedOrder && (
          <Card>
            <Card.Body>
              <Card.Title>{selectedOrder?.productName}</Card.Title>
              <div className="d-flex justify-content-between align-items-center">
                <div className=" d-flex flex-column justify-content-between ">
                  <Card.Subtitle className="mb-2 text-muted text-start d-flex">
                    Order Status:{" "}
                    {`${orderStatus[selectedOrder?.status] ?? "-"}`}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2 text-muted text-start d-flex">
                    Payment Status:{" "}
                    {`${selectedOrder?.payment_id ? "Paid" : "Pending"}`}
                  </Card.Subtitle>
                </div>
                <Card.Subtitle className="mb-2 text-muted text-end">
                  Order Code: {`${selectedOrder?.code}`}
                  {/* Order Code: {`#ORD${padZeros(selectedOrder?.id, 6)}`} */}
                </Card.Subtitle>
              </div>
              <Card.Text>
                {selectedOrder?.products?.map(SingleOrder => <div
                  key={SingleOrder?.id}
                  className="row align-items-center mx-0 my-2"
                  gutter={8}
                  style={{ padding: "10px", border: "1px solid gray" }}
                >
                  <div className="col-6 col-sm-12  col-md-1 mb-2 align-items-center justify-content-center">
                    <Image
                      src={
                        SingleOrder?.product?.featured_image[0]
                          ?.original_url
                      }
                      style={{ width: "50px", height: "auto" }}
                    />
                  </div>
                  <div className="col-6 col-sm-4 d-block d-sm-none mb-3  col-md-2  justify-content-end align-items-center  text-end">
                    <p className="  text-md-end m-0">{`${currencyIcon(
                      SingleOrder?.currency ??
                      localStorage.getItem("currency")
                    )} ${SingleOrder?.amount}`}</p>
                    <p className="text-muted  m-0 text-end text-md-end">
                      Qty: {SingleOrder?.qty}
                    </p>
                    {SingleOrder?.size
                      && <p className="text-muted m-0  text-end text-md-end">
                        Size: {SingleOrder?.size}
                      </p>}
                  </div>
                  <div className="col-12 col-sm-8 col-md-9 text-start text-md-start">
                    <strong>
                      {SingleOrder?.product?.title}
                    </strong>
                    <p className="text-muted  text-start m-0">
                      {SingleOrder?.product?.caption}
                    </p>
                    <p className="text-muted  text-start ">
                      SKU: {SingleOrder?.product?.sku}
                    </p>
                  </div>
                  <div className="col-12 col-sm-4 d-none d-sm-block   col-md-2  d-flex justify-content-end align-items-center  text-end">
                    <p className="  text-md-end">{`${currencyIcon(
                      SingleOrder?.currency ??
                      localStorage.getItem("currency")
                    )} ${SingleOrder?.amount}`}</p>
                    <p className="text-muted  m-0 text-end text-md-end">
                      Qty: {SingleOrder?.qty}
                    </p>
                    {SingleOrder?.size
                      && <p className="text-muted m-0  text-end text-md-end">
                        Size: {SingleOrder?.size}
                      </p>}
                  </div>
                </div>)}
                {/* <div
                  className="row align-items-center mx-0 my-2"
                  gutter={8}
                  style={{ padding: "10px", border: "1px solid gray" }}
                >
                  <div className="col-2 col-md-1 mb-2">
                    <Image
                      src={
                        selectedOrder?.products?.[0]?.product?.featured_image[0]
                          ?.original_url
                      }
                      style={{ width: "50px", height: "auto" }}
                    />
                  </div>
                  <div className="col-10 col-md-9 text-start text-md-start">
                    <strong>
                      {selectedOrder?.products[0]?.product?.title}
                    </strong>
                    <p className="text-muted  text-start m-0">
                      {selectedOrder?.products?.[0]?.product?.caption}
                    </p>
                    <p className="text-muted  text-start ">
                      SKU: {selectedOrder?.products?.[0]?.product?.sku}
                    </p>
                  </div>
                  <div className="col-12   col-md-2 d-md-block d-flex justify-content-end align-items-center  text-end">
                    <p className=" text-start text-md-end">{`${currencyIcon(
                      selectedOrder?.currency ??
                        localStorage.getItem("currency")
                    )} ${selectedOrder?.amount}`}</p>
                    <p className="text-muted  text-end text-md-end">
                      Qty: {selectedOrder?.products?.[0]?.qty}
                    </p>
                  </div>
                </div> */}

                <div className="row mx-0 ">
                  <div className="col-12 col-md-8 d-flex justify-content-center">
                    {/* <strong>Order Notes : </strong>
                                        <p className="mx-1 text-muted">notesssss</p> */}
                    {/* Billing Address */}
                    <div
                      className="py-2"
                      style={{
                        paddingRight: "20px",
                        borderRight: "1px solid gray",
                        height: "120px",
                      }}
                    >
                      <strong className="py-3">Billing Address</strong>
                      <p className="mx-1 text-muted my-2">
                        {selectedOrder?.billing_data?.name}
                        <br />
                        {selectedOrder?.billing_data?.address1},{" "}
                        {selectedOrder?.billing_data?.address2}
                        <br />
                        {selectedOrder?.billing_data?.city},{" "}
                        {selectedOrder?.billing_data?.postal_code}
                        <br />
                      </p>
                    </div>

                    {/* Shipping Address */}
                    <div className="py-2" style={{ paddingLeft: "20px" }}>
                      <strong className="my-2">Shipping Address</strong>
                      <p className="mx-1 text-muted my-2">
                        {selectedOrder?.shipping_data?.name}
                        <br />
                        {selectedOrder?.shipping_data?.address1},{" "}
                        {selectedOrder?.shipping_data?.address2}
                        <br />
                        {selectedOrder?.shipping_data?.city},{" "}
                        {selectedOrder?.shipping_data?.postal_code}
                        <br />
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 text-end">
                    <div className="d-flex justify-content-between align-items-center py-2">
                      <p className="m-0">SubTotal</p>
                      <p className="m-0">{`${currencyIcon(
                        selectedOrder?.currency ??
                        localStorage.getItem("currency") ?? "INR"
                      )} ${selectedOrder?.amount}`}</p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center py-2">
                      <p className="m-0">Shipping</p>
                      <p className="m-0">
                        {`${currencyIcon(
                          selectedOrder?.currency ??
                          localStorage.getItem("currency")
                        )}`}
                        0.00
                      </p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center py-2">
                      <p className="m-0">Tax</p>
                      <p className="m-0">
                        {`${currencyIcon(
                          selectedOrder?.currency ??
                          localStorage.getItem("currency")
                        )}`}
                        0.00
                      </p>
                    </div>
                    <div
                      className="d-flex justify-content-between align-items-center py-2"
                      style={{
                        borderTop: "1px solid gray",
                      }}
                    >
                      <strong>Total</strong>
                      <strong>{`${currencyIcon(
                        selectedOrder?.currency ??
                        localStorage.getItem("currency")
                      )} ${selectedOrder?.amount}`}</strong>
                    </div>
                  </div>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OrderModal;
