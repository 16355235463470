import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import Loader from "../UI/Loader";
import SlideCard from "./slideCard";
import SliderComponent from "./sliderComponent";
function ImageSliderNew({ data, getOnclick, productKey }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          ...buttonStyles,
          left: window.screen.width < 500 ? "-8px" : "-30px",
          top: "33%",
        }}
        onClick={onClick}
      >
        {/* &lsaquo; */}
      </div>
    );
  };

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          ...buttonStyles,
          right: window.screen.width < 500 ? "-8px" : "-30px",
          top: "33%",
        }}
        onClick={onClick}
      >
        {/* &rsaquo; */}
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: data.length > 4 ? 4 : data?.length,
    slidesToScroll: data.length > 4 ? 4 : data?.length,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const responsive = [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        // dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ];

  const buttonStyles = {
    backgroundColor: "#e77e50",
    padding: "10px",
    color: "#333",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  // const [countryCurrency, setCountryCurrency] = useState("INR");

  // useEffect(() => {
  //   setCountryCurrency(localStorage.getItem("currency"));
  // }, []);

  return (
    <>
      {data?.length == 0 ? (
        <div className="text-center">
          <Loader />
          {/* <strong>No data Available</strong> */}
        </div>
      ) : (
        <div className="slider-container collection-slider my-3">
          {/* <SliderComponent
            title={"Related Products"}
            responsive={responsive}
            slidesToShow={data.length > 4 ? 4 : data?.length}
            slidesToScroll={data.length > 4 ? 4 : data?.length}
            data={
              data?.map((item) => {
                return (
                  <SlideCard
                    item={item}
                    getOnclick={getOnclick}
                    productKey={productKey}
                  />
                );
              }) ?? []
            }
            // infinite
          /> */}
          <Slider {...settings}>
            {data?.map((item) => {
              return (
                <SlideCard
                  item={item}
                  getOnclick={getOnclick}
                  productKey={productKey}
                />
              );
            })}
          </Slider>
        </div>
      )}
    </>
  );
}

export default ImageSliderNew;
