import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import Slider from "react-slick";
import useMediaQuery from "../../Hooks/useMeadiaQuery";
import SliderComponent from "../Slider/sliderComponent";
const EngagementReview = ({ Reviews = [], title = "" }) => {
  const isAboveDesktop = useMediaQuery("(min-width: 768px)");
  const buttonStyles = {
    backgroundColor: "#e77e50",
    padding: "10px",
    borderRadius: "50%",
    zIndex: 1,
    // fontSize: '0PX',
    color: "#333",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  // const PrevArrow = (props) => {
  //   const { className, style, onClick } = props;
  //   return (
  //     <div
  //       className={className}
  //       style={{
  //         ...style,
  //         ...buttonStyles,
  //         left: window.screen.width < 500 ? "-8px" : "-30px",
  //         top: "51%",
  //       }}
  //       onClick={onClick}
  //     >
  //       {/* &lsaquo; */}
  //     </div>
  //   );
  // };

  // const NextArrow = (props) => {
  //   const { className, style, onClick } = props;
  //   return (
  //     <div
  //       className={className}
  //       style={{
  //         ...style,
  //         ...buttonStyles,
  //         right: window.screen.width < 500 ? "-8px" : "-30px",
  //         top: "51%",
  //       }}
  //       onClick={onClick}
  //     >
  //       {/* &rsaquo; */}
  //     </div>
  //   );
  // };
  // const settings = {
  //   infinite: true,
  //   dots: false,
  //   speed: 500,
  //   slidesToShow: 1,
  //   arrow: false,
  //   swipe: true,
  //   slidesToScroll: 1,
  //   // centerMode: true,
  //   prevArrow: <PrevArrow />,
  //   nextArrow: <NextArrow />,
  // };

  return (
    <div className="slider-container collection-slider mt-3 my-6">
      {/* {!!title && <h2 className="text-center heading-55 pt-4 my-6">{title}</h2>} */}
      <div
        className="collection-slider mt-3 my-6"
        style={{
          background: "#fafafa",
        }}
      >
        <Container>
          <div className="mb-4">
            <SliderComponent
              title={title}
              responsive={[]}
              data={
                Reviews?.map((singleReview) => {
                  return (
                    <div key={singleReview?.id} className="w-100 h-100">
                      <div className="d-md-flex w-100 ">
                        <Col
                          md={6}
                          className="px-4 py-5   h-100 d-flex  justify-content-center flex-column"
                          style={{
                            maxHeight: "440px",
                            minHeight: isAboveDesktop ? "440px" : "100%",
                          }}
                        >
                          <p>
                            {Array(singleReview?.star ?? 1)
                              ?.fill(1)
                              ?.map((el, i) => {
                                return (
                                  <i
                                    key={`${singleReview?.id}_${i}`}
                                    className="rating__icon rating__icon--star fa fa-star text-primary h2-32"
                                  ></i>
                                );
                              })}
                          </p>
                          <p
                            className="h2-32 "
                            style={{
                              color: "grey",
                            }}
                          >
                            {singleReview?.review}
                            {/* <EllipsisText maxLines={3} text={singleReview?.review} /> */}
                          </p>
                          <p
                            className="p-0 m-0 mt-2  h2-32 "
                            style={{
                              color: "grey",
                            }}
                          >{`- ${singleReview?.first_name} ${singleReview?.last_name}`}</p>
                        </Col>
                        <Col
                          md={6}
                          className=" h-100 p-2 d-flex align-items-center justify-content-center"
                          style={{
                            minHeight: isAboveDesktop ? "440px" : "100%",
                            maxHeight: "440px",
                          }}
                        >
                          <div className="d-flex align-items-center justify-content-center">
                            <Image
                              src={singleReview?.image}
                              alt={singleReview?.name}
                              style={{
                                width: "100%",
                                height: "300px",
                              }}
                            />
                          </div>
                        </Col>
                      </div>
                    </div>
                  );
                }) ?? []
              }
            />
            {/* <Slider {...settings}>
              {Reviews?.map((singleReview) => {
                return (
                  <div key={singleReview?.id} className="w-100 h-100">
                    <div className="d-md-flex w-100 ">
                      <Col
                        md={6}
                        className="px-4 py-5   h-100 d-flex  justify-content-center flex-column"
                        style={{
                          maxHeight: "440px",
                          minHeight: isAboveDesktop ? "440px" : "100%",
                        }}
                      >
                        <p>
                          {Array(singleReview?.star ?? 1)
                            ?.fill(1)
                            ?.map((el, i) => {
                              return (
                                <i
                                  key={`${singleReview?.id}_${i}`}
                                  className="rating__icon rating__icon--star fa fa-star text-primary h2-32"
                                ></i>
                              );
                            })}
                        </p>
                        <p
                          className="h2-32 "
                          style={{
                            color: "grey",
                          }}
                        >
                          {singleReview?.review}
                        
                        </p>
                    
                        <p
                          className="p-0 m-0 mt-2  h2-32 "
                          style={{
                            color: "grey",
                          }}
                        >{`- ${singleReview?.first_name} ${singleReview?.last_name}`}</p>
                      </Col>
                      <Col
                        md={6}
                        className=" h-100 p-2 d-flex align-items-center justify-content-center"
                        // style={{
                        //   height: "300px",
                        // }}
                        style={{
                          minHeight: isAboveDesktop ? "440px" : "100%",
                          maxHeight: "440px",
                        }}
                      >
                        <div className="d-flex align-items-center justify-content-center">
                          <Image
                            // style={{aspectRatio : 1}}
                            src={singleReview?.image}
                            // src="https://ion.r2net.com/Images/engagement-rings/2022/Reviews/1920/review1_new.png"
                            alt={singleReview?.name}
                            // fluid
                            style={{
                              width: "100%",
                              // height: "100%",
                              height: "300px",
                              // aspectRatio: 1,
                              // maxHeight: "300px",
                            }}
                            // className="Rings_img_hover"
                          />
                        </div>
                      </Col>
                    </div>
                  </div>
                );
              })}
            </Slider> */}
          </div>
        </Container>
      </div>
    </div>
  );
};

export default EngagementReview;
