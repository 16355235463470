import Footer from "./element/Footer";

const Custom404 = () => {
  return (
    <div>
      <div className="container mx-auto mt-5 py-20 text-center">
        <h1 className="text-4xl font-bold mb-4">404 - Page Not Found</h1>
        <p className="text-lg mb-8">
          The page you are looking for does not exist.
        </p>

        <a href="/" className="text-blue-500 hover:underline">
          Go back to home
        </a>
      </div>
      <Footer />
    </div>
  );
};

export default Custom404;
