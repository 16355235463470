import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";
import {
  addToCartLocal,
  addToWishlistLocal,
  cartDecreaseQtyLocal,
  cartIncreaseQtyLocal,
  getCartLocal,
  getWishlistLocal,
  isLogin,
  removeFromCartLocal,
  removeFromWishlistLocal,
} from "../../constants/function";
import {
  addToCartUrl,
  addToFavouriteUrl,
  cartDetailsUrl,
  decreaseCartProductUrl,
  getFavouriteListUrl,
  increaseCartProductUrl,
  placeOrderUrl,
  proceedToPaymentUrl,
  removeFavoriteListUrl,
  removeFromCartUrl,
  updateCartProductSizeUrl
} from "../../constants/urls";
const token = localStorage.getItem("token");

export const getCartDetails = createAsyncThunk("cart/details", async () => {
  // if (!isLogin()) return;
  if (isLogin()) {
    const user = JSON?.parse(localStorage.getItem("user"));
    const res = await axios.get(
      `${cartDetailsUrl}${user?.id ? `?user_id=${user?.id}` : ""}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return res.data;
  } else {
    console.log("first cartData")
    const res = { data: getCartLocal() };
    return res;
  }
});

export const removeFromCart = createAsyncThunk(
  "cart/removeToCart",
  async (cartProductId) => {
    try {
      // if (!isLogin())
      //   return toast.error("Oops, Please login to remove to cart");
      if (isLogin()) {
        const res = await axios.get(
          `${removeFromCartUrl}/${cartProductId?.productCartId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        toast?.success("Your Cart Item Successfully Removed!!");
        return cartProductId?.productCartId;
      } else {
        removeFromCartLocal(cartProductId);
        toast?.success("Your Cart Item Successfully Removed!!");
        // getCartDetails()
     
        return cartProductId;
      }
    } catch (error) {
      console.error("Error removing from cart: ", error);
      throw error;
    }
  }
);

export const increaseCartProduct = createAsyncThunk(
  "cart/increase",
  async (cartProductData) => {
    try {
      if (isLogin()) {
        const res = await axios.get(
          `${increaseCartProductUrl}/${cartProductData?.cartProductId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const cartDetailRes = await axios.get(cartDetailsUrl, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const cartDetails = cartDetailRes.data.data;
        return cartDetails;
      } else {
        const cartDetails = cartIncreaseQtyLocal(cartProductData);
        // const cartDetails = getCartDetails();
        return cartDetails;
      }
    } catch (error) {
      console.error("Error increase product in cart :", error);
      throw error;
    }
  }
);

export const decreaseCartProduct = createAsyncThunk(
  "cart/decrease",
  async (cartProductdata) => {
    try {
      if (isLogin()) {
        const res = await axios.get(
          `${decreaseCartProductUrl}/${cartProductdata?.cartProductId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const cartDetailRes = await axios.get(cartDetailsUrl, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const cartDetails = cartDetailRes.data.data;
        return cartDetails;
      } else {
        const cartDetails = cartDecreaseQtyLocal(cartProductdata);

        return cartDetails;
      }
    } catch (error) {
      console.error("Error increase product in cart :", error);
      throw error;
    }
  }
);

export const updateSize = createAsyncThunk(
  "cart/update-size",
  async (payload, size) => {
    try {
      if (isLogin()) {
        const formData = new FormData();
        formData.append("id", payload?.cartId);
        formData.append("size", payload?.value);
        const res = await axios.post(`${updateCartProductSizeUrl}`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        toast?.success("Size Successfully Updated!!");
        const cartDetailRes = await axios.get(cartDetailsUrl, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const cartDetails = cartDetailRes.data.data;
        return cartDetails;
      }
      // else {
      //   const cartDetails = cartDecreaseQtyLocal(cartProductId);

      //   return cartDetails;
      // }
    } catch (error) {
      console.error("Error increase product in cart :", error);
      throw error;
    }
  }
);

export const addToCart = createAsyncThunk("cart/addToCart", async (product) => {
  try {
    // if (!isLogin()) return toast.error("Oops, Please login to add to cart");
    if (isLogin()) {
      const localStorageCurrency = localStorage.getItem("currency");
      const currency =
        localStorageCurrency === "INR"
          ? 0
          : localStorageCurrency === "USD"
          ? 1
          : localStorageCurrency === "GBP"
          ? 2
          : 1;
      // const pay = {
      //   id: product.id,
      //   // size: product?.Productsize,
      //   qty: 1,
      // };
      // if (product?.Productsize) {
      //   pay["size"] = +product?.Productsize;
      // }
      // // const payload = { "cart_items[0]": [pay] };
      // const payload = { cart_items: [pay] };
      // [pay].forEach((item, index) => {
      //   payload["cart_items"][index] = item;
      // });

      const payload = {
        cart_items: product?.length
          ? product
          : [
              {
                id: product?.id,
                qty: product?.qty ?? 1,
                size: product?.Productsize,
              },
            ],
      };
      const formData = new FormData();

      // Iterate through the payload object and append each key-value pair to formData
      payload?.cart_items?.forEach((item, index) => {
        formData.append(
          `cart_items[${index}]`,
          JSON.stringify({
            ["id"]: item?.id,
            ["quantity"]: item?.qty,
            ["size"]: item?.size,
            ["currency"]: currency,
          })
        );
      });
      formData.append(`currency`, currency);
      const user = JSON?.parse(localStorage.getItem("user"));
      const res = await axios.post(
        `${addToCartUrl}${user?.id ? `?user_id=${user?.id}` : ""}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            // "Content-Type": "application/json",
          },
        }
      );

      const cartDetailRes = await axios.get(cartDetailsUrl, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const totalItemsInCart = cartDetailRes.data.data.length;
      toast.success("Yout order has been added to cart");
      return totalItemsInCart;
    } else {
      const totalItemsInCart = addToCartLocal(product);
      toast.success("Yout order has been added to cart");
      return totalItemsInCart;
    }
  } catch (error) {
    console.error("Error add to cart: ", error);
    throw error;
  }
});

export const placeOrder = createAsyncThunk("cart/placeorder", async (order) => {
  try {
    const res = await axios.post(placeOrderUrl, order, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    return res.data;
  } catch (error) {
    console.error("Erron place order : ", error);
    throw error;
  }
});

export const proccedToPayment = createAsyncThunk(
  "cart/ProccedToPayment",
  async (paymentInfo) => {
    try {
      const res = await axios.post(proceedToPaymentUrl, paymentInfo, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      return res?.data;
    } catch (error) {
      // console.error("Error procced to payement : ", error);
      toast.error(error?.response ?? "Something went wrong");
      throw error;
    }
  }
);

export const addToFavourite = createAsyncThunk(
  "wishlist/addToFavourite",
  async (product) => {
    try {
      // if (!isLogin())
      //   return toast.error("Oops, Please login to add to wishlist");
      if (isLogin()) {
        const formData = new FormData();
        product?.length
          ? formData.append("product_ids", product?.join(","))
          : formData.append("product_ids", product?.id);
        const res = await axios.post(`${addToFavouriteUrl}`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            // "Content-Type": "application/json",
          },
        });
        toast.success("Yout product has been added to wishlist");
        return res.data;
      } else {
        // const data = addToWishlistLocal(product);
        const res = { data: addToWishlistLocal(product) };
        toast.success("Yout product has been added to wishlist");
        return res;
      }
    } catch (error) {
      console.error("Error add to favourite: ", error);
    }
  }
);

export const getFavouriteList = createAsyncThunk(
  "wishlist/getFavouriteList",
  async () => {
    try {
      // if (!isLogin())
      //   return toast.error("Oops, Please login to get to wishlist");
      if (isLogin()) {
        const res = await axios.get(getFavouriteListUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        return res.data.data;
      } else {
        const data = getWishlistLocal();

        return data;
      }
    } catch (error) {
      console.error("Error favourite list: ", error);
      throw error;
    }
  }
);

export const removeFavoriteList = createAsyncThunk(
  "wishlist/removeWishlist",
  async (id) => {
    try {
      // if (!isLogin())
      //   return toast.error("Oops, Please login to remove to wishlist");
      if (isLogin()) {
        const res = await axios.get(`${removeFavoriteListUrl}/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        // if (!res?.success)
        //   return toast.error(res?.message ?? "Something went wrong");
        toast.success("Product has been removed from your wishlist");
        return res.data.data;
      } else {
        const res = removeFromWishlistLocal(id);
        toast?.success("Your Cart Item Successfully Removed!!");
        return res;
      }
    } catch (error) {
      console.error("Error favourite list: ", error);
      throw error;
    }
  }
);

const initialState = {
  cartItems: [],
  isLoading: false,
  error: null,
  totalCartItems: 0,
  totalAmount: 0,
  wishList: [],
  favoriteRemoveSuccess: false,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    calculateTotal: (state, action) => {
      state.totalAmount = state.cartItems.reduce((acc, curr) => {
        const qty = curr.qty;
        const price = +curr.product.product_price;
        const itemTotal = qty * price;

        return acc + itemTotal;
      }, 0);
    },
    resetFavorite: (state, action) => {
      state.favoriteRemoveSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addToCart.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(addToCart.fulfilled, (state, action) => {
      state.isLoading = false;
      state.totalCartItems = action.payload;
    });
    builder.addCase(getCartDetails.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getCartDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.cartItems = action.payload.data;
      state.totalCartItems = state.cartItems.length;
    });

    builder.addCase(getCartDetails.rejected, (state, action) => {
      state.error = action.payload;
    });

    builder.addCase(removeFromCart.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(removeFromCart.fulfilled, (state, action) => {
      state.isLoading = false;
      if (isLogin()) {
        state.cartItems = state.cartItems.filter(
          (item) => item.id !== action.payload
        );
      } else {
        state.cartItems = state.cartItems.filter(
          (item) =>
            !(
              +item.id === +action?.payload?.productCartId &&
              +item?.product?.Productsize === +action?.payload?.size
            )
        );
      }
      state.totalCartItems = state.cartItems.length;
    });

    builder.addCase(removeFromCart.rejected, (state, action) => {
      state.error = action.payload;
    });

    builder.addCase(increaseCartProduct.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(increaseCartProduct.fulfilled, (state, aciton) => {
      state.isLoading = false;
      state.cartItems = aciton.payload;
    });
    builder.addCase(increaseCartProduct.rejected, (state, action) => {
      state.error = action.payload;
    });

    builder.addCase(decreaseCartProduct.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(decreaseCartProduct.fulfilled, (state, action) => {
      state.isLoading = false;
      state.cartItems = action.payload;
    });

    builder.addCase(decreaseCartProduct.rejected, (state, action) => {
      state.error = action.payload;
    });
    builder.addCase(updateSize.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(updateSize.fulfilled, (state, action) => {
      state.isLoading = false;
      state.cartItems = action.payload;
    });

    builder.addCase(updateSize.rejected, (state, action) => {
      state.error = action.payload;
    });

    builder.addCase(placeOrder.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(placeOrder.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(placeOrder.rejected, (state, action) => {
      state.error = action.payload;
    });

    builder.addCase(proccedToPayment.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(proccedToPayment.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.response.url) {
        window.location.href = action.payload.response.url;
      }
    });

    builder.addCase(proccedToPayment.rejected, (state, action) => {
      state.error = action.payload;
    });

    builder.addCase(addToFavourite.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(addToFavourite.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(addToFavourite.rejected, (state, action) => {
      state.error = action.error;
    });

    builder.addCase(getFavouriteList.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(getFavouriteList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.wishList = action.payload;
    });

    builder.addCase(getFavouriteList.rejected, (state, action) => {
      state.error = action.error;
    });

    // remove
    builder.addCase(removeFavoriteList.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(removeFavoriteList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.favoriteRemoveSuccess = true;
      // state.wishList = action.payload;
    });

    builder.addCase(removeFavoriteList.rejected, (state, action) => {
      state.error = action.error;
    });
  },
});
export const { calculateTotal, resetFavorite } = cartSlice.actions;
export default cartSlice.reducer;
