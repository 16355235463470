import React from "react";
import { Container } from "react-bootstrap";
import { imageURL2 } from "../../images";

const HeroRingSize = () => {
  return (
    <Container>
      <div className="w-100 mb-5 ">
        <div className="bg-light mx-auto relative min-1440px:flex min-1440px:h-full flex-col mb-14 lg:mb-20">
          <div
            className="w-100 inline-block overflow-hidden h-full relative max-1023px:aspect-6/4 max-1023px:block"
            style={{
              maxHeight: "60vh",
            }}
          >
            <div className="w-100 inline-block overflow-hidden h-full relative">
              <picture>
                {/* <source
                  srcSet="https://image.brilliantearth.com/cdn-cgi/image/width=499,height=416,quality=100,format=auto/https://cdn.builder.io/api/v1/image/assets%2F9f2a69003c86470ea05deb9ecb9887be%2Fa1e02b8861d2465b8d295fa7f7e04287"
                  media="(min-width: 0px) and (max-width: 499px)"
                />
                <source
                  srcSet="https://image.brilliantearth.com/cdn-cgi/image/width=1023,height=853,quality=100,format=auto/https://cdn.builder.io/api/v1/image/assets%2F9f2a69003c86470ea05deb9ecb9887be%2Fa1e02b8861d2465b8d295fa7f7e04287"
                  media="(min-width: 500px) and (max-width: 1023px)"
                />
                <source
                  srcSet="https://image.brilliantearth.com/cdn-cgi/image/width=1440,height=450,quality=100,format=auto/https://cdn.builder.io/api/v1/image/assets%2F9f2a69003c86470ea05deb9ecb9887be%2Fdcc491eda37e4645afb4e18882edaa6b"
                  media="(min-width: 1024px) and (max-width: 1024px)"
                />
                <source
                  srcSet="https://image.brilliantearth.com/cdn-cgi/image/width=2880,height=900,quality=100,format=auto/https://cdn.builder.io/api/v1/image/assets%2F9f2a69003c86470ea05deb9ecb9887be%2Fdcc491eda37e4645afb4e18882edaa6b"
                  media="(min-width: 1025px)"
                /> */}
                <img
                  loading="eager"
                  fetchpriority="high"
                  src={imageURL2?.image_135}
                  alt="Model wearing an assortment of yellow gold rings"
                  className="w-100 h-100 object-fit-cover "
                />
              </picture>
            </div>
          </div>
          {/* <div className="flex pointer-events-none min-1024px:max-1130px:pr-44 items-end md:items-end lg:items-center justify-center md:justify-center lg:justify-start relative inset-x-auto inset-y-auto px-4 pb-0 pt-10 md:px-0 lg:p-64 max-1023px:bg-white lg:absolute lg:top-0 lg:left-0 lg:bottom-0 lg:right-0">
            <div className="max-w-480 w-full min-1024px:max-1130px:w-420 text-center md:text-center lg:text-left text-black undefined undefined max-1023px:text-black">
              <h1 className="mb-16">
                Free Ring Sizer + Ring Size Chart: How To Measure Ring Size{" "}
              </h1>
              <div className="">
                <div className="sc-beySPh llhskx">
                  <div className="text-be-dark-gray p-0 mb-0 font-Avenir text-be-sm desktop:text-be-base [&>p]>a:underline [&>h1]>h1:normal-case [&>h1]>h1:text-be-2xl [&>h2]>h2:normal-case [&>h2]>h2:text-be-xl [&>h3]>h3:normal-case [&>h3]>h3:text-be-base [&>h4]>h4:normal-case [&>h5]>h5:normal-case [&>h6]>h6:normal-case tw-text-inherit">
                    <p>
                      We've made measuring your ring size at home easy with our
                      free ring sizer, printable ring size chart, and
                      step-by-step methods to help you find the perfect fit.
                    </p>
                  </div>
                </div>
              </div>
              <div className="pointer-events-auto flex items-center justify-center lg:justify-between md:flex-row flex-col flex-wrap md:gap-x-16 gap-y-2 lg:gap-y-4 gap-x-0" />
            </div>
          </div> */}
        </div>
      </div>
    </Container>
  );
};

export default HeroRingSize;
