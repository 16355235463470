import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { UserDetails, editUserDetails } from "../../constants/urls";

const initialState = {
  user: {},
  isLoading: false,
  error: null,
};

export const getUserdetails = createAsyncThunk("user/userDetails", async () => {
  try {
    const res = await axios.get(UserDetails, {
        headers:  {Authorization: `Bearer ${localStorage.getItem('token')}`,}
    });
    
    return res.data;
  } catch (error) {
    console.error("error in get profile: ", error);
    throw error.response.data.error;
  }
});

// export const updateRegion = createAsyncThunk("user/updateRegion", async () => {
//   try {
//     const res = await axios.get(UserDetails, {
//         headers:  {Authorization: `Bearer ${localStorage.getItem('token')}`,}
//     });
    
//     return res.data;
//   } catch (error) {
//     console.error("error in get profile: ", error);
//     throw error.response.data.error;
//   }
// });


export const UserdetailsEdit = createAsyncThunk("user/updateDetails", async (details) => {
  try {
    const res = await axios.post(editUserDetails,details,  {
        headers:  {Authorization: `Bearer ${localStorage.getItem('token')}`,}
    });
    return res.data;
  } catch (error) {
    console.error("error in update profile: ", error);
    throw error.response.data.error;
  }
});

const useDetails = createSlice({
  name: "details",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserdetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUserdetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.user = action.payload;
    });

    builder.addCase(getUserdetails.rejected, (state, action) => {
      state.error = action.error;
    });

    // update profile
    builder.addCase(UserdetailsEdit.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(UserdetailsEdit.fulfilled, (state, action) => {
      state.isLoading = false;
      state.user = action.payload;
    });

    builder.addCase(UserdetailsEdit.rejected, (state, action) => {
      state.error = action.error;
    });
  },
});

export default useDetails.reducer;
