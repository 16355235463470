import React from "react";

const AbountCounter = () => {
  return (
    <>
      <div
        className="my-6 text-primary d-none d-xs-flex justify-content-center align-items-center text-center"
        style={{
          fontSize: "20px",
          fontWeight: "bold",
          marginBottom: "80px",
        }}
      >
        <div className="p-4 px-5">
          Carbon-Neutral
          <br />
          Diamond Labs
        </div>
        <div className="p-4 px-5 border-right border-left">
          Fully-Recyclable
          <br />
          Packaging
        </div>
        <div className="p-4 px-5">Ethical</div>
      </div>
      <div
        className="text-primary d-xs-none justify-content-center align-items-center text-center "
        style={{
          fontSize: "20px",
          fontWeight: "bold",
          marginBottom: "80px",
        }}
      >
        <div className="p-4 px-5">
          Carbon-Neutral
          <br />
          Diamond Labs
        </div>
        <div className="p-4 px-5 border-top-2 border-bottom-2">
          Fully-Recyclable
          <br />
          Packaging
        </div>
        <div className="p-4 px-5">Ethical</div>
      </div>
    </>
  );
};

export default AbountCounter;
