import React from "react";

const DimondSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="w-100 h-100"
      viewBox="0 0 44.453 52.936"
      //   xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <clipPath id="clipPath">
          <rect
            id="Rectangle_156"
            data-name="Rectangle 156"
            className="w-100 h-100"
            fill="#000"
          ></rect>
        </clipPath>
      </defs>
      <g id="Group_203" data-name="Group 203" clipPath="url(#clipPath)">
        <path
          id="Path_67"
          data-name="Path 67"
          d="M37.581,33.051a9.968,9.968,0,0,1,4.728,3.462A9.848,9.848,0,0,1,43.579,46.7a9.649,9.649,0,0,1-8.145,6.15,9.873,9.873,0,0,1-9.9-4.659.759.759,0,0,0-.743-.356q-11.768.008-23.536,0C.238,47.836,0,47.6,0,46.582V1.265C0,.238.234,0,1.242,0H36.359c.976,0,1.222.249,1.222,1.234V33.051ZM1.731,46.1H24.478A10.3,10.3,0,0,1,35.847,32.578V1.738H1.731ZM34.185,34.169a8.54,8.54,0,1,0,8.52,8.482,8.548,8.548,0,0,0-8.52-8.482"
          transform="translate(0 0)"
          fill="#000"
        ></path>
        <path
          id="Path_68"
          data-name="Path 68"
          d="M26.484,62.608H14.1c-.984,0-1.232-.246-1.232-1.224,0-1.512,0-3.025,0-4.538,0-.771.3-1.071,1.062-1.071q12.6,0,25.2,0c.77,0,1.07.3,1.072,1.062q.007,2.322,0,4.645c0,.839-.284,1.125-1.117,1.126q-6.3,0-12.6,0m11.983-5.088H14.6v3.351H38.468Z"
          transform="translate(-7.743 -33.564)"
          fill="#000"
        ></path>
        <path
          id="Path_69"
          data-name="Path 69"
          d="M43.667,14.575a11.774,11.774,0,0,1-.947,5.547,10.536,10.536,0,0,1-5.39,5.369,1.331,1.331,0,0,1-1-.011,10.5,10.5,0,0,1-6.318-9.6C29.958,14.633,30,13.386,30,12.142c0-.7.3-.992,1.011-1.015A7.335,7.335,0,0,0,36.045,9c.612-.593.968-.593,1.587.006a7.34,7.34,0,0,0,5.032,2.122c.7.022.994.324,1,1.026s0,1.387,0,2.422M41.954,12.8a9.284,9.284,0,0,1-5.125-2.2,9.318,9.318,0,0,1-5.112,2.212c0,1.136-.054,2.205.01,3.268a8.8,8.8,0,0,0,4.765,7.576.867.867,0,0,0,.71-.008,8.922,8.922,0,0,0,4.034-4.385c.925-2.066.7-4.244.718-6.465"
          transform="translate(-18.044 -5.148)"
          fill="#000"
        ></path>
        <path
          id="Path_70"
          data-name="Path 70"
          d="M15.4,101.22q-2.856,0-5.712,0c-.832,0-1.113-.286-1.115-1.127q0-2.322,0-4.645c0-.764.3-1.06,1.072-1.06q5.765,0,11.53,0c.763,0,1.06.3,1.062,1.072q.006,2.349,0,4.7c0,.763-.3,1.06-1.072,1.062q-2.883.005-5.765,0M10.31,99.475H20.492V96.123H10.31Z"
          transform="translate(-5.161 -56.8)"
          fill="#000"
        ></path>
        <rect
          id="Rectangle_154"
          data-name="Rectangle 154"
          width="22.158"
          height="1.643"
          transform="translate(3.442 34.207)"
          fill="#000"
        ></rect>
        <rect
          id="Rectangle_155"
          data-name="Rectangle 155"
          width="18.714"
          height="1.663"
          transform="translate(3.46 30.789)"
          fill="#000"
        ></rect>
        <path
          id="Path_71"
          data-name="Path 71"
          d="M72.445,97.869l5.129-5.134c.693-.693,1.035-.691,1.737.01s1.411,1.406,2.112,2.114c.576.581.586.982.021,1.549q-4.11,4.117-8.225,8.227c-.554.552-.963.554-1.509.01q-2.42-2.41-4.831-4.829a.9.9,0,0,1,0-1.509q1.125-1.138,2.263-2.264a.9.9,0,0,1,1.509.006c.59.587,1.168,1.185,1.792,1.821m-.048,4.98,7.251-7.259-1.22-1.262c-.139.13-.27.245-.393.367q-2.341,2.339-4.681,4.68c-.73.729-1.062.735-1.775.014-.547-.554-1.082-1.118-1.575-1.628l-1.346,1.35,3.739,3.738"
          transform="translate(-40.001 -55.495)"
          fill="#000"
        ></path>
      </g>
    </svg>
    // <svg
    //   id="a8141bcd-065c-4d1c-a9e3-0de979645481"
    //   data-name="Layer 1"
    //   xmlns="http://www.w3.org/2000/svg"
    //   viewBox="0 0 30 30"
    //   width={40}
    // >
    //   <path
    //     id="bddcf0b1-add3-4c03-82de-4650eed3fe0d"
    //     data-name="Path 1003"
    //     className="aa4c7ed5-5690-41cd-ac00-76ec36987d32"
    //     d="M5.892,5.629,1.875,10.713,15.001,24.371,28.125,10.713,24.108,5.629Zm20.225,5.592-9.571,9.958,3.479-9.958Zm-14.526-1.1,3.4-3.388,3.4,3.388Zm-1.662,1.1,3.445,9.872L3.886,11.221ZM7.521,6.727h5.923l-3.1,3.091Zm1.615,3.4h-5.4l2.5-3.168Zm9.726,1.1L14.975,22.3549,11.092,11.227Zm3.6-4.4941L19.64,9.8239l-3.1-3.091Zm1.29.2151,2.513,3.181H20.8469Z"
    //   />
    // </svg>
  );
};

export default DimondSvg;
