import React from "react";
import { BsTelephone } from "react-icons/bs";
import { Link } from "react-router-dom";

const ContactBanner = () => {
  return (
    <div className="p-5 " style={{ background: "#ffede4" }}>
      <div className="row d-flex justify-content-between align-items-center">
        <div className="col-12 col-md-10">
          <h4 className="common-hd">Customized Care, Meaningful Interaction</h4>
          <p>
            At Andor Luxury, you’re not just a customer; you’re part of our
            lab-grown family. From custom designs to resizing, we’re here to
            make your experience exceptional.
          </p>
        </div>
        <div className="col-12 col-md-2 ">
          <Link to="/contact" className="d-flex flex-column align-items-center">
            <BsTelephone color="black" className="mb-2" size={40} />

            <p className="black-color">Contact us</p>
          </Link>
        </div>
      </div>
    </div>
    // <div
    //   className="common-banner position-relative left-text-banner common-space1 m"
    //   style={{ margin: "0px -75px 80px -75px" }}
    // >
    //   <a
    //     href="https://www.grownbrilliance.com/education-guide/sustainability-report"
    //     className="d-block"
    //     aria-label
    //     title
    //   >
    //     <div className="thumb text-center">
    //       <img
    //         src="https://media.grownbrilliance.com/a2c5372a-e16a-4ea3-b361-da9fdc89a59f/https://images.grownbrilliance.com/images/about-us/sustainability-report-desk-new.jpg"
    //         className=" d-md-block height-100"
    //         width="3840"
    //         height="1300"
    //         alt
    //         title
    //       />

    //     </div>
    //     <div className="container top-0 bottom-0 left-0 right-0 position-absolute  px-sm-0 px-5">
    //       <div className=" text-white height-100 btn-group-vertical">
    //         <h3 className="common-hd">
    //           5. Customized Care, Meaningful Interaction
    //         </h3>
    //         <p>
    //           At Andor Luxury, you’re not just a customer; you’re part of our
    //           lab-grown family. From custom designs to resizing, we’re here to
    //           make your experience exceptional.
    //         </p>
    //       </div>
    //     </div>
    //   </a>
    // </div>
  );
};

export default ContactBanner;
