import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import image10 from "../../assets/images/home/seenon/10.png";
import image2 from "../../assets/images/home/seenon/2.png";
import image3 from "../../assets/images/home/seenon/3.png";
import image4 from "../../assets/images/home/seenon/4.png";
import image5 from "../../assets/images/home/seenon/5.png";
import image6 from "../../assets/images/home/seenon/6.png";
import image7 from "../../assets/images/home/seenon/7.png";
import image8 from "../../assets/images/home/seenon/8.png";
import image9 from "../../assets/images/home/seenon/9.png";
import { getCartDetails } from "../../features/cart/cartSlice";
import { getPageReview } from "../../features/review/ReviewSlice";
import { useDynamicText } from "../../Hooks/useDynamicText";
import { imageURL2 } from "../../images";
import Footer from "../element/Footer";
import CollectionDontMiss from "./CollectionDontMiss";
import ContainerGridHome from "./ContainerGridHome";
import CustomerReviewSection from "./customerReviewSection";
import ElegantArea from "./ElegantArea";
import GetInspired from "./getInspired";
import HomeHeroSection from "./HomeHeroSection";
import ImageWithLinkHome from "./ImageWithLinkHome";
import NoDealBreaker from "./NoDealBreaker";
import Ourlook from "./Ourlook";
import ShopByCategory from "./shopByCategory";
import TabWithProductHome from "./TabWithProductHome";

const Home = () => {
  const dispatch = useDispatch();
  const Reviews = useSelector((state) => state?.reviews?.data);

  // const dynamicText = useSelector((state) => state?.pageTextReducer?.data);

  // const [text, setText] = useState(null);

  // useEffect(() => {
  //   const objectWithText = dynamicText?.reduce((curr, { id, text }) => {
  //     curr[`text_${id}`] = text;
  //     return curr;
  //   }, {});

  //   setText(objectWithText);
  // }, [dynamicText]);
  const text = useDynamicText();
  useEffect(() => {
    dispatch(getPageReview());
  }, []);
  // console.log("text", text);
  useEffect(function getInitialCartDetails() {
    dispatch(getCartDetails());
  }, []);

  return (
    <>
      <HomeHeroSection text={text} image={imageURL2} />
      <ShopByCategory />
      <NoDealBreaker />
      <ImageWithLinkHome
        link={"/eternity-bands"}
        imagelink={imageURL2.image_140}
        imageMobilelink={imageURL2.image_161}
      />

      <CollectionDontMiss text={text} imageURL2={imageURL2} />
      <ElegantArea />
      {/* <CustomerReviewSection Reviews={Reviews} /> */}
      <ImageWithLinkHome
        link={"/three-stone-engagement-rings"}
        imagelink={imageURL2.image_141}
        imageMobilelink={imageURL2.image_162}
      />
      <ContainerGridHome text={text} imageURL2={imageURL2} />
      <Ourlook imageURL2={imageURL2} />

      <ImageWithLinkHome
        link={"/tennis-bracelets"}
        imagelink={imageURL2.image_115}
        imageMobilelink={imageURL2.image_163}
      />

      {/* OUR LOOK */}

      {/* <BestNBeloved text={text} imageURL2={imageURL2} /> */}
      <TabWithProductHome text={text} imageURL2={imageURL2} />
      <ImageWithLinkHome
        link={"/jewelry-collection"}
        imagelink={imageURL2.image_142}
        imageMobilelink={imageURL2.image_164}
      />
      <CustomerReviewSection Reviews={Reviews} />
      <GetInspired inspiredDummyData={inspiredDummyData} />
      {/* <HomeReviewSection Reviews={Reviews} /> */}
      <Footer />
    </>
  );
};

export default Home;

export const inspiredDummyData = [
  // {
  //   title: "Engagement Rings",
  //   link: "/panoramic-sky-emerald-halo-pendant/AL3-JP2.EMB-2W4",
  //   image: image1,
  // },
  {
    title: "Women's Wedding Rings",
    link: "/twin-pear-toi-et-moi-ring/AL4-WTM.PSS-W4",
    image: image2,
  },
  {
    title: "Gemstone Rings",
    link: "/diamonds-droplet-necklace/AL4-JP4.RD-W4",
    image: image3,
  },
  {
    title: "Men's Wedding Rings",
    link: "/eternity-bands",
    image: image4,
  },
  {
    title: "Fine Jewelry",
    link: "/willow's-heart-studs/AL3-JER1.HT-W4",
    image: image5,
  },
  {
    title: "Fine Jewelry",
    link: "/luxe-luminary-round-bracelet/AL4-JBR1.RD-W4",
    image: image6,
  },
  {
    title: "Fine Jewelry",
    link: "/sunny-marquise-garden-pendant/AL3-JP2.MQ-2W4",
    image: image7,
  },
  {
    title: "Fine Jewelry",
    link: "/duet-of-hearts-toi-et-moi-pendant/AL4-JP2.X-W4",
    image: image8,
  },
  {
    title: "Fine Jewelry",
    link: "/sparkling-serenity-eternity-band/AL3-WEB.MQ-W4",
    image: image9,
  },
  {
    title: "Fine Jewelry",
    link: "/azure-twilight-toi-et-moi-ring/AL4-WTM.XB-W4",
    image: image10,
  },
];
