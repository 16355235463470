import classNames from 'classnames';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { imageURL, imageURL2 } from '../../../images';

const EngagementCategory = ({
    isHeaderTextWhite,
    setIsShown,
    isShown,
    isSticky,
    closeOffcanvas,
    isSmallScreen,
    path

}) => {
    const navigate = useNavigate();
    const engagement = useSelector((state) => state.navbar.navbarData.engagement);

    return (
        <>
            <Dropdown
                className="full-menu-dropdown nav-item header-section-hover-maintainer"
                onClick={() => {
                    setIsShown(!isShown ? "engagement" : false);
                }}
                onMouseEnter={() => setIsShown("engagement")}
                onMouseLeave={() => setIsShown(false)}
                show={isShown === "engagement"}
            >

                <Dropdown.Toggle
                    className={`p-0 ${isHeaderTextWhite ? "text-white" : ""
                        } btn border-0 bg-transparent nav-link d-none d-lg-block`}
                    onClick={() => {
                        navigate("/engagement");
                    }}
                >
                    ENGAGEMENT
                </Dropdown.Toggle>
                {/* Mobile view dropdown */}
                <Dropdown.Toggle
                    className={`p-0  btn border-0 bg-transparent nav-link d-flex d-lg-none text-center w-100`}
                >
                    <span
                        onClick={() => {
                            navigate("/engagement");
                            closeOffcanvas();
                        }}
                    // className="btn-close"
                    >
                        ENGAGEMENT
                    </span>
                </Dropdown.Toggle>
                <Dropdown.Menu
                    className={classNames({
                        isMenuShow: !!isSmallScreen && isShown === "engagement",
                    })}
                >
                    <div className="container">
                        <div className="row gx-lg-5">
                            <div className="col-md-8">
                                <div className="row">
                                    <div className="col-md-3">
                                        <h5 className="mb-2 fw-bold">Shop by Style</h5>
                                        <ul>
                                            {Object.keys(engagement).length > 0 &&
                                                engagement
                                                    .filter(
                                                        (item) => item.name === "Shop by Style"
                                                    )
                                                    .map((item) =>
                                                        item.level3.map((item) => (
                                                            <li
                                                                key={item.id}
                                                                onClick={closeOffcanvas}
                                                            >
                                                                <Link to={`/${item.slug}`}>
                                                                    {item.name}
                                                                </Link>
                                                            </li>
                                                        ))
                                                    )}
                                        </ul>
                                    </div>
                                    <div className="col-md-6">
                                        <h5 className="mb-2 fw-bold">Shop by Shape</h5>
                                        <ul className="shape-list">
                                            {Object.keys(engagement).length > 0 &&
                                                engagement
                                                    .filter(
                                                        (item) => item.name === "Shop by Shape"
                                                    )
                                                    .map((item) =>
                                                        item.level3.map((item) => (
                                                            <li
                                                                key={item.id}
                                                                onClick={closeOffcanvas}
                                                            >
                                                                <Link to={`/${item.slug}`}>
                                                                    <div className="diam-shap">
                                                                        <img
                                                                            src={
                                                                                imageURL[
                                                                                item.name.toLowerCase()
                                                                                ]
                                                                            }
                                                                            className="img-fluid"
                                                                            alt="Round"
                                                                        />
                                                                    </div>
                                                                    {item.name}
                                                                </Link>
                                                            </li>
                                                        ))
                                                    )}
                                        </ul>
                                    </div>
                                    <div className="col-md-3">
                                        <h5 className="mb-2 fw-bold">Shop by Metal</h5>
                                        <ul>
                                            {Object.keys(engagement).length > 0 &&
                                                engagement
                                                    .filter(
                                                        (item) => item.name === "Shop by Metal"
                                                    )
                                                    .map((item) =>
                                                        item.level3.map((item) => (
                                                            <li
                                                                key={item.id}
                                                                onClick={closeOffcanvas}
                                                            >
                                                                <Link to={`/${item.slug}`}>
                                                                    {item.name}
                                                                </Link>
                                                            </li>
                                                        ))
                                                    )}
                                        </ul>
                                        <h5 className="mb-2 fw-bold mt-5">
                                            Shop by Material
                                        </h5>
                                        <ul>
                                            {Object.keys(engagement).length > 0 &&
                                                engagement
                                                    .filter(
                                                        (item) =>
                                                            item.name === "Shop by Material"
                                                    )
                                                    .map((item) =>
                                                        item.level3.map((item) => (
                                                            <li
                                                                key={item.id}
                                                                onClick={closeOffcanvas}
                                                            >
                                                                <Link to={`/${item.slug}`}>
                                                                    {item.name}
                                                                </Link>
                                                            </li>
                                                        ))
                                                    )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <h4 className="fw-bold border-bottom mb-4 pb-2 ">
                                    Jewelry Guide
                                </h4>
                                <ul>
                                    <li>
                                        <Link to={"/engagement-ring-style"}>
                                            Engagement Ring Style
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={"/find-perfect-engagement-ring"}>
                                            Find the perfect engagement ring
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={"/find-right-metal"}>
                                            Find the right metal
                                        </Link>
                                    </li>
                                    {/* <li>
                                <Link to={"/find-ring-size"}>Find the Ring size</Link>
                              </li> */}
                                </ul>

                                <div>
                                    <div
                                        style={{
                                            // border: "1px solid black",
                                            width: "100%",
                                            height: "120px",
                                        }}
                                    >
                                        <img
                                            src={imageURL2.image_113}
                                            style={{ width: "100%", height: "100%" }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}

export default EngagementCategory