import React from 'react'
import { Accordion, Card } from 'react-bootstrap';
import { CustomToggle } from '../pages/OurPolicy';

const GlobalFaq = ({ data }) => {
    const [isOpen, setIsOpen] = React.useState("0");
    return (
        <div className='p-4'>
            <Accordion defaultActiveKey="0">
                {data?.map((item, index) => {
                    return (
                        <Card className='border-0' key={index}>
                            <Card.Header
                                style={{
                                    backgroundColor: "white",
                                    borderBottom: "1px solid #ccc",
                                }}
                            >
                                <CustomToggle
                                    eventKey={index}
                                    handleClick={() =>
                                        setIsOpen(
                                            isOpen === index ? "" : index
                                        )
                                    }
                                >
                                    <h3 className="text-black">{item?.title}</h3>
                                </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={index}>
                                <Card.Body
                                    style={{
                                        color: "#333",
                                    }}
                                >
                                    {item?.des.map((desc, index) => {
                                        return (
                                            <div className="mb-2">
                                                <p>{desc}</p>
                                            </div>
                                        );
                                    })}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    );
                })}
            </Accordion>
        </div>
    );
}

export default GlobalFaq;

