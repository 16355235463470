import React from "react";
import { Col, Row } from "react-bootstrap";
import useMediaQuery from "../../Hooks/useMeadiaQuery";

const EngagementHero = ({ text, imageURL2 }) => {
  const isAboveDesktop = useMediaQuery("(min-width: 768px)");
  // return (
  //   <section className="enagement-hero">
  //     <Row className="align-items-center">
  //       {/* <Col md="6 px-5">
  //         <Col lg="8 ps-md-5">
  //           <h1 className="mt-5 mt-md-0">{text?.text_14}</h1>
  //           <h4>{text?.text_15}</h4>
  //         </Col>
  //       </Col> */}
  //       <Col lg="12" md="12" className="text-end  engagement-hero">
  //         <img src={imageURL2.image_21} className="img-fluid" alt="Hero bg" />
  //       </Col>
  //     </Row>
  //   </section>
  // );
  return (
    <img
      src={isAboveDesktop ? imageURL2.image_21 : imageURL2?.image_148}
      className=" engagment-hero-section-master"
      alt="Hero bg"
    />
  );
};

export default EngagementHero;
