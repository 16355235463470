import { Container, Row, Col } from "react-bootstrap";
import Footer from "./element/Footer";

const ComingSoon = () => {
  return (
    <>
      <Container className="py-5 text-center">
        <Row>
          <Col>
            <h1 className="display-4">Coming Soon</h1>
            <p className="lead">Our website is under construction.</p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default ComingSoon;
