import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { currencyIcon } from "../../constants/function";
import { getCartDetails, removeFromCart } from "../../features/cart/cartSlice";
import formatCurrency from "../../helper/currencyFormater";

const ProductCartSidebar = ({
  imgUrl,
  title,
  style,
  price,
  qty,
  productCartId,
  currency,
  caption,
  sku,
  fullDeatials,
  sidemenuClose,
}) => {
  const formatedPrice = formatCurrency(price);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const countryCurrency = localStorage.getItem("currency");
  const onRemoveHandelar = async() => {
    // eslint-disable-next-line no-restricted-globals
    const conf = confirm("Are you sure you want to remove this item?");
    if (!conf) return;
// console.log(productCartId,"productCartId")
  await  dispatch(
      removeFromCart({
        productCartId,
        size: fullDeatials?.size ?? fullDeatials?.Productsize,
      })
    );
  await  dispatch(getCartDetails());

  };
  const handleClick = () => {
    // console.log(fullDeatials)
    let formattedName = fullDeatials?.title?.toLowerCase();
    // Replace all white spaces with hyphens
    formattedName = formattedName?.replace(/\s+/g, "-");
    navigate(`/${formattedName}/${fullDeatials?.sku}`, {
      state: {
        slug: fullDeatials?.category ? fullDeatials?.category : "",
      },
    });
    sidemenuClose();
  };
  return (
    <div className="sidebar-cart-container p-2 border-bottom">
      <div className="first-section gap-2">
        <img
          className="img-fluid"
          src={imgUrl}
          style={{ width: "60px", height: "60px" }}
          onClick={handleClick}
        />
        <div
          className="d-flex align-items-start flex-column"
          style={{ width: "200px" }}
        >
          <p className="m-0" onClick={handleClick}>
            {title} <span>- {style}</span>
          </p>
          {/* <p className="m-0">{style}</p> */}
          <p className="m-0">{caption}</p>
          {(fullDeatials?.size ?? fullDeatials?.Productsize) && (
            <p className="m-0">Size : {fullDeatials?.size ?? fullDeatials?.Productsize}</p>
          )}
          <p className="m-0">{sku}</p>
        </div>
      </div>
      {/* <div>
        <p>{caption}</p>
        <p>{caption}</p>
        </div> */}
      <div className="second-section">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: "24px",
          }}
        >
          {currencyIcon(currency ?? countryCurrency)}{" "}
          {/* <LiaRupeeSignSolid /> */}
          {formatedPrice}
        </div>
        <div>
          <span className="remove-sidebar-btn" onClick={onRemoveHandelar}>
            Remove
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProductCartSidebar;
