import classNames from 'classnames';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { imageURL, imageURL2 } from '../../../images';

const WeddingCategory = ({
    setIsShown,
    isShown,
    isSticky,
    closeOffcanvas,
    isSmallScreen,
    path,
    isHeaderTextWhite
}) => {
    const navigate = useNavigate();
    const wedding = useSelector((state) => state.navbar.navbarData.wedding);

    return (
        <>
            <Dropdown
                className="full-menu-dropdown nav-item"
                onClick={() => {
                    setIsShown(!isShown ? "wedding" : false);
                }}
                onMouseEnter={() => setIsShown("wedding")}
                onMouseLeave={() => setIsShown(false)}
                show={isShown === "wedding"}
            >
                <Dropdown.Toggle
                    className={`p-0 ${isHeaderTextWhite ? "text-white" : ""
                        }  btn border-0 bg-transparent nav-link d-none d-lg-block`}
                    onClick={() => {
                        navigate("/wedding");
                    }}
                >
                    WEDDING
                </Dropdown.Toggle>
                <Dropdown.Toggle className="p-0 btn border-0 bg-transparent nav-link d-lg-none text-center w-100">
                    <span
                        // className={`${isHeaderTextWhite ? "text-white" : ""} `}
                        onClick={() => {
                            navigate("/wedding");
                            closeOffcanvas();
                        }}
                    // className="btn-close"
                    >
                        WEDDING
                    </span>
                </Dropdown.Toggle>
                <Dropdown.Menu
                    className={classNames({
                        isMenuShow: !!isSmallScreen && isShown === "wedding",
                    })}
                >
                    <div className="container">
                        <div className="row gx-lg-5">
                            <div className="col-md-7">
                                <h4 className="fw-bold border-bottom mb-4 pb-2">
                                    Women
                                </h4>
                                <div className="row">
                                    <div className="col-md-3">
                                        <h5 className="mb-2 fw-bold">Shop by Style</h5>
                                        <ul>
                                            {Object.keys(wedding).length > 0 &&
                                                wedding
                                                    .filter((item) => item.name === "WOMEN")
                                                    .map((item) =>
                                                        item.level2
                                                            .filter(
                                                                (item) =>
                                                                    item.name === "Shop by Style"
                                                            )
                                                            .map((item) =>
                                                                item.level3.map((item) => (
                                                                    <li
                                                                        key={item.id}
                                                                        onClick={closeOffcanvas}
                                                                    >
                                                                        <Link to={`/${item.slug}`}>
                                                                            {item.name}
                                                                        </Link>
                                                                    </li>
                                                                ))
                                                            )
                                                    )}
                                        </ul>
                                    </div>
                                    <div className="col-md-6">
                                        <h5 className="mb-2 fw-bold">Shop by Shape</h5>
                                        <ul className="shape-list">
                                            {Object.keys(wedding).length > 0 &&
                                                wedding
                                                    .filter((item) => item.name === "WOMEN")
                                                    .map((item) =>
                                                        item.level2
                                                            .filter(
                                                                (item) =>
                                                                    item.name === "Shop by Shape"
                                                            )
                                                            .map((item) =>
                                                                item.level3.map((item) => (
                                                                    <li
                                                                        key={item.id}
                                                                        onClick={closeOffcanvas}
                                                                    >
                                                                        <Link to={`/${item.slug}`}>
                                                                            <div className="diam-shap">
                                                                                <img
                                                                                    src={
                                                                                        imageURL[
                                                                                        item.name.toLowerCase()
                                                                                        ]
                                                                                    }
                                                                                    className="img-fluid"
                                                                                    alt="Round"
                                                                                />
                                                                            </div>
                                                                            {item.name}
                                                                        </Link>
                                                                    </li>
                                                                ))
                                                            )
                                                    )}
                                        </ul>
                                    </div>
                                    <div className="col-md-3">
                                        <h5 className="mb-2 fw-bold">Shop by Metal</h5>
                                        <ul>
                                            {Object.keys(wedding).length > 0 &&
                                                wedding
                                                    .filter((item) => item.name === "WOMEN")
                                                    .map((item) =>
                                                        item.level2
                                                            .filter(
                                                                (item) =>
                                                                    item.name === "Shop by Metal"
                                                            )
                                                            .map((item) =>
                                                                item.level3.map((item) => (
                                                                    <li
                                                                        key={item.id}
                                                                        onClick={closeOffcanvas}
                                                                    >
                                                                        <Link to={`/${item.slug}`}>
                                                                            {item.name}
                                                                        </Link>
                                                                    </li>
                                                                ))
                                                            )
                                                    )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="row gx-lg-5">
                                    <div className="col-md-6">
                                        <h4 className="fw-bold border-bottom mb-4 pb-2">
                                            Men
                                        </h4>
                                        <h5 className="mb-2 fw-bold">Shop by Style</h5>
                                        <ul>
                                            {Object.keys(wedding).length > 0 &&
                                                wedding
                                                    .filter((item) => item.name === "MEN")
                                                    .map((item) =>
                                                        item.level2
                                                            .filter(
                                                                (item) =>
                                                                    item.name === "Shop by Style"
                                                            )
                                                            .map((item) =>
                                                                item.level3.map((item) => (
                                                                    <li
                                                                        key={item.id}
                                                                        onClick={closeOffcanvas}
                                                                    >
                                                                        <Link to={`/${item.slug}`}>
                                                                            {item.name}
                                                                        </Link>
                                                                    </li>
                                                                ))
                                                            )
                                                    )}
                                        </ul>
                                    </div>
                                    <div className="col-md-6">
                                        <h4 className="fw-bold border-bottom mb-4 pb-2">
                                            Collection
                                        </h4>
                                        <ul>
                                            {Object.keys(wedding).length > 0 &&
                                                wedding
                                                    .filter(
                                                        (item) => item.name === "COLLECTION"
                                                    )
                                                    .map((item) =>
                                                        item.level2.map((item) => (
                                                            <li
                                                                key={item.id}
                                                                onClick={closeOffcanvas}
                                                            >
                                                                <Link to={`/${item.slug}`}>
                                                                    {item.name}
                                                                </Link>
                                                            </li>
                                                        ))
                                                    )}
                                        </ul>

                                        <div>
                                            <div
                                                style={{
                                                    width: "100%",
                                                    height: "120px",
                                                }}
                                            >
                                                <img
                                                    src={imageURL2.image_114}
                                                    style={{ width: "100%", height: "100%" }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}

export default WeddingCategory