import React from 'react'
import BagFill from '../../assets/svgs/bagFill'

const CartIcon = ({
    isHeaderTextWhite = false,
    data = [],
    onClick = () => { },
    countStyle,
    title = ""
}) => {
    return (
        <>
            <div
                className="menu-slide-icon mobile-bag  cursor-pointer btn-bag"
                // className="menu-slide-icon mobile-bag position-relative btn-bag p-0 cursor-pointer"
                style={{ zIndex: "99999" }}
                onClick={onClick}
            >
                <span className="menu-icon">

                {data?.length > 0 && (
                    <div style={{ ...countStyle }}>
                        <p
                            className={`${isHeaderTextWhite ? "text-transperant" : "text-white"
                                }`}
                        >
                            {data?.reduce((acc, item) => acc + item.qty, 0) ?? 0}
                        </p>
                    </div>
                )}
                <BagFill
                    className={`
                        ${isHeaderTextWhite ? "sv-bag-start" : "sv-bag"}
                        ${data?.length > 0
                            ? isHeaderTextWhite
                                ? "filled-start"
                                : "filled"
                            : ""
                        }`}
                />
                </span>
                {title}
            </div>
        </>
    )
}

export default CartIcon