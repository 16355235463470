import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { navbarContentUrl } from "../../constants/urls";
import axios from "axios";

const initialState = {
  contents: [],
  isLoading: false,
  error: null,
  navbarData: {
    engagement: {},
    wedding: {},
    jewelry: {},
  },
};

export const fetchNavbarContent = createAsyncThunk(
  "content/fetchNavbarContent",
  async () => {
    const res = await axios(navbarContentUrl);
    const data = await res.data;
    return data;
  }
);

export const navbarContentSlice = createSlice({
  name: "navbarContent",
  initialState,
  reducers: {
    setEngagementData: (state, action) => {
      state.navbarData.engagement = action.payload;
    },
    setWeddingData: (state, action) => {
      state.navbarData.wedding = action.payload;
    },
    setJewelryData: (state, action) => {
      state.navbarData.jewelry = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNavbarContent.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchNavbarContent.fulfilled, (state, action) => {
      state.isLoading = false;
      state.contents = action.payload;
    });
    builder.addCase(fetchNavbarContent.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export const { setEngagementData, setWeddingData, setJewelryData } =
  navbarContentSlice.actions;
export default navbarContentSlice.reducer;
