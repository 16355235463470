import React from 'react'
import { Container, Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const ImageWithDetailSec = ({
    title = "",
    description = "",
    buttonText = "",
    buttonLink = "#",
    imagePath,
    imageAlt = "Andor",
    textPosition = "left",
    subTitle
}) => {
    return (
        <div className="mt-4 mb-4 resize-area pt-5 pb-5 image-with-detail-sec">
            <Container>
                <Container>
                    <div class={`d-flex ${textPosition === "left" ? "cms-img-text-item-left " : "cms-img-text-item-right"} align-items-center  subSplash-split-image-banner-item-right`}>
                        <div class="p-5 d-flex flex-column gap-3 text-item-content subSplash-item-content col-lg-6">
                            {subTitle && <span className='text-item-sub-title'>{subTitle}</span>}
                            <h2>{title}</h2>
                            <p className='text-item-description'>
                                {description}
                            </p>
                            <div class="cms-img-text-item-btns-wrapper subSplash-split-image-banner-item-btns-wrapper">
                                <Link
                                    to={buttonLink}
                                    title="FIND YOUR RING SIZE"
                                    type="button"
                                    className="btn btn-secondary btn-theme"
                                    style={{
                                        width: "250px",
                                    }}
                                >
                                    {buttonText}
                                </Link>
                            </div>
                        </div>
                        <div class={`item-img-wrapper subSplash-split-image-banner-item-img-wrapper col-lg-6 d-lg-flex ${textPosition === "left" ? "justify-content-start " : "justify-content-end"}`}>
                            <Image
                                src={imagePath}
                                title={imageAlt ?? title}
                                alt={imageAlt ?? title}
                                maxWidth={720}
                                maxHeight={560}
                                style={{
                                    // maxWidth:720,
                                    // maxHeight:460,
                                    position:"relative",
                                    height:"100%",
                                    width:"100%"
                                    // aspectRatio : "720/560"
                                }}
                                class="cms-picture-base subSplash-split-image-banner-picture"
                            />
                        </div>
                    </div>
                </Container>
            </Container>
        </div>
    )
}

export default ImageWithDetailSec