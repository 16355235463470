import React from "react";
import Footer from "../element/Footer";
import GlobalTitle from "../common/global-title";

const Privacy = () => {
  return (
    <>
      <main className="container">
        <section className="static-page container">
          {/* <div className="container">
            <div className="row">
              <div className="col-12"> */}
          {/* <div className="mb-2 mb-md-4 text-center"> */}
          <GlobalTitle title="Privacy Policy" />
          {/* <h2 className="fnormal cust-h2">Privacy Policy</h2> */}
          {/* </div> */}
          {/* </div> */}
          <div className="">
            <h3 className="sub-hd1 mb-3">INTRODUCTION AND OVERVIEW</h3>
            <p>
              Thank you for visiting Andor Luxury operated by VABS Diamond
              (collectively hereinafter "VABS Diamond" or "Andor Luxury" or "we"
              or "our" or "us") we respect your privacy and understand and share
              your concern about the privacy of your personal information. This
              notice describes our privacy policies. By visiting Andor Luxury
              website. you are confirming your agreement to the privacy policies
              described in this Privacy Policy, if you do not agree to these
              terms, exit this page and not access or use the website.
            </p>
            <p>ENJOY SECURE SHOPPING</p>
            <p>
              We use encryption technology, digital certificates, secure
              commerce servers, and authentication to ensure that your personal
              information is secure online.
            </p>
            <h3 className="sub-hd1 mb-2 pt-3">
              INFORMATION COLLECTION AND USE
            </h3>
            <p>
              We collect and store any information that you may enter on any of
              our websites or provide to us in some other way. For example, when
              you place an order and/or sign up for email communications and
              updates, we collect and store personal information so that we may
              provide the products and services that you have ordered or
              requested. Such information may include your name, address, age,
              date of birth, gender, education level, email address, telephone
              and fax numbers, credit card information and information about
              your interests and activities. You can choose not to provide
              certain types of information, but that may result in you being
              unable to use certain features of our website. We may also collect
              and store information about other people that you provide to us.
              For example, you may submit a person’s name and address if you
              wish us to ship a product as a gift to a friend.
            </p>
            <p>
              We may also automatically collect and store certain types of
              non-personally identifiable information whenever you visit any of
              our web pages. For example, like most commercial shopping
              websites, we use “cookies” and we obtain certain types of
              information whenever your web browser accesses the Andor Luxury
              website. Such information may include the type of web browser you
              are using, the operating system of your computer and the domain
              name of your internet service provider. Most browsers accept
              cookies automatically but allow you to disable them. We recommend
              that you leave cookies “turned on” so that we can offer you a
              better shopping experience. Utilities are available from third
              parties to help you visit websites anonymously, but the use of
              such utilities may also prevent us from providing you with the
              best shopping experience at Andor Luxury.
            </p>
            <p>
              All personal information that we collect from our customers is
              used to improve our relevance to you and to improve your shopping
              experience with us.
            </p>
            <p>
              We use the information that you provide so that we may provide the
              products and services that you have ordered or requested. In
              addition, we may send you (via email or other means) offerings of
              products or services which we think may be of interest to you or
              we may ask you to participate in activities such as special
              surveys. At any time, you can choose to no longer receive any
              non-transactional emails from Andor Luxury. You may also
              unsubscribe from other campaigns that you have previously opted to
              receive by following the instructions on the bottom of the
              specific campaign.
            </p>
            <p>
              We will not share the personal information which you provide to us
              except
            </p>
            <ul>
              <li>for the purposes that you provided it to us,</li>
              <li>with your prior consent,</li>
              <li>
                as may be required by law as we reasonably deem necessary to
                protect Andor Luxury or others from injury or loss, or
              </li>
              <li>
                with other persons or entities with whom we contract to provide
                services in connection with our website or other business
                activities.
              </li>
            </ul>
            <p>
              If we do share such personal information with a third party, we
              will, to the extent practical, require that the recipient protect
              that information in a manner consistent with this Privacy Policy.
              However, we cannot guarantee how such a third party will use
              personal information.
            </p>
            <p>
              We sometimes use non-personally identifiable, summary or aggregate
              information that we collect to improve the design and content of
              our website and to enable us to further enhance your shopping
              experience with Andor Luxury. We may also share such aggregate
              information with third parties for that purpose.
            </p>
            <p>
              The personal information which you provide to Andor Luxury is an
              asset of the company and may be included in the assets transferred
              to an acquirer if Andor Luxury is acquired by a third party. In
              the event of such a transfer, any personal information transferred
              shall remain subject to the terms and promises contained in this
              Privacy Policy.
            </p>
            <h3 className="sub-hd1 mb-2 pt-3">CHANGES IN PRIVACY POLICY</h3>
            <p>
              SMS notifications are sent only to those who have subscribed to
              Andor Luxury newsletters or updates. Subscribers’ or customers’
              information will not be shared elsewhere, we respect your privacy.
            </p>
            <h3 className="sub-hd1 mb-2 pt-3 pt-3">SECURITY</h3>
            <p>
              At Andor Luxury, we believe to have in place appropriate
              procedures to protect the security of personal information
              transmitted to us or by us. We have designed our website to
              encrypt such information during transmission using Secure Sockets
              Layer software.
            </p>
            <h3 className="sub-hd1 mb-2 pt-3 pt-3">ACCESS TO CORRECTION</h3>
            <p>
              Andor Luxury gives you access to the personal information that we
              have collected about you and the ability to correct any errors in
              such information. For instructions on how to access and/or make
              corrections to your personal information please contact +91
              7600510079 Children’s privacy
            </p>
            <p>
              Our website is not directed toward children and Andor Luxury does
              not sell products for purchase by children. We do not knowingly
              collect information from anyone under the age of eighteen.
            </p>
            <h3 className="sub-hd1 mb-2 pt-3">PHISHING</h3>
            <p>
              Identity theft and the practice currently known as “phishing” are
              of great concern to Platform. Safeguarding information to help
              protect You from identity theft is a top priority. We do not and
              will not, at any time, request your credit card information or
              national identification numbers in a non-secure or unsolicited
              e-mail or telephone communication.
            </p>
            <h3 className="sub-hd1 mb-2 pt-3">CHANGES IN PRIVACY POLICY</h3>
            <p>
              From time to time We may update this Privacy Policy. Your
              continued subscription to our services constitutes an acceptance
              of the then-current Privacy Policy and Terms of Use so we
              encourage You to visit this page periodically to review any
              changes.
            </p>
            <h3 className="sub-hd1 mb-2 pt-3">
              TERMS OF USE, NOTICES AND REVISION
            </h3>
            <p>
              If you visit Andor Luxury's website, your visit and any dispute
              concerning privacy will be subject to this Privacy Notice and our
              Terms of Use. Our business continues to develop and evolve and
              this Privacy Notice and our Terms of Use will accordingly change
              from time to time. We may send you reminders of our privacy
              policies and terms from time to time, by email or otherwise,
              unless you have told us not to do so. However, you should check
              our website from time to time to inform yourself of any changes.
              The form of our Privacy Policy in effect from time to time will
              apply to all information that we have collected.
            </p>
            <h3 className="sub-hd1 mb-2 pt-3">CONTACT US</h3>
            <p>
              If you have questions about this privacy policy, or the
              information practices, please contact us as follows:
            </p>
            <p>
              By email:{" "}
              <a href="mailto:info@andorluxury.com">info@andorluxury.com</a>
            </p>
          </div>
          {/* </div>
          </div> */}
        </section>
      </main>
      <Footer />
    </>
  );
};

export default Privacy;
