import { Form, Formik } from "formik";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { passwordChangeSchema } from "../../../../Validation/Schema/UserDetailsSchema";
import classNames from "classnames";
import axios from "axios";
import { token, updatePassword_url } from "../../../../constants/urls";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";

function ChangePassword() {

  const navigate = useNavigate()

  const initialState = {
    old_password: "",
    new_password: "",
    confirmPassword: "",
  };

  return (
    <div className="mb-3 py-3 ">
      <div className="">
        <Formik
          initialValues={initialState}
          validationSchema={passwordChangeSchema}
          onSubmit={(values, { resetForm }) => {
            axios
              .post(updatePassword_url, values, {
                headers: { Authorization: `Bearer ${token}` },
              })
              .then((res) => {
                toast.success("Password Changed Successful");
                resetForm();
              })
              .catch((error) => {
                toast.error("something wrong!!!");
              });
          }}
        >
          {({ handleChange, errors, touched, values }) => {
            return (
              <Form className="auth-form p-0">
                <Row className="justify-content-center">
                  <Col md={6} sm={12} className="my-3">
                    <div className="py-3">
                      <div className="mb-3">
                        <label htmlFor="" className="form-label">
                          Old Password *
                        </label>
                        <input
                          name="old_password"
                          className={classNames("form-control", {
                            "error-border":
                              errors.old_password && touched.old_password,
                          })}
                          onChange={handleChange}
                          placeholder="Old Password"
                          type="password"
                          value={values.old_password}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="" className="form-label">
                          New Password *
                        </label>
                        <input
                          name="new_password"
                          className={classNames("form-control", {
                            "error-border":
                              errors.new_password && touched.new_password,
                          })}
                          onChange={handleChange}
                          placeholder="New Password"
                          value={values.new_password}
                          type="password"
                        />
                        {errors.new_password && touched.new_password && (
                          <div className="text-danger fw-normal">
                            {errors.new_password}
                          </div>
                        )}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="" className="form-label">
                          Re-Type New Password *
                        </label>
                        <input
                          name="confirmPassword"
                          className={classNames("form-control", {
                            "error-border":
                              errors.confirmPassword && touched.confirmPassword,
                          })}
                          onChange={handleChange}
                          placeholder="Re-type Password"
                          value={values.confirmPassword}
                          type="password"
                        />
                        {errors.confirmPassword && touched.confirmPassword && (
                          <div className="text-danger fw-normal">
                            {errors.confirmPassword}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="">
                      <Row>
                        <Col className="text-start">
                          <div
                            variant="link"
                            className="text-decoration-none fs-5"
                            style={{ cursor: "pointer", color: "#e77e50" }}
                            onClick={() => {
                              navigate('/profile')
                            }}
                          >
                            &lt; Back
                          </div>
                        </Col>
                        <Col className="text-end">
                          <Button
                            variant="primary"
                            className="px-4 py-2"
                            type="submit"
                          >
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default ChangePassword;
