import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { userSingin } from "../../features/user/loginSlice";
import { Link, useNavigate } from "react-router-dom";
import { LuEye, LuEyeOff } from "react-icons/lu";

const LoginForm = ({ sm }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const dispatch = useDispatch();
  const error = useSelector((state) => state?.singin?.error?.message);

  const onUserLogin = async () => {
    try {
      if (email.trim().length > 0 && password.length > 0) {
        const userInfo = { email, password };

        dispatch(userSingin(userInfo));
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const loginReducer = useSelector((state) => state.singin);
  const navigate = useNavigate();
  useEffect(() => {
    if (!!loginReducer.success) {
      // navigate("/");
      window.location.assign("/");
    }
  }, [loginReducer]);

  useEffect(() => {
    if (password.trim().length > 0 && email.trim().length > 0) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [email, password, isButtonDisabled]);

  const [isShow, setIsShow] = useState(false);

  return (
    <div className="d-flex">
      <form
        className="auth-form"
        style={{
          width: sm ? "250px" : "500px",
          margin: "auto",
          marginTop: "20px",
        }}
      >
        <h5 className="mb-3 text-center">Sign in to your account</h5>
        <div className="mb-3">
          <label>Email</label>
          <Form.Control
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        <div className="mb-3">
          <label>Password</label>
          <div className="position-relative">
            <Form.Control
              type={isShow ? "text" : "password"}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              style={{ paddingRight: "30px" }}
            />

            <div
              className="position-absolute"
              style={{ top: "10px", right: "10px", cursor: "pointer" }}
              onClick={() => setIsShow(!isShow)}
            >
              {isShow ? <LuEye /> : <LuEyeOff />}
            </div>
          </div>

          <Link
            to={"/forgot-password"}
            className="text-primary text-end d-block mt-2 small"
          >
            Forgot password ?
          </Link>
          {error && (
            <p className="text-danger" style={{ color: "red" }}>
              {error}
            </p>
          )}
        </div>
        <button
          className="btn w-100 me-0"
          type="button"
          onClick={onUserLogin}
          disabled={isButtonDisabled}
        >
          Sign in
        </button>
        <p className="text-center mt-4 mb-0 small">
          Don't have an Account?{" "}
          <a href="/signup" className="btn-link text-primary">
            {" "}
            Create an Account
          </a>
        </p>
      </form>
    </div>
  );
};

export default LoginForm;
