import React from "react";

const BagFill = (props) => {
  return (
    <svg
      // stroke={props}
      // fill={props}

      strokeWidth="0"
      viewBox="0 0 256 256"
      height="23px"
      width="23px"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {/* Background rectangle */}
      <rect className={``} x="26.39" y="60" width="203.22" height="160"></rect>
      {/* Bag path */}
      <path
        d="M243.86,197.65l-14.25-120A20.06,20.06,0,0,0,209.67,60H179.83A52,52,0,0,0,76.17,60H46.33A20.06,20.06,0,0,0,26.39,77.65l-14.25,120A20,20,0,0,0,32.08,220H223.92a20,20,0,0,0,19.94-22.35ZM128,36a28,28,0,0,1,27.71,24H100.29A28,28,0,0,1,128,36ZM36.5,196,49.81,84H76v20a12,12,0,0,0,24,0V84h56v20a12,12,0,0,0,24,0V84h26.19L219.5,196Z"
        // fill={props}
        {...props}
      ></path>
    </svg>
  );
};

export default BagFill;
