import * as Yup from 'yup';

export const generateYupSchema = (fields) => {
  const schema = {};

  // for (const formType in fields) {
  //   const formFields = fields[formType];
  // const formSchema = {};

  fields?.forEach(field => {
    let validator = null;

    switch (field.type) {
      case 'email':
        validator = Yup.string().email('Invalid email').required('Email is required');
        break;
      case 'text':
        validator = Yup.string();
        if (field.name === 'phone') {
          validator = validator.matches(/^[0-9]{10}$/, 'Invalid phone number');
        }
        if (field.required) {
          validator = validator.required(`${field.label} is required`);
        }
        break;
      case 'textarea':
        validator = Yup.string();
        if (field.required) {
          validator = validator.required(`${field.label} is required`);
        }
        break;
      case 'file':
        validator = Yup.mixed();
        if (field.required) {
          validator = validator.required(`${field.label} is required`);
        }
        break;
      case 'select':
        validator = Yup.string();
        if (field.required) {
          validator = validator.required(`${field.label} is required`);
        }
        break;
      default:
        validator = Yup.string();
    }

    schema[field.name] = validator;
  });

  // schema[formType] = Yup.object().shape(formSchema);
  // }

  return Yup.object().shape(schema);;
};

