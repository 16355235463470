import React from 'react';
import { Button, Modal } from 'react-bootstrap';

function DeleteConfirmationModal({show, setShow, setDeleteId, handleDelete}) {
    // const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false)
        setDeleteId(null)
    };
    // const handleShow = () => setShow(true);

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={handleDelete}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default DeleteConfirmationModal;
