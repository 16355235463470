import classNames from 'classnames';
import React from 'react';
import { Dropdown, Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { imageURL2 } from '../../../images';

const JewelryCategory = ({
    setIsShown,
    isShown,
    isSticky,
    closeOffcanvas,
    isSmallScreen,
    path,
    isHeaderTextWhite
}) => {
    const navigate = useNavigate();
    const jewelry = useSelector((state) => state.navbar.navbarData.jewelry);

    return (
        <>
            <Dropdown
                className="full-menu-dropdown nav-item"
                onClick={() => {
                    setIsShown(!isShown ? "jewelry" : false);
                }}
                onMouseEnter={() => setIsShown("jewelry")}
                onMouseLeave={() => setIsShown(false)}
                show={isShown === "jewelry"}
            >
                <Dropdown.Toggle
                    className={`p-0 btn ${isHeaderTextWhite ? "text-white" : ""
                        } border-0 bg-transparent nav-link d-none d-lg-block`}
                    onClick={() => {
                        navigate("/jewelry");
                        setIsShown(false);
                    }}
                >
                    JEWELRY
                </Dropdown.Toggle>
                <Dropdown.Toggle
                    className={`p-0 btn  border-0 bg-transparent nav-link d-lg-none text-center w-100`}
                >
                    <span
                        onClick={() => {
                            navigate("/jewelry");
                            closeOffcanvas();
                        }}
                    // className="btn-close"
                    >
                        JEWELRY
                    </span>
                </Dropdown.Toggle>

                <Dropdown.Menu
                    className={classNames({
                        isMenuShow: !!isSmallScreen && isShown === "jewelry",
                    })}
                >
                    <div className="container">
                        <div className="row gx-lg-5">
                            <div className="col-md-9">
                                <div className="row">
                                    <div className="col-md-2 col-lg">
                                        <h5 className="mb-2 fw-bold">Rings</h5>
                                        <ul>
                                            {Object.keys(jewelry).length > 0 &&
                                                jewelry
                                                    .filter((item) => item.name === "Rings")
                                                    .map((item) =>
                                                        item.level2.map((item) => (
                                                            <li
                                                                key={item.id}
                                                                onClick={closeOffcanvas}
                                                            >
                                                                <Link to={`/${item.slug}`}>
                                                                    {item.name}
                                                                </Link>
                                                            </li>
                                                        ))
                                                    )}
                                        </ul>
                                    </div>
                                    <div className="col-md-2 col-lg">
                                        <h5 className="mb-2 fw-bold">Earrings</h5>
                                        <ul>
                                            {Object.keys(jewelry).length > 0 &&
                                                jewelry
                                                    .filter(
                                                        (item) => item.name === "Earrings"
                                                    )
                                                    .map((item) =>
                                                        item.level2.map((item) => (
                                                            <li
                                                                key={item.id}
                                                                onClick={closeOffcanvas}
                                                            >
                                                                <Link to={`/${item.slug}`}>
                                                                    {item.name}
                                                                </Link>
                                                            </li>
                                                        ))
                                                    )}
                                        </ul>
                                    </div>
                                    <div className="col-md-2 col-lg">
                                        <h5 className="mb-2 fw-bold">Necklaces</h5>
                                        <ul>
                                            {Object.keys(jewelry).length > 0 &&
                                                jewelry
                                                    .filter(
                                                        (item) => item.name === "Necklaces"
                                                    )
                                                    .map((item) =>
                                                        item.level2.map((item) => (
                                                            <li
                                                                key={item.id}
                                                                onClick={closeOffcanvas}
                                                            >
                                                                <Link to={`/${item.slug}`}>
                                                                    {item.name}
                                                                </Link>
                                                            </li>
                                                        ))
                                                    )}
                                        </ul>
                                    </div>
                                    <div className="col-md-2 col-lg">
                                        <h5 className="mb-2 fw-bold">Bracelets</h5>
                                        <ul>
                                            {Object.keys(jewelry).length > 0 &&
                                                jewelry
                                                    .filter(
                                                        (item) => item.name === "Bracelets"
                                                    )
                                                    .map((item) =>
                                                        item.level2.map((item) => (
                                                            <li
                                                                key={item.id}
                                                                onClick={closeOffcanvas}
                                                            >
                                                                <Link to={`/${item.slug}`}>
                                                                    {item.name}
                                                                </Link>
                                                            </li>
                                                        ))
                                                    )}
                                        </ul>
                                    </div>
                                    <div className="col-md-2 col-lg">
                                        <h5 className="mb-2 fw-bold">Men's</h5>
                                        <ul>
                                            {Object.keys(jewelry).length > 0 &&
                                                jewelry
                                                    .filter((item) => item.name === "Men's")
                                                    .map((item) =>
                                                        item.level2.map((item) => (
                                                            <li
                                                                key={item.id}
                                                                onClick={closeOffcanvas}
                                                            >
                                                                <Link to={`/${item.slug}`}>
                                                                    {item.name}
                                                                </Link>
                                                            </li>
                                                        ))
                                                    )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <Image
                                    src={imageURL2.image_20}
                                    alt="Jwelery Image"
                                    className=" w-100 h-100 relative"
                                />
                            </div>
                        </div>
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}

export default JewelryCategory