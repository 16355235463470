import { configureStore } from "@reduxjs/toolkit";
import todosReducer from "../features/todo/todosSlice";
import navbarContentSlice from "../features/navbar/navbarSlice";
import productsListSlice from "../features/productsList/productsListSlice";
import singleProductSlice from "../features/productsList/singleProductSlice";
import singupSilce from "../features/user/singupSilce";
import loginSlice from "../features/user/loginSlice";
import cartSlice from "../features/cart/cartSlice";
import addressSlice from "../features/address/addressSlice";
import orderSlice from "../features/productsList/orderSlice";
import useDetails from "../features/user/getUserDetailsSlice";
import getPageTextSlice from "../features/text/getTextSlice";
import collectionSlice from "../features/Collections/collectionSlice";
import jewelrySlicer from "../features/jewelry/jewelrySlicer";
import getHomeReviewSlice from "../features/review/ReviewSlice";
export const store = configureStore({
  reducer: {
    todos: todosReducer,
    navbar: navbarContentSlice,
    products: productsListSlice,
    singleProduct: singleProductSlice,
    singup: singupSilce,
    singin: loginSlice,
    cart: cartSlice,
    address: addressSlice,
    order: orderSlice,
    userDetails: useDetails,
    pageTextReducer: getPageTextSlice,
    collectionsReducer: collectionSlice,
    jewelryReducer: jewelrySlicer,
    reviews: getHomeReviewSlice,
  },
});
