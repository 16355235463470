/* eslint-disable no-restricted-globals */
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Breadcrumb, Container, Tab, Tabs } from "react-bootstrap";
import Slider from "react-slick";
import Footer from "../element/Footer";

import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getSingleProduct,
  resetDetails,
} from "../../features/productsList/singleProductSlice";
import formatCurrency from "../../helper/currencyFormater";
// import {
//   LiaDollarSignSolid,
//   LiaPoundSignSolid,
//   LiaRupeeSignSolid,
// } from "react-icons/lia";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import toast from "react-hot-toast";
import ReactImageMagnify from "react-image-magnify";
import { LinkContainer } from "react-router-bootstrap";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import HeartFill from "../../assets/svgs/heartFill";
import {
  currencyIcon,
  getRingSize,
  getSuggestionProduct,
} from "../../constants/function";
import { baseUrl } from "../../constants/urls";
import {
  braceletSize,
  colorsVariant,
  necklesSize,
} from "../../constants/utils";
import {
  addToCart,
  addToFavourite,
  getCartDetails,
  getFavouriteList,
  removeFavoriteList,
} from "../../features/cart/cartSlice";
import { convertToTitleCase } from "../../helper/constant";
import EngagementFAQ from "../Engagement/EngagementFAQ";
import ImageSliderNew from "../Slider/imageSlidernew";
import DealBreakerSection from "./deal-breaker-section";
import ProductDetailTab from "./detailtab/product-detail-tab";
import ReviewTab from "./detailtab/review-tab";

const faqs = {
  1: [
    {
      question: "How much should I spend on an engagement ring?",
      answer:
        "No matter what, diamonds are a huge investment. However, there is no 'one size fits all' answer. Andor Luxury has a wide range of engagement rings to suit all budgets.",
    },
    {
      question: "What size diamond is best for an engagement ring?",
      answer:
        "The most popular diamond size chosen for engagement rings is a 1.0 carat center stone. However, couples can opt for both smaller and larger diamonds. Another great and more affordable option at Andor Luxury is lab grown diamonds. Lab grown diamonds are the next generation of diamonds that give you the benefit of purchasing a larger size diamond at a more affordable price.",
    },
    {
      question: "What are the different diamond shapes for engagement rings?",
      answer:
        "There is a selection of diamond shapes to choose from. These include round, princess, cushion, emerald, oval, radiant, Asscher, marquise, pear and heart shaped diamonds.",
    },
    {
      question: "How to choose diamond engagement rings?",
      answer:
        "Buying an engagement ring comes with many questions. At Andor Luxury, we're here to offer you 100% free advice and will assist you in understanding the best diamond quality for your budget and unique preferences. Our non-commissioned diamond and jewelry experts are available 24/7. Contact us here.",
    },
    {
      question: "What are the 4Cs?",
      answer:
        "The 4Cs include carat (size), color, clarity and cut, and are the globally accepted standards by the Gemological Institute of America (GIA) used to assess the quality of the diamond.",
    },
    {
      question: "How to measure ring size?",
      answer:
        "There are several methods available to determine your ring size (or the ring size of a loved one). Please click here for a full list of the options available to you.",
    },
    {
      question: "What hand do engagement rings go on?",
      answer:
        "Traditionally, an engagement ring is worn on the left hand. However, in many countries and cultures around the world this may differ.",
    },
  ],
  2: [
    {
      question: "When should the wedding rings be purchased?",
      answer:
        "It is recommended that wedding rings be purchased 3 to 4 months prior to the wedding.",
    },
    {
      question: "Who buys the wedding rings?",
      answer:
        "Traditionally, each partner buys the wedding ring for the other person. Many couples also prefer to choose and buy wedding rings together.",
    },
    {
      question: "What metal should a wedding ring be?",
      answer:
        "The wedding ring should be any metal you choose! Gold is the most common metal and provides the most options for any style or budget due to its availability in yellow, white, or rose. Platinum, a luxurious, rare, and durable metal, is also becoming increasingly popular.",
    },
    {
      question: "Where do you wear a wedding ring?",
      answer:
        "Wedding rings are most commonly worn on the same finger as an engagement ring, which is frequently the ring finger on the left hand.However, this practice is different for cultures and countries.",
    },
    {
      question: "How much do wedding rings cost?",
      answer:
        " There is no precise amount a wedding ring should cost. Prices will vary depending on the amount and sizes of diamonds and gemstones, as well as the metal choice. Luckily, Andor Luxury carries wedding rings to fit every budget.",
    },
    {
      question: "Do wedding rings need to match?",
      answer:
        "In a word: no! Wedding rings can match each other, can complement the engagement ring, and/or can reflect the wearer’s individual style.",
    },
    {
      question:
        "What is the difference between a wedding ring and an eternity ring?",
      answer:
        "Wedding rings traditionally feature diamonds halfway around the band or are plain metal only, while eternity rings feature diamonds that go all the way around the band.",
    },
    {
      question: "When do you buy an eternity ring?",
      answer:
        "An eternity ring is typically given on an anniversary or a special occasion, including the birth of a child. Many choose to use an eternity ring as a wedding ring.",
    },
    {
      question: "How much does an eternity ring cost?",
      answer:
        "Just like wedding rings, there is no precise amount an eternity ring should cost, as the price is affected by the number of diamonds and gemstones, as well as the metal choice. Also like wedding rings, Andor Luxury carries a variety of eternity rings to fit your budget.",
    },
    {
      question: "How do you figure out the ring size?",
      answer:
        "It is easier than you think to figure out the ring size. Andor Luxury provides multiple options including a virtual ring sizer. Click here for more information.",
    },
    {
      question: "What do the 4Cs mean?",
      answer:
        "The 4Cs stand for carat, cut, color, and clarity. The 4Cs are the worldwide standard accepted by the Gemological Institute of America (GIA),to determine a diamond’s quality.",
    },
  ],
  3: [
    {
      question: "What are GIA, AGS, and IGI diamond certificates?",
      answer:
        "A Gemological Institute of America (GIA), American Gem Society (AGS), or International Gemological Institute (IGI) diamond certificate assures the value and quality of your diamond. GIA, AGS, and IGI are the gold standard in diamond grading. Their reports detail key characteristics of each diamond.",
    },

    {
      question: " What does 'Cut' mean?",
      answer:
        "A diamond's cut determines how effectively it returns light to the viewer's eye. A well-cut diamond appears brilliant and fiery, while poorly cut stones look dark and lifeless. Well-cut diamonds often appear larger than stones of the same carat weight. ",
    },
    {
      question: "What does the term 'eye-clean' mean?",
      answer:
        "'Eye-Clean' is a term used to describe a diamond that is clean to an unaided eye in a face-up position from a distance of 8 to 10 inches. Diamonds of VS2 or higher in quality will usually be eye-clean. Diamonds of SI1, SI2, and I1 need to be visually inspected to determine whether any inclusions are visible from the top. We can inspect any diamond at no charge.",
    },
    {
      question: "What is the quality of the diamonds used in your jewelry?",
      answer:
        "Smaller diamonds (often called melee) are cut in factories around the world and are available in a variety of qualities.",
    },
    {
      question: "Do the 18K white gold rings turn yellow over time?",
      answer:
        "Yes, very slightly. In order to give 18K White Gold a pure white color, it is plated with rhodium. This is a standard practice for 14K and 18K White Gold jewelry. Over time, the rhodium plating may wear off.",
    },
    {
      question: "How often do you add diamonds to your inventory?",
      answer: "We add new diamonds to our inventory on a daily basis.",
    },
  ],
};

const Detail = () => {
  const [size, setSize] = useState(0);
  const [Productsize, setProductSize] = useState(null);

  const token = localStorage.getItem("token");
  const { sku, title } = useParams();
  // console.log(sku, title, 'as')
  const [image, setImage] = useState("");
  const [itemDetails, setItemDetails] = useState([]);
  const [isProduct, setIsProduct] = useState(0);
  const [isFaq, setIsFaq] = useState(0);
  // const [selectedColor, setSelectedColor] = useState({
  //   color: colorsVariant?.["silver"],
  //   key: "silver",
  // });
  const [diamondDetails, setDiamondDetails] = useState([]);
  const [sideDiamondDetails, setSideDiamondDetails] = useState([]);
  const [imageGallery, setImageGallery] = useState([]);
  const [productPrice, setProductPrice] = useState("");
  // console.log("imageGallery", imageGallery);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [productVariant, setProductVariant] = useState({});

  const [res_slug, setRes_slug] = useState("");
  const productDetailsReducer = useSelector((state) => state.singleProduct);

  const [reviewCount, setReviewCount] = useState(0);
  const [reviewList, setReviewList] = useState([]);
  const [avgReview, setAvgReview] = useState(0);
  // const handleSelect = (color, key) => {
  //   setSelectedColor({ color, key });
  // };
  // const availableColors = product
  //   ? Object?.keys(product)?.filter((key) => product?.[key])
  //   : {};
  useEffect(() => {
    if (!!product) {
      const getData = async () => {
        const res = await axios.get(
          `${baseUrl}/api/product-review?product_id=${product?.id}`
        );
        const data = await res.data;
        setReviewCount(data?.count);
        setReviewList(data?.reviews);
        const listOfReviewStar = data?.reviews.map((item) => item.star);
        const avg = Math.ceil(
          listOfReviewStar?.reduce((a, b) => a + b, 0) /
            listOfReviewStar?.length
        );
        setAvgReview(avg);
      };
      getData();
    }
  }, [product]);
  const [deliveryDate, setDeliveryDate] = useState(
    new Date(new Date().getTime() + 15 * 24 * 60 * 60 * 1000)
  );

  useEffect(() => {
    if (productDetailsReducer?.success) {
      // const produtInfo = transformProductData(
      //   productDetailsReducer.data,
      //   countryCurrency
      // );
      // return {
      //   ...produtInfo,
      // };
      setProduct(productDetailsReducer.data);
      setProductVariant({
        silver: productDetailsReducer?.product_variation_sku?.white,
        yellow: productDetailsReducer?.product_variation_sku?.yellow,
        rose: productDetailsReducer?.product_variation_sku?.rose,
      });
      setRes_slug(
        !!productDetailsReducer.slug ? productDetailsReducer.slug : ""
      );
      setIsProduct(productDetailsReducer?.isProduct);
      setIsFaq(productDetailsReducer?.data?.category_id);
      dispatch(resetDetails());
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    } else if (productDetailsReducer?.error) {
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  }, [productDetailsReducer]);

  const location = useLocation();
  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const [countryCurrency, setCountryCurrency] = useState("INR");

  useEffect(() => {
    setCountryCurrency(localStorage.getItem("currency"));
  }, []);

  useEffect(() => {
    setLoading(true);
    // console.log("first", location?.state?.slug);

    dispatch(getSingleProduct({ sku: sku, slug: location?.state?.slug ?? "" }));
  }, [sku, location?.state?.slug]);

  useEffect(() => {
    if (!!product) {
      const price =
        countryCurrency == "INR"
          ? product?.product_price
          : countryCurrency == "USD"
          ? product?.usa_price
          : countryCurrency == "GBP"
          ? product?.uk_price
          : product?.usa_price;
      const formatedPrice = formatCurrency(price);
      setProductPrice(formatedPrice);
    }
  }, [product]);

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          width: "25px",
          color: "#333",
          top: "-30px",
          left: "50%",
          transform: "translateX(-50%)",
        }}
        onClick={onClick}
      >
        &lsaquo;
      </div>
    );
  };

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          width: "25px",
          color: "#333",
          left: "50%",
          transform: "translateX(-50%)",
        }}
        onClick={onClick}
      >
        &rsaquo;
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    // adaptiveHeight: true,
    prevArrow: imageGallery.length > 4 ? <PrevArrow /> : <></>,
    nextArrow: imageGallery.length > 4 ? <NextArrow /> : <></>,
  };

  useEffect(() => {
    if (!!product) {
      const itemDetailsObject = JSON.parse(product.item_detail);
      const itemDetails = Object.entries(itemDetailsObject).map(
        ([key, value]) => ({ key: key, value: value })
      );

      const diamondDetailsObj = JSON.parse(product.diamond_detail);
      if (Object.keys(diamondDetailsObj).length !== 0) {
        const diamondDetails = Object.entries(diamondDetailsObj)
          .filter(([key, value]) => !!value)
          .map(([key, value]) => ({ key: key, value: value }));
        setDiamondDetails(diamondDetails);
      }

      if (!!product.side_diamond_detail) {
        const sideDiamondDetailsObj = JSON.parse(product.side_diamond_detail);
        const flag = !!sideDiamondDetailsObj["SD1"]
          ? sideDiamondDetailsObj["SD1"]
          : sideDiamondDetailsObj; // tempory for old diamond details
        if (Object.keys(flag).length !== 0) {
          const diamondDetails = Object.entries(flag)
            .filter(([key, value]) => !!value)
            .map(([key, value]) => ({ key: key, value: value }));
          setSideDiamondDetails(diamondDetails);
        }
      }
      setImage(product.media[0].original);
      setItemDetails(itemDetails);

      setImageGallery(product.media);
    }
  }, [product, imageGallery]);

  const onAddToCartHandelar = async (redirect) => {
    // await dispatch(addToCart({ product_id: product.id, qty: 1 }));
    if (!Productsize && product?.product_type !== 1)
      return toast.error("Please select size");
    alert("Product has been added to your cart.");
    await dispatch(addToCart({ ...product, Productsize }));
    await dispatch(getCartDetails());
    if (redirect) {
      window.location.href = "/cart";
    }
  };

  const [suggestProduct, setSuggestProduct] = useState([]);

  const getSuggestionProductHandler = async (product) => {
    getSuggestionProduct(product).then((suggestionData) => {
      setSuggestProduct(suggestionData);
    });
  };

  useEffect(() => {
    if (!!product) {
      // const suggestionData = getSuggestionProduct(product);
      getSuggestionProductHandler(product);
      // const getData = async () => {
      //   const user = JSON?.parse(localStorage.getItem("user"));
      //   const res = await axios.get(
      //     `${baseUrl}/api/suggestion?product_id=${product?.id}${
      //       user?.id ? `&user_id=${user?.id}` : ""
      //     }`
      //   );
      //   const data = await res.data;
      //   const formatData = await data?.data?.map((item) => {
      //     const produtInfo =
      //       item?.product_variation || item?.item?.product_variation
      //         ? transformProductData(item, countryCurrency)
      //         : newTransformProductData(item, countryCurrency);
      //     return {
      //       ...produtInfo,
      //     };
      //   });
      // console.log(suggestionData);
      // setSuggestProduct(suggestionData);
      // };
      // getData();
    }
  }, [product]);

  const handleFavorite = async (productData) => {
    // if (!isLogin()) return toast.error("Oops, Please login to add to wishlist");
    alert("Product has been added to your wishlist.");
    await dispatch(addToFavourite(productData));
    await dispatch(getFavouriteList());
  };

  const handleRemove = async (id) => {
    // if (!isLogin())
    //   return toast.error("Oops, Please login to Remove to wishlist");
    const userdata = JSON.parse(localStorage.getItem("user"));
    const category = localStorage.getItem("category");
    const conf = confirm(
      "Are you sure you want to remove from your wishlist item?"
    );
    if (!conf) return;
    await dispatch(removeFavoriteList(id));
    await dispatch(getFavouriteList());
    // await dispatch(
    //   fetchProducts({ category: category, page: 1, userId: userdata?.id })
    // );
  };
  const [isMobile, setIsMobile] = useState(false);
  // const transformWrapperRef = useRef(null);
  // const [isZoomedIn, setIsZoomedIn] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const transformWrapperRef = useRef(null);
  const [isZoomedIn, setIsZoomedIn] = useState(false);
  const [tapTimeout, setTapTimeout] = useState(null);
  const tapTimeoutRef = useRef(null);
  const zoomScale = 2;

  const handleTap = (e) => {
    if (tapTimeoutRef.current) {
      clearTimeout(tapTimeoutRef.current);
      tapTimeoutRef.current = null;
      handleDoubleClick(e);
    } else {
      tapTimeoutRef.current = setTimeout(() => {
        tapTimeoutRef.current = null;
      }, 300);
    }
  };

  const handleDoubleClick = (e) => {
    if (transformWrapperRef.current) {
      const { zoomIn, resetTransform, setTransform } =
        transformWrapperRef.current;

      if (isZoomedIn) {
        resetTransform(200);
      } else {
        const { clientX, clientY } = e;
        const rect = e.target.getBoundingClientRect();

        const offsetX = clientX - rect.left;
        const offsetY = clientY - rect.top;

        const newX = offsetX * (zoomScale - 1);
        const newY = offsetY * (zoomScale - 1);

        setTransform(-newX, -newY, zoomScale, 200);
      }
      setIsZoomedIn((prevZoom) => !prevZoom);
    }
  };
  useEffect(() => {
    if (transformWrapperRef.current) {
      const { zoomIn, resetTransform, setTransform } =
        transformWrapperRef.current;
      resetTransform(200);
    }
  }, [window?.location?.pathname]);
  if (!!product) {
    return (
      <>
        {/* <Container className="page-title text-start">
          <h5>Home - Contact us</h5>
        </Container> */}

        <div className="container my-3">
          <Breadcrumb>
            <LinkContainer to="/" className="link-breadcrumb">
              <Breadcrumb.Item>Home</Breadcrumb.Item>
            </LinkContainer>
            <LinkContainer to={`/${res_slug}`} className="link-breadcrumb">
              <Breadcrumb.Item>{convertToTitleCase(res_slug)}</Breadcrumb.Item>
            </LinkContainer>
            {/* <Breadcrumb.Item active>{sku}</Breadcrumb.Item> */}
          </Breadcrumb>
        </div>

        {isProduct == 0 ? (
          <Container className="mt-5">
            <div
              style={{
                fontSize: "30px",
                fontWeight: 500,
                textAlign: "center",
              }}
            >
              This product is not available
            </div>
          </Container>
        ) : (
          <>
            {/* Begin:: Product Detail */}
            <Container className="mt-5">
              <div className="row gx-xxl-4">
                <div className="col-lg-7 mb-4 mb-lg-0 p-0">
                  <div className="product-showcase">
                    {size > 991 && (
                      <div className="img-thumbs" style={{ height: "100px" }}>
                        <Slider {...settings}>
                          {imageGallery.map((item, index) => {
                            return (
                              <div key={index}>
                                <img
                                  src={item.thumb}
                                  className="img-fluid"
                                  alt={item.thumb}
                                  style={{
                                    cursor: "pointer",
                                    width: "100%",
                                    height: "auto",
                                    marginRight: "0px",
                                    marginBottom: "20px",
                                  }}
                                  onClick={() => {
                                    setImage(item.original);
                                  }}
                                />
                              </div>
                            );
                          })}
                        </Slider>
                      </div>
                    )}
                    {/* <div className="main-display" data-aos="zoom-in">
                      <ReactImageMagnify
                        {...{
                          smallImage: {
                            alt: "productImage",
                            isFluidWidth: true,
                            src: image,
                          },
                          largeImage: {
                            src: image,
                            width: 2000,
                            height: 2000,
                          },
                        }}
                      />
                    </div> */}
                    <div className="main-display" data-aos="zoom-in">
                      {isMobile ? (
                        <>
                          <div onClick={handleTap}>
                            <TransformWrapper
                              ref={transformWrapperRef}
                              doubleClick={{ disabled: true }}
                            >
                              <TransformComponent>
                                <img
                                  src={image}
                                  alt="Zoomable"
                                  style={{ width: "100%", cursor: "pointer" }}
                                  onClick={handleTap}
                                />
                              </TransformComponent>
                            </TransformWrapper>
                          </div>
                        </>
                      ) : (
                        <ReactImageMagnify
                          {...{
                            smallImage: {
                              alt: "productImage",
                              isFluidWidth: true,
                              src: image,
                            },
                            largeImage: {
                              src: image,
                              width: 2000,
                              height: 2000,
                            },
                          }}
                        />
                      )}
                    </div>
                    {size > 991 ? (
                      <div className="show-horizon img-thumbs">
                        {/* <img src={imageURL.showImg2} className="img-fluid " alt="Product 1" />
                                      <img src={imageURL.showImg3} className="img-fluid" alt="Product 1" />
                                      <img src={imageURL.showImg4} className="img-fluid " alt="Product 1" />
                                      <img src={imageURL.showImg4} className="img-fluid" alt="Product 1" />
                                      <img src={imageURL.showImg4} className="img-fluid" alt="Product 1" /> */}
                      </div>
                    ) : (
                      <>
                        {/* For mobile  */}
                        <div className="img-thumbs all-thumbs d-flex">
                          {product?.media?.map((item, index) => {
                            return (
                              <>
                                <img
                                  key={index}
                                  src={item.thumb}
                                  className="img-fluid"
                                  alt={item.thumb}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    if (transformWrapperRef.current) {
                                      const { zoomIn, resetTransform } =
                                        transformWrapperRef.current;

                                      resetTransform();

                                      setIsZoomedIn(false);
                                    }
                                    setImage(item.original);
                                    // setIsZoomedIn(true);
                                    // handleDoubleClick();
                                  }}
                                />
                              </>
                            );
                          })}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="product-detail">
                    <div
                      className="ratting d-flex mb-1"
                      style={{
                        fontSize: "12px",
                        color: "#777777",
                      }}
                    >
                      <div className="mb-0">
                        {[1, 2, 3, 4, 5]?.map((item) => {
                          if (avgReview == 0) {
                            return <i className="fa-solid fa-star"></i>;
                          } else if (item <= avgReview) {
                            return (
                              <i className="fa-solid fa-star text-primary"></i>
                            );
                          } else {
                            return <i className="fa-solid fa-star"></i>;
                          }
                        })}
                      </div>
                      <div className="mb-0 ms-2">{reviewCount} review</div>
                    </div>
                    <div
                      style={{
                        fontSize: "24px",
                        marginBottom: "5px",
                        fontWeight: 600,
                      }}
                    >
                      {product.title}
                    </div>
                    <div style={{ fontSize: "16px", marginBottom: "10px" }}>
                      {product.caption}
                    </div>

                    {!!productPrice && (
                      <div
                        className="product-price mb-3"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "24px",
                        }}
                      >
                        {currencyIcon(countryCurrency)}{" "}
                        {/* {countryCurrency === "INR" ? (
                          <LiaRupeeSignSolid />
                        ) : countryCurrency === "USD" ? (
                          <LiaDollarSignSolid />
                        ) : countryCurrency == "GBP" ? (
                          <LiaPoundSignSolid />
                        ) : (
                          <LiaDollarSignSolid />
                        )} */}
                        {productPrice}
                      </div>
                    )}

                    {!!productVariant && (
                      <div
                        className="color-select"
                        style={{ justifyContent: "start" }}
                      >
                        {Object?.keys(productVariant)?.map((key, index) => {
                          if (!!!productVariant?.[key]) return;
                          return (
                            <div
                              key={index}
                              className={`main-color ${
                                productVariant?.[key] === sku ? "selected" : ""
                              } ${
                                productVariant?.[key]
                                  ? "cursor-pointer"
                                  : "not-allowed"
                              }`}
                            >
                              <div
                                key={index}
                                className={`color-circle ${
                                  productVariant?.[key] === sku
                                    ? "selected"
                                    : ""
                                }`}
                                style={{ background: colorsVariant?.[key] }}
                                onClick={() =>
                                  productVariant?.[key] &&
                                  navigate(`/${title}/${productVariant?.[key]}`)
                                }
                              ></div>
                            </div>
                          );
                        })}
                      </div>
                    )}

                    <div>
                      <div
                        className="form-group mb-3"
                        style={{
                          fontSize: "18px",
                          // width: "50%",
                        }}
                      >
                        <label htmlFor="" className="mb-2">
                          {product?.product_type == 0
                            ? "Ring Size"
                            : product?.product_type == 2
                            ? "Chain Length(Inches)"
                            : product?.product_type == 3
                            ? "Length(Inches)"
                            : ""}
                        </label>
                        <div className="d-flex flex-row gap-2 align-items-center ">
                          {product?.product_type == 0 && (
                            <select
                              className="form-select w-auto"
                              onChange={(e) => {
                                setProductSize(e.target.value);
                              }}
                              defaultValue={"Please Select"}
                            >
                              <option value={""}> Please Select</option>
                              {getRingSize(countryCurrency)?.map((el) => {
                                return (
                                  <option value={el?.value}>{el?.label}</option>
                                );
                              })}
                            </select>
                          )}
                          {product?.product_type == 2 && (
                            <select
                              className="form-select w-auto"
                              onChange={(e) => {
                                setProductSize(e.target.value);
                              }}
                              defaultValue={"Please Select"}
                            >
                              <option value={""}> Please Select</option>
                              {necklesSize?.map((el) => {
                                return (
                                  <option value={el?.value}>{el?.label}</option>
                                );
                              })}
                            </select>
                          )}
                          {product?.product_type == 3 && (
                            <select
                              className="form-select w-auto"
                              onChange={(e) => {
                                setProductSize(e.target.value);
                              }}
                              defaultValue={"Please Select"}
                            >
                              <option value={""}> Please Select</option>
                              {braceletSize?.map((el) => {
                                return (
                                  <option value={el?.value}>{el?.label}</option>
                                );
                              })}
                            </select>
                          )}
                          <div className="guide-info mb-0 d-flex flex-row align-items-center ">
                            <a href="/buying-guide/ring-size/" className="mb-0">
                              <i className="fa-solid fa-chart-gantt"></i> Size
                              Guide
                            </a>
                          </div>
                        </div>
                      </div>
                      <div
                        className="mb-3"
                        style={{
                          display: "flex",
                          // justifyContent: "center",
                          // alignItems: "center",
                          gap: "10px",
                          // width: "50%",
                        }}
                      >
                        <div
                        // style={{
                        //   flexGrow: "1",
                        // }}
                        >
                          <button
                            // className="  p-2"
                            onClick={() => onAddToCartHandelar()}
                            style={{
                              fontSize: "18px",
                              color: "#ffffff",
                              backgroundColor: "#23351D",
                              textWrap: "nowrap",
                            }}
                          >
                            Add to cart
                          </button>
                        </div>
                        <div>
                          <button
                            type="button"
                            className="btn"
                            // onClick={() => {
                            //   handleFavorite(product?.id);
                            //   setProduct((pre) => {
                            //     return {
                            //       ...pre,
                            //       favourite: !product?.favourite,
                            //     };
                            //   });
                            // }}
                          >
                            {product?.favourite ? (
                              <HeartFill
                                className="wishlist-heart"
                                fillColor={"#000"}
                                strokeColor={"#000"}
                                onClick={() => {
                                  handleRemove(product?.id);
                                  setProduct((pre) => {
                                    return {
                                      ...pre,
                                      favourite: !product?.favourite,
                                    };
                                  });
                                }}
                              />
                            ) : (
                              <HeartFill
                                className="wishlist-heart"
                                fillColor={"none"}
                                strokeColor={"#000"}
                                onClick={() => {
                                  handleFavorite(product);
                                  setProduct((pre) => {
                                    return {
                                      ...pre,
                                      // favourite: true,
                                      favourite: !!!product?.favourite,
                                    };
                                  });
                                }}
                              />
                            )}
                            {/* {product?.favourite ? (
                              <i
                                className="fa-solid fa-heart"
                                onClick={() => {
                                  handleRemove(product?.id);
                                  setProduct((pre) => {
                                    return {
                                      ...pre,
                                      favourite: !product?.favourite,
                                    };
                                  });
                                }}
                              ></i>
                            ) : (
                              <i
                                className="fa-regular fa-heart"
                                onClick={() => {
                                  handleFavorite(product);
                                  setProduct((pre) => {
                                    return {
                                      ...pre,
                                      // favourite: true,
                                      favourite: !!!product?.favourite,
                                    };
                                  });
                                }}
                              ></i>
                            )} */}
                            {/* <i className="fa-regular fa-heart"></i> */}
                          </button>
                        </div>
                      </div>
                      <div
                        className="mb-3"
                        style={{
                          width: "50%",
                        }}
                      >
                        {!!productPrice ? (
                          <button
                            type="button"
                            className="btn btn-buy w-100 mb-0"
                            onClick={() => {
                              // window.location.href = "/cart";
                              onAddToCartHandelar(true);
                            }}
                          >
                            Buy it Now
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-buy w-100 mb-0"
                          >
                            Enquire Now
                          </button>
                        )}
                      </div>
                    </div>

                    <div className="guide-info">
                      {/* <a href="/buying-guide/ring-size/">
                        <i className="fa-solid fa-chart-gantt"></i> Size Guide
                      </a> */}
                      {/* <a href="#">
                    <i className="fa-solid fa-arrow-rotate-left"></i> Delivery &
                    Return
                  </a> */}
                      <a href="#">
                        <i className="fa-regular fa-clock"></i> Estimated
                        Delivery:{" "}
                        <span className="text-muted">
                          {" "}
                          {deliveryDate.toDateString()}
                        </span>
                      </a>
                    </div>

                    {/* <p className="text-muted">
                  Made-to-Order | Free Shipping | Free Returns
                </p> */}
                  </div>
                </div>
              </div>
            </Container>
            {/* End:: Product Detail */}

            {/* Begin::  Long detail */}
            <Container className="product-desc">
              <Tabs
                defaultActiveKey="home"
                className="mb-sm-3 mb-4 flex-column flex-md-row"
                // className="custom-tab selling-tabs"
                variant="underline"
                style={
                  {
                    // justifyContent: "center",
                    // alignItems: "center",
                    // flexDirection: "column",
                  }
                }
              >
                <Tab eventKey="home" title="Product Detail">
                  <ProductDetailTab
                    diamondDetails={diamondDetails}
                    itemDetails={itemDetails}
                    sideDiamondDetails={sideDiamondDetails}
                  />
                </Tab>

                <Tab eventKey="contact" title="Reviews">
                  <ReviewTab reviewList={reviewList} />
                </Tab>

                <Tab eventKey="faq" title="FAQ">
                  {isFaq !== 0 ? (
                    <EngagementFAQ isHeaderShow={false} faqs={faqs?.[isFaq]} />
                  ) : null}
                  {/* <div className="review-section">FAQ Section</div> */}
                  {/* <Accordion defaultActiveKey="0">
                    {isFaq !== 0 &&
                      faqs?.[isFaq]?.map((item, index) => {
                        // console.log("item😥", item);
                        return (
                          <Card style={{ border: "none" }} key={index}>
                            <Card.Header
                              style={{
                                backgroundColor: "white",
                                borderBottom: "1px solid #ccc",
                              }}
                            >
                              <CustomToggle
                                eventKey={index}
                                handleClick={() => {}}
                              >
                                <h3>{item?.question}</h3>
                              </CustomToggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={index}>
                              <Card.Body
                                style={{
                                  color: "#333",
                                  fontSize: "20px",
                                }}
                              >
                                {item?.answer}
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        );
                      })}
                  </Accordion> */}
                </Tab>
              </Tabs>

              <div className="mt-5">
                <div
                  style={{
                    fontWeight: "600",
                    fontSize: "28px",
                  }}
                >
                  Related Products
                </div>
                <div className="row">
                  <ImageSliderNew
                    data={suggestProduct}
                    productKey="suggestionProduct"
                    getOnclick={() => getSuggestionProductHandler(product)}
                  />
                </div>
              </div>

              {/* Begin:: Review form */}

              {!!token && (
                <div className="row col-lg-10 col-xl-8 mx-auto review-form">
                  <div className="col-md-12">
                    <h3 className="text-center mb-4">Write Your own review</h3>
                    <div className="review-ratting  mb-3">
                      Product Rating :
                      <div className="rating-group ms-2">
                        <label
                          aria-label="1 star"
                          className="rating__label"
                          htmlFor="rating3-1"
                        >
                          <i className="rating__icon rating__icon--star fa fa-star"></i>
                        </label>
                        <input
                          className="rating__input"
                          name="rating3"
                          id="rating3-1"
                          value="1"
                          type="radio"
                        />
                        <label
                          aria-label="2 stars"
                          className="rating__label"
                          htmlFor="rating3-2"
                        >
                          <i className="rating__icon rating__icon--star fa fa-star"></i>
                        </label>
                        <input
                          className="rating__input"
                          name="rating3"
                          id="rating3-2"
                          value="2"
                          type="radio"
                        />
                        <label
                          aria-label="3 stars"
                          className="rating__label"
                          htmlFor="rating3-3"
                        >
                          <i className="rating__icon rating__icon--star fa fa-star"></i>
                        </label>
                        <input
                          className="rating__input"
                          name="rating3"
                          id="rating3-3"
                          value="3"
                          type="radio"
                        />
                        <label
                          aria-label="4 stars"
                          className="rating__label"
                          htmlFor="rating3-4"
                        >
                          <i className="rating__icon rating__icon--star fa fa-star"></i>
                        </label>
                        <input
                          className="rating__input"
                          name="rating3"
                          id="rating3-4"
                          value="4"
                          type="radio"
                        />
                        <label
                          aria-label="5 stars"
                          className="rating__label"
                          htmlFor="rating3-5"
                        >
                          <i className="rating__icon rating__icon--star fa fa-star"></i>
                        </label>
                        <input
                          className="rating__input"
                          name="rating3"
                          id="rating3-5"
                          value="5"
                          type="radio"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="" className="form-label">
                        Name*
                      </label>
                      <input className="form-control" type="text" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="" className="form-label">
                        Email*
                      </label>
                      <input className="form-control" type="text" />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="" className="form-label">
                        Your Review*
                      </label>
                      <textarea className="form-control" rows={7}></textarea>
                    </div>
                  </div>
                  <div className="col-md-12 text-center">
                    <button
                      type="button"
                      className="btn btn-secondary btn-theme"
                    >
                      {" "}
                      Submit
                    </button>
                  </div>
                </div>
              )}
              {/* Begin:: Review form */}
            </Container>
            {/*  End::  Long detail */}
          </>
        )}

        {/* NO DEALBREAKERS */}
        <DealBreakerSection />

        {/* Footer */}
        <Footer
          style={{
            marginTop: "0px",
          }}
        />
      </>
    );
  }

  return (
    <div className="vw-100 vh-100 border d-flex justify-content-center align-items-center">
      {!!loading && (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
    </div>
  );
};

export default Detail;
