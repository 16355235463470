import React from "react";
import { Breadcrumb, Col, Container } from "react-bootstrap";
import Footer from "./Footer";
import { Formik } from "formik";
import { forgotPasswordSchema } from "../../Validation/Schema/UserDetailsSchema";
import { LinkContainer } from "react-router-bootstrap";

function ForgotPassword() {
  return (
    <>
      <Container className="page-title text-start">
        <Breadcrumb>
          <LinkContainer to="/" className="link-breadcrumb">
            <Breadcrumb.Item>Home</Breadcrumb.Item>
          </LinkContainer>
          <Breadcrumb.Item active>Forgot Password</Breadcrumb.Item>
        </Breadcrumb>
      </Container>

      <Container>
        <Col lg={8} xl={7} className="mx-auto">
          <h2 className="text-center fw-bold mb-3">Forgot Password</h2>
          <div className="text-center mb-5">
            Please enter your email address for password.
          </div>
          <Formik
            initialValues={{ email: "" }}
            validationSchema={forgotPasswordSchema}
            onSubmit={(values, { setSubmitting }) => {
            }}
          >
            {({ handleChange, errors, touched }) => (
              <form className="auth-form">
                <div className="form-group mb-3 ">
                  <label htmlFor="" className="form-label">
                    Email *
                  </label>
                  <input
                    // type="email"
                    name="email"
                    className="form-control"
                    onChange={handleChange}
                    style={{
                      borderRadius: "0px !important",
                      height: "44px !important",
                    }}
                  />

                  {errors.email && touched.email ? (
                    <div className="text-danger">{errors.email}</div>
                  ) : null}
                </div>

                <div className="text-center mt-5">
                  <button
                    type="submit"
                    className="btn btn-secondary btn-theme px-5"
                  >
                    Submit
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </Col>
      </Container>

      <Footer />
    </>
  );
}

export default ForgotPassword;
