import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  getEngagementCollection,
  getJewelryCollection,
  getWeddingCollection,
} from "../../constants/urls";
import { isLogin } from "../../constants/function";
const token = localStorage.getItem("token");
let config = {
  headers: {
    'Authorization': 'Bearer ' + token
  }
}
const initialState = {
  engagement: {
    loading: false,
    success: false,
    data: [],
    error: false,
  },
  wedding: {
    loading: false,
    success: false,
    data: [],
    error: false,
  },
  jewelry: {
    loading: false,
    success: false,
    data: [],
    error: false,
  },
};

export const getEngagementCollectionAction = createAsyncThunk(
  "collection/engagement",
  async () => {
    const res = await axios(getEngagementCollection, config);
    if (isLogin()) {
      const data = await res.data;
      return data;
    } else {
      const data = await res.data;
      const localData = JSON.parse(localStorage.getItem("wishlist")) || [];
      data.data = data.data.map((item) => {
        // Iterate over the keys (e.g., "white", "yellow", "rose")
        const updatedItem = {};
        for (const key in item) {
          if (item.hasOwnProperty(key)) {
            const localItem = localData.find((i) => i.id === item[key].id);

            const isFavourite = localItem ? localItem.product?.favourite : item[key].favourite;

            updatedItem[key] = {
              ...item[key],
              favourite: isFavourite,
            };
          }
        }
        return updatedItem;
      });
      return data;
    }
  }
);
export const getWeddingCollectionAction = createAsyncThunk(
  "collection/wedding",
  async () => {
    const res = await axios(getWeddingCollection, config);
    if (isLogin()) {
      const data = await res.data;
      return data;
    } else {
      const data = await res.data;
      const localData = JSON.parse(localStorage.getItem("wishlist")) || [];
      data.data = data.data.map((item) => {
        // Iterate over the keys (e.g., "white", "yellow", "rose")
        const updatedItem = {};
        for (const key in item) {
          if (item.hasOwnProperty(key)) {
            const localItem = localData.find((i) => i.id === item[key].id);

            const isFavourite = localItem ? localItem.product?.favourite : item[key].favourite;

            updatedItem[key] = {
              ...item[key],
              favourite: isFavourite,
            };
          }
        }
        return updatedItem;
      });
      return data;
    }
  }
);

export const getJewelryCollectionAction = createAsyncThunk(
  "collection/jewelry",
  async () => {
    const res = await axios(getJewelryCollection, config);
    if (isLogin()) {
      const data = await res.data;
      return data;
    } else {
      const data = await res.data;
      const localData = JSON.parse(localStorage.getItem("wishlist")) || [];
      data.data = data.data.map((item) => {
        // Iterate over the keys (e.g., "white", "yellow", "rose")
        const updatedItem = {};
        for (const key in item) {
          if (item.hasOwnProperty(key)) {
            const localItem = localData.find((i) => i.id === item[key].id);

            const isFavourite = localItem ? localItem.product?.favourite : item[key].favourite;

            updatedItem[key] = {
              ...item[key],
              favourite: isFavourite,
            };
          }
        }
        return updatedItem;
      });
      return data;
    }
  }
);

export const collectionSlice = createSlice({
  name: "collection",
  initialState,
  reducers: {
    // setEngagementData: (state, action) => {
    //   state.navbarData.engagement = action.payload;
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(getEngagementCollectionAction.pending, (state) => {
      state.engagement.loading = true;
    });
    builder.addCase(
      getEngagementCollectionAction.fulfilled,
      (state, action) => {
        state.engagement.loading = false;
        state.engagement.success = true;
        state.engagement.data = action.payload;
      }
    );
    builder.addCase(getEngagementCollectionAction.rejected, (state, action) => {
      state.engagement.loading = false;
      state.engagement.error = action.error.message;
    });

    //    wedding
    builder.addCase(getWeddingCollectionAction.pending, (state) => {
      state.wedding.loading = true;
    });
    builder.addCase(getWeddingCollectionAction.fulfilled, (state, action) => {
      state.wedding.loading = false;
      state.wedding.success = true;
      state.wedding.data = action.payload;
    });
    builder.addCase(getWeddingCollectionAction.rejected, (state, action) => {
      state.wedding.loading = false;
      state.wedding.error = action.error.message;
    });

    //    jewelry
    builder.addCase(getJewelryCollectionAction.pending, (state) => {
      state.jewelry.loading = true;
    });
    builder.addCase(getJewelryCollectionAction.fulfilled, (state, action) => {
      state.jewelry.loading = false;
      state.jewelry.success = true;
      state.jewelry.data = action.payload;
    });
    builder.addCase(getJewelryCollectionAction.rejected, (state, action) => {
      state.jewelry.loading = false;
      state.jewelry.error = action.error.message;
    });
  },
});

export const { } = collectionSlice.actions;
export default collectionSlice.reducer;
