import React from 'react';

const SelectedAddressCard = ({ backHandler = () => { }, isNew, newAddress, selectedAddress, stateList, countryList }) => {
    const stateId = newAddress?.state ? newAddress?.state : newAddress?.state_id;
    // firstname: item?.firstname,
    // lastname: item?.lastname,
    // // name: item.name,
    // name:
    //   (item?.firstname || item?.lastname)
    //     ? `${item?.firstname ?? ""} ${
    //         item?.lastname ?? ""
    //       }`
    //     : item?.name ?? "",
    return (
        <>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "",
                }}
            >
                <div className="address-info">
                    <h5 className="fw-bold">
                        {isNew
                            ?      (newAddress?.firstname || newAddress?.lastname)
                            ? `${newAddress?.firstname ?? ""} ${
                                newAddress?.lastname ?? ""
                              }`
                            : newAddress?.name ?? ""
                            :   (selectedAddress?.firstname || selectedAddress?.lastname)
                            ? `${selectedAddress?.firstname ?? ""} ${
                                selectedAddress?.lastname ?? ""
                              }`
                            : selectedAddress?.name ?? ""}
                    </h5>
                    <p className="mb-0">
                        {isNew
                            ? newAddress?.address1
                            : selectedAddress?.address1}
                    </p>
                    <p className="mb-0">
                        {isNew
                            ? newAddress?.address2
                            : selectedAddress?.address2}
                    </p>
                    <p className="mb-0">
                        {isNew
                            ? stateList?.find(
                                (state) =>
                                    state?.id ==
                                    stateId
                            )?.name
                            : stateList?.find(
                                (state) =>
                                    state?.id ==
                                    selectedAddress?.state
                            )?.name}
                        ,{" "}
                        {isNew
                            ? newAddress?.city
                            : selectedAddress?.city}
                        ,{" "}
                        {isNew
                            ? newAddress?.postal_code
                            : selectedAddress?.postal_code}
                    </p>
                    <p className="mb-0">
                        {isNew
                            ? countryList?.find(
                                (c) =>
                                    c?.id == newAddress?.country
                            )?.name
                            : countryList?.find(
                                (c) =>
                                    c?.id ==
                                    selectedAddress?.country
                            )?.name}
                    </p>
                    {/* <p className="mb-0">(740) 947-0033</p> */}
                </div>
                <div className="text-end">
                    <button
                        className="btn btn-link fw-bold text-primary"
                        onClick={backHandler}
                    >
                        back
                    </button>
                    {/* <button
                                      className="btn btn-link fw-bold text-primary"
                                      onClick={() => {
                                        if (!!isNew) {
                                          setisNewForm(true);
                                        } else {
                                          setIsEditBilling(true);
                                        }
                                      }}
                                    >
                                      Edit
                                    </button> */}
                </div>
            </div>
        </>
    )
}

export default SelectedAddressCard