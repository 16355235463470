import React from "react";
import { Col, Container, Image, Row, Tab, Tabs } from "react-bootstrap";
import "./Home.css";
// import { imageURL2 } from "../../images";
const TabWithProductHome = ({ text, imageURL2 }) => {
  const DATAOFTAB = [
    {
      id: "RINGS",
      label: "RINGS",
      data: [
        {
          id: 1,
          name: text?.text_203,
          image: imageURL2?.image_120,
          link: "/fashion-jewelry-rings",
          description: text?.text_204,
        },
        {
          id: 2,
          name: text?.text_205,
          image: imageURL2?.image_121,
          link: "/fashion-bands-jewelry-rings",
          description: text?.text_206,
        },
        {
          id: 3,
          name: text?.text_207,
          image: imageURL2?.image_122,
          link: "/gold-jewelry-rings",
          description: text?.text_208,
        },
        {
          id: 4,
          name: text?.text_209,
          image: imageURL2?.image_123,
          link: "/mens-rings",
          description: text?.text_210,
        },
      ],
    },
    {
      id: "EARRINGS",
      label: "EARRINGS",
      data: [
        {
          id: 1,
          name: text?.text_211,
          image: imageURL2?.image_124,
          link: "/diamond-studs-earrings",
          description: text?.text_212,
        },
        {
          id: 2,
          name: text?.text_213,
          image: imageURL2?.image_125,
          link: "/hoops-earrings",
          description: text?.text_214,
        },
        {
          id: 3,
          name: text?.text_215,
          image: imageURL2?.image_126,
          link: "/fashion-earrings",
          description: text?.text_216,
        },
        {
          id: 4,
          name: text?.text_217,
          image: imageURL2?.image_127,
          link: "/earring-jakcets",
          description: text?.text_218,
        },
      ],
    },
    {
      id: "BRACELETS",
      label: "BRACELETS",
      data: [
        {
          id: 1,
          name: text?.text_219,
          image: imageURL2?.image_128,
          link: "/tennis-bracelets",
          description: text?.text_220,
        },
        {
          id: 2,
          name: text?.text_221,
          image: imageURL2?.image_129,
          link: "/fashion-bracelets",
          description: text?.text_222,
        },
        {
          id: 3,
          name: text?.text_223,
          image: imageURL2?.image_130,
          link: "/bangles",
          description: text?.text_224,
        },
        {
          id: 4,
          name: text?.text_225,
          image: imageURL2?.image_131,
          link: "/anklets",
          description: text?.text_226,
        },
      ],
    },
    {
      id: "NECKLACES",
      label: "NECKLACES",
      data: [
        {
          id: 1,
          name: text?.text_227,
          image: imageURL2?.image_132,
          link: "/tennis-necklaces",
          description: text?.text_228,
        },
        {
          id: 2,
          name: text?.text_229,
          image: imageURL2?.image_133,
          link: "/fashion-necklaces",
          description: text?.text_230,
        },
        {
          id: 3,
          name: text?.text_231,
          image: imageURL2?.image_134,
          link: "/solitaire-pendants",
          description: text?.text_232,
        },
        {
          id: 4,
          name: text?.text_233,
          image: imageURL2?.image_138,
          link: "/fashion-pendants",
          description: text?.text_234,
        },
      ],
    },
  ];

  return (
    <Container className="mb-5 my-6 ">
      <h2 className="text-center heading-55 mt-5 mb-3">{text?.text_247}</h2>
      <Tabs
        // defaultActiveKey="RINGS"
        transition={false}
        id="product-tab-home"
        variant="underline"
        style={{
          justifyContent: "center",
          alignItems: "center",
        }}
        className="custom-tab selling-tabs"
      >
        {DATAOFTAB?.map((singleTab) => {
          return (
            <Tab eventKey={singleTab?.id} title={singleTab?.label} className="">
              <Container className=" small-container product-container-small my-md-5  ">
                <Container>
                  <Row className="my-3 row-gap-4">
                    {singleTab?.data?.map((singleProduct) => {
                      return (
                        <Col
                          lg={3}
                          sm={6}
                          xs={6}
                          key={singleProduct?.id}
                          className="h-100 flex-column  justify-content-between "
                        >
                          <div className="aspect-ratio aspect-ratio-16/9 mb-2">
                            <a href={singleProduct?.link}>
                              <Image
                                src={singleProduct?.image}
                                alt={singleProduct?.name}
                                fluid
                                style={{
                                  aspectRatio: "9/12",
                                }}
                              />
                            </a>
                          </div>
                          <h2 className="mt-0 mb-2 h2-30 text-center  ">
                            {singleProduct?.name}
                          </h2>
                          {/* <div className="mt-0 mb-4">
                            <p className="text-secondary">
                              <span>{singleProduct?.description}</span>
                            </p>
                          </div>
                          <div className="d-flex flex-column gap-2 gap-lg-4 w-100 w-lg-75 h-fit">
                            <Button
                              href={singleProduct?.link}
                              className="btn btn-theme btn-outline text-secondary m-0 btn-hoverable"
                            >
                              Shop Now
                            </Button>
                          </div> */}
                        </Col>
                      );
                    })}
                  </Row>
                </Container>
              </Container>
            </Tab>
          );
        })}
      </Tabs>
    </Container>
  );
};

export default TabWithProductHome;
