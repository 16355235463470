import React, { useRef, useState } from 'react'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import Slider from 'react-slick';

const SliderComponent = ({
    infinite = false,
    dots = false,
    speed = 500,
    slidesToShow = 1,
    slidesToScroll = 1,
    arrow = false,
    swipe = true,
    prevArrow,
    nextArrow,
    responsive,
    data = [],
    title = "",
    ...props
}) => {
    // const [currentSlide, setCurrentSlide] = useState(0);
    const [disabledNext, setDisabledNext] = useState(false);
    const [disabledPrev, setDisabledPrev] = useState(true);
    const settings = {
        infinite: infinite,
        dots: dots,
        speed: speed,
        slidesToShow: slidesToShow,
        slidesToScroll: slidesToScroll,
        arrows: arrow,
        beforeChange: (current, next) => {
            // setCurrentSlide(next);
            if (next === 0) {
                setDisabledPrev(true);
            } else {
                setDisabledPrev(false);
            }
            if (next === data.length - slidesToShow) {
                setDisabledNext(true);
            } else {
                setDisabledNext(false);
            }
        },
        // prevArrow: <PrevArrow />,
        // nextArrow: <NextArrow />,
        responsive: responsive ?? [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    let sliderRef = useRef(null);
    const next = () => {
        if (!disabledNext) {
            sliderRef.slickNext();
        }
    };
    const previous = () => {
        if (!disabledPrev) {
            sliderRef.slickPrev();
        }
    };

    return (
        <>
            <div>
                <p className="d-flex align-items-center justify-content-between">
                    <h3 className="text-center my-4">{title}</h3>
                    <span className='d-flex align-items-center gap-2 justify-content-between'>
                        <PrevArrow onClick={previous} />
                        <NextArrow onClick={next} />
                    </span>
                </p>
                <Slider
                    ref={(slider) => {
                        sliderRef = slider;
                    }}
                    {...settings}
                    className="get-inspired-slider"
                >
                    {data}
                </Slider>
            </div>
        </>
    )
}

export default SliderComponent


export const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <FiChevronLeft
            className={className}
            style={{
                ...style,
                cursor: "pointer",
                fontSize: "24px",
            }}
            onClick={onClick}
        />
    );
};

export const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <FiChevronRight
            className={className}
            style={{
                ...style,
                cursor: "pointer",
                fontSize: "24px",
            }}
            onClick={onClick}
        />
    );
};