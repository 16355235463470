/* eslint-disable no-restricted-globals */
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import HeartFill from "../../assets/svgs/heartFill";
import { currencyIcon } from "../../constants/function";
import {
  addToFavourite,
  decreaseCartProduct,
  getCartDetails,
  getFavouriteList,
  increaseCartProduct,
  removeFavoriteList,
  removeFromCart,
} from "../../features/cart/cartSlice";
import formatCurrency from "../../helper/currencyFormater";

const ProductCartList = ({
  imgUrl,
  name,
  price,
  sku,
  style,
  qty,
  productCartId,
  product_type,
  cartId,
  size,
  caption,
  fullDeatials,
  currency,
}) => {
  const formatedPrice = formatCurrency(price);
  const countryCurrency = currency ?? localStorage.getItem("currency");
  // const productCartId = fullDeatials?.id;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onRemoveFromCartHandelar = async () => {
    // eslint-disable-next-line no-restricted-globals
    const conf = confirm("Are you sure you want to remove this item?");
    if (!conf) return;
    await dispatch(
      removeFromCart({
        productCartId: productCartId,
        size: fullDeatials?.size ?? fullDeatials?.Productsize,
      })
    );
    await dispatch(getCartDetails());
  };

  const onIncreaseQtyHandelar = () => {
    dispatch(
      increaseCartProduct({
        cartProductId: productCartId,
        size: fullDeatials?.size ?? fullDeatials?.Productsize,
      })
    );
  };

  const onDecreaseQtyHandelar = () => {
    dispatch(
      decreaseCartProduct({
        cartProductId: productCartId,
        size: fullDeatials?.size ?? fullDeatials?.Productsize,
      })
    );
  };
  const onAddToFavourite = async (data) => {
    // if (!isLogin()) return toast.error("Oops, Please login to add to wishlist");
    // const userdata = JSON.parse(localStorage.getItem("user"));

    // const cat = localStorage.getItem("category");
    // eslint-disable-next-line no-restricted-globals
    alert("Product has been added to your wishlist.");
    // await dispatch(addToFavourite(produtInfo?.[selectedColor?.key]?.id));
    await dispatch(addToFavourite(data));
    await dispatch(getFavouriteList());
    await dispatch(getCartDetails());

    // await setProducts((pre) => {
    //   return [...pre, ...productReducer?.products];
    // });
  };

  const handleRemove = async (id) => {
    // if (!isLogin())
    //   return toast.error("Oops, Please login to Remove to wishlist");
    const userdata = JSON.parse(localStorage.getItem("user"));
    const cat = localStorage.getItem("category");
    const conf = confirm(
      "Are you sure you want to remove from your wishlist item?"
    );
    if (!conf) return;
    await dispatch(removeFavoriteList(id));
    await dispatch(getFavouriteList());
    await dispatch(getCartDetails());
  };

  const handleClick = () => {
    // console.log(fullDeatials)
    let formattedName = fullDeatials?.title?.toLowerCase();
    // Replace all white spaces with hyphens
    formattedName = formattedName?.replace(/\s+/g, "-");
    navigate(`/${formattedName}/${fullDeatials?.sku}`, {
      state: {
        slug: fullDeatials?.category ? fullDeatials?.category : "",
      },
    });
  };
  return (
    <div className="cart-item" data-aos="fade-up">
      <div
        className="imageHolder "
        style={{
          position: "relative",
          cursor: "pointer",
        }}
      >
        <div
          className=""
          style={{
            position: "relative",
          }}
        >
          <img
            onClick={handleClick}
            src={imgUrl}
            className="img-fluid"
            alt="cart product"
          />
          <div
            style={{
              position: "absolute",
              top: "5px",
              right: "0px",
              fontSize: "14px",
              color: "#e77e50",
              padding: "3px 10px",
              borderRadius: "5px",
            }}
          >
            {/* {console.log(fullDeatials?.favourite)} */}
            {fullDeatials?.favourite ? (
              <div
                className="btn-heart cursor-pointer"
                onClick={() => {
                  handleRemove(fullDeatials?.id);
                }}
              >
                <HeartFill
                  className="wishlist-heart"
                  fillColor={"#e77e50"}
                  strokeColor={"#e77e50"}
                />
              </div>
            ) : (
              <div
                className="btn-heart fill-border cursor-pointer"
                onClick={() => {
                  onAddToFavourite(fullDeatials);
                }}
              >
                <HeartFill
                  className="wishlist-heart"
                  fillColor={"none"}
                  strokeColor={"#e77e50"}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="item-info border-bottom">
        <div className="d-flex justify-content-between mb-md-4 mb-2 flex-column flex-sm-row">
          <h2
            className="fw-normal"
            style={{
              fontSize: "1.9rem",
            }}
            onClick={handleClick}
          >
            {name}
          </h2>

          <div className="d-flex">
            <div
              className="fw-normal product-price"
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "24px",
              }}
            >
              {/* <LiaRupeeSignSolid /> */}
              {currencyIcon(fullDeatials?.currency ?? countryCurrency)}
              {fullDeatials?.amount ?? formatedPrice}
            </div>
            <button
              type="button"
              className="btn btn-link btn-close"
              onClick={onRemoveFromCartHandelar}
            ></button>
          </div>
        </div>
        <h5>{caption}</h5>
        <div className="d-flex  justify-content-between">
          <div
            className="d-flex justify-content-between justify-content-center align-items-center gap-2"
            style={{ marginRight: "8px", paddingBottom: "10px" }}
          >
            <label>
              {product_type == 0 && "Ring Size : "}
              {product_type == 2 && "Chain Length(Inches) : "}
              {product_type == 3 && "Length(Inches): "}
              {size}
            </label>
            {/* {product_type == 0 && (
                <select
                  className="form-select w-auto"
                  value={size}
                  disabled
                  
                  onChange={(e) => {
                    // setProductSize(e.target.value);
                    const value = e.target.value;
                    console.log("first", value);
                    dispatch(updateSize({ cartId, value }));
                  }}
                >
                  <option value={"5"}>5</option>
                  <option value={"6"}>6</option>
                  <option value={"7"}>7</option>
                  <option value={"8"}>8</option>
                  <option value={"9"}>9</option>
                  <option value={"10"}>10</option>
                </select>
              )}
              {product_type == 2 && (
                <select
                  className="form-select w-auto"
                  onChange={(e) => {
                    // setProductSize(e.target.value);
                    updateSize(cartId, e.target.value);
                  }}
                  value={size}
                  disabled

                >
                  <option value={"14"}>14</option>
                  <option value={"16"}>16</option>
                  <option value={"18"}>18</option>
                </select>
              )}
              {product_type == 3 && (
                <select
                value={size}
                  className="form-select w-auto"
                  onChange={(e) => {
                    // setProductSize(e.target.value);
                    updateSize(cartId, e.target.value);
                  }}
                  disabled
                >
                  <option value={"5"}>5</option>
                  <option value={"5.5"}>5.5</option>
                  <option value={"6"}>6</option>
                  <option value={"6.5"}>6.5</option>
                  <option value={"7"}>7</option>
                  <option value={"7.5"}>7.5</option>
                  <option value={"8"}>8</option>
                  <option value={"8.5"}>8.5</option>
                </select>
              )} */}
            {/* <div className="guide-info mb-0 d-flex flex-row align-items-center ">
              <a href="/buying-guide/ring-size/" className="mb-0">
                <i className="fa-solid fa-chart-gantt"></i> Size Guide
              </a>
            </div> */}
          </div>
          <div className="qty-control ms-auto mb-2 mb-sm-0">
            <button
              type="button"
              className="btn btn-qty"
              onClick={onDecreaseQtyHandelar}
            >
              <i className="fa-solid fa-minus"></i>
            </button>
            <span style={{ margin: "0 5px" }}>{qty}</span>
            <button
              type="button"
              disabled={qty >= 10}
              className="btn btn-qty"
              onClick={() => {
                if (qty < 10) {
                  onIncreaseQtyHandelar();
                }
              }}
            >
              <i className="fa-solid fa-plus"></i>
            </button>
          </div>
        </div>
        <div className="d-sm-flex justify-content-between">
          <p className="text-muted mb-2">
            {style} <br />
            Product SKU: {sku}
            <br />
            <strong>Made-to-Order.</strong> Estimated Ship Date:{" "}
            {new Date(
              new Date().getTime() + 15 * 24 * 60 * 60 * 1000
            ).toDateString()}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProductCartList;
