import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { singupUrl } from "../../constants/urls";
import toast from "react-hot-toast";

const initialState = {
  user: {},
  isLoading: false,
  error: false,
  success: false,
  errorMsg: null,
};

export const userSingup = createAsyncThunk("user/singup", async (user) => {
  try {
    const res = await axios.post(singupUrl, user);
    if (res.status === 201) {
      window.location.href = "/login";
      return res.data;
    }
  } catch (error) {
    toast?.error(
      error?.response?.data?.error?.[0]?.message ?? "Something went wrong"
    );
    throw error;
  }
});

const singup = createSlice({
  name: "singup",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(userSingup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(userSingup.fulfilled, (state, action) => {
      state.isLoading = false;
      state.user = action.payload;
      state.success = true;
      state.error = false;
    });

    builder.addCase(userSingup.rejected, (state, action) => {
      state.errorMsg = action.payload;
      state.error = true;
    });
  },
});

export default singup.reducer;
