import React from "react";
import Footer from "./element/Footer";
import { imageURL, imageURL2 } from "../images";

import Aboutus2 from "./aboutus/aboutus2";
import RelatedProduct from "./aboutus/related-product";

const About = () => {
  return (
    <>
      {/* <div className="page-title-img container">
        <div className="row align-items-end">
          <div className="col-md-4 order-1 order-md-0">
            <h2>Our Story</h2>
          </div>
          <div className="col-md-8 order-0 order-md-1">
            <div className="page-banner">
              <img
                src={imageURL2.image_64}
                className="img-fluid"
                alt="Banner"
              />
            </div>
          </div>
        </div>
      </div> */}
      <img
        src={imageURL2.image_64}
        className=" engagment-hero-section-master"
        alt="Hero bg"
      />

      {/* About US */}
      {/* <Aboutus /> */}
      <Aboutus2 />

      {/* Begin:: Categories */}
      <RelatedProduct />
      {/* End:: Categories */}
      <Footer />
    </>
  );
};

export default About;
