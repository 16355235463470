import React from "react";
import { Image } from "react-bootstrap";
import useMediaQuery from "../../Hooks/useMeadiaQuery";

const ImageWithLinkHome = ({ link, imagelink, imageMobilelink }) => {
  const isAboveDesktop = useMediaQuery("(min-width: 768px)");
  return (
    <div className="custom-banner-home2 my-6">
      <a href={link} className="custom-banner-home2">
        <Image
          src={isAboveDesktop ? imagelink : imageMobilelink}
          alt="category bg"
          className="custom-banner-home2"
        />
      </a>
    </div>
  );
};

export default ImageWithLinkHome;
