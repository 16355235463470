import { Container, Row, Col, Image, Button } from "react-bootstrap";
import React from "react";
import { Link } from "react-router-dom";
// import { imageURL2 } from "../../images";
import Slider from "react-slick";
import useMediaQuery from "../../Hooks/useMeadiaQuery";

const EngagementRingElegance = ({ Products, title = "", description = "" }) => {
  const isAboveDesktop = useMediaQuery("(min-width: 768px)");

  return Products?.length ? (
    <Container className=" my-6">
      {!!title && <h2 className="text-center heading-55 pt-4">{title}</h2>}
      {isAboveDesktop ? (
        <>
          {" "}
          <p className="text-secondary text-black pt-3 m-0 text-center">
            From round cut diamonds to princess cut diamonds and{" "}
          </p>
          <p className="text-secondary text-black p-0 m-0 text-center">
            everything in between, find the diamond
          </p>
          <p className="text-secondary text-black p-0 m-0 text-center">
            shape that speaks to you.
          </p>{" "}
        </>
      ) : (
        <p className="text-secondary text-black pt-3 m-0 text-center">
          From round cut diamonds to princess cut diamonds and everything in
          between, find the diamond shape that speaks to you.
        </p>
      )}

      <div className="mb-4">
        <Container className=" ">
          <div className=" pt-2 pb-4  collection-slider abcd ">
            <ProductSlider Products={Products} />
          </div>
        </Container>
      </div>
    </Container>
  ) : (
    ""
  );
};

export default EngagementRingElegance;

export const ProductSlider = ({ Products }) => {
  const buttonStyles = {
    backgroundColor: "#e77e50",
    padding: "10px",
    borderRadius: "50%",
    zIndex: 1,
    // fontSize: '0PX',
    color: "#333",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          ...buttonStyles,
          left: window.screen.width < 500 ? "-8px" : "-30px",
          top: "38%",
        }}
        onClick={onClick}
      >
        {/* &lsaquo; */}
      </div>
    );
  };

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          ...buttonStyles,
          right: window.screen.width < 500 ? "-8px" : "-30px",
          top: "38%",
        }}
        onClick={onClick}
      >
        {/* &rsaquo; */}
      </div>
    );
  };
  const settings = {
    infinite: true,
    dots: false,
    speed: 500,
    slidesToShow: 5,
    arrow: false,
    swipe: true,
    slidesToScroll: 1,
    // centerMode: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5.5,
          slidesToScroll: 1,
          // infinite: true,
          // dots: false,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          // initialSlide: ,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          // initialSlide: ,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.03,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {Products?.map((singleProduct) => {
        return (
          <div
            key={singleProduct?.id}
            className="text-center Rings_hover_on w-100 h-100"
          >
            <div className="Rings_hover_on  mb-2">
              <Link
                to={singleProduct?.link}
                className="d-flex justify-content-center align-items-center"
              >
                <Image
                  src={singleProduct?.image}
                  alt={singleProduct?.name}
                  style={{
                    width: "112px",
                    // height: "47px",
                    objectFit: "cover",
                  }}
                  fluid
                  // style={{
                  //   aspectRatio: "9/12",
                  // }}
                  className="Rings_img_hover"
                />
              </Link>
            </div>
            <h2 className=" h2-30">{singleProduct?.name}</h2>
          </div>
        );
      })}
    </Slider>
  );
};

// import React from "react";
// import EngagementGridHome from "./EngagmentGrid";

// import img1 from "../../assets/images/halo-ring.png";
// import img2 from "../../assets/images/side-stone-ring.png";
// import img3 from "../../assets/images/solitaire-ring.png";
// import EngagementGridHomeRong from "./EngagmentGridMetal";

// const EngagementRingElegance = ({ text, imageURL2 }) => {
//   return (
//     <>

//       <EngagementGridHome
// Products={[
//   {
//     id: 1,
//     name: "Solitaire",
//     image: img1,
//     link: "/solitaire-engagement-rings",
//     // description: text?.text_196,
//   },
//   {
//     id: 2,
//     name: "Three Stone",
//     image: img1,
//     link: "/three-stone-engagement-rings",
//     // description: text?.text_196,
//   },
//   {
//     id: 3,
//     name: " Side Stone",
//     image: img2,
//     link: "/side-stone-engagement-rings",
//     // description: text?.text_196,
//   },
//   {
//     id: 4,
//     name: "Round",
//     image: img3,
//     link: "/round-engagement-rings",
//     // description: text?.text_196,
//   },
//   {
//     id: 5,
//     name: "Solitaire",
//     image: img3,
//     link: "/solitaire-engagement-rings",
//     // description: text?.text_196,
//   },
//   {
//     id: 6,
//     name: "Three Stone",
//     image: img3,
//     link: "/three-stone-engagement-rings",
//     // description: text?.text_196,
//   },
//   {
//     id: 7,
//     name: " Side Stone",
//     image: img2,
//     link: "/side-stone-engagement-rings",
//     // description: text?.text_196,
//   },
//   {
//     id: 8,
//     name: "Round",
//     image: img1,
//     link: "/round-engagement-rings",
//     // description: text?.text_196,
//   },
// ]}
//         title={"Shop by Shape"}
//       />

//     </>
//   );
// };

// export default EngagementRingElegance;
