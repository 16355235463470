import React from "react";
import { Col, Row } from "react-bootstrap";

const ProductDetailTab = ({
  diamondDetails,
  itemDetails,
  sideDiamondDetails,
}) => {
  return (
    <Row className="mt-5 gx-lg-5">
      {itemDetails.length > 0 && (
        <Col lg={4} className="border-end">
          <h4 className="fw-bold text-primary">Item Details</h4>
          <table className="table">
            <tbody>
              {diamondDetails &&
                itemDetails.map((item) => (
                  <tr key={item.key}>
                    <td>{item.key}</td>
                    <td>{item.value}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Col>
      )}
      {diamondDetails.length !== 0 && (
        <Col lg={4} className="border-end">
          <h4 className="fw-bold text-primary">Diamond Details</h4>
          <table className="table">
            <tbody>
              {diamondDetails.map((item) => (
                <tr key={item.key}>
                  <td>{item.key}</td>
                  <td>{item.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Col>
      )}
      {sideDiamondDetails.length !== 0 && (
        <Col lg={4}>
          <h4 className="fw-bold text-primary">Side Diamond Details</h4>
          <table className="table">
            <tbody>
              {sideDiamondDetails.map((item) => (
                <tr key={item.key}>
                  <td>{item.key}</td>
                  <td>{item.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Col>
      )}
    </Row>
  );
};

export default ProductDetailTab;
