import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import Footer from "../element/Footer";
import { Breadcrumb, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { imageURL } from "../../images";
import Slider from "react-slick";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSingleProduct } from "../../features/productsList/singleProductSlice";
import formatCurrency from "../../helper/currencyFormater";
import { LiaRupeeSignSolid } from "react-icons/lia";
import Spinner from "react-bootstrap/Spinner";
import { addToCart, getCartDetails } from "../../features/cart/cartSlice";
import ReactImageMagnify from "react-image-magnify";
import { LinkContainer } from "react-router-bootstrap";
import { currencyIcon, getRingSize } from "../../constants/function";
import toast from "react-hot-toast";
import { RingSizes } from "./productUtility";

const WhishlistDetailsPage = () => {
  const [size, setSize] = useState(0);
  const disptach = useDispatch();
  const { category, id } = useParams();
  const [image, setImage] = useState("");
  const [itemDetails, setItemDetails] = useState([]);
  const [diamondDetails, setDiamondDetails] = useState([]);
  const [sideDiamondDetails, setSideDiamondDetails] = useState([]);
  const [imageGallery, setImageGallery] = useState([]);
  const [productPrice, setProductPrice] = useState("");
  const [Productsize, setProductSize] = useState(null);

  const dispatch = useDispatch();
  const product = useSelector((state) => state.singleProduct.data);
  const [deliveryDate, setDeliveryDate] = useState(
    new Date(new Date().getTime() + 15 * 24 * 60 * 60 * 1000)
  );
  const countryCurrency = localStorage.getItem("currency");

  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const handleSingleProduct = useCallback(
    (id) => {
      disptach(getSingleProduct(id));
    },
    [disptach, id]
  );

  useEffect(() => {
    handleSingleProduct(id);
  }, []);

  useEffect(() => {
    if (!!product) {
      const formatedPrice = formatCurrency(product.product_price);
      setProductPrice(formatedPrice);
    }
  }, [product]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
  };

  useEffect(() => {
    if (!!product) {
      const itemDetailsObject = JSON.parse(product.item_detail);
      const itemDetails = Object.entries(itemDetailsObject).map(
        ([key, value]) => ({ key: key, value: value })
      );

      const diamondDetailsObj = JSON.parse(product.diamond_detail);
      if (Object.keys(diamondDetailsObj).length !== 0) {
        const diamondDetails = Object.entries(diamondDetailsObj)
          .filter(([key, value]) => !!value)
          .map(([key, value]) => ({ key: key, value: value }));
        setDiamondDetails(diamondDetails);
      }

      const sideDiamondDetailsObj = JSON.parse(product.side_diamond_detail);

      if (Object.keys(sideDiamondDetailsObj).length !== 0) {
        const diamondDetails = Object.entries(sideDiamondDetailsObj)
          .filter(([key, value]) => !!value)
          .map(([key, value]) => ({ key: key, value: value }));
        setSideDiamondDetails(diamondDetails);
      }

      setImage(product.image_gallery[0].original_url);
      setItemDetails(itemDetails);

      setImageGallery(product.image_gallery);
    }
  }, [product, imageGallery]);

  const onAddToCartHandelar = async () => {
    if (!Productsize) return toast.error("Please select size");
    alert("Product has been added to your cart.");
    await dispatch(addToCart({ ...product, Productsize }));
    await dispatch(getCartDetails());
  };

  if (!!product) {
    return (
      <>
        {/* <Container className="page-title text-start">
            <h5>Home - Contact us</h5>
          </Container> */}

        <div className="container my-3">
          <Breadcrumb>
            <LinkContainer to="/" className="link-breadcrumb">
              <Breadcrumb.Item>Home</Breadcrumb.Item>
            </LinkContainer>
            <LinkContainer
              to={`/edit-profile`}
              state={{
                eventKey: "wishlist",
              }}
              className="link-breadcrumb"
            >
              <Breadcrumb.Item>wishlist</Breadcrumb.Item>
            </LinkContainer>
            <Breadcrumb.Item active>{id}</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        {/* Begin:: Product Detail */}
        <Container className="mt-5">
          <div className="row gx-xxl-4">
            <div className="col-lg-7 mb-4 mb-lg-0">
              <div className="product-showcase">
                {size > 767 && (
                  <div
                    className="img-thumbs show-verti"
                    style={{ height: "100px" }}
                  >
                    <Slider {...settings}>
                      {imageGallery.map((item, index) => {
                        return (
                          <a key={index}>
                            <img
                              src={item.original_url}
                              className="img-fluid"
                              alt={item.title}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setImage(item.original_url);
                              }}
                            />
                          </a>
                        );
                      })}
                    </Slider>
                  </div>
                )}
                <div className="main-display" data-aos="zoom-in">
                  <ReactImageMagnify
                    {...{
                      smallImage: {
                        alt: "Wristwatch by Ted Baker London",
                        isFluidWidth: true,
                        src: image,
                      },
                      largeImage: {
                        src: image,
                        width: 2000,
                        height: 2000,
                      },
                    }}
                  />
                </div>
                {size > 767 ? (
                  <div className="show-horizon img-thumbs"></div>
                ) : (
                  <>
                    {/* For mobile  */}
                    <div className="img-thumbs all-thumbs d-flex">
                      {product.image_gallery.map((item) => {
                        return (
                          <>
                            <img
                              key={`img ${item.id}`}
                              src={item.original_url}
                              className="img-fluid"
                              alt={item.title}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setImage(item.original_url);
                              }}
                            />
                          </>
                        );
                      })}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="col-lg-5">
              <div className="product-detail">
                <h2>{product.title}</h2>
                <h6>{product.caption}</h6>
                <div className="ratting d-flex mb-4">
                  <h5 className="mb-0">
                    <i className="fa-solid fa-star text-primary"></i>
                    <i className="fa-solid fa-star text-primary"></i>
                    <i className="fa-solid fa-star text-primary"></i>
                    <i className="fa-solid fa-star text-primary"></i>
                    <i className="fa-solid fa-star"></i>
                  </h5>
                  <h6 className="mb-0 ms-3">(40 customer review)</h6>
                </div>
                <div
                  className="product-price"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "24px",
                  }}
                >
                  {currencyIcon(countryCurrency)} {/* <LiaRupeeSignSolid /> */}
                  {productPrice}
                </div>

                <div className="border w-auto p-4 rounded-3 mb-4">
                  <div className="row product-metal-info mt-0">
                    <div className="col-md-7 ">
                      <div className="row gx-2">
                        {/* <div className="form-group col-6">
                                            <label htmlFor="" className="form-label">Carat</label>
                                            <input type="text" className="form-control" />
                                        </div> */}
                      </div>
                    </div>
                  </div>

                  <div className="row gx-3 product-cart-info mb-20">
                    <div className="col-xxl-3 col-md-4 col-sm-4 col-3">
                      {/* <input
                          type="number"
                          placeholder="Qty"
                          className="form-control"
                        
                        /> */}
                      <div className="form-group">
                        {/* <label htmlFor="" className="form-label">
                              Ring size
                            </label> */}
                        <select
                          className="form-select"
                          onChange={(e) => {
                            setProductSize(e.target.value);
                          }}
                        >
                          <option>Ring Size</option>
                          <option>5</option>
                          <option>6</option>
                          <option>7</option>
                          <option>8</option>
                          <option>9</option>
                          <option>10</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-xxl-4 col-md-6 col-sm-5 col-6">
                      <button
                        type="button"
                        className="btn btn-secondary w-100"
                        onClick={onAddToCartHandelar}
                      >
                        Add to cart
                      </button>
                    </div>
                    <div className="col-xxl-2 col-md-2 col-sm-3 col-3">
                      <button
                        type="button"
                        className="btn btn-outline-secondary"
                      >
                        <i className="fa-regular fa-heart"></i>
                      </button>
                    </div>
                  </div>

                  <div className="col-xxl-9 col-md-12">
                    <button
                      type="button"
                      className="btn btn-buy w-100 mb-0"
                      onClick={() => {
                        window.location.href = "/cart";
                        onAddToCartHandelar();
                      }}
                    >
                      Buy it Now
                    </button>
                  </div>
                </div>

                <div className="guide-info">
                  <a href="/buying-guide/ring-size/">
                    <i className="fa-solid fa-chart-gantt"></i> Size Guide
                  </a>
                  <a href="#">
                    <i className="fa-solid fa-arrow-rotate-left"></i> Delivery &
                    Return
                  </a>
                  <a href="#">
                    <i className="fa-regular fa-clock"></i> Estimated Delivery:{" "}
                    <span className="text-muted">
                      {" "}
                      {deliveryDate.toDateString()}
                    </span>
                  </a>
                </div>

                <p className="text-muted">
                  Made-to-Order | Free Shipping | Free Returns
                </p>

                <div className="summary-sec">
                  <div className="row">
                    <div className="col-md-3 col-6 text-center">
                      <img
                        src={imageURL.van}
                        className="img-fluid"
                        alt="Free Shipping"
                      />
                      <h6>Free Shipping</h6>
                    </div>
                    <div className="col-md-3 col-6 text-center">
                      <img
                        src={imageURL.security}
                        className="img-fluid"
                        alt="Free Shipping"
                      />
                      <h6>1 Year Warranty</h6>
                    </div>
                    <div className="col-md-3 col-6 text-center">
                      <img
                        src={imageURL.handshake}
                        className="img-fluid"
                        alt="Free Shipping"
                      />
                      <h6>Secure Payment</h6>
                    </div>
                    <div className="col-md-3 col-6 text-center">
                      <img
                        src={imageURL.return}
                        className="img-fluid"
                        alt="Free Shipping"
                      />
                      <h6>30 Days Return</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        {/* End:: Product Detail */}

        {/* Begin::  Long detail */}
        <Container className="product-desc">
          <Tabs
            defaultActiveKey="home"
            className="mb-sm-3 mb-4"
            variant="underline"
          >
            <Tab eventKey="home" title="Product Detail">
              <Row className="mt-5 gx-5">
                {itemDetails.length > 0 && (
                  <Col lg={4} className="border-end">
                    <h4 className="fw-bold text-primary">Item Details</h4>
                    <table className="table">
                      <tbody>
                        {diamondDetails &&
                          itemDetails.map((item) => (
                            <tr key={item.key}>
                              <td>{item.key}</td>
                              <td>{item.value}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </Col>
                )}
                {diamondDetails.length !== 0 && (
                  <Col lg={4} className="border-end">
                    <h4 className="fw-bold text-primary">Diamond Details</h4>
                    <table className="table">
                      <tbody>
                        {diamondDetails.map((item) => (
                          <tr key={item.key}>
                            <td>{item.key}</td>
                            <td>{item.value}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Col>
                )}
              </Row>
            </Tab>
            <Tab eventKey="contact" title="Reviews">
              <div className="review-section">
                <div className="product-review">
                  <img
                    src={imageURL.client1}
                    className="img-fluid"
                    alt="Images"
                  />
                  <div>
                    <div className="ratting mb-2">
                      <i className="fa-solid fa-star text-primary"></i>
                      <i className="fa-solid fa-star text-primary"></i>
                      <i className="fa-solid fa-star text-primary"></i>
                      <i className="fa-solid fa-star text-primary"></i>
                      <i className="fa-solid fa-star"></i>
                    </div>
                    <h6 className="mb-3">
                      <strong>Kay Mills</strong>{" "}
                      <span className="text-muted">September 7, 2022</span>
                    </h6>
                    <p className="mb-0">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p>
                  </div>
                </div>
                <div className="product-review">
                  <img
                    src={imageURL.client1}
                    className="img-fluid"
                    alt="Images"
                  />
                  <div>
                    <div className="ratting mb-2">
                      <i className="fa-solid fa-star text-primary"></i>
                      <i className="fa-solid fa-star text-primary"></i>
                      <i className="fa-solid fa-star text-primary"></i>
                      <i className="fa-solid fa-star text-primary"></i>
                      <i className="fa-solid fa-star"></i>
                    </div>
                    <h6 className="mb-3">
                      <strong>Kay Mills</strong>{" "}
                      <span className="text-muted">September 7, 2022</span>
                    </h6>
                    <p className="mb-0">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p>
                  </div>
                </div>
                <div className="product-review">
                  <img
                    src={imageURL.client1}
                    className="img-fluid"
                    alt="Images"
                  />
                  <div>
                    <div className="ratting mb-2">
                      <i className="fa-solid fa-star text-primary"></i>
                      <i className="fa-solid fa-star text-primary"></i>
                      <i className="fa-solid fa-star text-primary"></i>
                      <i className="fa-solid fa-star text-primary"></i>
                      <i className="fa-solid fa-star"></i>
                    </div>
                    <h6 className="mb-3">
                      <strong>Kay Mills</strong>{" "}
                      <span className="text-muted">September 7, 2022</span>
                    </h6>
                    <p className="mb-0">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p>
                  </div>
                </div>
              </div>

              {/* Begin:: Review form */}
              <div className="row col-lg-10 col-xl-8 mx-auto review-form">
                <div className="col-md-12">
                  <h3 className="text-center mb-4">Write Your own review</h3>
                  <div className="review-ratting  mb-3">
                    Product Rating :
                    <div className="rating-group ms-2">
                      <label
                        aria-label="1 star"
                        className="rating__label"
                        htmlFor="rating3-1"
                      >
                        <i className="rating__icon rating__icon--star fa fa-star"></i>
                      </label>
                      <input
                        className="rating__input"
                        name="rating3"
                        id="rating3-1"
                        value="1"
                        type="radio"
                      />
                      <label
                        aria-label="2 stars"
                        className="rating__label"
                        htmlFor="rating3-2"
                      >
                        <i className="rating__icon rating__icon--star fa fa-star"></i>
                      </label>
                      <input
                        className="rating__input"
                        name="rating3"
                        id="rating3-2"
                        value="2"
                        type="radio"
                      />
                      <label
                        aria-label="3 stars"
                        className="rating__label"
                        htmlFor="rating3-3"
                      >
                        <i className="rating__icon rating__icon--star fa fa-star"></i>
                      </label>
                      <input
                        className="rating__input"
                        name="rating3"
                        id="rating3-3"
                        value="3"
                        type="radio"
                      />
                      <label
                        aria-label="4 stars"
                        className="rating__label"
                        htmlFor="rating3-4"
                      >
                        <i className="rating__icon rating__icon--star fa fa-star"></i>
                      </label>
                      <input
                        className="rating__input"
                        name="rating3"
                        id="rating3-4"
                        value="4"
                        type="radio"
                      />
                      <label
                        aria-label="5 stars"
                        className="rating__label"
                        htmlFor="rating3-5"
                      >
                        <i className="rating__icon rating__icon--star fa fa-star"></i>
                      </label>
                      <input
                        className="rating__input"
                        name="rating3"
                        id="rating3-5"
                        value="5"
                        type="radio"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="" className="form-label">
                      Name*
                    </label>
                    <input className="form-control" type="text" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="" className="form-label">
                      Email*
                    </label>
                    <input className="form-control" type="text" />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="" className="form-label">
                      Your Review*
                    </label>
                    <textarea className="form-control" rows={7}></textarea>
                  </div>
                </div>
                <div className="col-md-12 text-center">
                  <button type="button" className="btn btn-secondary btn-theme">
                    {" "}
                    Submit
                  </button>
                </div>
              </div>
              {/* Begin:: Review form */}
            </Tab>
          </Tabs>
        </Container>
        {/*  End::  Long detail */}
        <Footer />
      </>
    );
  }

  return (
    <div className="vw-100 vh-100 border d-flex justify-content-center align-items-center">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};

export default WhishlistDetailsPage;
