import React, { useState } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Modal,
  Row,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate } from "react-router-dom";
import { imageURL } from "../../../images";
import Footer from "../../element/Footer";

const ProfilePage = () => {
  const navigate = useNavigate();
  const [isLogout, setIsLogout] = useState(false);

  const handleIsDeleteShow = () => {
    setIsLogout(true);
  };

  const handleAddressClick = () => {
    // Add logic for handling address card click
    navigate("/edit-profile", {
      state: {
        eventKey: "address",
      },
    });
  };

  const handleForgetPasswordClick = () => {
    // Add logic for handling forget password card click
    navigate("/edit-profile", {
      state: {
        eventKey: "changePassword",
      },
    });
  };

  const handleEditUserInfoClick = () => {
    // Add logic for handling edit user info card click
    navigate("/edit-profile", {
      state: {
        eventKey: "editProfile",
      },
    });
  };

  const handleOrderHistoryClick = () => {
    navigate("/edit-profile", {
      state: {
        eventKey: "orders",
      },
    });
  };

  const handleWishlistClick = () => {
    navigate("/edit-profile", {
      state: {
        eventKey: "wishlist",
      },
    });
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("user_name");
    // navigate("/");
    window.location.href = '/'
  };

  return (
    <>
      <div className="container my-3">
        <Breadcrumb>
          <LinkContainer to="/" className="link-breadcrumb">
            <Breadcrumb.Item>Home</Breadcrumb.Item>
          </LinkContainer>
          <Breadcrumb.Item active>My Account</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="page-title mb-5">
        <div
          style={{
            color: "#e77e50",
            fontSize: "22px",
            fontWeight: 700,
          }}
        >
          My Account
        </div>
      </div>
      <Container className="mt-5">
        <h5 className="text-center">
          Welcome, <strong> {localStorage.getItem("user_name")} </strong>
        </h5>
        <p className="text-center">
          Use the options below to manage your account with us.
        </p>

        <Row className="mt-80 gx-xl-5 profile-cards">
          <Col xs={12} md={6} lg={4} className="mb-4">
            {/* Edit User Info Card */}
            <Card
              onClick={handleEditUserInfoClick}
              style={{ cursor: "pointer" }}
            >
              <Card.Body>
                <img
                  className="img-fluid"
                  src={imageURL.editUserPrf}
                  alt="Address icon"
                />
                <Card.Title className="h2-32">Edit User Info</Card.Title>
                <i className="fa-solid fa-circle-chevron-right"></i>
                {/* Add your edit user info form or information here */}
              </Card.Body>
            </Card>
          </Col>

          <Col xs={12} md={6} lg={4} className="mb-4">
            {/* Address Card */}
            <Card onClick={handleAddressClick} style={{ cursor: "pointer" }}>
              <Card.Body>
                <img
                  className="img-fluid"
                  src={imageURL.accountPrf}
                  alt="Address icon"
                />
                <Card.Title className="h2-32">Address</Card.Title>
                <i className="fa-solid fa-circle-chevron-right"></i>
                {/* Add your address form or information here */}
              </Card.Body>
            </Card>
          </Col>

          <Col xs={12} md={6} lg={4} className="mb-4">
            {/* Forget Password Card */}
            <Card
              onClick={handleForgetPasswordClick}
              style={{ cursor: "pointer" }}
            >
              <Card.Body>
                <img
                  className="img-fluid"
                  src={imageURL.forgotPrf}
                  alt="Address icon"
                />
                <Card.Title className="h2-32">Change Password</Card.Title>
                <i className="fa-solid fa-circle-chevron-right"></i>
                {/* Add your forget password form or information here */}
              </Card.Body>
            </Card>
          </Col>

          <Col xs={12} md={6} lg={4} className="mb-4">
            {/* Order History Card */}
            <Card
              onClick={handleOrderHistoryClick}
              style={{ cursor: "pointer" }}
            >
              <Card.Body>
                <img
                  className="img-fluid"
                  src={imageURL.orderHistoryPrf}
                  alt="Address icon"
                />
                <Card.Title className="h2-32">Order History</Card.Title>
                <i className="fa-solid fa-circle-chevron-right"></i>
                {/* Add your order history information here */}
              </Card.Body>
            </Card>
          </Col>

          <Col xs={12} md={6} lg={4} className="mb-4">
            {/* Wishlist Card */}
            <Card onClick={handleWishlistClick} style={{ cursor: "pointer" }}>
              <Card.Body>
                <img
                  className="img-fluid"
                  src={imageURL.wishlistPrf}
                  alt="Address icon"
                />
                <Card.Title className="h2-32">Wishlist</Card.Title>
                <i className="fa-solid fa-circle-chevron-right"></i>
                {/* Add your wishlist information here */}
              </Card.Body>
            </Card>
          </Col>

          <Col xs={12} md={6} lg={4} className="mb-4">
            {/* Logout Card */}
            <Card onClick={handleIsDeleteShow} style={{ cursor: "pointer" }}>
              <Card.Body>
                <img
                  className="img-fluid"
                  src={imageURL.logoutPrf}
                  alt="Address icon"
                />
                <Card.Title className="h2-32">Logout</Card.Title>
                <i className="fa-solid fa-circle-chevron-right"></i>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Footer />

      <Modal show={isLogout} onHide={() => setIsLogout(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure, you want to logout?</Modal.Body>
        <Modal.Footer>
          <Button variant="" onClick={() => setIsLogout(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleLogout}
            style={{ color: "white" }}
          >
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProfilePage;
