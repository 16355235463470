import React, { useState, useEffect } from "react";

function UpSideButton() {
  const [isVisible, setIsVisible] = useState(false);

  // Function to check the scroll position
  const checkScrollTop = () => {
    if (!isVisible && window.pageYOffset > 300) {
      setIsVisible(true);
    } else if (isVisible && window.pageYOffset <= 300) {
      setIsVisible(false);
    }
  };

  // Function to scroll to the top smoothly
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // Add scroll event listener
    window.addEventListener("scroll", checkScrollTop);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
  }, [isVisible]); // Ensures useEffect is only re-run if isVisible changes

  return (
    <div>
      {window.screen.width >= 991 && (
        <>
          {isVisible && (
            <button className="btn btn-top" onClick={scrollToTop}>
              <i className="fa-solid fa-arrow-up-long"></i>
            </button>
          )}
        </>
      )}
    </div>
  );
}

export default UpSideButton;
