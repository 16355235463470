import React, { useEffect, useState } from "react";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  newTransformProductData,
  transformProductData,
} from "../constants/function";
import {
  getBraceletJewelry,
  getEarringJewelry,
  getNecklessJewelry,
  getRingsJewelry,
} from "../features/jewelry/jewelrySlicer";
import ImageSliderNew from "./Slider/imageSlidernew";

const CategoryComingSoonProducts = () => {
  const dispatch = useDispatch();
  const earringData = useSelector((state) => state.jewelryReducer.earrings);
  const braceletData = useSelector((state) => state.jewelryReducer.bracelet);
  const ringData = useSelector((state) => state.jewelryReducer.rings);
  const necklessData = useSelector((state) => state.jewelryReducer.neckless);
  const userdata = JSON.parse(localStorage.getItem("user"));

  // const earringData = useSelector((state) => state.jewelryReducer.bracelet);
  // const braceletData = useSelector((state) => state.jewelryReducer.neckless);
  // const ringData = useSelector((state) => state.jewelryReducer.earrings);
  // const necklessData = useSelector((state) => state.jewelryReducer.rings);

  useEffect(() => {
    dispatch(getEarringJewelry(userdata?.id));
  }, []);

  const [countryCurrency, setCountryCurrency] = useState("INR");

  useEffect(() => {
    setCountryCurrency(localStorage.getItem("currency"));
  }, []);

  const [listOfEarring, setListOfEarring] = useState([]);
  const [listOfBracelet, setListOfBracelet] = useState([]);
  const [listOfRing, setListOfRing] = useState([]);
  const [listOfNeckless, setListOfNeckless] = useState([]);
  useEffect(() => {
    if (!!earringData?.data) {
      const data = earringData?.data?.data.map((data) => {
        const produtInfo = data?.product_variation
          ? transformProductData(data, countryCurrency)
          : newTransformProductData(data, countryCurrency);
        return {
          ...produtInfo,
          // ...data,
          // id: data.id,
          // title: data.title,
          // type: "Collection",
          // media_arr: data?.media_arr,
          // sku: data?.sku,
          // countryCurrency,
          // price:
          //   countryCurrency == "INR"
          //     ? formatCurrency(data?.product_price)
          //     : countryCurrency == "USD"
          //     ? formatCurrency(data?.usa_price)
          //     : countryCurrency == "GBP"
          //     ? formatCurrency(data?.uk_price)
          //     : formatCurrency(data?.usa_price),
        };
      });
      setListOfEarring(data);
    }
  }, [earringData.data]);
  useEffect(() => {
    if (!!braceletData?.data) {
      const data = braceletData?.data?.data.map((data) => {
        const produtInfo = data?.product_variation
          ? transformProductData(data, countryCurrency)
          : newTransformProductData(data, countryCurrency);
        return {
          ...produtInfo,
          // ...data,
          // id: data.id,
          // title: data.title,
          // type: "Collection",
          // media_arr: data?.media_arr,
          // sku: data?.sku,
          // countryCurrency,
          // price:
          //   countryCurrency == "INR"
          //     ? formatCurrency(data?.product_price)
          //     : countryCurrency == "USD"
          //     ? formatCurrency(data?.usa_price)
          //     : countryCurrency == "GBP"
          //     ? formatCurrency(data?.uk_price)
          //     : formatCurrency(data?.usa_price),
        };
      });
      setListOfBracelet(data);
    }
  }, [braceletData.data]);
  useEffect(() => {
    if (!!ringData?.data) {
      const data = ringData?.data?.data.map((data) => {
        const produtInfo = data?.product_variation
          ? transformProductData(data, countryCurrency)
          : newTransformProductData(data, countryCurrency);
        return {
          ...produtInfo,
          // ...data,
          // id: data.id,
          // title: data.title,
          // type: "Collection",
          // media_arr: data?.media_arr,
          // sku: data?.sku,
          // countryCurrency,
          // price:
          //   countryCurrency == "INR"
          //     ? formatCurrency(data?.product_price)
          //     : countryCurrency == "USD"
          //     ? formatCurrency(data?.usa_price)
          //     : countryCurrency == "GBP"
          //     ? formatCurrency(data?.uk_price)
          //     : formatCurrency(data?.usa_price),
        };
      });
      setListOfRing(data);
    }
  }, [ringData.data]);
  useEffect(() => {
    if (!!necklessData?.data) {
      const data = necklessData?.data?.data.map((data) => {
        const produtInfo = data?.product_variation
          ? transformProductData(data, countryCurrency)
          : newTransformProductData(data, countryCurrency);
        return {
          ...produtInfo,
          // ...data,
          // id: data.id,
          // title: data.title,
          // type: "Collection",
          // media_arr: data?.media_arr,
          // sku: data?.sku,
          // countryCurrency,
          // price:
          //   countryCurrency == "INR"
          //     ? formatCurrency(data?.product_price)
          //     : countryCurrency == "USD"
          //     ? formatCurrency(data?.usa_price)
          //     : countryCurrency == "GBP"
          //     ? formatCurrency(data?.uk_price)
          //     : formatCurrency(data?.usa_price),
        };
      });
      setListOfNeckless(data);
    }
  }, [necklessData.data]);
  console.log(braceletData);
  const getBraceletData = () => {
    if (!braceletData.success) {
      const userdata = JSON.parse(localStorage.getItem("user"));
      console.log(userdata?.id, userdata);
      dispatch(getBraceletJewelry(userdata?.id));
    }
  };
  const getNecklessData = () => {
    if (!necklessData.success) {
      dispatch(getNecklessJewelry(userdata?.id));
    }
  };
  const getRingData = () => {
    if (!ringData.success) {
      dispatch(getRingsJewelry(userdata?.id));
    }
  };
  const getEarringData = () => {
    if (!earringData.success) {
      dispatch(getEarringJewelry(userdata?.id));
    }
  };

  return (
    <>
      <Tab.Container id="category-tabs" defaultActiveKey="Earrings">
        <Row className="">
          <Col md={12} className="">
            <Nav
              // variant="pills"
              variant="underline"
              style={{
                justifyContent: "center",
                alignItems: "center",
              }}
              // className="flex category-tab-list-wedding"
              className="flex custom-tab selling-tabs"
            >
              <Nav.Item>
                <Nav.Link eventKey="Earrings">Earrings</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Bracelet" onClick={getBraceletData}>
                  Bracelet
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Rings" onClick={getRingData}>
                  Rings
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="Necklaces"
                  onClick={getNecklessData}
                  // className="mb-0"
                >
                  Necklaces
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col md={12}>
            <Container className="position-relative about-sec1">
              <Tab.Content className="category-content">
                <Tab.Pane eventKey="Earrings">
                  <div className="row">
                    <ImageSliderNew
                      data={listOfEarring}
                      productKey="earring"
                      getOnclick={() => {
                        getEarringData();
                      }}
                    />
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="Bracelet">
                  <div className="row">
                    <ImageSliderNew
                      data={listOfBracelet}
                      productKey="bracelet"
                      getOnclick={() => {
                        getBraceletData();
                      }}
                    />
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="Rings">
                  <div className="row">
                    <ImageSliderNew
                      data={listOfRing}
                      productKey="ring"
                      getOnclick={() => {
                        getRingData();
                      }}
                    />
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="Necklaces">
                  <div className="row">
                    <ImageSliderNew
                      data={listOfNeckless}
                      productKey="neckless"
                      getOnclick={() => {
                        getNecklessData();
                      }}
                    />
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Container>
          </Col>
        </Row>
      </Tab.Container>
      {/* </Container> */}
      {/* End:: Categories */}
      {/* <Footer /> */}
    </>
  );
};

export default CategoryComingSoonProducts;
