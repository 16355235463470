import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userSingin } from "../../features/user/loginSlice";
import { useNavigate } from "react-router";
import { Breadcrumb, Col, Container, Form } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import { LuEye, LuEyeOff } from "react-icons/lu";

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const dispatch = useDispatch();
  const error = useSelector((state) => state?.singin?.error?.message);

  const onUserLogin = async () => {
    try {
      if (email.trim().length > 0 && password.length > 0) {
        const userInfo = { email, password };

        await dispatch(userSingin(userInfo));
        // await syncLocalStorageToDatabase();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const loginReducer = useSelector((state) => state.singin);
  const navigate = useNavigate();
  useEffect(() => {
    if (!!loginReducer.success) {
      // navigate("/");
      window.location.href = "/";
    }
  }, [loginReducer]);

  const syncLocalStorageToDatabase = async () => {
    // const cart = JSON.parse(localStorage.getItem("cart")) || [];
    // const wishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
    // const localStorageCurrency = localStorage.getItem("currency");
    // const currency =
    //   localStorageCurrency === "INR"
    //     ? 0
    //     : localStorageCurrency === "USD"
    //     ? 1
    //     : localStorageCurrency === "GBP"
    //     ? 2
    //     : 1;
    // const wishlistPayload = wishlist?.map((item) => {
    //   return item?.id;
    // });
    // const cartPayload = {
    //   cart_items: cart?.map((item) => {
    //     return {
    //       id: item?.id,
    //       qty: item?.qty,
    //       size: +item?.product?.Productsize,
    //     };
    //   }),
    // };
    // const formData = new FormData();

    // // Iterate through the payload object and append each key-value pair to formData
    // cartPayload?.cart_items?.forEach((item, index) => {
    //   console.log({ ...item });
    //   formData.append(
    //     `cart_items[${index}]`,
    //     JSON.stringify({
    //       ["id"]: item?.id,
    //       ["quantity"]: item?.qty,
    //       ["size"]: item?.size,
    //       ["currency"]: currency,
    //     })
    //   );
    // });
    // const cartData = cart?.map((item) => {
    //   return {
    //     ...item,
    //     id: item?.id,
    //     qty: item?.qty,
    //     size: +item?.product?.Productsize,
    //   };
    // });
    // formData.append(`currency`, currency);
    // formData.append(`cart_items`, JSON.stringify(cartData));

    // console.log(wishlistPayload.join(","), cartPayload);
    // await dispatch(addToCart(cartData));
    // await dispatch(addToFavourite(wishlistPayload));
    // await dispatch(getCartDetails());
    // await dispatch(getFavouriteList());
    //Wishlist

    // // Dispatch actions to add items to cart
    // cart.forEach((productData) => {
    //   dispatch(
    //     addToCart({ product_id: productData?.id, qty: productData?.id })
    //   );
    // });

    // // Dispatch actions to add items to wishlist
    // wishlist.forEach((productId) => {
    //   dispatch(addToFavourite(productId));
    // });

    // Clear localStorage
    localStorage.removeItem("cart");
    localStorage.removeItem("wishlist");
  };

  useEffect(() => {
    if (password.trim().length > 0 && email.trim().length > 0) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [email, password, isButtonDisabled]);

  const [isShow, setIsShow] = useState(false);

  return (
    <>
      <div className="container my-3">
        <Breadcrumb>
          <LinkContainer to="/" className="link-breadcrumb">
            <Breadcrumb.Item>Home</Breadcrumb.Item>
          </LinkContainer>
          <Breadcrumb.Item active>Login User</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <Container>
        <Col lg={8} xl={7} className="mx-auto">
          <div className="d-flex">
            <form
              className="auth-form"
              style={{
                width: "500px",
                margin: "auto",
                marginTop: "20px",
              }}
            >
              <h5 className="mb-3 text-center">Sign in to your account</h5>
              <div className="mb-3">
                <label>Email</label>
                <Form.Control
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
              <div className="mb-3">
                <label>Password</label>
                <div className="position-relative">
                  <Form.Control
                    type={isShow ? "text" : "password"}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    style={{ paddingRight: "30px" }}
                  />

                  <div
                    className="position-absolute"
                    style={{ top: "10px", right: "10px", cursor: "pointer" }}
                    onClick={() => setIsShow(!isShow)}
                  >
                    {isShow ? <LuEye /> : <LuEyeOff />}
                  </div>
                </div>

                <Link
                  to={"/forgot-password"}
                  className="text-primary text-end d-block mt-2 small"
                >
                  Forgot password ?
                </Link>
                {error && (
                  <p className="text-danger" style={{ color: "red" }}>
                    {error}
                  </p>
                )}
              </div>
              <button
                className="btn w-100 me-0"
                type="button"
                onClick={onUserLogin}
                disabled={isButtonDisabled}
              >
                Sign in
              </button>
              <p className="text-center mt-4 mb-0 small">
                Don't have an Account?{" "}
                <a href="/signup" className="btn-link text-primary">
                  {" "}
                  Create an Account
                </a>
              </p>
            </form>
          </div>
        </Col>
      </Container>

      <Footer />
    </>
  );
}

export default LoginPage;
