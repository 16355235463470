import React from "react";

const SavingSvg = () => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 118.58 96.68"
      height={40}
    >
      <defs>
        <style>{`.cls-1{fill:#000;}`}</style>
      </defs>
      <title>Free Resizing-2--2</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1-2">
          <path
            className="cls-1"
            d="M37.42,94.65c.37-2.67.65-5.35,1.15-8a57.27,57.27,0,0,1,10.51-24A58.5,58.5,0,0,1,69.76,45.23c.33-.17.65-.37,1.12-.64L65,38.77l5.53-5.49c-3.12-3.12-6.08-6.25-9.24-9.19a3.76,3.76,0,0,1-.5-5.29,3.86,3.86,0,0,1,.5-.49C66.67,13.06,72,7.66,77.29,2.29a2.63,2.63,0,0,1,2-.86h34.58a2.48,2.48,0,0,1,1.91.77c5.43,5.47,10.85,11,16.38,16.34a3.73,3.73,0,0,1,.1,5.27.94.94,0,0,1-.1.1C129,26.86,126,30,123,33.05a3.45,3.45,0,0,0-.2.32l5.54,5.35-5.93,5.8C143.8,56,154.9,73.73,156,97.91c-.49,0-.75.09-1,.1h-3.47a55,55,0,0,0-10.16-31.77c-6.69-9.47-15.69-16-26.58-20.08l7.35-7.3-2.48-2.47c-2.54,2.54-5.07,5-7.52,7.57a2.37,2.37,0,0,1-2.59.76,54.75,54.75,0,0,0-26.32.12,1.5,1.5,0,0,1-1.63-.46c-2.61-2.68-5.26-5.3-8-8l-2.4,2.47,7.22,7.19C55.26,55.61,43,72.82,41.59,98.11H37.38ZM84.81,23.46a2.67,2.67,0,0,0,0,.4c2.11,4.83,4.2,9.68,6.36,14.49a1.52,1.52,0,0,0,1.17.55c2.87,0,5.74,0,8.55.06a1.24,1.24,0,0,0,1.34-1c1.91-4.52,3.93-9,5.91-13.54.14-.31.23-.64.38-1Zm7-17.63H80.53a1.94,1.94,0,0,0-1.15.63Q73.53,12.28,67.7,18.14c-.2.2-.39.42-.65.69a2,2,0,0,0,.41.13H79.71a1.76,1.76,0,0,0,1.13-.59c1.62-1.8,3.2-3.65,4.78-5.47,2-2.28,4-4.56,6.18-7.08m9.81,0a5.64,5.64,0,0,0,.31.53c3.55,4.05,7.08,8.12,10.66,12.15a1.93,1.93,0,0,0,1.3.47h11.47c.28,0,.56-.07,1-.12-4.31-4.3-8.47-8.46-12.66-12.6a2,2,0,0,0-1.23-.43H101.61Zm24.8,18-.37-.47c-3.65,0-7.3.07-11,0-1.25,0-2,.24-2.36,1.52a22.13,22.13,0,0,1-1.23,2.87c-1.7,3.91-3.4,7.81-5.13,11.8a11.72,11.72,0,0,1,1.64.36,2.37,2.37,0,0,0,2.8-.83c4.78-4.89,9.66-9.71,14.52-14.54a5,5,0,0,1,1.08-.66M67,23.44c.15.28.17.37.23.41q8,8,16,15.95a1.22,1.22,0,0,0,.89.33c1-.12,1.81-.34,2.79-.53l-.52-1.24c-1.74-4-3.66-7.91-5.16-12-.88-2.36-2-3.21-4.59-3-3.12.26-6.28.05-9.56.05m19.23-4.51h20.84L96.65,7,86.25,18.93"
            transform="translate(-37.38 -1.43)"
          ></path>
          <path
            className="cls-1"
            d="M50.78,98h-4.4C47,80.18,54.17,65.76,69.06,56.1c18.16-11.76,37-11.73,55.3-.3A49,49,0,0,1,147.21,98H143c-.44-14.87-6.24-27.14-17.94-36.33a44.84,44.84,0,0,0-31.68-9.56A46.29,46.29,0,0,0,50.78,98"
            transform="translate(-37.38 -1.43)"
          ></path>
        </g>
      </g>
    </svg>
    // <svg
    //   fill="#000000"
    //   viewBox="0 0 32 32"
    //   id="icon"
    //   xmlns="http://www.w3.org/2000/svg"
    //   height={40}
    // >
    //   <g id="SVGRepo_bgCarrier" strokeWidth={0} />
    //   <g
    //     id="SVGRepo_tracerCarrier"
    //     strokeLinecap="round"
    //     strokeLinejoin="round"
    //   />
    //   <g id="SVGRepo_iconCarrier">
    //     {" "}
    //     <defs>
    //       {" "}
    //       <style
    //         dangerouslySetInnerHTML={{ __html: " .cls-1 { fill: none; } " }}
    //       />{" "}
    //     </defs>{" "}
    //     <title>upgrade</title>{" "}
    //     <path d="M21,24H11a2,2,0,0,0-2,2v2a2,2,0,0,0,2,2H21a2,2,0,0,0,2-2V26A2,2,0,0,0,21,24Zm0,4H11V26H21Z" />{" "}
    //     <path d="M28.707,14.293l-12-12a.9994.9994,0,0,0-1.414,0l-12,12A1,1,0,0,0,4,16H9v4a2.0023,2.0023,0,0,0,2,2H21a2.0027,2.0027,0,0,0,2-2V16h5a1,1,0,0,0,.707-1.707ZM21,14v6H11V14H6.4141L16,4.4141,25.5859,14Z" />{" "}
    //     <rect
    //       id="_Transparent_Rectangle_"
    //       data-name="<Transparent Rectangle>"
    //       className="cls-1"
    //       width={32}
    //       height={32}
    //     />{" "}
    //   </g>
    // </svg>
  );

  // return (
  //   <svg
  //     id="ad72ec8e-729a-4c96-a1de-0e867ca535d7"
  //     data-name="Layer 1"
  //     xmlns="http://www.w3.org/2000/svg"
  //     viewBox="0 0 30 30"
  //     width={40}
  //   >
  //     <path
  //       className="bb171031-2251-4568-b5ff-b030e9a5563d"
  //       d="M27.0824,12.4192H26.32a8.9253,8.9253,0,0,0-3.2725-4.2159,15.0167,15.0167,0,0,1,.604-1.7568l.0342-.0889c.1274-.33.2476-.6425.3311-.91a1.2256,1.2256,0,0,0-.7857-1.544,1.2317,1.2317,0,0,0-.82.0235A10.0675,10.0675,0,0,0,18.91,6.3a13.9886,13.9886,0,0,0-3.4346-.4277H15.452C10.1068,5.8723,5.47,8.88,4.4,13.0324A2.72,2.72,0,0,1,2.65,9.65,2.74,2.74,0,0,1,4.0033,8.0315a.6307.6307,0,0,0-.5816-1.1192,3.9855,3.9855,0,0,0,.77,7.3838c-.0157.207-.0225.3779-.0225.5371a8.2587,8.2587,0,0,0,3.9819,6.833l-.7441,1.8506a1.66,1.66,0,0,0,.918,2.1543l.9121.3643a1.6635,1.6635,0,0,0,2.1538-.918l.6972-1.7344a14.2632,14.2632,0,0,0,6.3394.09l.6621,1.6455a1.657,1.657,0,0,0,2.1538.9179l.9121-.3652a1.6582,1.6582,0,0,0,.918-2.1533l-.6572-1.6328a9.6956,9.6956,0,0,0,3.24-3.22h1.4263a1.6594,1.6594,0,0,0,1.6553-1.6543V14.0744A1.66,1.66,0,0,0,27.0824,12.4192Zm-6.2471,8.917L21.9027,23.99a.3951.3951,0,0,1-.209.5088l-.9224.3691a.3988.3988,0,0,1-.3,0,.3854.3854,0,0,1-.21-.2148l-1.0718-2.6641-.5366.14A12.897,12.897,0,0,1,11.89,22.03l-.55-.1611-1.12,2.7842a.3934.3934,0,0,1-.21.2158.3875.3875,0,0,1-.3008.0029l-.01-.0029-.9033-.3623a.3914.3914,0,0,1-.22-.5039L9.7171,21.16l-.4609-.28A7.2173,7.2173,0,0,1,5.43,14.8381c0-4.2432,4.4952-7.6963,10.02-7.6963h.0312a12.6369,12.6369,0,0,1,3.47.4854l.4077.1181.2608-.332a8.57,8.57,0,0,1,3.1641-2.2461c-.0845.2451-.1993.543-.3106.8291a11.4036,11.4036,0,0,0-.75,2.4248l-.0454.3848.3242.2158a7.6751,7.6751,0,0,1,3.2471,4.2041l.1289.4609H27.08a.3951.3951,0,0,1,.3946.3946l.0019,2.93a.3954.3954,0,0,1-.3945.3935H24.91l-.1777.333a8.4343,8.4343,0,0,1-3.4116,3.3281Z"
  //     />
  //     <path
  //       className="bb171031-2251-4568-b5ff-b030e9a5563d"
  //       d="M21.7845,11.9553h-.0014a.9519.9519,0,1,0,.6753.2793A.9438.9438,0,0,0,21.7845,11.9553Z"
  //     />
  //     <path
  //       className="bb171031-2251-4568-b5ff-b030e9a5563d"
  //       d="M19.1361,9.3176a6.9174,6.9174,0,0,0-7.271.002.6319.6319,0,0,0,.3018,1.1269.822.822,0,0,0,.0839.0049.6254.6254,0,0,0,.3819-.13l.0175-.0146a5.6922,5.6922,0,0,1,5.6993-.003.6517.6517,0,0,0,.4663.125.6317.6317,0,0,0,.3261-1.1074Z"
  //     />
  //   </svg>
  // );
};

export default SavingSvg;
