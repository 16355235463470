import React from "react";

function HassleReturnSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 400.69 399.02"
      height="52.936"
    >
      <defs>
        <style>{`.cls-3,.cls-4,.cls-5{fill:#253243;}.cls-4{font-size:154.98px;font-family:Agenda-Semibold, Agenda Semibold;font-weight:600;}.cls-5{font-size:72px;font-family:Agenda-Thin, Agenda Thin;font-weight:200;}.cls-6{letter-spacing:0.04em;}.cls-7{letter-spacing:-0.03em;}`}</style>
        <clipPath id="clipPath" transform="translate(0 0)">
          <rect className="cls-1" width="43.573" height="43.572"></rect>
        </clipPath>
      </defs>
      <title>Asset 1</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <g className="cls-2">
            <path
              className="cls-3"
              d="M400.69,106.75c-2,10.2-4.2,20.38-6,30.61s-5,12.08-14.66,8.27c-10.64-4.19-21.26-8.46-31.92-12.59-3.5-1.36-6.17-3.17-6.36-7.34s2.39-6.25,5.74-7.85c4.93-2.36,9.82-4.79,15.9-7.77-7.07-9.85-13.28-19.84-20.81-28.72-31.3-36.89-71.06-58.64-119.17-64.61a184.15,184.15,0,0,0-196,121.18,29.84,29.84,0,0,1-1.58,4.17c-2,3.65-5.08,5.29-9.14,4s-5.8-4.45-5-8.57A41.08,41.08,0,0,1,14.15,130c28.2-70,79.21-112.52,153.38-127,6.16-1.2,12.41-2,18.62-2.92h29.93c4.08.64,8.15,1.32,12.24,1.91,50.45,7.28,92.31,30.31,125.23,69.15,8.52,10.06,15.61,21.34,23.5,32.27,2.93-1.41,6.37-3.06,9.8-4.73,7-3.43,8.45-3.22,13.84,2.16v6"
              transform="translate(0 0)"
            ></path>
            <path
              className="cls-3"
              d="M26,295.39c-5.08,2.9-9.84,5.6-14.58,8.34-3,1.76-6.5,2.29-8.67-.4C1,301.19-.37,297.6.09,295c2.2-12.4,5-24.68,7.73-37,1.51-6.9,5.73-9.07,12.14-6.28,10.49,4.56,21.07,9,31.33,14,2.55,1.25,5.35,4.57,5.59,7.17.22,2.29-2.51,5.51-4.76,7.23-3.9,3-8.45,5.11-13,7.74A175.46,175.46,0,0,0,72.3,331.64c38.47,36.75,84.5,54.56,137.56,52.23,76.8-3.38,142.12-53.57,166.63-126.7a29.72,29.72,0,0,1,1.32-3.75c1.82-3.67,5-4.87,8.75-3.88s5.48,3.68,5.2,7.47a20.63,20.63,0,0,1-1.23,5.3C363.63,336.19,312,382,234.44,395.88,151.38,410.74,70.74,372,28.3,299.21c-.67-1.14-1.38-2.26-2.32-3.82"
              transform="translate(0 0)"
            ></path>
          </g>
          <text className="cls-4" transform="translate(124.13 231.22)">
            30
          </text>
          <text className="cls-5" transform="translate(135.05 292.89)">
            <tspan className="cls-6">D</tspan>
            <tspan className="cls-7" x="50.98" y="0">
              A
            </tspan>
            <tspan x="92.66" y="0">
              Y
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  );
}

export default HassleReturnSvg;
