import classNames from "classnames";
import { ErrorMessage, Formik, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { currencyIcon } from "../../constants/function";
import { cartShippingFields } from "../../constants/utils";
import {
  getCountry,
  getState,
  getUserAddresses,
} from "../../features/address/addressSlice";
import {
  getCartDetails,
  proccedToPayment,
} from "../../features/cart/cartSlice";
import formatCurrency from "../../helper/currencyFormater";
import { userAddressListSchema } from "../../Validation/Schema/UserDetailsSchema";
import AddressCard from "../cart/AddressCard";
import SummaryCartItem from "../cart/SummaryCartItem";
import ListofAddressCard from "../checkout/listofAddressCard";
import NewBillingForm from "../checkout/newBillingForm";
import NewShippingForm from "../checkout/newShippingForm";
import SelectedAddressCard from "../checkout/selectedAddressCard";
import Footer from "../element/Footer";
import GlobalForm from "../form/globalForm";
const hideCountryState = "GBP";
function Checkout() {
  const dispatch = useDispatch();
  const countryCurrency = localStorage.getItem("currency") ?? "INR";
  const initialValue = {
    firstname: "",
    lastname: "",
    name: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    postal_code: "",
    country_id: "",
    country: "",
    state_id: "",
  };

  // state
  const [listOfAddress, setListOfAddress] = useState([]);

  const [selectedShippingAddressId, setSelectedShippingAddressId] =
    useState(null);
  const [selectedShippingAddress, setSelectedShippingAddress] = useState(null);

  const [saveShippingAddress, setSaveShippingAddress] = useState(false);

  const [isNewShipping, setIsNewShipping] = useState(false);
  const [isNewShippingForm, setIsNewShippingForm] = useState(false);
  const [newShippingAddress, setNewShippingAddress] = useState({});

  const [isEditShipping, setIsEditShipping] = useState(false);

  useEffect(() => {
    dispatch(getUserAddresses());
    dispatch(getCountry(countryCurrency));
    dispatch(getState(countryCurrency));
  }, [dispatch]);

  const dataFormate = (data) => {
    return {
      ...data,
      firstname: data?.firstname ?? "",
      lastname: data?.lastname ?? "",
      // name: data?.name ?? "",
      name:
        data?.firstname && data?.lastname
          ? `${data?.firstname ?? ""} ${data?.lastname ?? ""}`
          : data?.name ?? "",
      address1: data?.address1 ?? "",
      address2: data?.address2 ?? "",
      city: data?.city ?? "",
      state: `${data?.state?.id}` ?? "",
      postal_code: data?.postal_code ?? "",
      country: `${data?.country?.id}` ?? "",
      country_id: `${data?.country?.id}` ?? "",
      // id: data?.id,
    };
  };

  const userAddress = useSelector((state) => state.address.userAddresses);
  const countryList = useSelector((state) => state?.address?.country);
  const stateList = useSelector((state) => state?.address?.states);

  useEffect(() => {
    setNewShippingAddress({
      ...initialValue,
      country_id:
        countryCurrency == "INR"
          ? "101"
          : countryCurrency == "USD"
          ? "231"
          : countryCurrency == "GBP"
          ? "230"
          : "101",
    });
  }, [countryList]);

  useEffect(() => {
    setListOfAddress(userAddress);
  }, [userAddress]);

  useEffect(() => {
    if (!!setSelectedShippingAddressId) {
      const add = listOfAddress.find(
        (address) => address.id == selectedShippingAddressId
      );
      setSelectedShippingAddress(dataFormate(add));
      // setSelectedShippingAddress({
      //   firstname: add?.firstname ?? "",
      //   lastname: add?.lastname ?? "",
      //   // name: add?.name ?? "",
      //   name:
      //     add?.firstname && add?.lastname
      //       ? `${add?.firstname ?? ""} ${add?.lastname ?? ""}`
      //       : add?.name ?? "",
      //   address1: add?.address1 ?? "",
      //   address2: add?.address2 ?? "",
      //   city: add?.city ?? "",
      //   state: `${add?.state?.id}` ?? "",
      //   postal_code: add?.postal_code ?? "",
      //   country: `${add?.country?.id}` ?? "",
      //   country_id: `${add?.country?.id}` ?? "",
      // });
    }
  }, [selectedShippingAddressId]);

  //  billing Address

  const [isSameShipping, setIsSameShipping] = useState(false);

  const [isNewBilling, setIsNewBilling] = useState(false);
  const [isNewBillingForm, setIsNewBillingForm] = useState(false);
  const [newBillingAddress, setNewBillingAddress] = useState({});

  const [selectedBillingAddressId, setSelectedBillingAddressId] =
    useState(null);

  const [selectedBillingAddress, setSelectedBillingAddress] = useState(null);

  const [saveBillingAddress, setSaveBillingAddress] = useState(false);

  const [isEditBilling, setIsEditBilling] = useState(false);
  const useDetails = useSelector((state) => state.userDetails.user);
  useEffect(() => {
    setNewBillingAddress({
      ...initialValue,
      country_id:
        countryCurrency == "INR"
          ? "101"
          : countryCurrency == "USD"
          ? "231"
          : countryCurrency == "GBP"
          ? "230"
          : "101",
    });
  }, []);

  useEffect(() => {
    if (!!selectedBillingAddressId) {
      const add = listOfAddress.find(
        (address) => address.id == selectedBillingAddressId
      );
      setSelectedBillingAddress(dataFormate(add));
      // setSelectedBillingAddress({
      //   firstname: add?.firstname ?? "",
      //   lastname: add?.lastname ?? "",
      //   // name: add?.name ?? "",
      //   name:
      //     add?.firstname && add?.lastname
      //       ? `${add?.firstname ?? ""} ${add?.lastname ?? ""}`
      //       : add?.name ?? "",
      //   address1: add?.address1 ?? "",
      //   address2: add?.address2 ?? "",
      //   city: add?.city ?? "",
      //   state: `${add?.state?.id}` ?? "",
      //   postal_code: add?.postal_code ?? "",
      //   country: `${add?.country?.id}` ?? "",
      //   country_id: `${add?.country?.id}` ?? "",
      // });
    }
  }, [selectedBillingAddressId]);

  useEffect(() => {
    if (!!selectedBillingAddressId) {
      setIsSameShipping(false);
    }
  }, [selectedBillingAddressId]);

  // card details;

  const cartItems = useSelector((state) => state.cart.cartItems);

  useEffect(() => {
    dispatch(getCartDetails());
  }, []);
  const [total, setTotal] = useState(0);
  useEffect(() => {
    if (cartItems && cartItems.length > 0) {
      const totalAmount = cartItems.reduce((acc, curr) => {
        const qty = curr.qty;
        // const price = +curr.product.product_price;
        const price = curr?.amount;
        // const price =
        //   countryCurrency == "INR"
        //     ? +curr?.product?.product_price
        //     : countryCurrency == "USD"
        //     ? +curr?.product?.usa_price
        //     : countryCurrency == "GBP"
        //     ? +curr?.product?.uk_price
        //     : +curr?.product?.usa_price;
        const itemTotal = qty * price;

        return acc + itemTotal;
      }, 0);

      // Assuming setTotal is a state updater function
      setTotal(totalAmount);
    }
  }, [cartItems]);

  const handleBilling = () => {
    const paymentIfo = {
      // same_as_shipping_address: isSameShipping ? 1 : 0,
      // billing_id: saveBillingAddress,
      // shipping_id: saveShippingAddress,
      same_as_shipping_address:
        selectedShippingAddressId === selectedBillingAddressId ? 1 : 0,
      billing_id: selectedBillingAddressId,
      shipping_id: selectedShippingAddressId,
      created_at: new Date().toUTCString(),
    };

    dispatch(proccedToPayment(paymentIfo));
  };

  const handleFromAddress = (billingData, newAddress) => {
    setNewBillingAddress(billingData);
    setSelectedBillingAddressId(newAddress?.payload?.id);
    setSaveBillingAddress(true);
    setIsNewBillingForm(false);
    setIsEditBilling(false);
  };

  return (
    <>
      <div className="container my-3">
        <Breadcrumb>
          <LinkContainer to="/" className="link-breadcrumb">
            <Breadcrumb.Item>Home</Breadcrumb.Item>
          </LinkContainer>

          <Breadcrumb.Item active>Checkout</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="page-title mb-5">
        <div
          style={{
            color: "#e77e50",
            fontSize: "22px",
            fontWeight: 700,
          }}
        >
          Secure Checkout
        </div>
      </div>

      <Container>
        <div className="row gx-5 justify-content-between">
          {/* Side 1 */}
          <div className="col-lg-7 col-xxl-7">
            <div>
              <h4 className="mb-4 fw-bold">Contact Information</h4>
              <p className="mb-4">{useDetails?.data?.email}</p>
            </div>

            {/*  shipping address */}
            <div className="border-top-2 pt-5 pb-">
              <div>
                <h4 className="fw-bold">Shipping Information</h4>
              </div>

              <div
                className="pt-4"
                style={
                  !!saveShippingAddress && !isNewShippingForm && !isEditShipping
                    ? { height: "230px" }
                    : {}
                }
              >
                <div className="checkout-sec">
                  {isNewShippingForm ? (
                    <NewShippingForm
                      setIsNewShipping={setIsNewShippingForm}
                      setIsNewShippingForm={setIsNewShippingForm}
                      newShippingAddress={newShippingAddress}
                      userAddressListSchema={userAddressListSchema}
                      stateList={stateList}
                      countryList={countryList}
                      setNewShippingAddress={setNewShippingAddress}
                      setSelectedShippingAddressId={
                        setSelectedShippingAddressId
                      }
                      setSaveShippingAddress={setSaveShippingAddress}
                      setIsEditShipping={setIsEditShipping}
                      // cartShippingFields={cartShippingFields}
                      cartShippingFields={
                        countryCurrency === hideCountryState
                          ? cartShippingFields?.filter(
                              (el) => el?.name !== "state_id"
                            )
                          : cartShippingFields
                      }
                    />
                  ) : (
                    <>
                      {isEditShipping ? (
                        <>
                          <Container
                            className="contact-form position-relative pb-4"
                            style={{ marginTop: "80px" }}
                          >
                            <GlobalForm
                              initialValues={selectedShippingAddress}
                              validationSchema={userAddressListSchema}
                              dynamicOption={{
                                state: stateList?.map((stat) => ({
                                  label: stat.name,
                                  value: stat.id,
                                })),
                                country: countryList?.map((stat) => ({
                                  label: stat.name,
                                  value: stat.id,
                                })),
                              }}
                              onSubmit={async (values) => {
                                // setState(values);
                                setSelectedShippingAddress(values);
                                setSaveShippingAddress(true);
                                setIsNewShippingForm(false);
                                setIsEditShipping(false);
                              }}
                              fields={cartShippingFields}
                              btnText="Save & Continue"
                              btnSet="start"
                            />
                          </Container>
                          {/* <DetailsForm
                            state={selectedShippingAddress}
                            stateList={stateList}
                            countryList={countryList}
                            initialValue={initialValue}
                            setState={setSelectedShippingAddress}
                            setIsNewForm={setIsNewShippingForm}
                            setSaveAddress={setSaveShippingAddress}
                            setIsEdit={setIsEditShipping}
                          /> */}
                        </>
                      ) : (
                        <>
                          {saveShippingAddress ? (
                            <SelectedAddressCard
                              isNew={isNewShipping}
                              newAddress={newShippingAddress}
                              selectedAddress={selectedShippingAddress}
                              stateList={stateList}
                              countryList={countryList}
                              backHandler={() => {
                                setSaveShippingAddress(false);
                                // setSelectedShippingAddressId(false);
                                // setSelectedShippingAddress(null);
                                // for billing Address
                                setIsSameShipping(false);
                                setSaveBillingAddress(false);
                                // setSelectedBillingAddress(false);
                                // setSelectedBillingAddressId(null);
                              }}
                            />
                          ) : (
                            <div className="">
                              <div className="row address-card-container  g-3">
                                <div className="col-md-6 col-lg-4  mt-0 mb-2">
                                  <Button
                                    onClick={() => {
                                      setIsNewShipping(true);
                                      setIsNewShippingForm(true);
                                    }}
                                    style={{
                                      height: "165px",
                                    }}
                                    className="btn-addAddress"
                                  >
                                    Add new Address
                                  </Button>
                                </div>
                                {listOfAddress.map((item) => {
                                  // const addressInfo = dataFormate(item);
                                  // console.log(item)
                                  const addressInfo = {
                                    firstname: item?.firstname,
                                    lastname: item?.lastname,
                                    // name: item.name,
                                    name:
                                      item?.firstname || item?.lastname
                                        ? `${item?.firstname ?? ""} ${
                                            item?.lastname ?? ""
                                          }`
                                        : item?.name ?? "",
                                    city: item.city,
                                    state: item.state.name,
                                    address1: item.address1,
                                    address2: item.address2,
                                    postal_code: item.postal_code,
                                    country: item.country.name,
                                    id: item.id,
                                  };

                                  return (
                                    <>
                                      <AddressCard
                                        {...addressInfo}
                                        key={item.id}
                                        setSelectedAddress={
                                          setSelectedShippingAddressId
                                        }
                                        selectedId={selectedShippingAddressId}
                                        onSelectAddress={() => {
                                          setIsNewShipping(false);
                                          setIsNewShippingForm(false);
                                        }}
                                      />
                                    </>
                                  );
                                })}
                              </div>
                              <div className="text-start mt-4 mb-4">
                                <Button
                                  variant="primary"
                                  className="btn btn-theme btn-white-outline btn-white-outline-hover text-dark1 border-dark1 px-4"
                                  // style={{
                                  //   color: "white",
                                  // }}
                                  disabled={!selectedShippingAddressId}
                                  onClick={() => {
                                    setSaveShippingAddress(true);
                                  }}
                                >
                                  Save & Continue
                                </Button>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>

            {/* billing address */}
            {/* {!!saveShippingAddress && ( */}
            <div className="border-top-2 border-bottom-2 pt-4 pb-4">
              <div>
                <h4 className="fw-bold">Billing Information</h4>
              </div>

              {!!saveShippingAddress && (
                <div
                  className="pt-4"
                  style={
                    !!saveBillingAddress && !isNewBillingForm && !isEditBilling
                      ? { height: "230px" }
                      : {}
                  }
                >
                  {/* billing Address */}
                  <div className="checkout-sec">
                    {isNewBillingForm ? (
                      <>
                        <NewBillingForm
                          setIsNewShipping={setIsNewShippingForm}
                          handleFromAddress={handleFromAddress}
                          newBillingAddress={newBillingAddress}
                          setIsNewShippingForm={setIsNewShippingForm}
                          setIsEditBilling={setIsEditBilling}
                          userAddressListSchema={userAddressListSchema}
                          stateList={stateList}
                          countryList={countryList}
                          setNewShippingAddress={setNewShippingAddress}
                          setSelectedShippingAddressId={
                            setSelectedShippingAddressId
                          }
                          // cartShippingFields={cartShippingFields}
                          cartShippingFields={
                            countryCurrency === hideCountryState
                              ? cartShippingFields?.filter(
                                  (el) => el?.name !== "state_id"
                                )
                              : cartShippingFields
                          }
                          setNewBillingAddress={setNewBillingAddress}
                          setIsNewBillingForm={setIsNewBillingForm}
                          setSaveBillingAddress={setSaveBillingAddress}
                        />
                        {/* <button
                          className="btn btn-link fw-bold text-primary"
                          onClick={() => {
                            setSaveBillingAddress(false);
                            setIsNewBillingForm(null);
                            // setNewBillingAddress(null);
                            // setIsSameShipping(false);
                          }}
                        >
                          back
                        </button>
                        <DetailsForm
                          state={newBillingAddress}
                          stateList={stateList}
                          countryList={countryList}
                          initialValue={initialValue}
                          // setState={setNewBillingAddress}
                          setState={async (values) => {
                            const details = {
                              name: values?.name,
                              address1: values?.address1,
                              address2: values?.address2,
                              city: values?.city,
                              state_id: values?.state,
                              country_id: values?.country,
                              postal_code: values?.postal_code,
                              address_type: 0, // keep static 0
                            };
                            const newAddress = await dispatch(
                              addNewAddress(details)
                            );
                            await dispatch(getUserAddresses());
                            await dispatch(resetUpdateAddress());
                            await setNewBillingAddress(values);
                            await setSelectedBillingAddressId(
                              newAddress?.payload?.id
                            );
                          }}
                          setIsNewForm={setIsNewBillingForm}
                          setSaveAddress={setSaveBillingAddress}
                        /> */}
                      </>
                    ) : (
                      <>
                        {isEditBilling ? (
                          <>
                            <button
                              className="btn btn-link fw-bold text-primary"
                              onClick={() => {
                                setSaveBillingAddress(false);
                                // setSelectedBillingAddressId(null);
                                // setSelectedBillingAddress(null);
                                // setIsSameShipping(false);
                              }}
                            >
                              back
                            </button>
                            <Container
                              className="contact-form position-relative pb-4"
                              style={{ marginTop: "80px" }}
                            >
                              <GlobalForm
                                initialValues={selectedBillingAddress}
                                validationSchema={userAddressListSchema}
                                dynamicOption={{
                                  state: stateList?.map((stat) => ({
                                    label: stat.name,
                                    value: stat.id,
                                  })),
                                  country: countryList?.map((stat) => ({
                                    label: stat.name,
                                    value: stat.id,
                                  })),
                                }}
                                onSubmit={async (values) => {
                                  // setState(values);
                                  setSelectedBillingAddress(values);
                                  setIsNewBillingForm(false);
                                  setIsEditBilling(false);
                                  setSaveBillingAddress(true);
                                }}
                                fields={cartShippingFields}
                                btnText="Save & Continue"
                                btnSet="start"
                              />
                            </Container>
                            {/* <DetailsForm
                              state={selectedBillingAddress}
                              // stateList={stateList}
                              // countryList={countryList}
                              // initialValue={initialValue}
                              setState={setSelectedBillingAddress}
                              setIsNewForm={setIsNewBillingForm}
                              setSaveAddress={setSaveBillingAddress}
                              setIsEdit={setIsEditBilling}
                            /> */}
                          </>
                        ) : (
                          <>
                            {saveBillingAddress ? (
                              <SelectedAddressCard
                                isNew={isNewBilling}
                                newAddress={newBillingAddress}
                                selectedAddress={selectedBillingAddress}
                                stateList={stateList}
                                countryList={countryList}
                                backHandler={() => {
                                  setSaveBillingAddress(false);
                                  // setSelectedBillingAddressId(null);
                                  // setSelectedBillingAddress(null);
                                  setIsSameShipping(false);
                                }}
                              />
                            ) : (
                              <>
                                <Form.Check // prettier-ignore
                                  type="checkbox"
                                  id={`default`}
                                  label="Same as shipping Address"
                                  className="mb-4"
                                  // defaultChecked={isSameShipping}
                                  checked={
                                    selectedShippingAddressId ===
                                    selectedBillingAddressId
                                  }
                                  onChange={(e) => {
                                    // console.log(e.target.checked, "Checkbox");
                                    setIsSameShipping(e.target.checked);
                                    if (!e.target.checked) {
                                      setSelectedBillingAddressId(null);
                                    }
                                    if (e.target.checked) {
                                      setSelectedBillingAddressId(
                                        selectedShippingAddressId
                                      );
                                    }
                                  }}
                                />
                                <ListofAddressCard
                                  selectedShippingAddressId={
                                    selectedShippingAddressId
                                  }
                                  selectedBillingAddressId={
                                    selectedBillingAddressId
                                  }
                                  setIsSameShipping={setIsSameShipping}
                                  setSelectedBillingAddressId={
                                    setSelectedBillingAddressId
                                  }
                                  setIsNewBilling={setIsNewBilling}
                                  setIsNewBillingForm={setIsNewBillingForm}
                                  listOfAddress={listOfAddress}
                                  isSameShipping={isSameShipping}
                                  onNewHandler={() => {
                                    setIsNewBilling(true);
                                    setIsNewBillingForm(true);
                                    // setIsSameShipping(false);
                                  }}
                                  onClickHandler={() => {
                                    setSaveBillingAddress(true);
                                    if (!!isSameShipping) {
                                      setSelectedBillingAddress(
                                        selectedShippingAddress
                                      );
                                      setSelectedBillingAddressId(
                                        selectedBillingAddressId
                                      );
                                    }
                                  }}
                                />
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
            {/* )} */}

            <div className="py-4">
              <Button
                variant="primary"
                // className="px-4 py-2"
                className="btn btn-theme btn-white-outline btn-white-outline-hover text-dark1 border-dark1 px-4"
                // style={{
                //   color: "white",
                // }}
                type="submit"
                disabled={!saveBillingAddress || !saveShippingAddress}
                onClick={handleBilling}
              >
                Proceed to Payment
              </Button>
            </div>
          </div>

          {/* side 2 */}
          <div className="col-lg-5 col-xxl-4">
            <div className="order-summary">
              <h5 className="text-center fw-bold mb-5 ">Order Summary</h5>
              <div className="cartItem-list">
                {cartItems.map((item) => {
                  const produtInfo = {
                    name: item?.product?.title,
                    caption: item?.product?.caption,
                    sku: item?.product?.sku,
                    type: item?.product?.Necklaces,
                    imgUrl: item?.product?.featured_image[0]?.original_url,
                    hoverImageUrl:
                      item?.product?.featured_image[1]?.original_url,
                    id: item?.product?.id,
                    // price: item?.product?.product_price,
                    price: item?.amount,
                    currency: item?.currency,
                    // price:
                    //   countryCurrency == "INR"
                    //     ? formatCurrency(item?.product?.product_price)
                    //     : countryCurrency == "USD"
                    //     ? formatCurrency(item?.product?.usa_price)
                    //     : countryCurrency == "GBP"
                    //     ? formatCurrency(item?.product?.uk_price)
                    //     : formatCurrency(item?.product?.usa_price),
                    qty: item.qty,
                    cartProductId: item.id,
                  };
                  return <SummaryCartItem {...produtInfo} />;
                })}
              </div>
              <table className="table mt-4">
                <tbody>
                  <tr>
                    <td> Subtotal:</td>
                    <td className="text-right">
                      {currencyIcon(
                        cartItems?.[0]?.currency ?? countryCurrency
                      )}

                      {formatCurrency(total)}
                    </td>
                  </tr>
                  <tr>
                    <td> Shipping:</td>
                    <td className="text-right">
                      {/* {currencyIcon(countryCurrency)}{" "} */}
                      Free
                      {/* {currencyIcon(
                        cartItems?.[0]?.currency ?? countryCurrency
                      )}
                      {formatCurrency(0.0)} */}
                    </td>
                  </tr>
                  <tr>
                    <td> Tax:</td>
                    <td className="text-right">
                      {currencyIcon(
                        cartItems?.[0]?.currency ?? countryCurrency
                      )}

                      {formatCurrency(0.0)}
                    </td>
                  </tr>
                  <tr className="border-bottom-2">
                    <td className="pb-2">Order Total:</td>
                    <td className="text-right pb-2">
                      {currencyIcon(
                        cartItems?.[0]?.currency ?? countryCurrency
                      )}

                      {formatCurrency(total)}
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-bold pt-2">Total Payable Amount:</td>
                    <td className="text-right fw-bold pt-2">
                      {currencyIcon(
                        cartItems?.[0]?.currency ?? countryCurrency
                      )}

                      {/* <LiaRupeeSignSolid /> */}
                      {formatCurrency(total)}
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* <p className="mt-5 mb-0">Need assistance?</p>
              <div className="mb-0 chat-detail mt-0">
                <Link className="text-black" to="">
                  {" "}
                  <i className="fa-solid fa-phone"></i> 987-654-3210{" "}
                </Link>
                <Link className="text-black mx-2" to="">
                  {" "}
                  <i className="fa-solid fa-message"></i> Live Chat{" "}
                </Link>
                <Link className="text-black" to="">
                  {" "}
                  <i className="fa-solid fa-envelope"></i> Email Us{" "}
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}

const DetailsForm = ({
  state,
  countryList,
  stateList,
  initialValue,
  setState,
  ...props
}) => {
  return (
    <>
      <Formik
        initialValues={state}
        enableReinitialize={true}
        validationSchema={userAddressListSchema}
        onSubmit={(values) => {
          setState(values);
          props?.setSaveAddress(true);
          props?.setIsNewForm(false);
          props?.setIsEdit(false);
        }}
      >
        {({ handleChange, errors, touched, values, handleSubmit }) => {
          return (
            <Form>
              <Row className=" g-0 g-md-5">
                <Col md={12} className="mb-3">
                  <label htmlFor="" className="form-label">
                    Name *
                  </label>
                  <input
                    name="name"
                    className={classNames("form-control", {
                      "error-border": errors.name && touched.name,
                    })}
                    onChange={handleChange}
                    placeholder="Name"
                    value={values.name}
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-danger"
                  />
                </Col>
              </Row>
              <Row className=" g-0 g-md-5">
                <Col md={12} className="mb-3">
                  <label htmlFor="" className="form-label">
                    Address 1 *
                  </label>
                  <input
                    name="address1"
                    className={classNames("form-control", {
                      "error-border": errors.address1 && touched.address1,
                    })}
                    onChange={handleChange}
                    placeholder="Address1"
                    value={values.address1}
                  />
                  <ErrorMessage
                    name="address1"
                    component="div"
                    className="text-danger"
                  />
                </Col>
              </Row>
              <Row className=" g-0 g-md-5">
                <Col md={12} className="mb-3">
                  <label htmlFor="" className="form-label">
                    Address 2
                  </label>
                  <input
                    name="address2"
                    className={classNames("form-control", {
                      "error-border": errors.address2 && touched.address2,
                    })}
                    onChange={handleChange}
                    placeholder="Address2"
                    value={values.address2}
                  />
                  <ErrorMessage
                    name="address2"
                    component="div"
                    className="text-danger"
                  />
                </Col>
              </Row>

              <Row className=" g-0 g-md-5">
                <Col md={6} className="mb-3">
                  <label htmlFor="" className="form-label">
                    City *
                  </label>
                  <input
                    name="city"
                    className={classNames("form-control", {
                      "error-border": errors.city && touched.city,
                    })}
                    onChange={handleChange}
                    placeholder="City"
                    value={values.city}
                  />
                  <ErrorMessage
                    name="city"
                    component="div"
                    className="text-danger"
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <label htmlFor="" className="form-label">
                    State *
                  </label>
                  <select
                    value={values.state}
                    onChange={handleChange}
                    className={classNames("form-select", {
                      "error-border": errors.state && touched.state,
                    })}
                    name="state"
                  >
                    <option value={""} style={{ cursor: "pointer" }}>
                      State
                    </option>
                    {stateList?.map((state) => {
                      return (
                        <option
                          value={state?.id}
                          style={{ cursor: "pointer" }}
                          key={state?.id}
                        >
                          {state?.name}
                        </option>
                      );
                    })}
                  </select>

                  <ErrorMessage
                    name="state"
                    component="div"
                    className="text-danger"
                  />
                </Col>
              </Row>
              <Row className=" g-0 g-md-5">
                <Col md={6} className="mb-3">
                  <label htmlFor="" className="form-label">
                    Postal code *
                  </label>
                  <input
                    name="postal_code"
                    className={classNames("form-control", {
                      "error-border": errors.postal_code && touched.postal_code,
                    })}
                    onChange={handleChange}
                    placeholder="Postal code"
                    value={values.postal_code}
                  />
                  <ErrorMessage
                    name="postal_code"
                    component="div"
                    className="text-danger"
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <CustomCountrySelect
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                    countryList={countryList}
                  />
                </Col>
              </Row>

              <div className="mt-4">
                <Row>
                  <Col className="text-start">
                    {/* <Button
                      variant="primary"
                      className="px-4 py-2"
                      style={{
                        color: "white",
                      }}
                      type="reset"
                      // disabled={isSubmitting}
                      onClick={() => {
                        props?.setState && props?.setState(false);
                        props?.setIsNewForm && props?.setIsNewForm(false);
                        props?.setIsEditBilling &&
                          props?.setIsEditBilling(false);
                      }}
                      // onClick={props?.back}
                    >
                      Back
                    </Button> */}
                    <Button
                      variant="primary"
                      className="px-4 py-2"
                      style={{
                        color: "white",
                      }}
                      type="submit"
                      // disabled={isSubmitting}
                      onClick={handleSubmit}
                    >
                      Save & Continue
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

const CustomCountrySelect = ({ label, countryCurrency, ...props }) => {
  const { values, setFieldValue } = useFormikContext();
  const dispatch = useDispatch();
  useEffect(() => {
    if (values.country == "") {
      setFieldValue("state", "");
    } else {
      dispatch(getState(countryCurrency));
    }
  }, [values.country]);

  return (
    <>
      <label htmlFor="" className="form-label">
        Country *
      </label>
      <select
        value={values.country}
        onChange={props?.handleChange}
        className={classNames("form-select", {
          "error-border": props?.errors.country && props?.touched.country,
        })}
        name="country"
      >
        <option value={""} style={{ cursor: "pointer" }}>
          Country
        </option>
        {props?.countryList?.map((country) => {
          return (
            <option
              value={country?.id}
              style={{ cursor: "pointer" }}
              key={country?.id}
            >
              {country?.name}
            </option>
          );
        })}
      </select>

      <ErrorMessage name="country" component="div" className="text-danger" />
    </>
  );
};

export default Checkout;
