import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import img2 from "../../assets/images/elegant/79832604-2100x1575-d2cbf2af-e7d2-4946-8921-387c9617b390_2_1512x.png";
import img3 from "../../assets/images/elegant/build---your-engagement-ring_780x.png";
import img1 from "../../assets/images/elegant/earrings.png";
import useMediaQuery from "../../Hooks/useMeadiaQuery";
import { imageURL2 } from "../../images";
import "./Home.css"; // Import the CSS file
import GlobalTitle from "../common/global-title";

const ElegantArea = () => {
  const isAboveDesktop = useMediaQuery("(min-width: 768px)");

  return (
    <>
      <Container className="my-6">
        <Container>
          <div className="elegant-container">
            <GlobalTitle
              title="Elegant Versatility"
              description="Discover our collection of timeless pieces with a modern twist, perfect for every occasion."
            />

            {isAboveDesktop ? (
              <div>
                <Row className="">
                  <Col xs={12} sm={12} md={12} lg={3}>
                    <div className="">
                      <Row className="gy-2">
                        <Col xs={12} sm={6} md={6} lg={12} className="mb-md-2">
                          <img
                            src={imageURL2?.image_168}
                            className="elegant-img"
                            alt="Earring"
                          />
                          <p className="text-center fw-bold mt-2 mb-md-2 mb-lg-0">
                            Earring Collection
                          </p>
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={12}>
                          <img
                            src={imageURL2?.image_169}
                            className="elegant-img"
                            alt="Bracelet"
                          />
                          <p className="text-center fw-bold mt-2 mb-0">
                            Bracelet Collection
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={9}>
                    <img
                      src={imageURL2?.image_170}
                      className="elegant-main-img"
                      alt="Jewelry"
                    />
                    <p className="text-center fw-bold mt-2 mb-0">
                      Jewelry Collection
                    </p>
                  </Col>
                </Row>
              </div>
            ) : (
              <div className="collection-slider">
                <ElegantAreaSlider />
              </div>
            )}
          </div>
        </Container>
      </Container>
    </>
  );
};

export default ElegantArea;

export const ElegantAreaSlider = ({ imageURL2 }) => {
  const buttonStyles = {
    // backgroundColor: "#e77e50",
    padding: "10px",
    borderRadius: "50%",
    zIndex: 1,
    // fontSize: '0PX',
    color: "#333",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          ...buttonStyles,
          left: window.screen.width < 500 ? "12px" : "-30px",
          top: "40%",
        }}
        onClick={onClick}
      >
        {/* &lsaquo; */}
      </div>
    );
  };

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          ...buttonStyles,
          right: window.screen.width < 500 ? "12px" : "-30px",
          top: "40%",
        }}
        onClick={onClick}
      >
        {/* &rsaquo; */}
      </div>
    );
  };
  const settings = {
    // dots: false,
    // infinite: false,
    // arrows:false,
    // speed: 500,
    // slidesToShow: 3,
    // slidesToScroll: 3,
    // prevArrow: <PrevArrow />,
    // nextArrow: <NextArrow />,
    dots: false,

    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          centerPadding: "0px",
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          dots: false,
          centerMode: true,
          infinite: true,
          speed: 500,
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false,
          swipe: true,
          draggable: true,

          appendDots: (dots) => (
            <div className="dot-custom">
              <ul className="p-0 mb-0">{dots}</ul>
            </div>
          ),
          customPaging: (i) => (
            <div className="custom-dot">
              <span>{i + 1}</span>
            </div>
          ),
        },
      },
      {
        breakpoint: 425,
        settings: {
          centerPadding: "35px",
          arrows: false,
        },
      },
      {
        breakpoint: 375,
        settings: {
          centerPadding: "25px",
          arrows: false,
        },
      },
      {
        breakpoint: 320,
        settings: {
          centerPadding: "10px",
          arrows: false,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {/* <div className="mt-80 "> */}
      <Link
        // style={{
        //   width: "150px",
        // }}
        to={"/engagement-collection"}
        className="coll-card px-2"
      >
        <img
          src={img1}
          style={{
            // width: "150px",
            // height: "150px",
            objectFit: "cover",
            aspectRatio: "1/1",
          }}
          className="img-fluid"
          alt="collection"
        />
        <h4 className="h2-30 text-center">Earring</h4>
        {/* <Button className="btn btn-theme btn-outline text-secondary m-0 btn-hoverable">
            Shop Now
          </Button> */}
      </Link>
      {/* </div>
      <div className="mt-80 d-flex justify-content-center"> */}
      <Link
        // style={{
        //   width: "150px",
        // }}
        to={"/wedding-collection"}
        className="coll-card px-2"
      >
        <img
          src={img2}
          style={{
            // width: "150px",
            // height: "150px",
            objectFit: "cover",
            aspectRatio: "1/1",
          }}
          className="img-fluid"
          alt="collection"
        />
        <h4 className="h2-30 text-center">Bracelet</h4>
        {/* <Button className="btn btn-theme btn-outline text-secondary m-0 btn-hoverable">
            Shop Now
          </Button> */}
      </Link>
      {/* </div>
      <div className="mt-80 d-flex justify-content-center"> */}
      <Link
        // style={{
        //   width: "150px",
        // }}
        to={"/jewelry-collection"}
        className="coll-card px-2"
      >
        <img
          src={img3}
          style={{
            // width: "150px",
            // height: "150px",
            objectFit: "cover",
            aspectRatio: "1/1",
          }}
          className="img-fluid"
          alt="collection"
        />
        <h4 className="h2-30 text-center">Jewelry</h4>
        {/* <Button className="btn btn-theme btn-outline text-secondary m-0 btn-hoverable">
            Shop Now
          </Button> */}
      </Link>
      {/* </div> */}
    </Slider>
  );
};
