import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { imageURL2 } from "../../images";
import Footer from "../element/Footer";
import { useDynamicText } from "../../Hooks/useDynamicText";
import EngagementDiscover from "./EngagementDiscover";
import EngagementHero from "./EngagmentHero";
import EngagementRingElegance from "./EngagementRingElegance";
import EngagementGridHomeRong from "./EngagmentGridMetal";

import img1 from "../../assets/images/halo-ring.png";
import img2 from "../../assets/images/side-stone-ring.png";
import img3 from "../../assets/images/solitaire-ring.png";
import EngagementGridHome from "./EngagmentGrid";
import EngagementFAQ from "./EngagementFAQ";
import EngagementReview from "./EngagementReview";
import EngagementNoDealBreaker from "./EngagementNoDEalBreaker";
import {
  engagementFAQ,
  engagementGridHomeProduct,
  engagementGridHomeRongProduct,
  engagementReviewProduct,
  engagementRingEleganceProduct,
} from "../../constants/utils";

const Engagement = () => {
  const text = useDynamicText();
  return (
    <>
      <EngagementHero imageURL2={imageURL2} text={text} />
      <EngagementGridHome
        Products={engagementGridHomeProduct(imageURL2)}
        title={text?.text_16}
      />
      <EngagementReview
        Reviews={engagementReviewProduct(imageURL2)}
        title="OUR CUSTOMERS LOVE US"
      />
      <EngagementRingElegance
        Products={engagementRingEleganceProduct(imageURL2)}
        title={text?.text_17}
      />
      <EngagementGridHomeRong
        Products={engagementGridHomeRongProduct(imageURL2)}
        lg={4}
        title={text?.text_14}
      />
      <EngagementNoDealBreaker />
      <EngagementFAQ faqs={engagementFAQ()} />

      <Footer />
    </>
  );
};

export default Engagement;
