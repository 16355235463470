import { Container, Row, Col, Image, Button } from "react-bootstrap";
import React from "react";
import { Link } from "react-router-dom";
// import { imageURL2 } from "../../images";

const ContainerGridHome = ({ text, imageURL2 }) => {
  const Products = [
    {
      id: 1,
      name: text?.text_195,
      image: imageURL2?.image_116,
      link: "/wedding-bands",
      description: text?.text_196,
    },
    {
      id: 2,
      name: text?.text_197,
      image: imageURL2?.image_117,
      link: "/anniversary-bands",
      description: text?.text_198,
    },
    {
      id: 3,
      name: text?.text_199,
      image: imageURL2?.image_118,
      link: "/eternity-bands",
      description: text?.text_200,
    },
    {
      id: 4,
      name: text?.text_201,
      image: imageURL2?.image_119,
      link: "/ring-enhancers",
      description: text?.text_202,
    },
  ];
  return (
    <Container className=" my-6">
      <h2 className="text-center heading-55 pt-4">{text?.text_194}</h2>
      <Container className="mb-4">
        <Container className=" pt-2 pb-4 small-container product-container-small ">
          <Row className="my-3 row-gap-4">
            {Products?.map((singleProduct) => {
              return (
                <Col lg={3} sm={6} xs={6} key={singleProduct?.id}>
                  <div className="aspect-ratio aspect-ratio-16/9 mb-2">
                    <Link to={singleProduct?.link}>
                      <Image
                        src={singleProduct?.image}
                        alt={singleProduct?.name}
                        fluid
                        style={{
                          aspectRatio: "9/12",
                        }}
                      />
                    </Link>
                  </div>
                  <h2 className="mt-0 mb-2 h2-30 text-center">
                    {singleProduct?.name}
                  </h2>
                  {/* <div className="mt-0 mb-4">
                    <p className="text-secondary">
                      <span>{singleProduct?.description}</span>
                    </p>
                  </div>
                  <div className="d-flex flex-column gap-2 gap-lg-4 w-100 w-lg-75 h-fit">
                    <Button
                      href={singleProduct?.link}
                      className="btn btn-theme btn-outline text-secondary m-0 btn-hoverable"
                    >
                      Shop Now
                    </Button>
                  </div> */}
                </Col>
              );
            })}
          </Row>
        </Container>
      </Container>
    </Container>
  );
};

export default ContainerGridHome;

// import { Container, Row, Col, Image, Button } from "react-bootstrap";
// import React from "react";

// const ContainerGridHome = () => {
//   const Products = [
//     {
//       id: 1,
//       name: "The Hoop Shop",
//       image:
//         "https://image.brilliantearth.com/cdn-cgi/image/width=720,height=545,quality=100,format=auto/https://cdn.builder.io/api/v1/image/assets%2F9f2a69003c86470ea05deb9ecb9887be%2F1c5d4b45587a482b80271c35731d3751",
//       link: "/",
//       description: " Your one-stop-shop for perfectly proportioned pairs.",
//     },
//     {
//       id: 2,
//       name: "The Hoop Shop",
//       image:
//         "https://image.brilliantearth.com/cdn-cgi/image/width=720,height=545,quality=100,format=auto/https://cdn.builder.io/api/v1/image/assets%2F9f2a69003c86470ea05deb9ecb9887be%2F1c5d4b45587a482b80271c35731d3751",
//       link: "/",
//       description: " Your one-stop-shop for perfectly proportioned pairs.",
//     },
//     {
//       id: 3,
//       name: "The Hoop Shop",
//       image:
//         "https://image.brilliantearth.com/cdn-cgi/image/width=720,height=545,quality=100,format=auto/https://cdn.builder.io/api/v1/image/assets%2F9f2a69003c86470ea05deb9ecb9887be%2F1c5d4b45587a482b80271c35731d3751",
//       link: "/",
//       description: " Your one-stop-shop for perfectly proportioned pairs.",
//     },
//     {
//       id: 4,
//       name: "The Hoop Shop",
//       image:
//         "https://image.brilliantearth.com/cdn-cgi/image/width=720,height=545,quality=100,format=auto/https://cdn.builder.io/api/v1/image/assets%2F9f2a69003c86470ea05deb9ecb9887be%2F1c5d4b45587a482b80271c35731d3751",
//       link: "/",
//       description: " Your one-stop-shop for perfectly proportioned pairs.",
//     },
//   ];
//   return (
//     <Container className="w-full mb-14 lg:mb-20">
//       <Row>
//         {Products?.map((singleProduct) => {
//           return (
//             <div className="col-lg-3 col-md-4 col-sm-6 col-6">
//               {/* <Col
//               key={singleProduct?.id}
//               xs={12}
//               md={3}
//               //   lg={6}
//               className="col-span-2 mb-0 md:col-span-3 lg:col-span-6 h-full flex flex-col justify-between justify-normal"
//             > */}
//               <div className="aspect-[169/128] mb-6 lg:mb-0 w-full h-full">
//                 <a className="w-full h-full" href={singleProduct?.link}>
//                   <img
//                     src={singleProduct?.image}
//                     alt={singleProduct?.name}
//                     className="img-fluid"
//                   />
//                 </a>
//               </div>
//               <h2 className="mt-0 mb-2">{singleProduct?.name}</h2>
//               <div className="mt-0 mb-6">
//                 <p className=" text-secondary ">
//                   <span>{singleProduct?.description}</span>
//                 </p>
//               </div>
//               <div className="flex flex-col gap-2 lg:gap-4 w-full lg:w-7/12 min-[1324px]:w-5/12 h-fit">
//                 <Button
//                   href={singleProduct?.link}
//                   target="_self"
//                   className="px-4 min-w-[128px] w-full md:w-auto text-sm font-medium text-center bg-transparent border border-solid text-color-black border-black hover:text-white hover:border-black hover:bg-black active:text-white active:border-black active:bg-black max-w-none whitespace-nowrap text-ellipsis overflow-hidden flex-initial inline-block no-underline leading-[42px] hover:no-underline active:no-underline"
//                   aria-label="Shop Now"
//                 >
//                   Shop Now
//                 </Button>
//               </div>
//               {/* </Col> */}
//             </div>
//           );
//         })}
//       </Row>
//     </Container>
//   );
// };

// export default ContainerGridHome;
