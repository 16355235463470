import { Container, Row, Col, Image, Button } from "react-bootstrap";
import React from "react";
import { Link } from "react-router-dom";
import useMediaQuery from "../../Hooks/useMeadiaQuery";
import Slider from "react-slick";
import GlobalTitle from "../common/global-title";

const EngagementGridHomeRong = ({ Products, title = "", lg = 3 }) => {
  const isAboveDesktop = useMediaQuery("(min-width: 768px)");

  return Products?.length ? (
    <Container className=" collection-slider my-6 ">
      {
        !!title && <GlobalTitle title={title} />
        // <h2 className="text-center heading-55 pt-4">{title}</h2>
      }
      <div className="mb-4">
        <Container className=" ">
          {isAboveDesktop ? (
            <Container className=" pt-2 pb-4 small-container product-container-small ">
              <Row>
                {Products?.map((singleProduct) => {
                  return (
                    <Col
                      key={singleProduct?.id}
                      className="text-center"
                      xs={12}
                      sm={4}
                      // md={6}
                      lg={4}
                    >
                      <div className="Rings_hover  mb-2">
                        <Link to={singleProduct?.link}>
                          <Image
                            src={singleProduct?.image}
                            alt={singleProduct?.name}
                            fluid
                            // style={{
                            //   aspectRatio: "9/12",
                            // }}
                            style={{
                              maxHeight: isAboveDesktop ? "100%" : "100px",
                            }}
                            className="Rings_img_hover"
                          />
                        </Link>
                      </div>
                      <h2 className="mt-0 mb-2 h2-32">{singleProduct?.name}</h2>
                    </Col>
                  );
                })}
              </Row>
            </Container>
          ) : (
            <ProductSlider Products={Products} />
          )}
        </Container>
      </div>
    </Container>
  ) : (
    ""
  );
};

export default EngagementGridHomeRong;

export const ProductSlider = ({ Products }) => {
  const buttonStyles = {
    backgroundColor: "#e77e50",
    padding: "10px",
    borderRadius: "50%",
    zIndex: 1,
    // fontSize: '0PX',
    color: "#333",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          ...buttonStyles,
          left: window.screen.width < 500 ? "-8px" : "-30px",
          top: "43%",
        }}
        onClick={onClick}
      >
        {/* &lsaquo; */}
      </div>
    );
  };

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          ...buttonStyles,
          right: window.screen.width < 500 ? "-8px" : "-30px",
          top: "43%",
        }}
        onClick={onClick}
      >
        {/* &rsaquo; */}
      </div>
    );
  };
  const settings = {
    infinite: true,
    dots: false,
    speed: 500,
    slidesToShow: 5,
    arrow: false,
    swipe: true,
    slidesToScroll: 1,
    // centerMode: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5.5,
          slidesToScroll: 1,
          // infinite: true,
          // dots: false,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          // initialSlide: ,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          // initialSlide: ,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {Products?.map((singleProduct) => {
        return (
          <div key={singleProduct?.id} className="text-center">
            <div className="Rings_hover  mb-2">
              <Link to={singleProduct?.link}>
                <Image
                  src={singleProduct?.image}
                  alt={singleProduct?.name}
                  fluid
                  // style={{
                  //   aspectRatio: "9/12",
                  // }}
                  // style={{
                  //   maxHeight: "100px",
                  // }}
                  className="Rings_img_hover"
                />
              </Link>
            </div>
            <h2 className="mt-0 mb-2 h2-32">{singleProduct?.name}</h2>
          </div>
        );
      })}
      {/* {Products?.map((singleProduct) => {
        return (
          <div
            key={singleProduct?.id}
            className="text-center Rings_hover_on w-100 h-100"
          >
            <div className="Rings_hover_on  mb-2">
              <Link to={singleProduct?.link}>
                <Image
                  src={singleProduct?.image}
                  alt={singleProduct?.name}
                  fluid
                  // style={{
                  //   aspectRatio: "9/12",
                  // }}
                  className="Rings_img_hover"
                />
              </Link>
            </div>
            <h2 className=" h2-32">{singleProduct?.name}</h2>
          </div>
        );
      })} */}
    </Slider>
  );
};
