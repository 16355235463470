import React from "react";
import { imageURL2 } from "../../images";
import GlobalTitle from "../common/global-title";

const MeasureRingSection2 = () => {
  return (
    <section className="container py-4">
      <GlobalTitle title="Method Two: Measure an Existing Ring with Our Chart" />
      {/* <h3 className="text-center my-7">
        Method Two: Measure an Existing Ring with Our Chart
      </h3> */}
      <div className="row justify-content-center mt-3">
        <div className="col-md-6">
          <div className="text-muted">
            <p>
              <strong>What you'll need:</strong>
            </p>
            <ul>
              <li>Printer & paper</li>
              <li>Scissors</li>
              <li>An existing ring</li>
            </ul>
            <p>
              <strong>Steps:</strong>
            </p>
            <ol>
              <li>
                Print the{" "}
                <a
                  href="https://andorluxury.blr1.cdn.digitaloceanspaces.com/ring-sizer/andor-ring-sizer.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  className=" text-black text-decoration-underline"
                >
                  Printable Ring Sizer & Conversion Chart
                </a>
                . Set page scaling to "100" in your print settings and use 8.5 x
                11-inch standard paper.
              </li>
              <li>Choose a ring that correctly fits the intended finger.</li>
              <li>
                Lay the ring over each size circle within the chart until you
                can closely match the inside edge to one of the circles on the
                guide. If the ring falls between two sizes, contact us to order
                the quarter size between them.
              </li>
            </ol>
          </div>
        </div>
        <div className="col-md-6">
          <div className="ratio ratio-4x3">
            <img src={imageURL2?.image_137} alt="" className="img-fluid" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MeasureRingSection2;
