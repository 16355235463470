import React from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "../../Hooks/useMeadiaQuery";
import image1 from "../../assets/images/home/mostpopular/1.png";
import image2 from "../../assets/images/home/mostpopular/2.png";
import image3 from "../../assets/images/home/mostpopular/3.png";
import image4 from "../../assets/images/home/mostpopular/4.png";
import image5 from "../../assets/images/home/mostpopular/5.png";
import image6 from "../../assets/images/home/mostpopular/6.png";
import image7 from "../../assets/images/home/mostpopular/7.png";
import SliderComponent from "../Slider/sliderComponent";

const categoryDummyData = [
  {
    title: "Fashion Pendants",
    link: "/fashion-pendants",
    image: image1,
  },
  {
    title: "Eternity Rings",
    link: "/eternity-bands",
    image: image2,
  },
  {
    title: "Anniversary Ring",
    link: "/anniversary-bands",
    image: image3,
  },
  {
    title: "Bracelets",
    link: "/tennis-bracelets",
    image: image4,
  },
  {
    title: "Solitaire Rings",
    link: "/solitaire-engagement-rings",
    image: image5,
  },
  {
    title: "Necklaces",
    link: "/fashion-necklaces",
    image: image6,
  },
  {
    title: "Bangles",
    link: "/bangles",
    image: image7,
  },
];
const ShopByCategory = () => {
  const navigate = useNavigate();
  const isAboveDesktop = useMediaQuery("(min-width: 768px)");
  const cardData = categoryDummyData?.map((categoryData, index) => {
    return (
      <div key={index} className="px-3" style={{ padding: "0 15px" }}>
        <div className="slider-card slider-card-zoom">
          <img
            className="img-fluid cursor-pointer"
            src={categoryData?.image}
            alt={categoryData?.title}
            style={{
              width: "100%",
              height: "auto",
              objectFit: "cover",
              aspectRatio: "3/4",
            }}
            onClick={() => navigate(categoryData?.link)}
          />
          
        </div>
        <h4
            className="text-center my-2 cursor-pointer"
            onClick={() => navigate(categoryData?.link)}
          >
            {categoryData?.title}
          </h4>
      </div>
    );
  });
  return (
    <Container className="my-6 collection-slide">
      {/* <ProductSlider categoryDummyData={categoryDummyData} /> */}
      <SliderComponent
        title={"Most Popular"}
        responsive={responsive}
        slidesToShow={3.5}
        data={cardData}
        // infinite
      />
      {/* )} */}
    </Container>
  );
};

export default ShopByCategory;

const responsive = [
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 3.5,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 769,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 600,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 480,
    settings: {
      slidesToShow: 1.05,
      slidesToScroll: 1,
    },
  },
];

// export const PrevArrow = (props) => {
//   const { className, style, onClick } = props;
//   return (
//     <FiChevronLeft
//       className={className}
//       style={{
//         ...style,
//         cursor: "pointer",
//         fontSize: "24px",
//       }}
//       onClick={onClick}
//     />
//   );
// };

// export const NextArrow = (props) => {
//   const { className, style, onClick } = props;
//   return (
//     <FiChevronRight
//       className={className}
//       style={{
//         ...style,
//         cursor: "pointer",
//         fontSize: "24px",
//       }}
//       onClick={onClick}
//     />
//   );
// };

// export const ProductSlider = ({ categoryDummyData }) => {
//   const navigate = useNavigate();

//   const settings = {
//     infinite: false,
//     dots: false,
//     speed: 500,
//     slidesToShow: 3.5,
//     slidesToScroll: 3,
//     arrows: false,
//     // prevArrow: <PrevArrow />,
//     // nextArrow: <NextArrow />,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 3.5,
//           slidesToScroll: 1,
//         },
//       },
//       {
//         breakpoint: 769,
//         settings: {
//           slidesToShow: 3,
//           slidesToScroll: 1,
//         },
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 1,
//         },
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//   };
//   let sliderRef = useRef(null);
//   const next = () => {
//     sliderRef.slickNext();
//   };
//   const previous = () => {
//     sliderRef.slickPrev();
//   };
//   // const settings = {
//   //   dots: true,
//   //   infinite: true,
//   //   speed: 500,
//   //   slidesToShow: 1,
//   //   slidesToScroll: 1
//   // };
//   return (
//     <div>
//       <p
//         className="d-flex align-items-center "
//         style={{ justifyContent: "space-between" }}
//       >
//         <h2 className="text-center my-7">Voted Most Popular</h2>
//         <span
//           style={{
//             display: "flex",
//             justifyContent: "space-between",
//             gap: "10px",
//           }}
//         >
//           <PrevArrow onClick={previous} />
//           <NextArrow onClick={next} />
//         </span>
//       </p>
//       <Slider
//         ref={(slider) => {
//           sliderRef = slider;
//         }}
//         {...settings}
//         className="get-inspired-slider"
//       >
//         {categoryDummyData.map((categoryData, index) => {
//           return (
//             <div key={index} className="px-3" style={{ padding: "0 15px" }}>
//               <div className="slider-card">
//                 <img
//                   className="img-fluid cursor-pointer"
//                   src={categoryData.image}
//                   alt={categoryData.title}
//                   style={{
//                     width: "100%",
//                     height: "auto",
//                     objectFit: "cover",
//                     aspectRatio: "3/4",
//                   }}
//                   onClick={() => navigate(categoryData.link)}
//                 />
//                 <h4
//                   className="text-center my-2 cursor-pointer"
//                   onClick={() => navigate(categoryData.link)}
//                 >
//                   {categoryData.title}
//                 </h4>
//               </div>
//             </div>
//           );
//         })}
//       </Slider>
//     </div>
//   );
// };
