import React from "react";
import "../../assets/css/aboutus.css";
import { Container } from "react-bootstrap";
import CardSection from "./cardSection";
import AbountCounter from "./abount-counter";
import AboutBgwithtext from "./about-bgwithtext";
import ContactBanner from "./contact-banner";
import { imageURL2 } from "../../images";

const Aboutus2 = () => {
  return (
    <div className="aboutus-new-main mt-5 my-6">
      <Container>
        <Container>
          <Container>
            <div className="mb-100">
              <h4 className="mb-20 text-center">
                {" "}
                We reinterpret diamond jewelry as a symbol of grace and morality
                rather than merely an ornament. 100% conflict-free lab-grown
                diamonds, expertly created to exemplify ageless Elegance and
                uniqueness, are featured in our collection. Every piece uses
                sustainable luxury to celebrate life's most spectacular moments,
                making sure that every sparkle demonstrates our dedication to
                fine craftsmanship and ethical sourcing. Explore our dedication
                at Andor Luxury and decorate yourself with jewelry that exudes
                integrity and meaning in addition to beauty.
              </h4>
              <h4 className="text-center">
                {" "}
                Celebrate your engagement and wedding moments with Andor Luxury,
                where diamonds, with their genuine brilliance and timeless
                allure, eloquently tell the story of your unique journey
                together. Each piece embodies elegance, capturing the essence of
                your love and commitment, making your special day truly
                unforgettable.
              </h4>
            </div>
          </Container>

          <CardSection
            imgurl={imageURL2.image_65}
            width="78%"
            height="350px"
            sideText={
              <>
                <h3 className="common-hd common-space2 text-primary">
                  Sustainable Style:
                  <br />
                  Our promise
                </h3>
                <p className="">
                  At Andor Luxury, we believe true luxury shines brighter with
                  ethical practices. That's why we offer 100% conflict-free
                  labgrown diamonds, meticulously crafted with minimal
                  environmental impact. Your Andor treasure arrives nestled in
                  an eco-friendly box, ensuring every aspect of your experience
                  reflects sustainable luxury. Celebrate life's moments with
                  dazzling brilliance and a conscience for a brighter future –
                  choose Andor Luxury.
                </p>
              </>
            }
            cardPosition="left"
          />

          <AbountCounter />
          {/* <div className="ourstory-main common-space1 mb-5 ">
            <div className="thumb text-center">
              <img
                src={imageURL2.image_67}
                className=" d-md-block ml-auto mr-auto h-auto rounded-4"
                style={{ marginRight: "auto", marginLeft: "auto" }}
                width="2200"
                height="1160"
                alt
              />
            </div>
            <div className="content">
              <div className="d-flex align-items-start justify-content-between flex-wrap flex-md-nowrap">
                <h3 className="common-hd text-primary">
                  The Spark of : Innovation
                </h3>
                <div className="content-detail">
                  <p>
                    Welcome to ANDOR Luxury, where timeless elegance meets
                    eco-conscious innovation. Founded by Mr. Batuk Bhikadiya,
                    our brand represents a revolution in the world of fine
                    jewelry. With over 15 years of personal experience and a
                    family legacy spanning more than three decades in the
                    diamond industry, Mr. Bhikadiya has dedicated decades to
                    mastering the art of diamond manufacturing and exporting.
                    However, driven by a profound concern for the environment,
                    he decided to transition from natural to lab-grown diamonds.
                    Lab-grown diamonds are the cornerstone of our brand,
                    offering an ecofriendly alternative to traditional mined
                    diamonds, identical in every way to their natural
                    counterparts but with a significantly smaller environmental
                    footprint. Inspired by this vision of sustainability, Mr.
                    Bhikadiya set out to create a brand that not only offers
                    stunning jewelry but also promotes eco-friendly practices.
                  </p>
                  <p>
                    At ANDOR Luxury, we merge traditional craftsmanship with
                    contemporary design, offering a diverse array of jewelry to
                    suit every style and occasion. The name "ANDOR" symbolizes
                    our commitment to variety and choice, derived from the words
                    "AND" and "OR," reflecting the wide range of options
                    available to our customers. Our mission is to redefine
                    luxury by creating jewelry that not only captivates with its
                    beauty but also reflects our dedication to sustainability.
                    Join us at ANDOR Luxury, where each piece tells a tale of
                    elegance and responsibility in the modern world of fine
                    jewelry.
                  </p>
                </div>
              </div>
            </div>
          </div> */}

          {/* <CardSection
          imgurl="https://media.grownbrilliance.com/a2c5372a-e16a-4ea3-b361-da9fdc89a59f/https://images.grownbrilliance.com/images/about-us/our-promise-desk.jpg"
          sideText={
            <>
              <h3 className="common-hd common-space2 text-primary">
                Expert Gemmologists :
              </h3>
              <p className="">
                our team of expert gemmologists isn't just about grading
                diamonds - they're the stewards of sparkle. Their keen eyes
                ensure exceptional quality, while their passion for diamonds
                translates into expert guidance, helping you find the perfect
                lab-grown diamond to tell your unique story.
              </p>
            </>
          }
          cardPosition="left"
        /> */}

          {/* <div>
          <div style={{ order: 2 }}>
            <div style={{ position: "relative", paddingTop: "100%" }}>
              <img
                src="https://www.withclarity.com/cdn/shop/files/gemologist-using-diamond-microscope-desktop_1_1200x.jpg?v=1684211361"
                className=" d-md-block ml-auto mr-auto h-auto rounded-4"
                style={{
                  marginRight: "auto",
                  marginLeft: "auto",
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                }}
                width="2200"
                height="1160"
                alt
              />
            </div>
          </div>
          <div style={{ marginRight: "-80px" }}>
            <div style={{ backgroundColor: "#d5ebec", padding: "70px" }}>
              <h3>Expert Gemologists</h3>
              <p>
                Our team is as dedicated to you as you are to yours. They've
                curated and inspected our diamonds and products to ensure
                quality and craftsmanship is in every detail. Any questions?
                We're available to help.
              </p>
            </div>
          </div>
        </div> */}
          <div
            className="gemologists-wrapper d-md-flex align-items-center justify-content-center mb-5"
            // style={{
            //   display: "flex",
            //   alignItems: "center",
            //   justifyContent: "center",
            // }}
          >
            <div className="medium-up--one-half">
              <div style={{ position: "relative", paddingTop: "120%" }}>
                <img
                  src={imageURL2.image_66}
                  className=" d-md-block ml-auto mr-auto rounded-4"
                  style={
                    {
                      // marginRight: "auto",
                      // marginLeft: "auto",
                      // position: "absolute",
                      // objectFit: "cover",
                      // top: "0",
                      // left: "0",
                      // width: "75%",
                      // height: "75%",
                    }
                  }
                  // width="2200"
                  // height="1160"
                  alt
                />
              </div>
            </div>
            <div
              className="grid__item-content"
              style={
                {
                  // marginRight: "-80px",
                  // position: "relative",
                  // zIndex: "1",
                  // width: "50%",
                }
              }
            >
              <div className="content">
                <h3>Expert Gemologists</h3>
                <p>
                  Our team is as dedicated to you as you are to yours. They've
                  curated and inspected our diamonds and products to ensure
                  quality and craftsmanship is in every detail. Any questions?
                  We're available to help.
                </p>
              </div>
            </div>
          </div>

          <CardSection
            imgurl={imageURL2.image_68}
            sideText={
              <>
                <h3 className=" text-primary">
                  Exquisitely Crafted :<br />
                  Our Designs
                </h3>
                <p>You are unique, and your sparkle should be too.</p>
                <p>
                  Andor Luxury offers a curated collection of handcrafted
                  designs, featuring modern twists on timeless classics, to
                  ignite your brilliance. But for the truly individual, we offer
                  complete customization – from diamond selection to setting
                  design, our expert craftspeople bring your vision to life.
                </p>
                <p>These aren't just diamonds, they're a reflection of you.</p>
              </>
            }
          />
          {/* <AboutBgwithtext /> */}
          <ContactBanner />
        </Container>
      </Container>
    </div>
  );
};

export default Aboutus2;
