import React from "react";
import "./contact.css";
import * as Yup from "yup";
import { Container } from "react-bootstrap";
import GlobalForm from "../form/globalForm";
import { values } from "lodash";

const initialValues = {
  name: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "",
  country: "",
  postal_code: "",
  email: "",
  phone: "",
  file: null,
};

const fields = [
  { type: "text", name: "name", label: "Name", size: 12, required: true },
  { type: "email", name: "email", label: "Email", size: 12, required: true },
  { type: "text", name: "phone", label: "Phone", size: 12, required: false },
  {
    type: "select",
    name: "topic",
    label: "Topic",
    size: 12,
    required: true,

    options: [
      {
        label: "Diamonds",
        value: "diamonds",
      },
      {
        label: "Engagement Rings",
        value: "engagement_Rings",
      },
      {
        label: "Jewelry",
        value: "jewelry",
      },
      {
        label: "Home Preview",
        value: "home_Preview",
      },
      {
        label: "policies",
        value: "Policies",
      },
    ],
  },

  {
    type: "textarea",
    name: "askaQuestion",
    label: "Ask a Question",
    size: 12,
    required: false,
  },

  // {
  //   type: "file",
  //   name: "file",
  //   label: "File Upload",
  //   size: 12,
  //   required: true,
  // },
];

const userAddressListSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  topic: Yup.string().required("Topic is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  phone: Yup.string().matches(/^[0-9]{10}$/, "Invalid phone number"),
  // file: Yup.mixed().required("File is required"),
});

const ContactForm = ({
  state,
  handleChange,
  isError,
  errorMsg,
  handleContact,
}) => {
  const handleSubmit = (values) => {
    console.log("Form values:", values);
  };
  return (
    <Container>
      <div className="contact-form-section pt-5 pb-5">
        <div className="contact-form-wrapper">
          <div className="mb-5">
            <h2 className="text-center mb-4">Send us a message</h2>
            <h6 className="text-center">
              Contact one of our expert gemologists and we’ll get back to you as
              soon as possible. You’ll be amazed at how helpful we can be.
            </h6>
          </div>
          <Container
            className="contact-form position-relative"
            style={{ marginTop: "80px" }}
          >
            <GlobalForm
              initialValues={initialValues}
              validationSchema={userAddressListSchema}
              onSubmit={handleSubmit}
              fields={fields}
              // isNew={true}
              // dispatch={() => {}}
              // addNewAddress={() => {}}
              // updateAddress={() => {}}
              // editId={null}
              // setState={() => {}}
              // initialValue={initialValues}
              // setIsEdit={() => {}}
            />
            {/* <div className="row col-xxl-8 col-lg-10 mx-auto" data-aos="fade-up">
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="">Name</label>
                  <input
                    type="text"
                    placeholder=""
                    value={state?.name}
                    name="name"
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="">Phone Number</label>
                  <input
                    type="text"
                    placeholder=""
                    value={state?.phone}
                    name="phone"
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="">Email</label>
                  <input
                    type="text"
                    placeholder=""
                    value={state?.email}
                    name="email"
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="">
                    Topic <span aria-hidden="true">*</span>
                  </label>
                  <select
                    //   id="ContactForm-topic"
                    name="topic"
                    data-validate="{required:true}"
                    aria-required="true"
                    data-uw-rm-form="nfx"
                    className="form-control"
                  >
                    <option value="">Select</option>
                    <option value="Diamonds">Diamonds</option>
                    <option value="Engagement Rings">Engagement Rings</option>
                    <option value="Jewelry">Jewelry</option>
                    <option value="Home Preview">Home Preview</option>
                    <option value="Policies">Policies</option>
                  </select>
                </div>
              </div>

              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="">Ask a Question</label>
                  <textarea
                    rows="5"
                    value={state?.message}
                    name="message"
                    onChange={handleChange}
                    className="form-control"
                  ></textarea>
                </div>
              </div>
              {isError && (
                <div style={{ color: "red", fontSize: "16px" }}>{errorMsg}</div>
              )}
            </div>
            <div className="text-center">
              <button
                //   className="btn btn-secondary btn-contact"
                className="btn btn-theme btn-white-outline btn-white-outline-hover text-dark1 border-dark1"
                onClick={handleContact}
              >
                Submit
              </button>
              
            </div> */}
          </Container>
        </div>
      </div>
    </Container>
  );
};

export default ContactForm;
