import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import useMediaQuery from "../../Hooks/useMeadiaQuery";

const CollectionDontMiss = ({ text, imageURL2 }) => {
  const isAboveDesktop = useMediaQuery("(min-width: 768px)");
  return (
    <div className="my-7">
      {/* Begin:: Collection you don't want to miss */}
      <section
        className="container collection-sec pt-4 pt-md-3 pt-xl-5 small-container product-container-small my-5 mt-0 collection-slider"
        id="topCollection"
      >
        <div className="col-xxl-9 col-xl-10 col-md-12 mx-auto">
          <h2 className="text-center heading-55 " data-aos="fade-up">
            {text?.text_10}
          </h2>

          {isAboveDesktop ? (
            <Row className="mt-5  ">
              <Col md={4}>
                <Link to={"/engagement-collection"} className="coll-card">
                  <img
                    src={imageURL2.image_12}
                    className="img-fluid"
                    alt="collection"
                  />
                  <h4 className="h2-30 text-center">Engagement Collection</h4>
                  {/* <Button className="btn btn-theme btn-outline text-secondary m-0 btn-hoverable">
                    Shop Now
                  </Button> */}
                </Link>
              </Col>
              <Col md={4}>
                <Link to={"/wedding-collection"} className="coll-card">
                  <img
                    src={imageURL2.image_13}
                    className="img-fluid"
                    alt="collection"
                  />
                  <h4 className="h2-30 text-center">Wedding Collection</h4>
                  {/* <Button className="btn btn-theme btn-outline text-secondary m-0 btn-hoverable">
                    Shop Now
                  </Button> */}
                </Link>
              </Col>
              <Col md={4}>
                <Link to={"/jewelry-collection"} className="coll-card">
                  <img
                    src={imageURL2.image_14}
                    className="img-fluid"
                    alt="collection"
                  />
                  <h4 className="h2-30 text-center">Jewelry Collection</h4>
                  {/* <ButimageURL2.image_115> */}
                </Link>
              </Col>
            </Row>
          ) : (
            <CollectionSlider imageURL2={imageURL2} />
          )}
        </div>
      </section>
      {/* End:: Collection you don't want to miss */}
    </div>
  );
};

export default CollectionDontMiss;

export const CollectionSlider = ({ imageURL2 }) => {
  const buttonStyles = {
    // backgroundColor: "#e77e50",
    padding: "10px",
    borderRadius: "50%",
    zIndex: 1,
    // fontSize: '0PX',
    color: "#333",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          ...buttonStyles,
          left: window.screen.width < 500 ? "12px" : "-30px",
          top: "38.5%",
        }}
        onClick={onClick}
      >
        {/* &lsaquo; */}
      </div>
    );
  };

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          ...buttonStyles,
          right: window.screen.width < 500 ? "12px" : "-30px",
          top: "38.5%",
        }}
        onClick={onClick}
      >
        {/* &rsaquo; */}
      </div>
    );
  };
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      <div className="mt-80">
        <Link to={"/engagement-collection"} className="coll-card">
          <img
            src={imageURL2.image_12}
            className="img-fluid"
            alt="collection"
          />
          <h4 className="h2-30 text-center">Engagement Collection</h4>
          <Button className="btn btn-theme btn-outline text-secondary m-0 btn-hoverable">
            Shop Now
          </Button>
        </Link>
      </div>
      <div className="mt-80">
        <Link to={"/wedding-collection"} className="coll-card">
          <img
            src={imageURL2.image_13}
            className="img-fluid"
            alt="collection"
          />
          <h4 className="h2-30 text-center">Wedding Collection</h4>
          <Button className="btn btn-theme btn-outline text-secondary m-0 btn-hoverable">
            Shop Now
          </Button>
        </Link>
      </div>
      <div className="mt-80">
        <Link to={"/jewelry-collection"} className="coll-card">
          <img
            src={imageURL2.image_14}
            className="img-fluid"
            alt="collection"
          />
          <h4 className="h2-30 text-center">Jewelry Collection</h4>
          <Button className="btn btn-theme btn-outline text-secondary m-0 btn-hoverable">
            Shop Now
          </Button>
        </Link>
      </div>
    </Slider>
  );
};
