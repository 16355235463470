import React from "react";
import { imageURL2 } from "../../images";

const CardSection = ({
  imgurl,
  sideText = <></>,
  cardPosition = "right",
  width = "78%",
  height = "90%",
}) => {
  return (
    <div className="lft-right-section common-space1 card-section my-6">
      <div className="row align-items-center">
        {cardPosition === "left" && (
          <div className="col-lg-6 content mb-4">{sideText}</div>
        )}
        <div className="col-lg-6 mb-3 mb-lg-0 thumb">
          <img
            src={imgurl ?? imageURL2.image_68}
            className="d-lg-block inside-img"
            style={{
              width: `${width}`,
              height: height,
              objectFit: "cover",
            }}
            width="830"
            height="930"
            alt=""
          />
        </div>
        {cardPosition === "right" && (
          <div className="col-lg-6 content mb-4">{sideText}</div>
        )}
      </div>
    </div>
  );
};

export default CardSection;
