import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { productsListUrl } from "../../constants/urls";
import { isLogin } from "../../constants/function";

const token = localStorage.getItem("token");
let config = {
  headers: {
    'Authorization': 'Bearer ' + token
  }
}
export const fetchProducts = createAsyncThunk(
  "content/productList",
  async ({ category, page, userId, metal_type }) => {
    // console.log(userId);

    const res = await axios(`${productsListUrl}/${category}?page=${page}${userId ? `&user_id=${userId}` : ""}${metal_type ? `&metal_type=${metal_type}` : ""}`, config);
    if (isLogin()) {
      const data = await res.data;
      return data;
    } else {
      const data = await res.data;
      const localData = JSON.parse(localStorage.getItem("wishlist")) || [];
      data.data = data.data.map((item) => {
        // Iterate over the keys (e.g., "white", "yellow", "rose")
        const updatedItem = {};
        for (const key in item) {
          if (item.hasOwnProperty(key)) {
            const localItem = localData.find((i) => i.id === item[key].id);

            const isFavourite = localItem ? localItem.product?.favourite : item[key].favourite;

            updatedItem[key] = {
              ...item[key],
              favourite: isFavourite,
            };
          }
        }
        return updatedItem;
      });
      return data;
    }

  }
);

const initialState = {
  products: [],
  isLoading: false,
  error: null,
  success: false,
};

export const productsListSlice = createSlice({
  name: "productsList",
  initialState,
  reducers: {
    resetproductReducer: (state) => {
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProducts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.products = action.payload.data;
      state.success = true;
    });
    builder.addCase(fetchProducts.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export const { resetproductReducer } = productsListSlice.actions;
export default productsListSlice.reducer;
