import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { updateUserdetails } from "../../../../Validation/Schema/UserDetailsSchema";
import { monthDateList } from "../../../../helper/constant";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import {
  UserdetailsEdit,
  getUserdetails,
} from "../../../../features/user/getUserDetailsSlice";
import { useNavigate } from "react-router";
import toast from "react-hot-toast";

function UpdateProfile() {
  const navigate = useNavigate();
  const initialValue = {
    email: "",
    first_name: "",
    last_name: "",
    mobile: "",
    birthday_date: "",
    birthday_month: "",
  };

  const dispatch = useDispatch();

  const [details, setDetails] = useState(initialValue);

  const useDetails = useSelector((state) => state.userDetails.user);

  useEffect(() => {
    if (!!useDetails.data) {
      const { data } = useDetails;
      setDetails({
        email: data.email,
        first_name: data.first_name,
        last_name: data.last_name,
        mobile: data.mobile,
        birthday_date: data.birthday_date,
        birthday_month: data.birthday_month,
      });
    }
  }, [useDetails]);

  return (
    <div className="my-3">
      <Formik
        initialValues={details}
        enableReinitialize={true}
        validationSchema={updateUserdetails}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          try {
            dispatch(UserdetailsEdit(values));
            setSubmitting(false);
            resetForm();
          } catch (error) {
            toast.error(error);
          } finally {
            toast.success("Update Profile Successfully");
            dispatch(getUserdetails());
          }
        }}
      >
        {({ handleChange, errors, touched, values }) => {
          return (
            <Form className="auth-form px-0">
              <Row className=" g-0 g-md-5">
                <Col md={6} className="mb-3">
                  <label htmlFor="" className="form-label">
                    Email *
                  </label>
                  <input
                    name="email"
                    className={classNames("form-control", {
                      "error-border": errors.email && touched.email,
                    })}
                    disabled
                    onChange={handleChange}
                    placeholder="Email"
                    value={values.email}
                  />
                  {/* {errors.email && touched.email ? (
                  <div className="text-danger">{errors.email}</div>
                ) : null} */}
                </Col>
                <Col md={6} className="mb-3">
                  <label htmlFor="" className="form-label">
                    First Name *
                  </label>
                  <input
                    name="first_name"
                    className={classNames("form-control", {
                      "error-border": errors.first_name && touched.first_name,
                    })}
                    onChange={handleChange}
                    placeholder="First Name"
                    value={values.first_name}
                  />
                </Col>
              </Row>
              <Row className=" g-0 g-md-5">
                <Col md={6} className="mb-3">
                  <label htmlFor="" className="form-label">
                    Last Name *
                  </label>
                  <input
                    name="last_name"
                    className={classNames("form-control", {
                      "error-border": errors.last_name && touched.last_name,
                    })}
                    onChange={handleChange}
                    placeholder="Last Name"
                    value={values.last_name}
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <label htmlFor="" className="form-label">
                    mobile
                  </label>
                  <input
                    name="mobile"
                    className={classNames("form-control", {
                      "error-border": errors.mobile && touched.mobile,
                    })}
                    onChange={handleChange}
                    placeholder="mobile"
                    value={values.mobile}
                  />
                </Col>
              </Row>
              <Row className=" g-0 g-md-5">
                <Col md={6} className="mb-3">
                  <label htmlFor="" className="form-label">
                    Your Birthday
                  </label>
                  <Row className=" g-0 g-md-5">
                    <Col md={6} className="mb-3">
                      <select
                        value={values.birthday_month}
                        onChange={handleChange}
                        name="birthday_month"
                        className={classNames("form-select", {
                          "error-border":
                            errors.birthday_month && touched.birthday_month,
                        })}
                      >
                        <option value={""} style={{ cursor: "pointer" }}>
                          Month
                        </option>
                        {monthDateList.map((item, index) => {
                          return (
                            <option
                              value={item.month}
                              key={index}
                              style={{ cursor: "pointer" }}
                            >
                              {item.month}
                            </option>
                          );
                        })}
                      </select>
                    </Col>
                    <Col md={6} className="mb-0 mb-md-3">
                      <select
                        value={values.birthday_date}
                        onChange={handleChange}
                        className={classNames("form-select", {
                          "error-border":
                            errors.birthday_date && touched.birthday_date,
                        })}
                        name="birthday_date"
                      >
                        <option value={""} style={{ cursor: "pointer" }}>
                          Date
                        </option>
                        {monthDateList
                          ?.find((item) => item.month == values.birthday_month)
                          ?.date.map((item, index) => {
                            return (
                              <option
                                value={item}
                                key={index}
                                style={{ cursor: "pointer" }}
                              >
                                {item}
                              </option>
                            );
                          })}
                      </select>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <div className="">
                <Row>
                  <Col className="text-start">
                    <div
                      variant="link"
                      className="text-decoration-none fs-5"
                      style={{ cursor: "pointer", color: "#e77e50" }}
                      onClick={() => {
                        navigate("/profile");
                      }}
                    >
                      &lt; Back
                    </div>
                  </Col>
                  <Col className="text-end">
                    <Button
                      variant="primary"
                      className="px-4 py-2"
                      type="submit"
                      // disabled={isSubmitting}
                      // onClick={handleSubmit}
                    >
                      UPDATE
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default UpdateProfile;
