import React from "react";
import { Container } from "react-bootstrap";
import { IoStarSharp } from "react-icons/io5";
import Slider from "react-slick";
import useMediaQuery from "../../Hooks/useMeadiaQuery";
import SliderComponent from "../Slider/sliderComponent";
import EllipsisText from "./EllipseText";

const CustomerReviewSection = ({ Reviews = [] }) => {
  const isAboveDesktop = useMediaQuery("(min-width: 768px)");
  return (
    <Container className="my-6">
      <Container>
        <Container>
          {/* <h2 className="text-center heading-55 my-7">
            Hear From Our Happy Customers
          </h2>
          <div className="collection-slider">
            <ReviewSlider Reviews={Reviews} />
          </div> */}
          <SliderComponent
            title={"Hear From Our Happy Customers"}
            infinite
            // responsive={responsive}
            responsive={[
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: false,
                },
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  initialSlide: 1,
                },
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
            ]}
            slidesToShow={1}
            data={Reviews?.map((singleTestimonial) => {
              return (
                // <div className="">
                <div
                  className="px-5  d-flex flex-column justify-content-center align-items-center"
                  style={{
                    minHeight: "265px",
                  }}
                  key={singleTestimonial?.id}
                >
                  <h4>{`${singleTestimonial?.first_name} ${singleTestimonial?.last_name}`}</h4>
                  <p>
                    {Array(singleTestimonial?.star ?? 1)
                      ?.fill(1)
                      ?.map((el, i) => {
                        return (
                          <IoStarSharp
                            key={`${singleTestimonial?.id}_${i}`}
                            className=" text-primary "
                          />
                        );
                      })}
                  </p>
                  <div>
                    {isAboveDesktop ? (
                      <p className="text-center">{singleTestimonial?.review}</p>
                    ) : (
                      <EllipsisText
                        maxLines={3}
                        text={singleTestimonial?.review}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          />
        </Container>
      </Container>
    </Container>
  );
};

export default CustomerReviewSection;

export const ReviewSlider = ({ Reviews }) => {
  const isAboveDesktop = useMediaQuery("(min-width: 768px)");
  const buttonStyles = {
    backgroundColor: "#e77e50",
    padding: "10px",
    borderRadius: "50%",
    zIndex: 1,
    // fontSize: '0PX',
    color: "#333",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          ...buttonStyles,
          left: window.screen.width < 500 ? "-8px" : "-30px",
          top: "50%",
        }}
        onClick={onClick}
      >
        {/* &lsaquo; */}
      </div>
    );
  };

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          ...buttonStyles,
          right: window.screen.width < 500 ? "-8px" : "-30px",
          top: "50%",
        }}
        onClick={onClick}
      >
        {/* &rsaquo; */}
      </div>
    );
  };
  const settings = {
    dots: false,
    infinite: true,
    autoplay: {
      delay: 5000, // delay between transitions in milliseconds
      disableOnInteraction: false, // keep autoplay running even when user interacts
    },
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {Reviews?.map((singleTestimonial) => {
        return (
          // <div className="">
          <div
            className="px-5  d-flex flex-column justify-content-center align-items-center"
            style={{
              minHeight: "265px",
            }}
            key={singleTestimonial?.id}
          >
            <h3>{`${singleTestimonial?.first_name} ${singleTestimonial?.last_name}`}</h3>
            <p>
              {Array(singleTestimonial?.star ?? 1)
                ?.fill(1)
                ?.map((el, i) => {
                  return (
                    // <i
                    //   key={`${singleTestimonial?.id}_${i}`}
                    //   className="rating__icon rating__icon--star fa fa-star text-primary"
                    // ></i>
                    <IoStarSharp
                      key={`${singleTestimonial?.id}_${i}`}
                      className=" text-primary "
                    />
                  );
                })}
            </p>
            <div>
              {isAboveDesktop ? (
                <p className="text-center">{singleTestimonial?.review}</p>
              ) : (
                <EllipsisText maxLines={3} text={singleTestimonial?.review} />
              )}
            </div>
          </div>
        );
      })}
    </Slider>
  );
};
