import React from 'react';
import { Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { currencyIcon } from '../../constants/function';
import formatCurrency from '../../helper/currencyFormater';

const SearchMobileArea = ({

    search,
    handleSearchChange,
    setIsFocus,
    isFocus,
    closeOffcanvas,
    countryCurrency,
    cartData,
    searchResults,
    handleSearchClick,

}) => {
    const navigate = useNavigate();
    return (
        <>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid black",
                    padding: "10px",
                    marginBottom: "10px",
                }}
                className={`d-lg-none header-search fixed_search-hide position-relative `}
            >
                <i className="fa-solid fa-magnifying-glass"></i>
                <Form.Control
                    type="text"
                    placeholder="Search"
                    className="me-2"
                    style={{
                        border: "none",
                    }}
                    aria-label="Search"
                    value={search}
                    onChange={handleSearchChange}
                    onFocus={() => setIsFocus(true)}
                    onBlur={() => {
                        setTimeout(() => {
                            setIsFocus(false);
                        }, 500);
                    }}
                />
            </div>
            {isFocus && (
                <div
                    className="d-lg-none d-block  position-absolute shadow p-3 mb-5 bg-body rounded"
                    style={{
                        top: "80px",
                        width: "85vw",
                        overflow: "auto",
                        maxHeight: "75vh",
                        background: "#ffffff",
                        zIndex: 1000,
                        overflowY: "auto",
                    }}
                >
                    {searchResults.length > 0 ? (
                        <>
                            <Row className="g-3">
                                {searchResults.map((item, index) => {
                                    const price =
                                        countryCurrency == "INR"
                                            ? item?.product_price
                                            : countryCurrency == "USD"
                                                ? item?.usa_price
                                                : countryCurrency == "GBP"
                                                    ? item?.uk_price
                                                    : item?.usa_price;
                                    const formatedPrice = formatCurrency(price);
                                    return (
                                        <div
                                            key={index}
                                            style={{
                                                display: "flex",
                                                justifyContent: "start",
                                                gap: "10px",
                                                cursor: "pointer",
                                                font: "18px",
                                            }}
                                            onClick={() => {
                                                let formattedName = item?.title.toLowerCase();
                                                formattedName = formattedName.replace(
                                                    /\s+/g,
                                                    "-"
                                                );
                                                navigate(`/${formattedName}/${item?.sku}`, {
                                                    state: {
                                                        slug: "",
                                                    },
                                                });
                                                closeOffcanvas();
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "130px",
                                                }}
                                            >
                                                <img
                                                    src={item?.media_arr[0]}
                                                    className='w-100'
                                                />
                                            </div>
                                            <div style={{ margin: "5px 0px" }}>
                                                <div>{item?.title}</div>
                                                <div
                                                    style={{
                                                        color: "grey",
                                                        fontSize: "16px",
                                                    }}
                                                >
                                                    {item?.caption}
                                                </div>
                                                <div>
                                                    {currencyIcon(
                                                        cartData?.[0]?.currency ?? countryCurrency
                                                    )}{" "}
                                                    {formatedPrice}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </Row>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "end",
                                    alignItems: "center",
                                }}
                            >
                                <div
                                    style={{
                                        margin: "20px",
                                        cursor: "pointer",
                                        color: "#ffffff",
                                        background: "#e77e50",
                                        padding: "5px 10px",
                                        borderRadius: "25px",
                                    }}
                                    onClick={handleSearchClick}
                                >
                                    View More
                                </div>
                            </div>
                        </>
                    ) : (
                        <>No search result found</>
                    )}
                </div>
            )}
            {searchResults.length > 0 && <></>}
        </>
    )
}

export default SearchMobileArea