import React from "react";
import { Link } from "react-router-dom";
import GlobalTitle from "../common/global-title";

const OrderHelp = () => {
  return (
    <div className=" d-block text-center">
      <GlobalTitle title="Need Help With Your Order?" />
      {/* <h2 className="mb-4">Need Help With Your Order?</h2> */}
      <div className="mb-3 min-width-522 d-inline-block mr-2">
        <Link
          className="min-width-522 btn btn-theme btn-white-outline btn-white-outline-hover text-dark1 border-dark1"
          to="/contact/general-help"
        >
          General Help
        </Link>
      </div>
      <div className="mb-3 min-width-522 d-inline-block mr-2">
        <Link
          className=" min-width-522 btn btn-theme btn-white-outline btn-white-outline-hover text-dark1 border-dark1"
          to="/contact/retrun-my-item"
        >
          Return My Item(s)
        </Link>
      </div>
      <div className="mb-3 min-width-522 d-inline-block mr-2">
        <Link
          className="min-width-522 btn btn-theme btn-white-outline btn-white-outline-hover text-dark1 border-dark1"
          to="/contact/i-need-a-different-size"
        >
          I Need a Different Size
        </Link>
      </div>
    </div>
  );
};

export default OrderHelp;
