import React from "react";
import img1 from "../../assets/images/home/section-wise/perfect-pairing.jpg";
import useMediaQuery from "../../Hooks/useMeadiaQuery";
import ImageWithDetailSec from "../common/image-with-detail-sec";

const FindSizeArea = () => {
  const isAboveDesktop = useMediaQuery("(min-width: 768px)");
  return (
    <>
      <ImageWithDetailSec
        title="Find Your Ring Size"
        description="Don't know your ring size? You can order our free ring sizer, or use our printable guide which determines your size by using another ring that you already own."
        buttonText="FIND YOUR RING SIZE"
        buttonLink="/buying-guide/ring-size/"
        imagePath={img1}
        imageAlt="Find Your Ring Size"
        textPosition="left"
      />
      {/* <div className="resize-area pt-5 pb-5">
        <div class="d-flex align-items-center cms-img-text-item-right subSplash-split-image-banner-item-right">
          <div class="p-5 d-flex flex-column gap-3 text-item-content subSplash-split-image-banner-item-content">
            <h2>How to Find Your Perfect Fit</h2>
            <div>
              Don’t know your ring size? You can order our free ring sizer, or
              use our printable guide which determines your size by using
              another ring that you already own.
            </div>
            <div class="cms-img-text-item-btns-wrapper subSplash-split-image-banner-item-btns-wrapper">
              <Link
                to={"/buying-guide/ring-size/"}
                title="FIND YOUR RING SIZE"
                type="button"
                className="btn btn-secondary btn-theme"
              >
                FIND YOUR RING SIZE
              </Link>
            </div>
          </div>
          <div class="item-img-wrapper subSplash-split-image-banner-item-img-wrapper">
            <Image
              src="https://dam.bluenile.com/images/public/25205/Find Your Ring Size.jpeg"
              title="Find Your Ring Size"
              alt="Find Your Ring Size"
              class="cms-picture-base subSplash-split-image-banner-picture"
            />
          </div>
        </div>
      </div> */}
    </>
  );
};

export default FindSizeArea;
