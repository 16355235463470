import React from "react";
import { Container, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import useMediaQuery from "../../Hooks/useMeadiaQuery";
import img1 from "../../assets/images/wedding/women.jpg";

const MenWomenArea = () => {
  const isAboveDesktop = useMediaQuery("(min-width: 768px)");

  return (
    <>
      <Container className="pt-5 pb-5">
        <div className="row gx-3">
          <div className="col-12 col-sm-6">
            <div
              style={{
                aspectRatio: "169/128",
                width: "100%",
                // height: "100%",
              }}
              class="mb-4 mb-lg-0"
            >
              <a
                class="tw-w-full tw-h-full desktop:tw-h-auto large:tw-h-auto"
                href="/eternity-bands"
              >
                <Image
                  loading="lazy"
                  src={img1}
                  alt="Men"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    aspectRatio: "169/128",
                  }}
                  class="tw-w-full tw-h-full  mb-4"
                  width="735"
                  height="556"
                />
              </a>
              <h2 class=" mb-3 mt-3 text-center">Women's Wedding Rings</h2>
              <div class="mb-4 mt-0 text-center ">
                <p>
                  Browse stunning women's wedding bands, from nature inspired
                  styles to modern designs.
                </p>
              </div>
              <div class="d-flex justify-content-center">
                <Link
                  className="btn btn-theme btn-white-outline btn-white-outline-hover text-dark1 border-dark1"
                  style={{
                    width: isAboveDesktop ? "50%" : "100%",
                  }}
                  to="/eternity-bands"
                >
                  Shop Women's
                </Link>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div
              style={{
                aspectRatio: "169/128",
                width: "100%",
                // height: "100%",
              }}
              //   class="mb-4 mb-lg-0"
            >
              <a
                class="tw-w-full tw-h-full desktop:tw-h-auto large:tw-h-auto"
                href="/mens-wedding-bands/"
              >
                <Image
                  loading="lazy"
                  src="https://image.brilliantearth.com/cdn-cgi/image/width=735,height=556,quality=100,format=auto/https://cdn.builder.io/api/v1/image/assets%2F9f2a69003c86470ea05deb9ecb9887be%2Fe70dd8b426ea4317ae88ea0fb425be51"
                  alt="Men"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    aspectRatio: "169/128",
                  }}
                  class="tw-w-full tw-h-full  mb-4"
                  width="690"
                  height="450"
                />
              </a>
              <h2 class=" mb-3 mt-3 text-center">Men's Wedding Rings</h2>
              <div class="mb-4 mt-0 text-center">
                <p>
                  Explore our broad selection of men's wedding bands that
                  feature classic and unique styles.
                </p>
              </div>
              <div class="d-flex justify-content-center">
                <Link
                  className="btn btn-theme btn-white-outline btn-white-outline-hover text-dark1 border-dark1"
                  style={{
                    width: isAboveDesktop ? "50%" : "100%",
                  }}
                  to="/mens-wedding-bands/"
                >
                  Shop Men's
                </Link>
                {/* <a
                  href="/wedding-rings/womens/"
                  target="_self"
                  class="tw-px-4 tw-min-w-[128px] tw-w-full md:tw-w-auto tw-text-sm tw-font-medium tw-text-center tw-bg-transparent tw-border tw-border-solid tw-text-color-black tw-border-color-black hover:tw-text-color-white hover:tw-border-color-black hover:tw-bg-color-black active:tw-text-color-white active:tw-border-color-black active:tw-bg-color-black tw-max-w-none tw-whitespace-nowrap tw-text-ellipsis tw-overflow-hidden tw-flex-initial tw-inline-block tw-no-underline tw-leading-[42px] hover:tw-no-underline active:tw-no-underline"
                  aria-label="Shop Women's "
                >
                  Shop Women's{" "}
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default MenWomenArea;
