import React from "react";
import { imageURL2 } from "../../images";
import GlobalTitle from "../common/global-title";

const MeasureRingSection1 = () => {
  return (
    <section className="container py-4">
      <GlobalTitle title="Method One: Create Your Own Ring Sizer" />
      {/* <h3 className="text-center my-7">
        Method One: Create Your Own Ring Sizer
      </h3> */}
      <div className="row justify-content-center mt-3">
        <div className="col-md-6">
          <div className="ratio ratio-4x3">
            <img
              src={imageURL2?.image_136}
              alt="A length of ribbon"
              className="img-fluid"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="text-muted">
            <p>
              <strong>What you'll need:</strong>
            </p>
            <ul>
              <li>Printer & paper</li>
              <li>String, yarn, floss, or a thin strip of paper</li>
              <li>Pen or marker</li>
              <li>Ruler</li>
            </ul>
            <p>
              <strong>Steps:</strong>
            </p>
            <ol>
              <li>
                Cut a piece of string, yarn, floss, or thin strip of paper to at
                least three inches long.
              </li>
              <li>
                Wrap one end of the string around the base of the intended
                finger.
              </li>
              <li>
                Using a pen or marker, mark where the end of the string overlaps
                with the rest.
              </li>
              <li>
                Lay the string flat and use a ruler to measure the length
                between the end and your mark in millimeters.
              </li>
              <li>
                Use the{" "}
                <a
                  href="https://andorluxury.blr1.cdn.digitaloceanspaces.com/ring-sizer/andor-ring-sizer.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  className=" text-black text-decoration-underline"
                >
                  Ring Size Conversion Chart
                </a>{" "}
                to match the millimeter length to the corresponding ring size.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MeasureRingSection1;
