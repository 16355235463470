import React, { useEffect } from "react";
import { Breadcrumb, useAccordionButton } from "react-bootstrap";
import { CiCirclePlus } from "react-icons/ci";
import { LinkContainer } from "react-router-bootstrap";
import { useLocation } from "react-router-dom";
import Footer from "../element/Footer";
import GlobalTitle from "../common/global-title";
import GlobalFaq from "../common/global-faq";
import {
  appraisalandupgrades,
  crafting,
  customrings,
  diamondethics,
  diamondpricematch,
  financing,
  insuredshipping,
  lifeTimeWarranty,
  replicahomepreview,
  returns,
} from "../../constants/utils";

function OurPolicy() {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.substring(1);
      const element = document.getElementById(id);
      if (element) {
        setTimeout(() => {
          const headerOffset = 150;
          const elementPosition = element.getBoundingClientRect().top;
          const offsetPosition =
            elementPosition + window.pageYOffset - headerOffset;

          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
          });
        }, 0);
      }
    }
  }, [location]);

  return (
    <>
      <div className="container my-3">
        <Breadcrumb>
          <LinkContainer to="/" className="link-breadcrumb">
            <Breadcrumb.Item>Home</Breadcrumb.Item>
          </LinkContainer>

          <Breadcrumb.Item active>Our policy</Breadcrumb.Item>
        </Breadcrumb>

        <div className="mb-3">
          <GlobalTitle title="LIFETIME WARRANTY" />
          <GlobalFaq data={lifeTimeWarranty} />
        </div>

        <div className="mb-3">
          <GlobalTitle title="FINANCING" />
          <GlobalFaq data={financing} />
        </div>

        <div className="mb-3">
          <GlobalTitle title="REPLICA HOME PREVIEW" />
          <GlobalFaq data={replicahomepreview} />
        </div>

        <div className="mb-3">
          <GlobalTitle title="DIAMOND ETHICS" />
          <GlobalFaq data={diamondethics} />
        </div>

        <div className="mb-3">
          <GlobalTitle title="INSURED SHIPPING" />
          <GlobalFaq data={insuredshipping} />
        </div>

        <div className="mb-3">
          <GlobalTitle title="RETURNS" />
          <GlobalFaq data={returns} />
        </div>

        <div className="mb-3">
          <GlobalTitle title="CUSTOM RINGS & JEWELRY" />
          <GlobalFaq data={customrings} />
        </div>

        <div className="mb-3">
          <GlobalTitle title="APPRAISALS & UPGRADES" />
          <GlobalFaq data={appraisalandupgrades} />
        </div>

        <div className="mb-3">
          <GlobalTitle title="DIAMOND PRICE MATCH" />
          <GlobalFaq data={diamondpricematch} />
        </div>

        <div className="mb-3">
          <GlobalTitle title="CRAFTING" />
          <GlobalFaq data={crafting} />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default OurPolicy;

export function CustomToggle({ children, eventKey, handleClick }) {
  const decoratedOnClick = useAccordionButton(eventKey, () => {
    handleClick();
  });

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
      onClick={decoratedOnClick}
    >
      <button
        type="button"
        style={{
          backgroundColor: "transparent",
          border: "none",
          outline: "none",
          textAlign: "left",
          width: "100%",
          padding: "0.5rem 0rem",
        }}
      >
        {children}
      </button>
      <div
        style={{
          cursor: "pointer",
          fontSize: "30px",
        }}
      >
        <CiCirclePlus />
      </div>
    </div>
  );
}
