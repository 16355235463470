import React from "react";
import Header from "../element/Header";
import Footer from "../element/Footer";
import { Container } from "react-bootstrap";
import { imageURL } from "../../images";
import { Link } from "react-router-dom";

const Blog = () => {
  return (
    <>
      <div className="page-title">
        <h2>Blog</h2>
        <h5>Home - Blog</h5>
      </div>

      <Container className="blog-list">
        <div className="row">
          <div className="col-lg-9">
            <div className="row">
              <div className="col-md-12">
                <div className="blog-card blog-xl" data-aos="fade-up">
                  <div className="blog-img">
                    <img
                      src={imageURL.about5}
                      className="img-fluid"
                      alt="blog"
                    />
                  </div>
                  <div className="blog-body">
                    <p className="mb-0">December 28, 2022</p>
                    <h2>Lorem ipsum is placeholder text</h2>
                    <p className="text-muted">
                      Lorem ipsum is placeholder text commonly used in the
                      graphic, print, and publishing industries for previewing
                      layouts and visual mockups....
                    </p>
                    <a className="text-primary" href="/blog-detail">
                      Read More <i className="fa-solid fa-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="blog-card" data-aos="fade-up">
                  <div className="blog-img">
                    <img
                      src={imageURL.about5}
                      className="img-fluid"
                      alt="blog"
                    />
                  </div>
                  <div className="blog-body">
                    <p className="mb-0">December 28, 2022</p>
                    <h2>Lorem ipsum is placeholder text</h2>
                    <p className="text-muted">
                      Lorem ipsum is placeholder text commonly used in the
                      graphic, print, and publishing industries for previewing
                      layouts and visual mockups....
                    </p>
                    <a className="text-primary" href="/blog-detail">
                      Read More <i className="fa-solid fa-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="blog-card" data-aos="fade-up">
                  <div className="blog-img">
                    <img
                      src={imageURL.about5}
                      className="img-fluid"
                      alt="blog"
                    />
                  </div>
                  <div className="blog-body">
                    <p className="mb-0">December 28, 2022</p>
                    <h2>Lorem ipsum is placeholder text</h2>
                    <p className="text-muted">
                      Lorem ipsum is placeholder text commonly used in the
                      graphic, print, and publishing industries for previewing
                      layouts and visual mockups....
                    </p>
                    <a className="text-primary" href="/blog-detail">
                      Read More <i className="fa-solid fa-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="blog-side-panel">
              <div className="search" data-aos="fade-up">
                <input
                  type="text"
                  placeholder="Search"
                  className="form-control"
                />
                <i className="fa-solid fa-magnifying-glass"></i>
              </div>

              <div className="blog-category mb-4" data-aos="fade-up">
                <h4>
                  Categories <em></em>
                </h4>
                <ul>
                  <li>
                    <button className="btn btn-link" type="button">
                      Bracelet
                    </button>
                  </li>
                  <li>
                    <button className="btn btn-link active" type="button">
                      Charms
                    </button>
                  </li>
                  <li>
                    <button className="btn btn-link" type="button">
                      Earring
                    </button>
                  </li>
                  <li>
                    <button className="btn btn-link" type="button">
                      Necklace
                    </button>
                  </li>
                  <li>
                    <button className="btn btn-link" type="button">
                      Rings
                    </button>
                  </li>
                  <li>
                    <button className="btn btn-link" type="button">
                      Uncategorized
                    </button>
                  </li>
                </ul>
              </div>

              {/* Begin:: Latest post */}
              <div className="latest-post mb-4" data-aos="fade-up">
                <h4>
                  Latest Posts <em></em>
                </h4>
                <ul>
                  <li>
                    <img
                      src={imageURL.about1}
                      alt="blog post"
                      className="img-fluid"
                    />
                    <div>
                      <p className="mb-0">Monday, May 30, 22</p>
                      <h6 className="fw-bold">Easy Fixes For Home Decor</h6>
                    </div>
                  </li>
                  <li>
                    <img
                      src={imageURL.about1}
                      alt="blog post"
                      className="img-fluid"
                    />
                    <div>
                      <p className="mb-0">Monday, May 30, 22</p>
                      <h6 className="fw-bold">Easy Fixes For Home Decor</h6>
                    </div>
                  </li>
                  <li>
                    <img
                      src={imageURL.about1}
                      alt="blog post"
                      className="img-fluid"
                    />
                    <div>
                      <p className="mb-0">Monday, May 30, 22</p>
                      <h6 className="fw-bold">Easy Fixes For Home Decor</h6>
                    </div>
                  </li>
                </ul>
              </div>

              {/* Begin:: Tags */}
              <div className="blog-tags" data-aos="fade-up">
                <h4>
                  Tags <em></em>
                </h4>
                <ul>
                  <li>
                    <button className="btn btn-tag" type="button">
                      Baber
                    </button>
                  </li>
                  <li>
                    <button className="btn btn-tag" type="button">
                      Diamond
                    </button>
                  </li>
                  <li>
                    <button className="btn btn-tag" type="button">
                      Rings
                    </button>
                  </li>
                  <li>
                    <button className="btn btn-tag" type="button">
                      Gold
                    </button>
                  </li>
                  <li>
                    <button className="btn btn-tag" type="button">
                      Silver
                    </button>
                  </li>
                  <li>
                    <button className="btn btn-tag" type="button">
                      Fashion
                    </button>
                  </li>
                  <li>
                    <button className="btn btn-tag" type="button">
                      Jewelry
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Container>

      <Footer />
    </>
  );
};

export default Blog;
