import React from "react";

const ShippingSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={39}
      height={40}
      viewBox="0 0 50.156 43.497"
    >
      <path
        id="Path_32"
        data-name="Path 32"
        d="M50.155,11.266c-.909,1.164-1.83,2.319-2.726,3.493Q36.657,28.87,25.891,42.988c-.512.671-1.092.679-1.6.016Q12.439,27.472.588,11.938C.45,11.756.3,11.58.176,11.391a.827.827,0,0,1,.047-1.077Q4.359,5.351,8.494.389A1.216,1.216,0,0,1,9.544,0q13.265.007,26.53,0c1.566,0,3.133-.023,4.7.019a1.376,1.376,0,0,1,.919.414c2.769,3.277,5.51,6.577,8.257,9.873.072.087.138.18.207.27Zm-33.938.5,8.815,27.907.121-.024,8.8-27.883ZM2.591,11.752,22.667,38.06a.34.34,0,0,0,.012-.266q-4.05-12.839-8.094-25.681c-.1-.326-.274-.366-.566-.366q-5.288.012-10.575.005H2.591m44.991,0h-.548c-3.607,0-7.213.009-10.82-.012-.411,0-.547.136-.663.5Q31.571,24.9,27.566,37.549c-.049.155-.087.315-.13.472l.077.029,20.068-26.3M32.872,9.984,25.116,2.229l-7.76,7.755Zm-17.741-.64,7.6-7.6H10.7l4.433,7.6M27.284,1.725l7.708,7.706,4.5-7.706Zm-18.194.58L2.7,9.973H13.562L9.089,2.306m38.4,7.686L41.083,2.307,36.6,9.991Z"
        transform="translate(0 0)"
        fill="#000"
      ></path>
    </svg>
    // <svg
    //   id="aa9b852b-2bb5-4666-a6dc-39cb26caab54"
    //   data-name="Layer 1"
    //   xmlns="http://www.w3.org/2000/svg"
    //   viewBox="0 0 30 30"
    //   width={40}
    // >
    //   <path
    //     className="f557d930-5c45-4176-88e7-4bd6acdabf06"
    //     d="M28.19,14.4341l-4.7-4.6216A2.5664,2.5664,0,0,0,21.6787,9.07h-2.72V6.5684a1.6364,1.6364,0,0,0-1.6319-1.6319H2.6689A1.6373,1.6373,0,0,0,1.0361,6.5684v12.873a1.6378,1.6378,0,0,0,1.6328,1.6318H4.9746v.0352a3.9551,3.9551,0,1,0,7.91,0v-.0352l6.0507.0059v.0352A3.9552,3.9552,0,0,0,22.89,25.0571h.0127a3.9628,3.9628,0,0,0,3.9434-3.9516v-.0708l.0283-.0054a2.5888,2.5888,0,0,0,2.09-2.5269V16.2686A2.57,2.57,0,0,0,28.19,14.4341Zm-19.2646,9.29a2.6172,2.6172,0,1,1,2.6172-2.6172A2.62,2.62,0,0,1,8.9258,23.7236Zm8.7334-4.2822a.33.33,0,0,1-.0733.1992l-.0107.0127h-.0195v.022l-.0118.01a.3238.3238,0,0,1-.22.0879H12.6465l-.0088-.0234a3.9482,3.9482,0,0,0-7.4229,0l-.0087.0234H2.666a.3354.3354,0,0,1-.3349-.3349V6.5684a.3354.3354,0,0,1,.3349-.335H17.3242a.3355.3355,0,0,1,.335.335Zm5.2285,4.2822h-.002a2.6172,2.6172,0,0,1,.003-5.2343H22.89a2.6172,2.6172,0,1,1-.0019,5.2343Zm3.7012-4.0869-.0284.0054-.0107-.0264a3.9531,3.9531,0,0,0-7.3359.021l-.0088.022h-.2774l.0069-.04a1.5868,1.5868,0,0,0,.0156-.1729v-8.95H21.667a1.1549,1.1549,0,0,1,.8154.3345l4.6992,4.6221a1.1355,1.1355,0,0,1,.3457.8169l.0079,2.2338A1.1568,1.1568,0,0,1,26.5889,19.6367Z"
    //   />
    // </svg>
  );
};

export default ShippingSvg;
