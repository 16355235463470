import React from 'react';
import { Button } from 'react-bootstrap';
import AddressCard from '../cart/AddressCard';

const ListofAddressCard = ({ onClickHandler, onNewHandler, selectedShippingAddressId, selectedBillingAddressId, setIsSameShipping, setSelectedBillingAddressId, setIsNewBilling, setIsNewBillingForm, listOfAddress, isSameShipping, }) => {
  return (
    <>

      <div className="">
        <div className="row address-card-container  g-3">
          <div className="col-md-6 col-lg-4  mt-0 mb-2">
            <Button
              onClick={onNewHandler}
              style={{
                height: "165px",
              }}
              className="btn-addAddress"
            >
              Add new Address
            </Button>
          </div>
          {listOfAddress?.map((item) => {
            console.log(item)
            const addressInfo = {
              // name: `${item.firstname}`,
              firstname: item?.firstname,
              lastname: item?.lastname,
              // name: item.name,
              name:
                (item?.firstname || item?.lastname)
                  ? `${item?.firstname ?? ""} ${
                      item?.lastname ?? ""
                    }`
                  : item?.name ?? "",
              city: item.city,
              state: item.state.name,
              address1: item.address1,
              address2: item.address2,
              postal_code: item.postal_code,
              country: item.country.name,
              id: item.id,
            };

            return (
              <>
                <AddressCard
                  {...addressInfo}
                  key={item.id}
                  setSelectedAddress={
                    setSelectedBillingAddressId
                  }
                  selectedId={
                    selectedBillingAddressId
                  }
                  onSelectAddress={() => {
                    setIsNewBilling(false);
                    setIsNewBillingForm(false);
                  }}
                />
              </>
            );
          })}
        </div>
        <div className="text-start mt-4">
          <Button
            variant="primary"
            // className="px-4 py-2"
            className="btn btn-theme btn-white-outline btn-white-outline-hover text-dark1 border-dark1 px-4"
            style={{
              color: "white",
            }}
            disabled={
              !selectedBillingAddressId &&
              !isSameShipping
            }
            onClick={onClickHandler}
          >
            Save & Continue
          </Button>
        </div>
      </div>
    </>
  )
}

export default ListofAddressCard