import React from "react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";

const WeddingEngageCatSlider = ({ Products }) => {
  const buttonStyles = {
    backgroundColor: "#e77e50",
    padding: "10px",
    borderRadius: "50%",
    zIndex: 1,
    // fontSize: '0PX',
    color: "#333",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          ...buttonStyles,
          left: window.screen.width < 500 ? "-8px" : "-30px",
          top: "48%",
        }}
        onClick={onClick}
      >
        {/* &lsaquo; */}
      </div>
    );
  };

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          ...buttonStyles,
          right: window.screen.width < 500 ? "-8px" : "-30px",
          top: "48%",
        }}
        onClick={onClick}
      >
        {/* &rsaquo; */}
      </div>
    );
  };
  const settings = {
    infinite: true,
    dots: false,
    speed: 500,
    slidesToShow: 5,
    arrow: false,
    swipe: true,
    slidesToScroll: 1,
    // centerMode: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5.5,
          slidesToScroll: 1,
          // infinite: true,
          // dots: false,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          // initialSlide: ,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          // initialSlide: ,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {Products?.map((singleProduct) => {
        return (
          <div key={singleProduct?.id} className="text-center">
            <div className="Rings_hover  mb-2">
              <Link to={singleProduct?.link}>
                <Image
                  src={singleProduct?.image}
                  alt={singleProduct?.name}
                  fluid
                  // style={{
                  //   aspectRatio: "9/12",
                  // }}
                  // style={{
                  //   maxHeight: "100px",
                  // }}
                  className="Rings_img_hover"
                />
              </Link>
            </div>
          </div>
        );
      })}
    </Slider>
  );
};

export default WeddingEngageCatSlider;
