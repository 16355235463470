import React from "react";

function ConfictFresSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50.156"
      height="52.936"
      viewBox="0 0 50.156 43.497"
    >
      <path
        id="Path_32"
        data-name="Path 32"
        d="M50.155,11.266c-.909,1.164-1.83,2.319-2.726,3.493Q36.657,28.87,25.891,42.988c-.512.671-1.092.679-1.6.016Q12.439,27.472.588,11.938C.45,11.756.3,11.58.176,11.391a.827.827,0,0,1,.047-1.077Q4.359,5.351,8.494.389A1.216,1.216,0,0,1,9.544,0q13.265.007,26.53,0c1.566,0,3.133-.023,4.7.019a1.376,1.376,0,0,1,.919.414c2.769,3.277,5.51,6.577,8.257,9.873.072.087.138.18.207.27Zm-33.938.5,8.815,27.907.121-.024,8.8-27.883ZM2.591,11.752,22.667,38.06a.34.34,0,0,0,.012-.266q-4.05-12.839-8.094-25.681c-.1-.326-.274-.366-.566-.366q-5.288.012-10.575.005H2.591m44.991,0h-.548c-3.607,0-7.213.009-10.82-.012-.411,0-.547.136-.663.5Q31.571,24.9,27.566,37.549c-.049.155-.087.315-.13.472l.077.029,20.068-26.3M32.872,9.984,25.116,2.229l-7.76,7.755Zm-17.741-.64,7.6-7.6H10.7l4.433,7.6M27.284,1.725l7.708,7.706,4.5-7.706Zm-18.194.58L2.7,9.973H13.562L9.089,2.306m38.4,7.686L41.083,2.307,36.6,9.991Z"
        transform="translate(0 0)"
        fill="#253243"
      ></path>
    </svg>
  );
}

export default ConfictFresSvg;
