import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getPlacedOrdersUrl, placeOrderUrl } from "../../constants/urls";
const token = localStorage.getItem("token");

const initialState = {
  order: [],
  isLoading: false,
  error: null,
};

export const fetchOrderPlaced = createAsyncThunk(
  "content/fetchOrderPlaced",
  async (order) => {
    try {
      const res = await axios.post(placeOrderUrl, order, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return res.data;
    } catch (error) {
      console.error("Error fetch placed order: ", error);
    }
  }
);

export const getPlacedOrders = createAsyncThunk(
  "order/getPlacedOrders",
  async () => {
    try {
      const res = await axios.get(getPlacedOrdersUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res.data.data;
    } catch (error) {
      console.error("Error my orders: ", error);
      throw error;
    }
  }
);

const orderSlice = createSlice({
  name: "orderPlaced",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOrderPlaced.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchOrderPlaced.fulfilled, (state, action) => {
      state.isLoading = false;
      state.order = action.payload;
    });

    builder.addCase(fetchOrderPlaced.rejected, (state, action) => {
      state.error = action.payload;
    });
    builder.addCase(getPlacedOrders.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getPlacedOrders.fulfilled, (state, aciton) => {
      state.isLoading = false;
      state.order = aciton.payload;
    });

    builder.addCase(getPlacedOrders.rejected, (state, action) => {
      state.error = action.error;
    });
  },
});

export default orderSlice.reducer;
