import React from "react";
import { imageURL2 } from "../../images";
import GlobalTitle from "../common/global-title";

const RingFit = () => {
  return (
    <div className="container mt-4">
      <GlobalTitle
        title="How Should a Ring Fit?"
        description="A ring that fits appropriately will slide over your knuckle with
            minimal friction and sit snugly on your finger without feeling too
            tight. You should feel slight resistance but not need to apply too
            much force to take it on and off."
      />
      <GlobalTitle
        title="What to Do If You're in Between Ring Sizes"
        description="If you're in between ring sizes, we suggest sizing up rather than
            sizing down. Finger size can fluctuate throughout the day, and a
            ring that is too tight may not come over the knuckle easily — which
            can be painful. Additionally, there are simple, at-home ways to make
            a slightly loose ring feel tighter, but you'll need to consult a
            professional to size it up. Remember, if a half size doesn't feel
            quite right, contact us and we can craft a design in a quarter size
            to help you find the perfect fit."
      />
      {/* <h2 className="text-center my-7">How Should a Ring Fit?</h2>
      <div className="row justify-content-center mt-2">
        <div className="col-lg-8 text-dark-gray">
          <p>
            A ring that fits appropriately will slide over your knuckle with
            minimal friction and sit snugly on your finger without feeling too
            tight. You should feel slight resistance but not need to apply too
            much force to take it on and off.
          </p>
        </div>
      </div> */}

      {/* <h2 className="text-center mt-4">
        What to Do If You're in Between Ring Sizes
      </h2>
      <div className="row justify-content-center mt-2">
        <div className="col-lg-8 text-dark-gray">
          <p>
            If you're in between ring sizes, we suggest sizing up rather than
            sizing down. Finger size can fluctuate throughout the day, and a
            ring that is too tight may not come over the knuckle easily — which
            can be painful. Additionally, there are simple, at-home ways to make
            a slightly loose ring feel tighter, but you'll need to consult a
            professional to size it up. Remember, if a half size doesn't feel
            quite right, contact us and we can craft a design in a quarter size
            to help you find the perfect fit.
          </p>
        </div>
      </div> */}

      <div className="my-4">
        <GlobalTitle title="Ring Size Tips" />
        {/* <h2 className="text-center mb-3 ">Ring Size Tips</h2> */}
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="ratio ratio-16x9">
              <img
                src={imageURL2?.image_139}
                alt="Measure Ring Size Tips"
                className=" position-absolute  h-100 w-100 object-fit-cover bg-transparent "
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="text-dark-gray">
              <ul>
                <li>
                  Our dominant hands are generally slightly larger - measure the
                  hand you plan to wear the ring on.
                </li>
              </ul>
              <ul>
                <li>
                  Consider the design of your ring. Rings with wider bands will
                  fit tighter. Plan to size up by at least a half size for
                  chunky bands (&gt;4mm) and size down accordingly for very thin
                  designs (&lt;1.3mm). Note that ring sizes are generally
                  measured in millimeters (mm) and are not sized by inch.
                </li>
              </ul>
              <ul>
                <li>
                  Ensure your body temperature is normal - fingers tend to
                  shrink when cold and expand when hot.
                </li>
              </ul>
              <ul>
                <li>
                  Fingers change size throughout the day based on the water we
                  drink and the food we eat - the best time to measure is
                  towards the middle or end of the day.
                </li>
              </ul>
              <ul>
                <li>
                  If your knuckle is pronounced, you may want to measure both
                  the knuckle and the base of the finger. Then, choose a size in
                  between those measurements as rings need to fit over the
                  knuckle but not be too loose or tight to wear comfortably.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RingFit;
