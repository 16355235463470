import React from "react";
import { Accordion, Card, Container } from "react-bootstrap";
import { CustomToggle } from "../pages/OurPolicy";
import GlobalTitle from "../common/global-title";

const EngagementFAQ = ({ faqs = [], isHeaderShow = true }) => {
  const Data = [
    {
      "@type": "Question",
      name: "When should the wedding rings be purchased?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "It is recommended that wedding rings be purchased 3 to 4 months prior to the wedding.",
      },
    },
    {
      "@type": "Question",
      name: "Who buys the wedding rings?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Traditionally, each partner buys the wedding ring for the other person. Many couples also prefer to choose and buy wedding rings together.",
      },
    },
    {
      "@type": "Question",
      name: "What metal should a wedding ring be?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The wedding ring should be any metal you choose! Gold is the most common metal and provides the most options for any style or budget due to its availability in yellow, white, or rose. Platinum, a luxurious, rare, and durable metal, is also becoming increasingly popular.",
      },
    },
    {
      "@type": "Question",
      name: "Where do you wear a wedding ring?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Wedding rings are most commonly worn on the same finger as an engagement ring, which is frequently the ring finger on the left hand.However, this practice is different for cultures and countries.",
      },
    },
    {
      "@type": "Question",
      name: "How much do wedding rings cost?",
      acceptedAnswer: {
        "@type": "Answer",
        text: " There is no precise amount a wedding ring should cost. Prices will vary depending on the amount and sizes of diamonds and gemstones, as well as the metal choice. Luckily, Andor Luxury carries wedding rings to fit every budget.",
      },
    },
    {
      "@type": "Question",
      name: "Do wedding rings need to match?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "In a word: no! Wedding rings can match each other, can complement the engagement ring, and/or can reflect the wearer’s individual style.",
      },
    },
    {
      "@type": "Question",
      name: "What is the difference between a wedding ring and an eternity ring?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Wedding rings traditionally feature diamonds halfway around the band or are plain metal only, while eternity rings feature diamonds that go all the way around the band.",
      },
    },
    {
      "@type": "Question",
      name: "When do you buy an eternity ring?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "An eternity ring is typically given on an anniversary or a special occasion, including the birth of a child. Many choose to use an eternity ring as a wedding ring.",
      },
    },
    {
      "@type": "Question",
      name: "How much does an eternity ring cost?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Just like wedding rings, there is no precise amount an eternity ring should cost, as the price is affected by the number of diamonds and gemstones, as well as the metal choice. Also like wedding rings, Andor Luxury carries a variety of eternity rings to fit your budget.",
      },
    },
    {
      "@type": "Question",
      name: "How do you figure out the ring size?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "It is easier than you think to figure out the ring size. Andor Luxury provides multiple options including a virtual ring sizer. Click here for more information.",
      },
    },
    {
      "@type": "Question",
      name: "What do the 4Cs mean?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The 4Cs stand for carat, cut, color, and clarity. The 4Cs are the worldwide standard accepted by the Gemological Institute of America (GIA),to determine a diamond’s quality.",
      },
    },
  ];

  return (
    <Container className="my-6">
      {isHeaderShow ? (
        <GlobalTitle title="FREQUENTLY ASKED QUESTIONS" />
      ) : // <h2 className="text-center my-7">FREQUENTLY ASKED QUESTIONS</h2>
      null}

      <Accordion defaultActiveKey="0">
        {faqs?.map((item, index) => {
          return (
            <Card style={{ border: "none" }} key={index}>
              <Card.Header
                style={{
                  backgroundColor: "white",
                  borderBottom: "1px solid #ccc",
                }}
              >
                <CustomToggle eventKey={index} handleClick={() => {}}>
                  <h4 style={{ color: "black", margin: "0" }}>
                    {item?.question}
                  </h4>
                </CustomToggle>
              </Card.Header>
              <Accordion.Collapse eventKey={index}>
                <Card.Body
                  style={{
                    color: "#333",
                    fontSize: "17px",
                  }}
                >
                  {item?.answer}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          );
        })}
      </Accordion>
    </Container>
  );
};

export default EngagementFAQ;
