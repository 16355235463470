import moment from "moment";
import React from "react";
import { imageURL } from "../../../images";

const ReviewTab = ({ reviewList }) => {
  return (
    <div className="review-section">
      {reviewList.length == 0 ? (
        <div
          style={{
            textAlign: "center",
            padding: "75px",
            fontSize: "20px",
            fontWeight: "500",
          }}
        >
          Currently, there are no review fot this product.
        </div>
      ) : (
        <>
          {reviewList.map((review, index) => {
            return (
              <div className="product-review" key={index}>
                <img
                  src={imageURL.client1}
                  className="img-fluid"
                  alt="Images"
                />
                <div>
                  <div className="ratting mb-2">
                    {[1, 2, 3, 4, 5].map((item) => {
                      if (review?.star == 0) {
                        return <i className="fa-solid fa-star"></i>;
                      } else if (item <= review?.star) {
                        return (
                          <i className="fa-solid fa-star text-primary"></i>
                        );
                      } else {
                        return <i className="fa-solid fa-star"></i>;
                      }
                    })}
                  </div>
                  <h6 className="mb-3">
                    <strong>
                      {review?.first_name} {review?.last_name}
                    </strong>{" "}
                    <span className="text-muted">
                      {moment(review?.created_at).format("LL")}
                    </span>
                  </h6>
                  <p className="mb-0">{review?.review}</p>
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default ReviewTab;
