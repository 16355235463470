import axios from "axios";
import { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import "./App.scss";
import Custom404 from "./component/404";
import About from "./component/About";
import Account from "./component/Account";
import Blog from "./component/blog";
import BlogDetail from "./component/blog/Detail";
import BuyRingSize from "./component/BuyRingSize";
import Category from "./component/Category";
import EngagementCollection from "./component/Collections/EngagementCollection";
import JewelryCollection from "./component/Collections/JewelryCollection";
import WeddingCollection from "./component/Collections/WeddingCollection";
import ComingSoon from "./component/Coming-Soon";
import Contact from "./component/Contact";
import RequestForm from "./component/contact/request-form";
import ForgotPassword from "./component/element/ForgotPassword";
import LoginPage from "./component/element/LoginPage";
import SignUp from "./component/element/SignUp";
import Engagement from "./component/Engagement";
import FAQ from "./component/FAQ";
import Home from "./component/Home";
import PerfactRing from "./component/jewelryGuide/perfectRing";
import RightMetal from "./component/jewelryGuide/rightMetal";
import RingSize from "./component/jewelryGuide/ringSize";
import RingStyle from "./component/jewelryGuide/RingStyle";
import OrderPlaced from "./component/OrderPlaced";
import OurPolicy from "./component/pages/OurPolicy";
import Privacy from "./component/pages/Privacy";
import EditProfile from "./component/pages/Profile/EditProfile/EditProfile";
import ProfilePage from "./component/pages/Profile/Profile";
import Refund from "./component/pages/Refund";
import Shipping from "./component/pages/Shipping";
import Terms from "./component/pages/Terms";
import Cart from "./component/product/Cart";
import Checkout from "./component/product/Checkout";
import Detail from "./component/product/Detail";
import ProductList from "./component/product/Index";
import SearchResult from "./component/product/SearchResult";
import WhishlistDetailsPage from "./component/product/WhishlistDetailsPage";
import WhislistPage from "./component/product/WhislistPage";
import Wedding from "./component/wedding";
import { changeCartCurrency, isLogin } from "./constants/function";
import { UpdateRegion } from "./constants/urls";
import {
  addToCart,
  addToFavourite,
  getCartDetails,
  getFavouriteList,
} from "./features/cart/cartSlice";
import { getPageText } from "./features/text/getTextSlice";
import { getUserdetails } from "./features/user/getUserDetailsSlice";
import { getCountryPrice } from "./helper/constant";
// import { getCountryPrice } from "./helper/constant";
// import { response } from "express";

function App() {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (!!localStorage.getItem("token")) {
      dispatch(getUserdetails());
    }
  }, [localStorage.getItem("token")]);

  useEffect(() => {
  window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);

  useEffect(() => {
    getCountryPrice().then(async (resp) => {
      localStorage.setItem("currency", resp);
      if (isLogin()) {
        const currency =
          resp === "INR" ? 0 : resp === "USD" ? 1 : resp === "GBP" ? 2 : 1;
        const resPosnseofUpdate = await axios.get(
          `${UpdateRegion}/${currency}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        // console.log(resPosnseofUpdate.data.success,"locastion")
        if (resPosnseofUpdate?.data?.success) {
          // window.location.reload()
        }
      } else {
        changeCartCurrency(resp);
      }
    });
  }, []);

  useEffect(() => {
    dispatch(
      getPageText(
        location.pathname == "/"
          ? `${process.env.REACT_APP_WEB_URL}${location.pathname}`
          : `${process.env.REACT_APP_WEB_URL}${location.pathname}/`
      )
    );
  }, [location.pathname]);

  useEffect(() => {
    if (!localStorage.getItem("token")) return;
    async function getBulkData() {
      const cart = JSON.parse(localStorage.getItem("cart")) || [];
      const wishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
      if (cart?.length <= 0 || wishlist?.length <= 0) return;
      const wishlistPayload = wishlist?.map((item) => {
        return item?.id;
      });

      const cartData = cart?.map((item) => {
        return {
          ...item,
          id: item?.id,
          qty: item?.qty,
          size: +item?.product?.Productsize,
        };
      });
      await dispatch(addToCart(cartData));
      await dispatch(addToFavourite(wishlistPayload));
      await dispatch(getCartDetails());
      await dispatch(getFavouriteList());
      // Clear localStorage
      localStorage.removeItem("cart");
      localStorage.removeItem("wishlist");
    }
    getBulkData();
  }, []);

  const ProtectedRoute = ({ children }) => {
    if (!localStorage.getItem("token")) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return <Navigate to="/login" replace />;
    }

    return children ? children : <Outlet />;
  };

  return (
    <div className="App">
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/login" exact element={<LoginPage />} />
        <Route path="/forgot-password" exact element={<ForgotPassword />} />

        <Route path="/order-placed" exact element={<OrderPlaced />} />

        <Route path="/jewelry" exact element={<Category />} />
        <Route path="/engagement" exact element={<Engagement />} />
        <Route path="/wedding" exact element={<Wedding />} />
        <Route path="/account" exact element={<Account />} />
        {/* <Route path='/category' exact element={<Category />} /> */}
        <Route path="/about" exact element={<About />} />
        {/* <Route path="/product-list" exact element={<ProductList />} /> */}
        <Route path="/contact" exact element={<Contact />} />
        <Route path="/contact/:contact-form" exact element={<RequestForm />} />

        {/* <Route path="/detail" exact element={<Detail />} /> */}
        <Route path="/terms-and-conditions" exact element={<Terms />} />
        <Route path="/faq" exact element={<FAQ />} />

        <Route path="/cart" exact element={<Cart />} />
        <Route path="/checkout" exact element={<Checkout />} />
        <Route path="/blog" exact element={<Blog />} />
        <Route path="/blog-detail" exact element={<BlogDetail />} />
        <Route path="/signup" exact element={<SignUp />} />

        <Route path="/engagement-ring-style" exact element={<RingStyle />} />
        <Route
          path="/find-perfect-engagement-ring"
          exact
          element={<PerfactRing />}
        />
        <Route path="/find-right-metal" exact element={<RightMetal />} />
        <Route path="/find-ring-size" exact element={<RingSize />} />
        <Route
          path="/buying-guide/ring-size/"
          exact
          element={<BuyRingSize />}
        />

        {/* <Route path="/test" exact element={<CheckOutCopy />} /> */}
        <Route path="/privacy-policy" exact element={<Privacy />} />
        <Route path="/return" exact element={<Refund />} />
        <Route path="/shipping-policy" exact element={<Shipping />} />

        <Route
          path="/engagement-collection"
          exact
          element={<EngagementCollection />}
        />
        <Route
          path="/wedding-collection"
          exact
          element={<WeddingCollection />}
        />
        <Route
          path="/jewelry-collection"
          exact
          element={<JewelryCollection />}
        />
        <Route path="/search-result-page" exact element={<SearchResult />} />
        <Route path="/our-policy" exact element={<OurPolicy />} />

        <Route path="/:title/:sku" exact element={<Detail />} />
        <Route path="/:category" exact element={<ProductList />} />
        <Route path="/wishlist" exact element={<WhislistPage />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/profile" exact element={<ProfilePage />} />
          <Route path="/edit-profile" exact element={<EditProfile />} />

          <Route
            path="/wishlist/:id"
            exact
            element={<WhishlistDetailsPage />}
          />
        </Route>
        <Route path="/404" exact element={<Custom404 />} />
        <Route path="/coming-soon" exact element={<ComingSoon />} />
      </Routes>

      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        toastOptions={{
          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
    </div>
  );
}

export default App;
