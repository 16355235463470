import React from "react";
import { Container } from "react-bootstrap";
import { imageURL } from "../../images";
import Footer from "../element/Footer";

const BlogDetail = () => {
  return (
    <>
      <Container className="page-title text-start">
        <h5>Home - Contact us</h5>
      </Container>

      <Container className="blog-detail text-center">
        <img
          src={imageURL.catBracelet}
          className="img-fluid main-blog-img"
          alt="main"
        />
        <p className="text-center">By Admin on December 28, 2022</p>
        <h2 className="blog-heading">
          Lorem ipsum is placeholder text <span></span>
        </h2>
        <h4 className="fst-italic mb-lg-5 mb-4">
          Our goal has always been to motivate, encourage and release our fellow
          creatives to do their thing
        </h4>
        <p className="text-center">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Aenean
          euismod elementum nisi quis eleifend quam adipiscing vitae proin.
          Aliquam malesuada bibendum arcu vitae elementum curabitur vitae nunc.
          Semper viverra nam libero justo laoreet sit amet cursus sit. Ut sem
          viverra aliquet eget sit amet tellus. Imperdiet massa tincidunt nunc
          pulvinar sapien. Nulla facilisi morbi tempus iaculis urna id. Orci
          dapibus ultrices in iaculis nunc sed augue lacus viverra. Nullam
          vehicula ipsum a arcu cursus vitae. Convallis convallis tellus id
          interdum.
        </p>
        <p className="text-center">
          Enim ut tellus elementum sagittis vitae et. Viverra mauris in aliquam
          sem fringilla ut morbi tincidunt augue. A pellentesque sit amet
          porttitor eget dolor. Velit ut tortor pretium viverra. Lacus vel
          facilisis volutpat est velit egestas dui. Dui ut ornare lectus sit
          amet est placerat in. Amet risus nullam eget felis eget nunc lobortis.
          Enim nec dui nunc mattis. Vel elit scelerisque mauris pellentesque
          pulvinar pellentesque. Integer enim neque volutpat ac tincidunt vitae
          semper quis. Senectus et netus et malesuada fames ac turpis egestas.
          Eget magna fermentum iaculis eu non diam. Purus in mollis nunc sed id
          semper. Porttitor rhoncus dolor purus non. Magna fermentum iaculis eu
          non diam. Felis bibendum ut tristique et. Lectus urna duis convallis
          convallis tellus. In eu mi bibendum neque egestas congue quisque
          egestas. Nibh mauris cursus mattis molestie a iaculis at. Lobortis
          scelerisque fermentum dui faucibus in ornare quam viverra.
        </p>
        <p className="text-center">
          Tellus orci ac auctor augue mauris augue. Nulla aliquet enim tortor at
          auctor urna nunc id cursus. Sit amet mauris commodo quis imperdiet
          massa tincidunt. Cras adipiscing enim eu turpis egestas pretium
          aenean. Hendrerit gravida rutrum quisque non tellus orci ac auctor.
          Sed felis eget velit aliquet sagittis id consectetur purus ut. Nisl
          vel pretium lectus quam id leo. At volutpat diam ut venenatis tellus.
          Cursus sit amet dictum sit amet justo donec enim diam. Vulputate mi
          sit amet mauris. Donec pretium vulputate sapien nec. Sed sed risus
          pretium quam vulputate. Eget nunc lobortis mattis aliquam faucibus
          purus in. Pretium fusce id velit ut tortor. Habitant morbi tristique
          senectus et netus et malesuada fames ac. Vel quam elementum pulvinar
          etiam.
        </p>
        <p className="text-center">
          Pellentesque sit amet porttitor eget. Pharetra convallis posuere morbi
          leo. Tincidunt ornare massa eget egestas purus viverra accumsan in.
          Lacus suspendisse faucibus interdum posuere lorem ipsum dolor sit.
          Pharetra sit amet aliquam id. Sit amet mattis vulputate enim nulla
          aliquet. Eget mauris pharetra et ultrices. Augue ut lectus arcu
          bibendum at varius vel pharetra. Bibendum est ultricies integer quis
          auctor. Amet nisl purus in mollis. Tincidunt lobortis feugiat vivamus
          at augue eget. Neque aliquam vestibulum morbi blandit cursus risus.
          Tincidunt vitae semper quis lectus nulla. Nibh mauris cursus mattis
          molestie a iaculis at. Cras ornare arcu dui vivamus arcu felis.
        </p>
        <p className="text-center">
          Ultrices eros in cursus turpis massa tincidunt. Velit ut tortor
          pretium viverra suspendisse potenti. Mauris pharetra et ultrices
          neque. Orci a scelerisque purus semper eget duis at tellus at. Ut
          consequat semper viverra nam libero justo laoreet sit amet. Accumsan
          sit amet nulla facilisi morbi tempus iaculis urna. Ut aliquam purus
          sit amet luctus venenatis. Venenatis cras sed felis eget velit
          aliquet. Posuere sollicitudin aliquam ultrices sagittis orci a
          scelerisque purus semper. Blandit volutpat maecenas volutpat blandit
          aliquam etiam erat velit scelerisque. Vitae nunc sed velit dignissim
          sodales ut. Cras adipiscing enim eu turpis egestas pretium aenean
          pharetra magna. Nisl condimentum id venenatis a condimentum vitae.
          Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur
          vitae nunc. Phasellus faucibus scelerisque eleifend donec pretium
          vulputate sapien. Feugiat in ante metus dictum at tempor commodo
          ullamcorper a. Tristique et egestas quis ipsum suspendisse ultrices.
        </p>
        <p className="text-center">
          Ut etiam sit amet nisl purus in mollis nunc. Scelerisque fermentum dui
          faucibus in ornare quam viverra orci sagittis. Consectetur a erat nam
          at lectus urna duis. Urna cursus eget nunc scelerisque viverra mauris
          in aliquam. Diam donec adipiscing tristique risus nec feugiat in.
          Proin fermentum leo vel orci porta non pulvinar neque laoreet. Pretium
          aenean pharetra magna ac placerat vestibulum lectus mauris ultrices.
          Sed odio morbi quis commodo odio aenean sed adipiscing. Velit sed
          ullamcorper morbi tincidunt ornare. Vitae proin sagittis nisl rhoncus.
          Arcu non sodales neque sodales ut etiam sit amet. Blandit libero
          volutpat sed cras ornare arcu. Vestibulum rhoncus est pellentesque
          elit ullamcorper dignissim cras tincidunt lobortis. In ornare quam
          viverra orci sagittis eu volutpat odio facilisis. Pellentesque
          pulvinar pellentesque habitant morbi tristique senectus et.
        </p>
      </Container>
      <Footer />
    </>
  );
};

export default BlogDetail;
