import classNames from "classnames";
import { ErrorMessage, Form, Formik, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { MdModeEdit } from "react-icons/md";
import { RiDeleteBin7Fill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  addNewAddress,
  deleteAddress,
  getCountry,
  getState,
  getUserAddresses,
  resetAddNewAddress,
  resetDeleteAddress,
  resetState,
  resetUpdateAddress,
  updateAddress,
} from "../../../../features/address/addressSlice";
import { userAddressListSchema } from "../../../../Validation/Schema/UserDetailsSchema";
import DeleteConfirmationModal from "../../../Popup/DeleteConfirmationModal";
import { cartShippingFields } from "../../../../constants/utils";
import GlobalForm from "../../../form/globalForm";

function AddressPage({ activeKey }) {
  const [isFirstTime, setIsFirstTime] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const countryCurrency = localStorage.getItem("currency");

  const successToast = (message) => {
    toast.dismiss();
    toast.success(message);
  };

  const initialValue = {
    firstname: "",
    lastname: "",
    name: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    postal_code: "",
    country: "",
    country_id: "",
    state_id: "",
  };

  const [state, setState] = useState({});

  const userAddress = useSelector((state) => state?.address?.userAddresses);
  const countryList = useSelector((state) => state?.address?.country);
  const stateList = useSelector((state) => state?.address?.states);

  useEffect(() => {
    setState({
      ...initialValue,
      country:
        countryCurrency == "INR"
          ? "101"
          : countryCurrency == "USD"
          ? "231"
          : countryCurrency == "GBP"
          ? "230"
          : "101",
      country_id:
        countryCurrency == "INR"
          ? "101"
          : countryCurrency == "USD"
          ? "231"
          : countryCurrency == "GBP"
          ? "230"
          : "101",
    });
  }, [countryList]);

  const updateAddressReducer = useSelector(
    (state) => state?.address?.updateAddress
  );

  useEffect(() => {
    if (updateAddressReducer.success) {
      setIsEdit(false);
      setEditId(null);
      successToast("Address Updated!!");
      dispatch(getUserAddresses());
      dispatch(resetUpdateAddress());
    }
  }, [updateAddressReducer]);

  const addNewAddressReducer = useSelector(
    (state) => state?.address?.addAddress
  );

  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState(null);

  const [isNew, setIsNew] = useState(false);

  useEffect(() => {
    if (activeKey == "address" && isFirstTime) {
      dispatch(getUserAddresses());
      dispatch(getCountry(countryCurrency));
      setIsFirstTime(false);
    }
  }, [activeKey]);

  useEffect(() => {
    if (!!addNewAddressReducer?.success) {
      successToast("Address Add Successfully");
      dispatch(getUserAddresses());
      setState(initialValue);
      setIsEdit(false);
      setEditId(null);
      dispatch(resetAddNewAddress());
    }
  }, [addNewAddressReducer]);

  const [listOfAddress, setListOdAddress] = useState([]);

  useEffect(() => {
    setListOdAddress(userAddress);
  }, [userAddress]);

  const handleEditClick = (id = "new") => {
    setEditId(id);
    setIsEdit(true);
    dispatch(resetState());

    if (id !== "new") {
      const selected = listOfAddress.find((item) => item.id === id);
      setState({
        firstname: selected?.firstname,
        lastname: selected?.lastname,
        name: selected?.name,
        address1: selected?.address1,
        address2: selected?.address2,
        city: selected?.city,
        state_id: selected?.state?.id,
        country_id: selected?.country?.id,
        state: selected?.state?.id,
        postal_code: selected?.postal_code,
        country: selected?.country?.id,
      });
      setIsNew(false);
    } else {
      setState(initialValue);
      setIsNew(true);
    }
  };

  const [deleteId, setDeleteId] = useState(null);
  const [isDelete, setIsDelete] = useState(false);
  const handleDelete = () => {
    dispatch(deleteAddress(deleteId));
  };

  const deleteAddressReducer = useSelector(
    (state) => state?.address?.deleteAddress
  );

  useEffect(() => {
    if (deleteAddressReducer?.success) {
      setIsDelete(false);
      setDeleteId(null);
      successToast("Address Deleted!!");
      dispatch(getUserAddresses());
      dispatch(resetDeleteAddress());
    }
  }, [deleteAddressReducer]);
  const handleFormSubmit = (e) => {
    console.log("kdhnfdknfhkd");
    const details = {
      firstname: e?.firstname,
      lastname: e?.lastname,
      name: e?.name,
      address1: e?.address1,
      address2: e?.address2,
      city: e?.city,
      // country: e?.country,
      state_id: e?.state_id,
      country_id: e?.country_id,
      postal_code: e?.postal_code,
      address_type: 0, // keep static 0
    };
    console.log("first");
    if (!!isNew) {
      dispatch(addNewAddress(details));
    } else {
      dispatch(
        updateAddress({
          details: details,
          id: editId,
        })
      );
    }
  };
  return (
    <>
      {!isEdit && !editId ? (
        <div className="mt-4">
          <h3>List of Address</h3>
          <div className="address-grid">
            <Row xs={1} sm={2} md={3} lg={4} xl={5} className="g-3">
              <Col>
                <Card className="address-grid-card">
                  <Card.Body
                    style={{
                      minHeight: "165px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      fontWeight: 600,
                    }}
                    onClick={() => handleEditClick()}
                  >
                    Add new Address
                  </Card.Body>
                </Card>
              </Col>
              {listOfAddress.map((address, index) => {
                return (
                  <Col key={index}>
                    <Card className="address-grid-card">
                      <Card.Body
                        style={{
                          minHeight: "165px",
                        }}
                      >
                        {/* {JSON.stringify(address)} */}
                        <Card.Title>
                          {address.firstname} {address.lastname}
                        </Card.Title>
                        <Card.Text className="mb-1">
                          {address?.address1}
                        </Card.Text>
                        <Card.Text className="mb-1">
                          {address?.address2}
                        </Card.Text>
                        <Card.Text className="mb-1">
                          {address?.city}, {address?.state?.name},{" "}
                          {address?.country?.name} {address?.postal_code}
                        </Card.Text>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "10px",
                            marginTop: "10px",
                          }}
                        >
                          <MdModeEdit
                            className="edit-icon"
                            onClick={() => handleEditClick(address?.id)}
                          />
                          <RiDeleteBin7Fill
                            className="delete-icon"
                            onClick={() => {
                              setIsDelete(true);
                              setDeleteId(address?.id);
                            }}
                          />
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </div>

          <div className="mt-1">
            <Row>
              <Col className="text-start">
                <div
                  variant="link"
                  className="text-decoration-none fs-5"
                  style={{ cursor: "pointer", color: "#e77e50" }}
                  onClick={() => {
                    navigate("/profile");
                  }}
                >
                  &lt; Back
                </div>
              </Col>
            </Row>
          </div>
        </div>
      ) : (
        <div className="mt-4">
          <h3>Edit Address</h3>
          <div style={{ marginTop: "60px" }}>
            <GlobalForm
              initialValues={state}
              validationSchema={userAddressListSchema}
              dynamicOption={{
                state_id: stateList?.map((stat) => ({
                  ...stat,
                  label: stat.name,
                  value: stat.id,
                })),
                country_id: countryList?.map((stat) => ({
                  ...stat,
                  label: stat.name,
                  value: stat.id,
                })),
              }}
              onSubmit={async (values) => {
                // setState(values);
                console.log("on data submited", values);
                handleFormSubmit(values);
              }}
              fields={cartShippingFields}
              btnText="Save & Continue"
              btnSet="end"
              footerExtra={
                <Col className="text-start">
                  <div
                    variant="link"
                    className="text-decoration-none fs-5"
                    style={{ cursor: "pointer", color: "#e77e50" }}
                    onClick={() => {
                      setState(initialValue);
                      setIsEdit(false);
                      setEditId(null);
                    }}
                  >
                    &lt; Back
                  </div>
                </Col>
              }
            />
            {/* <Formik
              initialValues={state}
              enableReinitialize={true}
              validationSchema={userAddressListSchema}
              onSubmit={(values) => {
                console.log(values, "kdhfkjdh");
                handleFormSubmit(values);
              }}
            >
              {({ handleChange, errors, touched, values }) => {
                return (
                  <Form>
                    <Row className=" g-0 g-md-5">
                      <Col md={6} className="mb-3">
                        <label htmlFor="" className="form-label">
                          First Name *
                        </label>
                        <input
                          name="firstname"
                          className={classNames("form-control", {
                            "error-border":
                              errors.firstname && touched.firstname,
                          })}
                          onChange={handleChange}
                          placeholder="First Name"
                          value={values.firstname}
                        />
                        <ErrorMessage
                          name="firstname"
                          component="div"
                          className="text-danger"
                        />
                      </Col>
                      <Col md={6} className="mb-3">
                        <label htmlFor="" className="form-label">
                          Last Name *
                        </label>
                        <input
                          name="lastname"
                          className={classNames("form-control", {
                            "error-border": errors.lastname && touched.lastname,
                          })}
                          onChange={handleChange}
                          placeholder="Last Name"
                          value={values.lastname}
                        />
                        <ErrorMessage
                          name="lastname"
                          component="div"
                          className="text-danger"
                        />
                      </Col>
                    </Row>
                    <Row className=" g-0 g-md-5">
                      <Col md={12} className="mb-3">
                        <label htmlFor="" className="form-label">
                          Address 1 *
                        </label>
                        <input
                          name="addressLine1"
                          className={classNames("form-control", {
                            "error-border":
                              errors.addressLine1 && touched.addressLine1,
                          })}
                          onChange={handleChange}
                          placeholder="Address1"
                          value={values.addressLine1}
                        />
                        <ErrorMessage
                          name="addressLine1"
                          component="div"
                          className="text-danger"
                        />
                      </Col>
                    </Row>
                    <Row className=" g-0 g-md-5">
                      <Col md={12} className="mb-3">
                        <label htmlFor="" className="form-label">
                          Address 2
                        </label>
                        <input
                          name="addressLine2"
                          className={classNames("form-control", {
                            "error-border":
                              errors.addressLine2 && touched.addressLine2,
                          })}
                          onChange={handleChange}
                          placeholder="Address2"
                          value={values.addressLine2}
                        />
                        <ErrorMessage
                          name="addressLine2"
                          component="div"
                          className="text-danger"
                        />
                      </Col>
                    </Row>

                    <Row className=" g-0 g-md-5">
                      <Col md={6} className="mb-3">
                        <label htmlFor="" className="form-label">
                          City *
                        </label>
                        <input
                          name="city"
                          className={classNames("form-control", {
                            "error-border": errors.city && touched.city,
                          })}
                          onChange={handleChange}
                          placeholder="City"
                          value={values.city}
                        />
                        <ErrorMessage
                          name="city"
                          component="div"
                          className="text-danger"
                        />
                      </Col>
                      <Col md={6} className="mb-3">
                        <label htmlFor="" className="form-label">
                          State *
                        </label>
                        <select
                          value={values.state}
                          onChange={handleChange}
                          className={classNames("form-select", {
                            "error-border": errors.state && touched.state,
                          })}
                          name="state"
                        >
                          <option value={""} style={{ cursor: "pointer" }}>
                            State
                          </option>
                          {stateList?.map((state) => {
                            return (
                              <option
                                value={state?.id}
                                style={{ cursor: "pointer" }}
                                key={state?.id}
                              >
                                {state?.name}
                              </option>
                            );
                          })}
                        </select>

                        <ErrorMessage
                          name="state"
                          component="div"
                          className="text-danger"
                        />
                      </Col>
                    </Row>
                    <Row className=" g-0 g-md-5">
                      <Col md={6} className="mb-3">
                        <label htmlFor="" className="form-label">
                          Postal code *
                        </label>
                        <input
                          name="postal_code"
                          className={classNames("form-control", {
                            "error-border":
                              errors.postal_code && touched.postal_code,
                          })}
                          onChange={handleChange}
                          placeholder="Postal code"
                          value={values.postal_code}
                        />
                        <ErrorMessage
                          name="postal_code"
                          component="div"
                          className="text-danger"
                        />
                      </Col>
                      <Col md={6} className="mb-3">
                        <CustomCountrySelect
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                          countryList={countryList}
                          countryCurrency={countryCurrency}
                        />
                      </Col>
                    </Row>

                    <div className="mt-4">
                      <Row>
                        <Col className="text-start">
                          <div
                            variant="link"
                            className="text-decoration-none fs-5"
                            style={{ cursor: "pointer", color: "#e77e50" }}
                            onClick={() => {
                              setState(initialValue);
                              setIsEdit(false);
                              setEditId(null);
                            }}
                          >
                            &lt; Back
                          </div>
                        </Col>
                        <Col className="text-end">
                          <Button
                            variant="primary"
                            className="px-4 py-2"
                            type="submit"
                            style={{
                              color: "white",
                            }}
                            // disabled={isSubmitting}
                            onClick={handleFormSubmit(values)}
                          >
                            {isNew ? "Add new Address" : "Update"}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                );
              }}
            </Formik> */}
          </div>
        </div>
      )}
      <DeleteConfirmationModal
        show={isDelete}
        setShow={setIsDelete}
        setDeleteId={setDeleteId}
        handleDelete={handleDelete}
      />
    </>
  );
}

const CustomCountrySelect = ({ label, countryCurrency, ...props }) => {
  const { values, setFieldValue } = useFormikContext();
  const dispatch = useDispatch();
  useEffect(() => {
    if (values.country == "") {
      setFieldValue("state", "");
    } else {
      // console.log(values.country)
      dispatch(
        getState(
          props?.countryList?.find((el) => el?.id == values.country)?.code
        )
      );
    }
  }, [values.country]);

  return (
    <>
      <label htmlFor="" className="form-label">
        Country *
      </label>
      <select
        value={values.country}
        onChange={props?.handleChange}
        className={classNames("form-select", {
          "error-border": props?.errors.country && props?.touched.country,
        })}
        name="country"
      >
        <option value={""} style={{ cursor: "pointer" }}>
          Country
        </option>
        {props?.countryList?.map((country) => {
          return (
            <option
              value={country?.id}
              style={{ cursor: "pointer" }}
              key={country?.id}
            >
              {country?.name}
            </option>
          );
        })}
      </select>

      <ErrorMessage name="country" component="div" className="text-danger" />
    </>
  );
};

export default AddressPage;
