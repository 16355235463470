import React from "react";
import img1 from "../../assets/images/wedding/pairing.jpg";
import ImageWithDetailSec from "../common/image-with-detail-sec";

const RingParingArea = () => {
  return (
    <>
      <ImageWithDetailSec
        title="Perfect Pairings for Your Special Day"
        // title="Meet Your Match"
        description="Discover the ideal match for your engagement ring with our curated selection of wedding bands. Our experts have thoughtfully paired these timeless designs to complement some of our most-loved engagement styles."
        buttonText="SHOP NOW"
        buttonLink="/wedding-collection"
        imagePath={img1}
        imageAlt="Perfect Pairings for Your Special Day"
        textPosition="right"
        // subTitle="Perfect Pairings for Your Special Day"
      />
    </>
  );
};

export default RingParingArea;
