export const RingSizes = {
    "0": [
        {
            "label": "7",
            "value": "7"
        },
        {
            "label": "8",
            "value": "8"
        },
        {
            "label": "9",
            "value": "9"
        },
        {
            "label": "10",
            "value": "10"
        },
        {
            "label": "11",
            "value": "11"
        },
        {
            "label": "12",
            "value": "12"
        },
        {
            "label": "13",
            "value": "13"
        },
        {
            "label": "14",
            "value": "14"
        },
        {
            "label": "15",
            "value": "15"
        },
        {
            "label": "16",
            "value": "16"
        },
        {
            "label": "17",
            "value": "17"
        },
        {
            "label": "18",
            "value": "18"
        },
        {
            "label": "19",
            "value": "19"
        },
        {
            "label": "20",
            "value": "20"
        },
        {
            "label": "21",
            "value": "21"
        },
        {
            "label": "22",
            "value": "22"
        },
        {
            "label": "23",
            "value": "23"
        },
        {
            "label": "24",
            "value": "24"
        },
        {
            "label": "25",
            "value": "25"
        }
    ],
    "1": [
        {
            "label": "4",
            "value": "4"
        },
        {
            "label": "4.25",
            "value": "4.25"
        },
        {
            "label": "4.5",
            "value": "4.5"
        },
        {
            "label": "4.75",
            "value": "4.75"
        },
        {
            "label": "5",
            "value": "5"
        },
        {
            "label": "5.25",
            "value": "5.25"
        },
        {
            "label": "5.5",
            "value": "5.5"
        },
        {
            "label": "5.75",
            "value": "5.75"
        },
        {
            "label": "6",
            "value": "6"
        },
        {
            "label": "6.25",
            "value": "6.25"
        },
        {
            "label": "6.5",
            "value": "6.5"
        },
        {
            "label": "6.75",
            "value": "6.75"
        },
        {
            "label": "7",
            "value": "7"
        },
        {
            "label": "7.25",
            "value": "7.25"
        },
        {
            "label": "7.5",
            "value": "7.5"
        },
        {
            "label": "7.75",
            "value": "7.75"
        },
        {
            "label": "8",
            "value": "8"
        },
        {
            "label": "8.25",
            "value": "8.25"
        },
        {
            "label": "8.5",
            "value": "8.5"
        },
        {
            "label": "8.75",
            "value": "8.75"
        },
        {
            "label": "9",
            "value": "9"
        },
        {
            "label": "9.25",
            "value": "9.25"
        },
        {
            "label": "9.5",
            "value": "9.5"
        },
        {
            "label": "9.75",
            "value": "9.75"
        },
        {
            "label": "10",
            "value": "10"
        },
        {
            "label": "10.25",
            "value": "10.25"
        },
        {
            "label": "10.5",
            "value": "10.5"
        },
        {
            "label": "10.75",
            "value": "10.75"
        },
        {
            "label": "11",
            "value": "11"
        },
        {
            "label": "11.25",
            "value": "11.25"
        },
        {
            "label": "11.5",
            "value": "11.5"
        },
        {
            "label": "11.75",
            "value": "11.75"
        },
        {
            "label": "12",
            "value": "12"
        }
    ],
    "2": [
        {
            "label": "H",
            "value": "H"
        },
        {
            "label": "H 1/2",
            "value": "H 1/2"
        },
        {
            "label": "I",
            "value": "I"
        },
        {
            "label": "J",
            "value": "J"
        },
        {
            "label": "J 1/2",
            "value": "J 1/2"
        },
        {
            "label": "K",
            "value": "K"
        },
        {
            "label": "K 1/2",
            "value": "K 1/2"
        },
        {
            "label": "L",
            "value": "L"
        },
        {
            "label": "L 1/2",
            "value": "L 1/2"
        },
        {
            "label": "M",
            "value": "M"
        },
        {
            "label": "M 1/2",
            "value": "M 1/2"
        },
        {
            "label": "N",
            "value": "N"
        },
        {
            "label": "N 1/2",
            "value": "N 1/2"
        },
        {
            "label": "O",
            "value": "O"
        },
        {
            "label": "O 1/2",
            "value": "O 1/2"
        },
        {
            "label": "P",
            "value": "P"
        },
        {
            "label": "P 1/2",
            "value": "P 1/2"
        },
        {
            "label": "Q",
            "value": "Q"
        },
        {
            "label": "Q 1/2",
            "value": "Q 1/2"
        },
        {
            "label": "R",
            "value": "R"
        },
        {
            "label": "R 1/2",
            "value": "R 1/2"
        },
        {
            "label": "S",
            "value": "S"
        },
        {
            "label": "S 1/2",
            "value": "S 1/2"
        },
        {
            "label": "T",
            "value": "T"
        },
        {
            "label": "T 1/2",
            "value": "T 1/2"
        },
        {
            "label": "U",
            "value": "U"
        },
        {
            "label": "U 1/2",
            "value": "U 1/2"
        },
        {
            "label": "V",
            "value": "V"
        },
        {
            "label": "V 1/2",
            "value": "V 1/2"
        },
        {
            "label": "W",
            "value": "W"
        },
        {
            "label": "W 1/2",
            "value": "W 1/2"
        },
        {
            "label": "X",
            "value": "X"
        },
        {
            "label": "X 1/2",
            "value": "X 1/2"
        }
    ]
}