import React, { useEffect } from "react";
import Footer from "./element/Footer";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrderPlaced } from "../features/productsList/orderSlice";
import { Container } from "react-bootstrap";
import { getQueryParam } from "../constants/function";

const OrderPlaced = () => {
  const dispatch = useDispatch();
  const orderDetail = useSelector((state) => state.order.order);
  // console.log(orderDetail)
  useEffect(() => {
    const order_id = getQueryParam("order_id");
    const razorpay_payment_id = getQueryParam("razorpay_payment_id");
    const razorpay_payment_link_id = getQueryParam("razorpay_payment_link_id");
    const userInfo = {
      razorpay_payment_id,
      razorpay_payment_link_id,
      order_id,
    };
    dispatch(fetchOrderPlaced(userInfo));
  }, [dispatch]);
  return (
    <>
      <Container className="text-center mt-100">
        <h2 className="heading text-primary">Thank you</h2>
        <h5>{orderDetail?.message || "..."}</h5>
        {/* <h5>{orderDetail?.message || "Something went wrong Please Contact Our Support"}</h5> */}

        <button
          className="btn btn-theme btn-outline text-dark1 border-dark1"
          onClick={() =>
            (window.location.href = `${process.env.REACT_APP_WEB_URL}/`)
          }
        >
          Continue Shopping
        </button>
        <button
          className="btn btn-theme btn-outline text-dark1 border-dark1"
          onClick={() => (window.location.href = "/edit-profile?tab=orders")}
        >
          Order History
        </button>
      </Container>
      <Footer />
    </>
  );
};

export default OrderPlaced;
