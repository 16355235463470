import { Outlet } from "react-router";
import { ScrollRestoration } from "react-router-dom";
import Header from "../element/Header";
import App from "../../App";

export default function Root() {
  return (
    <>
      <Header />
      {/* <Header2 />  */}
      <App />
      {/* <ScrollRestoration /> */}


    </>
  );
}
