import React from 'react'
import { Link } from 'react-router-dom'
import HeartFill from '../../assets/svgs/heartFill'
import { isLogin } from '../../constants/function'

const WishlistIcon = ({
    isHeaderTextWhite = false,
    wishListData = [], onClick = () => { }, title = "", countStyle
}) => {
    const wishlistCount = wishListData?.length || 0;
    const heartFillColor = wishlistCount > 0 ? (isHeaderTextWhite ? "white" : "#e77e50") : "none";
    const heartStrokeColor = isHeaderTextWhite ? "white" : "#e77e50";

    return (
        <>
            <Link
                className={` p-0 btn-heart menu-slide-icon`}
                to={isLogin() ? "/edit-profile" : "/wishlist"}
                onClick={onClick}
                state={{
                    eventKey: "wishlist",
                }}
                style={{
                    position: "relative", display: "flex",
                    zIndex: "99999"
                }}
                data-toggle="tooltip"
                data-placement="bottom"
                title="Wishlist"
            >
                <span className="menu-icon">

                {wishlistCount > 0 && (
                    <div  className="wishlist-count menu-icon " >
                    {/* <div style={countStyle} className="wishlist-count menu-icon " > */}
                    <p className={isHeaderTextWhite ? "text-transparent" : "text-white"} style={{ fontSize: "9px",opacity:isHeaderTextWhite? "0.3" :1 ,top:"-20%",left:"-5%",position:"relative"}}>
                        {wishlistCount}
                    </p>
                </div>
            )}
              <HeartFill
                className="wishlist-heart"
                fillColor={heartFillColor}
                strokeColor={heartStrokeColor}
                />  
                </span>
            {/* <i
                    className={`${"fa-solid"} fa-heart ${isHeaderTextWhite ? "wishlist-icon-start" : "wishlist-icon"
                        } ${wishListData?.length > 0 ? "" : "fill-heart"}`}

                ></i>
                <i
                    className={`${"fa-regular"} fa-heart ${isHeaderTextWhite ? "wishlist-icon-start" : "wishlist-icon"
                        } ${wishListData?.length > 0 ? "d-none" : "border-heart"}`}
                ></i> */}
                {title}
            </Link></>
    )
}

export default WishlistIcon